// @flow

import React, { memo, useContext, useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import moment from 'moment';
import GenericLoader from '../../components/utils/GenericLoader';
import LoadingContext from '../../context/loader/LoadingContext';
import { momentFormat } from '../../utils/const';

import { FILES_ROUTE } from '../../utils/routes';
import type { ConjointCollaborateur, TypeDocument } from '../../types/types';
import Bloc from '../../components/accueil/Bloc';
import FileList from '../../components/files/FileList';
import documentApi from '../../network/api/documentApi';
import ModalContext from '../../context/modal/ModalContext';
import { extractErrorFromSigma } from '../../utils/modal';
import { fileNameToServiceRoute, nameToTypeCour } from '../../utils/enum';
import InternalPage from '../../components/utils/pages/InternalPage';

type Props = {|
  history: History,
  userNumpers: string,
  userFirstName: string,
  userLastName: string,
  conjointCollaborateur?: ConjointCollaborateur,
|};

const FileDetail = ({
  history, userFirstName, userLastName, userNumpers, conjointCollaborateur,
}: Props) => {
  const [fileList, setFileList] = useState<TypeDocument[]>([]);
  const [fileType, setFileType] = useState<TypeDocument[]>([]);
  const { openErrorModal } = useContext(ModalContext);
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const [isDocumentsLoaded, setIsDocumentsLoaded] = useState<boolean>(false);
  const urlParams = new URLSearchParams(location.search);
  const param = urlParams.get('name');
  const name = param && param.replace(/-/g, '_');
  const numpers = urlParams.get('numpers');

  const getMessage = (message: string) => (
    I18n.t(`files.details.${name}.${message}`) !== message
      ? I18n.t(`files.details.${name}.${message}`)
      : fileType.find((type: TypeDocument) => type.typeDocument === name)
        && fileType.find((type: TypeDocument) => type.typeDocument === name).label
  );

  const typeCour = nameToTypeCour[param] || param;

  const onLinkClick = () => {
    if (fileNameToServiceRoute[param] !== '') {
      history.push(fileNameToServiceRoute[param]);
    }
  };

  useEffect(() => {
    showLoading();
    documentApi
      .getDocuments(numpers)
      .then((response: Response) => response.json())
      .then((documentsByType: TypeDocument[]) => {
        if (documentsByType.length === 0) {
          setFileType([]);
        } else {
          setFileType(documentsByType.map((item: TypeDocument) => ({
            label: item.label,
            typeDocument: item.typeDocument,
          })));
        }
      })
      .finally(() => {
        setIsDocumentsLoaded(true);
        closeLoading();
      });
    if (typeCour) {
      documentApi
        .getDocumentsByTypeDocument(typeCour, numpers)
        .then((response: Response) => response.json())
        .then((documentsByType: TypeDocument[]) => {
          setFileList(
            documentsByType.map((item: TypeDocument) => ({
              label: item.label,
              subtitle:
                `${I18n.t('files.tier.generatedOn', {
                  date: moment(item.generationDate, momentFormat.DATE_TIME).format(
                    momentFormat.DATE,
                  ),
                })}`,
              idGED: item.idGED,
            })),
          );
        })
    }
  }, [closeLoading, openErrorModal, showLoading, typeCour, numpers]);

  const fullName = (conjointCollaborateur && numpers === conjointCollaborateur.numpers)
    ? `${conjointCollaborateur.prenom} ${conjointCollaborateur.nom}`
    : `${userFirstName} ${userLastName}`;

  return (
    <InternalPage
      menu={I18n.t('files.title')}
      color="blue"
      hasBackButton
      handleClickBack={() => {
        history.push(`${FILES_ROUTE}${numpers ? `?numpers=${numpers}` : ''}`);
      }}
    >
      {!isDocumentsLoaded
        ? <GenericLoader label={I18n.t('tooltip.loadingData')} />
        : (
          <>
            {conjointCollaborateur && (
              <div className="document-infos">
                {I18n.t('files.document_infos', { fullname: fullName, numpers: numpers || userNumpers })}
              </div>
            )}
            <div id="file-detail" className="bloc-container form mt-1 pb-5">
              <Bloc title={getMessage('title')} color="blue">
                <FileList
                  files={fileList}
                  getMessage={getMessage}
                  onLinkClick={onLinkClick}
                  redirectionRoute={fileNameToServiceRoute[param]}
                  hideSubtitle
                />
              </Bloc>
            </div>
          </>
        )
      }
    </InternalPage>
  );
};

export default connect(state => ({
  userFiles: state.user.file,
  userNumpers: state.user.user.numpers,
  userFirstName: state.user.user.firstName,
  userLastName: state.user.user.lastName,
  conjointCollaborateur: state.user.user.conjointCollaborateur,
}))(memo<Props>(FileDetail));

// @flow

import React, { memo, useContext, useEffect, useState } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import Bloc from '../../components/accueil/Bloc';
import { SERVICE_HOME_ROUTE } from '../../utils/routes';
import demandesApi from '../../network/api/demandesApi';
import type { TypeDemandsData } from '../../types/types';
import { extractErrorFromSigma } from '../../utils/modal';
import ModalContext from '../../context/modal/ModalContext';
import InternalPage from '../../components/utils/pages/InternalPage';

type Props = {|
  history: Object,
|};

const getMessage = (name: string) =>
  I18n.t(`service.requestMonitoring.${name}`);

const RequestMonitoring = ({ history }: Props) => {
  const { openErrorModal } = useContext(ModalContext);
  const [requests, setRequest] = useState<TypeDemandsData[]>([]);

  useEffect(() => {
    demandesApi
      .fetchSuiviDemandes()
      .then((response: Response) => {
        response.json();
      })
      .then((demandes: TypeDemandsData[]) => {
        setRequest(demandes);
      })
      .catch(extractErrorFromSigma(openErrorModal));
  }, []);

  return (
    <InternalPage
      menu={I18n.t('service.title')}
      color="yellow"
      hasBackButton
      handleClickBack={() => history.push(SERVICE_HOME_ROUTE)}
    >
      <div id="request-monitoring" className="bloc-container mt-1 pb-5 yellow">
        <Bloc title={getMessage('title')} color="yellow">
          <div className="container">
            {requests && requests.length > 0 ?
                requests.map((request: TypeDemandsData) => (
                  <div key={request.date} className="request-container">
                    <div className="informations">
                      <span className="document">
                        {I18n.t(
                      `service.requestMonitoring.type.${request.typeDocument}`,
                    )}
                      </span>
                      <span className="date">
                        <Translate
                          value="service.requestMonitoring.date"
                          date={request.date}
                        />
                      </span>
                    </div>
                    <div className="status">
                      {I18n.t(`service.requestMonitoring.status.${request.etat}`)}
                    </div>
                  </div>
            ))
            : <p> {I18n.t('service.requestMonitoring.noData')}</p>
            }
          </div>
        </Bloc>
      </div>
    </InternalPage>
  );
};

export default memo<Props>(RequestMonitoring);

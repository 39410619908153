// @flow

import React, { useCallback } from "react";
import { I18n, Translate } from "react-redux-i18n";
import CustomButton from "../../components/buttons/CustomButton";
import { ENROLEMENT_STEP_1_ROUTE, ENROLEMENT_STEP_2_ROUTE } from "../../utils/routes";
import VerticalTimeline from "../../components/enrolement/VerticalTimeline";
import type { Identity } from "./EnrolementRoutes";
import { statutType } from '../../utils/const';

type Props = {|
  identity: Identity,
  handleChangePage: Function,
  statut: string,
|};

const Landing = ({ identity, handleChangePage, statut }: Props) => {
  return (
    <div className="pt-4">
      <div className="enrolement-presentation text-center">
        <div>
          <Translate value="enrolement.presentation.part1" />
        </div>
        <div>
          <Translate value="enrolement.presentation.part2" />
        </div>
      </div>
      <VerticalTimeline />
      <CustomButton
        className="mt-4"
        message={I18n.t("actions.START")}
        onClick={useCallback(
          () => handleChangePage(statut === statutType.TIER ? ENROLEMENT_STEP_2_ROUTE : ENROLEMENT_STEP_1_ROUTE),
          [],
        )}
      />
    </div>
  );
};

export default Landing;

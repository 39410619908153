// @flow

import type { LoginStateType } from "./loginReducer";
import { store } from "../../network/reduce";
import { IS_CONNECTED } from "./loginReducer";
import { extractErrorFromSigma } from "../../utils/modal";
import { resetUser } from "../user/userService";

export const isUserConnected = (state: LoginStateType) => {
  return state.isConnected;
};

export const disconnectOnly = (dispatch: Function) => {
  sessionStorage.clear();
  dispatch(store(IS_CONNECTED, false));
  dispatch(resetUser());
};

export const disconnect = (
  dispatch: Function,
  openErrorModal: Function,
  error: any,
) => {
  disconnectOnly(dispatch);
  extractErrorFromSigma(openErrorModal)(error);
};

// @flow
import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import NotificationsSystem from 'reapop';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import ConfirmationModal from './components/modaleNotification/ConfirmationModal';
import theme from './components/modaleNotification/theme/index';
import Footer from './components/utils/Footer';
import HeaderApp from './components/utils/HeaderApp';
import MainContent from './MainContent';
import { isUserConnected } from './state/login/loginService';
import type { User } from './types/userType';
import { PRIMARY_COLOR, SECONDARY_COLOR } from './utils/const';
import { visitPage } from './utils/trackUtils';

type Props = {
  isConnected: boolean,
  user: User,
  location: Location,
};

const themeMaterial = createMuiTheme({
  palette: {
    primary: { main: PRIMARY_COLOR },
    secondary: { main: SECONDARY_COLOR },
  },
  typography: {
    useNextVariants: true,
  },
});

const App = ({ isConnected, user, location }: Props) => {
  useEffect(() => visitPage(location.pathname), [location.pathname]);

  return (
    <div id="app">
      <MuiThemeProvider theme={themeMaterial}>
        <NotificationsSystem theme={theme} />
        <ConfirmationModal />
        <HeaderApp isConnected={isConnected} user={user} />
        <div id="main">
          <main id="main-content">
            <MainContent />
          </main>
        </div>
        <Footer />
      </MuiThemeProvider>
    </div>
  );
};

export default connect(state => ({
  isConnected: isUserConnected(state.login),
  user: state.user.user,
}))(memo<Props>(App));

// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import SectionAccordion from '../../../components/data/SectionAccordion';
import Encaissement from './components/Encaissement';
import type { CotisationConjointCollaborateur } from '../../../types/types';
import Cotisations from './components/Cotisations';
import DonneesPro from './components/DonneesPro';

type Props = {
  donneesCotisation: CotisationConjointCollaborateur,
  conjointConsents: boolean,
  isTier: boolean,
};

const CotisationDataConjointCollaborateur = ({
  donneesCotisation, conjointConsents, isTier,
}: Props) => (
  <>
    { donneesCotisation.donneesProConjointCollaborateur &&
    <DonneesPro donneesPro={donneesCotisation.donneesProConjointCollaborateur} />
    }
    { !conjointConsents && (
    <div className="regime consent">
      <div className="warn-tile">
        <span className="title">
          {I18n.t(`personalData.cotisationData.conjointCollaborateur.consent${isTier ? 'Tiers' : ''}`)}
        </span>
      </div>
    </div>
    )}
    { donneesCotisation.encaissementList
        && donneesCotisation.encaissementList.anneeEncaissementList
        && donneesCotisation.encaissementList.anneeEncaissementList.length > 0
        && conjointConsents
        && (
          <SectionAccordion title={I18n.t('personalData.cotisationData.encaissement')}>
            <Encaissement
              isPrelevement={donneesCotisation.encaissementList.prelevement}
              encaissementData={donneesCotisation.encaissementList}
            />
          </SectionAccordion>
        )
    }
    { donneesCotisation.cotisationList
        && donneesCotisation.cotisationList.length > 0
        && conjointConsents
        && (
          <SectionAccordion title={I18n.t('personalData.cotisationData.conjointCollaborateur.cotisations.title')}>
            <Cotisations cotisations={donneesCotisation.cotisationList} />
          </SectionAccordion>
        )
    }
  </>
);

export default CotisationDataConjointCollaborateur;

export const userStatus = {
  ACTIF: "ACTIF",
  RETRAITE: "RETRAITE",
  HORS_EXERCICE: "HORS_EXERCICE",
  BENEFICIAIRE: "BENEFICIAIRE",
  TIERS_DE_CONFIANCE: "TIERS_DE_CONFIANCE",
  ALL: "ALL",
};

export type userStatusType = $Keys<typeof userStatus>;

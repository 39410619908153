// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import SectionAccordion from '../../../components/data/SectionAccordion';
import RegimeBase from './components/RegimeBase';
import RegimeComplementaireB from './components/RegimeComplementaireB';
import RegimeComplementaireC from './components/RegimeComplementaireC';
import RegimeInvaliditeDeces from './components/RegimeInvaliditeDeces';
import RegimeComplementaireCColmarMetz from './components/RegimeComplementaireCColmarMetz';
import Encaissement from './components/Encaissement';
import { TypeCotisationData } from '../../../types/types';

type Props = {
  donneesCotisation: TypeCotisationData,
};

const CotisationDataNotaire = ({
  donneesCotisation,
}: Props) => (
  <>
    { donneesCotisation.encaissements &&
        donneesCotisation.encaissements.anneeEncaissementList &&
        donneesCotisation.encaissements.anneeEncaissementList.length > 0 &&
        <SectionAccordion title={I18n.t('personalData.cotisationData.encaissement')}>
          <Encaissement
            encaissementData={donneesCotisation.encaissements}
            isPrelevement={donneesCotisation.prelevement}
          />
        </SectionAccordion>
    }
    { donneesCotisation.regimeBase &&
        donneesCotisation.regimeBase.length > 0 &&
        <SectionAccordion title={I18n.t('personalData.cotisationData.rbl')}>
          <RegimeBase data={donneesCotisation.regimeBase} />
        </SectionAccordion>
    }
    { donneesCotisation.regimeComplementaireB &&
        donneesCotisation.regimeComplementaireB.length > 0 &&
        <SectionAccordion title={I18n.t('personalData.cotisationData.rcb')}>
          <RegimeComplementaireB data={donneesCotisation.regimeComplementaireB} />
        </SectionAccordion>
    }
    { donneesCotisation.regimeComplementaireC &&
        donneesCotisation.regimeComplementaireC.length > 0 &&
        <SectionAccordion title={I18n.t('personalData.cotisationData.rcc')}>
          <RegimeComplementaireC data={donneesCotisation.regimeComplementaireC} />
        </SectionAccordion>
    }
    { donneesCotisation.regimeComplementaireCColmarMetz &&
        donneesCotisation.regimeComplementaireCColmarMetz.length > 0 &&
        <SectionAccordion title={I18n.t('personalData.cotisationData.rcccm')}>
          <RegimeComplementaireCColmarMetz
            data={donneesCotisation.regimeComplementaireCColmarMetz}
          />
        </SectionAccordion>
    }
    { donneesCotisation.regimeInvaliditeDeces &&
        donneesCotisation.regimeInvaliditeDeces.cotisationsRid.length > 0 &&
        <SectionAccordion title={I18n.t('personalData.cotisationData.rid')}>
          <RegimeInvaliditeDeces data={donneesCotisation.regimeInvaliditeDeces} />
        </SectionAccordion>
    }
  </>
);

export default CotisationDataNotaire;

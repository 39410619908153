// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { DonneesProConjointCollaborateur } from '../../../../types/types';
import { optionRegimeBase, paymentMode } from '../../../../utils/enum';
import { displayCotisationValue } from '../../../../utils/utils';
import Infobulle from './subComponents/Infobulle';

type Props = {
  donneesPro: DonneesProConjointCollaborateur,
}

const DonneesPro = ({ donneesPro }: Props) => {
  const regimeBase = (typeRegime: string) => {
    if (typeRegime === optionRegimeBase.FORFAIT) {
      return I18n.t('personalData.cotisationData.conjointCollaborateur.donneesPro.typeRegimeBase.FORFAIT');
    }
    return I18n.t(`personalData.cotisationData.conjointCollaborateur.donneesPro.typeRegimeBase.${typeRegime}`,
      { pourcentage: displayCotisationValue(donneesPro.tauxRegimeBase, false, true) });
  };

  return (
    <div className="regime donnees-pro">
      <div className="donnees-pro-content">
        <div className="title">
          {I18n.t('personalData.cotisationData.conjointCollaborateur.donneesPro.title')}
        </div>
        <div className="ligne">
          <span className="item category blue-cobalt">
            {I18n.t('personalData.cotisationData.conjointCollaborateur.donneesPro.dateCollaboration')}
          </span>
          <span className="item number">{donneesPro.dateDebutCollaboration}</span>
        </div>
        {donneesPro.typeRegimeBase && (
          <div className="ligne">
            <span className="item category blue-cobalt">
              {I18n.t('personalData.cotisationData.conjointCollaborateur.donneesPro.regimeBase')}
              <Infobulle title={I18n.t('personalData.cotisationData.conjointCollaborateur.donneesPro.infobulle')} />
            </span>
            <span className="item number">{regimeBase(donneesPro.typeRegimeBase)}</span>
          </div>
        )}
        <div className="ligne">
          <span className="item category blue-cobalt">
            {I18n.t('personalData.cotisationData.conjointCollaborateur.donneesPro.regimeComplementaire')}
          </span>
          <span className="item number">{displayCotisationValue(donneesPro.tauxRegimeComplementaire, false, true)}</span>
        </div>
        {donneesPro.paymentMode !== paymentMode.MASQUE && (
          <div className="ligne">
            <span className="item category blue-cobalt">
              {I18n.t('personalData.cotisationData.conjointCollaborateur.donneesPro.payment')}
            </span>
            {donneesPro.paymentMode === paymentMode.VIREMENT
                ? (
                  <span className="item payment">
                    {donneesPro.paymentMode}
                  </span>
                )
                : (
                  <div className="item number payment">
                    <span>
                      {I18n.t(`personalData.cotisationData.conjointCollaborateur.donneesPro.modePaiement.${donneesPro.paymentMode}`)}
                    </span>
                    <span>{donneesPro.iban} - {donneesPro.bic}</span>
                  </div>
                )
            }
          </div>
      )}
      </div>
    </div>);
};

export default DonneesPro;

// @flow

import React, { useEffect, useState } from 'react';

type Props = {
  itemPerPage: number,
  data: T[],
  renderTile: (item : T) => JSX.Element;
}

const Pagination = ({renderTile, data, itemPerPage} : Props) => {
  const MIN_PAGE = 1;
  const [minIndex, setMinIndex] = useState<number>(0);
  const [maxIndex, setMaxIndex] = useState<number>(itemPerPage);
  const [currentPage, setCurrentPage] = useState<number>(MIN_PAGE);
  const [maxPage, setMaxPage] = useState<number>(Math.ceil(data.length/itemPerPage));

  const updateIndexes = (page) => {
    setMinIndex((page - 1) * itemPerPage);
    setMaxIndex((page - 1) * itemPerPage + itemPerPage);
  };

  useEffect(() => {
    setCurrentPage(MIN_PAGE);
    updateIndexes(MIN_PAGE);
    setMaxPage(Math.ceil(data.length/itemPerPage));
  }, [data])

  const goToFirstPage = () => {
    setCurrentPage(MIN_PAGE);
    updateIndexes(MIN_PAGE);
  };

  const goToPreviousPage = () => {
    const newPage = currentPage - 1;
    if ( newPage > 0 ) {
      setCurrentPage(newPage);
      updateIndexes(newPage);
    }
  };

  const goToNextPage = () => {
    const newPage = currentPage + 1;
    if ( newPage <= maxPage ) {
      setCurrentPage(newPage);
      updateIndexes(newPage);
    }
  };

  const goToLastPage = () => {
    setCurrentPage(maxPage);
    updateIndexes(maxPage);
  };

  return (
    <div>
      <div className="pagination-content">
        {data.slice(minIndex, maxIndex).map(item => renderTile(item))}
      </div>
      {data.length > itemPerPage &&
        <div className="pagination centered-container">
          <i className="fas fa-chevron-double-left" onClick={goToFirstPage}/>
          <i className="fas fa-chevron-left" onClick={goToPreviousPage}/>
          <div className="pagination-numbers">
            <input
              type="number"
              className="fs-16"
              step="1"
              min={MIN_PAGE}
              max={maxPage}
              value={currentPage}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const value = e.target.value;
                if (!(value < MIN_PAGE) && !(value > maxPage)) {
                  setCurrentPage(value);
                  updateIndexes(value);
                }
              }
              }
            />
            <div className="separator fs-16"> / </div>
            <div className="max-page fs-16">{maxPage}</div>
          </div>
          <i className="fas fa-chevron-right" onClick={goToNextPage}/>
          <i className="fas fa-chevron-double-right" onClick={goToLastPage} />
        </div>
      }
      </div>
  )
}

export default Pagination;

// @flow

import { createContext } from "react";

type LoadingContextType = {|
  loading: boolean,
  message?: string,
  timeOutDuration: number,
  showLoading: Function,
  closeLoading: Function,
|};

const LoadingContext = createContext<LoadingContextType>({
  loading: false,
  message: "",
  timeOutDuration: 1000,
  showLoading: (message?: string, timeOutDuration?: number) => {},
  closeLoading: () => {},
});

export default LoadingContext;

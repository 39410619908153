// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';
import thunk from 'redux-thunk';
import 'font-awesome-5/src/lib/css/all.min.css';
import './utils/polyfill';

import App from './App';
import './sass/app.scss';
import fr from './i18n/locales/fr';
import reducers from './state/reducers';
import { initSentry } from './services/sentry';
import confApi from './network/api/confApi';
import { initializeGA } from './utils/trackUtils';

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const store = createStore(
  reducers,
  // eslint-disable-next-line no-underscore-dangle
  composeEnhancers(applyMiddleware(thunk)),
);

syncTranslationWithStore(store);
store.dispatch(
  loadTranslations({
    fr,
  }),
);
store.dispatch(setLocale('fr'));

/* eslint-disable react/jsx-filename-extension */
function wrapApp(RootComponent, reduxStore) {
  return (
    <Provider store={reduxStore}>
      <BrowserRouter basename="/">
        <Switch>
          <Route path="/" component={RootComponent} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

initSentry.then(() => {
  ReactDOM.render(
    wrapApp(
      App,
      store,
    ),
    // $FlowFixMe
    document.getElementById('root'),
  );
});

// init analytics
confApi
  .fetchGAUuid()
  .then((response: Response) => response.text())
  .then((analyticsKey: string | undefined) => {
    if (analyticsKey) {
      initializeGA(analyticsKey);
    }
  });

// @flow

import React, { memo, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import GenericLoader from '../../components/utils/GenericLoader';
import { formTranslationType, NAVIGATION_RIGHTS } from '../../utils/const';
import {
  COTISATION_DATA_ROUTE,
  FAMILY_DATA_ROUTE,
  PERSONAL_DATA_HOME_ROUTE,
  PERSONAL_DATA_ROUTE,
} from '../../utils/routes';
import GenericForm from '../../components/utils/GenericForm';
import { professionalCompletDataFields, professionalDataFields } from '../../utils/form';
import Back from '../../components/buttons/Back';
import Bloc from '../../components/accueil/Bloc';
import type { TypeProfessionalData } from '../../types/types';
import { extractErrorFromSigma } from '../../utils/modal';
import userApi from '../../network/api/userApi';
import ModalContext from '../../context/modal/ModalContext';
import SubPageTitle from '../../components/utils/SubPageTitle';
import NavigateDataButton from '../../components/buttons/NavigateDataButton';
import { canSeeFamilyData } from '../../services/permissionService';
import LoadingContext from '../../context/loader/LoadingContext';

type Props = {|
  history: Object,
  status: string,
  hasFamilyData: boolean,
|};

const ProfessionalData = ({
  history, status, hasFamilyData,
}: Props) => {
  const [
    professionalData,
    setProfessionalData,
  ] = useState<TypeProfessionalData>();
  const { openErrorModal } = useContext(ModalContext);
  const { loading, showLoading, closeLoading } = useContext(LoadingContext);

  useEffect(() => {
    showLoading();
    userApi
      .getProfessionalData()
      .then((response: Response) => response.json())
      .then(setProfessionalData)
      .catch(extractErrorFromSigma(openErrorModal))
      .finally(closeLoading);
  }, [closeLoading, openErrorModal, showLoading]);

  const grid = [1, 2, 1, 2, 1, 2, 1, 2, 1, 1];

  const onClick = () => history.push(PERSONAL_DATA_HOME_ROUTE);
  const onClickLeft = () => (NAVIGATION_RIGHTS.FAMILY.includes(status)
  && canSeeFamilyData(hasFamilyData, status)
    ? history.push(FAMILY_DATA_ROUTE)
    : history.push(PERSONAL_DATA_ROUTE));
  const onClickRight = () => (NAVIGATION_RIGHTS.COTISATION.includes(status)
    ? history.push(COTISATION_DATA_ROUTE)
    : history.push(PERSONAL_DATA_ROUTE));

  return (
    <div className="page-container">
      <SubPageTitle
        className="m-auto"
        message={I18n.t('personalData.pageTitle.professional')}
        color="cobalt"
      />
      <div id="professional-data">
        <Back
          className="mb-2"
          onClick={onClick}
          message={I18n.t('personalData.professionalData.back')}
        />
        <div className="bloc-container">
          <NavigateDataButton
            messageLeft={NAVIGATION_RIGHTS.FAMILY.includes(status)
            && canSeeFamilyData(hasFamilyData, status) ?
              I18n.t('personalData.familyData.title') :
              I18n.t('personalData.personalData.title')
            }
            onClickLeft={onClickLeft}
            className=""
            messageRight={NAVIGATION_RIGHTS.COTISATION.includes(status) ?
              I18n.t('personalData.cotisationData.title') :
              I18n.t('personalData.personalData.title')}
            onClickRight={onClickRight}
          />
          <Bloc className="data" color="cobalt">
            <div className="seperate-between-family">
              <div className="professional-logo" />
              <div className="cobalt family-consult">
                {' '}
                {I18n.t('personalData.professionalData.consult')}{' '}
              </div>
            </div>
            {loading
              ? <GenericLoader label={I18n.t('tooltip.loadingData')} />
              : (
                <>
                  <div className="description-line">
                    <div className="label">
                      {I18n.t(
                        'personalData.professionalData.form.office.label',
                      )}
                    </div>
                    <div className="value">
                      {' '}
                      {professionalData && professionalData.office ? professionalData.office : '-'}{' '}
                    </div>
                  </div>
                  <div className="description-line">
                    <div className="label">
                      {I18n.t(`personalData.professionalData.form.address.${
                        professionalData && professionalData.personalAddress ?
                          'professional' :
                          'professional_only'
                      }`)}
                    </div>
                    <div className="value">
                      {professionalData && professionalData.address
                        ? professionalData.address
                        : '-'}
                    </div>
                  </div>
                  <div className="description-line">
                    <div className="label">
                      {I18n.t(`personalData.professionalData.form.address.${
                        professionalData && professionalData.personalAddress ?
                          'personal' :
                          'personal_empty'
                      }`)}
                    </div>
                    <div className="value">
                      {professionalData && professionalData.personalAddress
                        ? professionalData.personalAddress
                        : '-'}
                    </div>
                  </div>
                  <GenericForm
                    className="m-auto"
                    onSubmit={() => { /* TODO replace generic form */ }}
                    initialValues={professionalData}
                    translationBase={formTranslationType.professionalData}
                    fields={
                      professionalData && professionalData.numberPoints > 0
                        ? professionalCompletDataFields
                        : professionalDataFields
                    }
                    grid={grid}
                    hasButton={false}
                  />
                </>
              )
            }
          </Bloc>
        </div>
      </div>
    </div>
  );
};

export default connect(state => ({
  status: state.user.user.statut,
  hasFamilyData: state.user.hasFamilyData,
}))(memo<Props>(ProfessionalData));

// @flow

import React, { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  CONTRIBUTOR_CERTIFICATION_ROUTE,
  DASHBOARD_ROUTE,
  REQUEST_ROUTE,
  RETREAT_REQUEST_ROUTE,
  SERVICE_HOME_ROUTE,
  PACK_PAYMENT_ROUTE,
  PACK_FISCAL_ROUTE,
  CERTIFICATE_RETIREMENT_ROUTE,
  RECIPIENT_INFO_ROUTE,
  INTER_REGIME_ROUTE,
  MA_CARRIERE_ROUTE,
} from '../../utils/routes';
import ServiceHome from './ServicesPage';
import RetreatRequest from './RetreatRequest';
import RequestMonitoring from './RequestMonitoring';
import ContributorCertification from './ContributorCertification';
import PackPayment from './PackPayment';
import PackFiscal from './PackFiscal';
import CertificateRetirementPayment from './CertificateRetirementPayment';
import RecipientInfo from './RecipientInfo';
import InterRegime from './InterRegime';
import MaCarriere from './MaCarriere';

const ServicesRoutes = () => (
  <div className="service-container">
    <Switch>
      <Route exact path={SERVICE_HOME_ROUTE} component={ServiceHome} />

      {/* DROIT A L'INFORMATION */}
      <Route exact path={MA_CARRIERE_ROUTE} component={MaCarriere} />

      {/* PREPARER MA RETRAITE */}
      {/* // FIXME : A décommenter début 2024, une fois le service mis à disposition */}
      {/* <Route */}
      {/*  exact */}
      {/*  path={RETREAT_ESTIMATION_ROUTE} */}
      {/*  component={RetirementEstimation} */}
      {/* /> */}
      <Route exact path={RETREAT_REQUEST_ROUTE} component={RetreatRequest} />
      <Route exact path={REQUEST_ROUTE} component={RequestMonitoring} />
      <Route exact path={INTER_REGIME_ROUTE} component={InterRegime} />

      {/* MA RETRAITE */}
      <Route exact path={PACK_PAYMENT_ROUTE} component={PackPayment} />
      <Route exact path={PACK_FISCAL_ROUTE} component={PackFiscal} />
      <Route exact path={RECIPIENT_INFO_ROUTE} component={RecipientInfo} />
      <Route
        exact
        path={CERTIFICATE_RETIREMENT_ROUTE}
        component={CertificateRetirementPayment}
      />

      {/* MES DOCUMENTS */}
      <Route
        exact
        path={CONTRIBUTOR_CERTIFICATION_ROUTE}
        component={ContributorCertification}
      />

      <Route path="*" render={() => <Redirect to={DASHBOARD_ROUTE} />} />
    </Switch>
  </div>
);

export default memo(ServicesRoutes);

// @flow

import React, { memo, useContext } from 'react';
import { I18n } from 'react-redux-i18n';
import type { DelegantDocument } from '../../types/types';
import { download } from '../../utils/utils';
import { extractErrorFromSigma } from '../../utils/modal';
import documentApi from '../../network/api/documentApi';
import LoadingContext from '../../context/loader/LoadingContext';
import ModalContext from '../../context/modal/ModalContext';

type Props = {
  document: DelegantDocument,
  displayName: boolean,
}

const FileItem = ({ document, displayName }: Props) => {
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const { openErrorModal } = useContext(ModalContext);
  const {
    idGED, typeDocument, label, generationDate, numpersDelegant, nomDelegant,
  } = document;

  const downloadFile = () => {
    showLoading();
    documentApi
      .downloadDocumentFromTier(idGED, numpersDelegant)
      .then(res => res.blob())
      .then(blob => download(blob, label))
      .catch(extractErrorFromSigma(openErrorModal))
      .finally(closeLoading);
  };

  return (
    <div className="document-item">
      <div className={`${typeDocument.toLowerCase()} icon-image`} />
      <div className="document-item-infos">
        <p>{label}</p>
        <p className="text-smaller">{I18n.t('files.tier.generatedOn', { date: generationDate.split(' ')[0] })}</p>
        { displayName &&
          <p className="text-bold">{nomDelegant}</p>
        }
      </div>
      <div onClick={downloadFile} className="icon fa fa-arrow-alt-to-bottom" />
    </div>
  );
};

export default memo<Props>(FileItem);

// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';

import { Field } from 'react-final-form';
import type { TypeEmail } from '../../../../types/types';
import { emailRequired } from '../../../../utils/validator';

type Props = {|
  emails: TypeEmail[],
|};

const EmailSelect = ({ emails }: Props) => (
  <>
    {emails && emails.length > 0 && (
    <>
      {emails.map(
        (emailType: TypeEmail) => (
          <Field
            key={`check_option_${emailType.email}`}
            name="email"
            type="radio"
            value={emailType.email}
            validate={emailRequired}
            render={({ input }) => (
              <label htmlFor="email" className="radio-container">
                <span>{I18n.t(`parametrerProfil.form.chooseEmailDescription.${emailType.sourceTypeEnum}`)}</span>
                <strong>{emailType.email}</strong>
                <input
                  {...input}
                  className="radio"
                  type="radio"
                  value={emailType.email}
                />
                <span className="radiomark" />
              </label>
            )}
          />
        ),
      )}
    </>
    )}
    <Field
      key="check_option_other"
      name="email"
      type="radio"
      value="other"
      render={({ input }) => (
        <label htmlFor="email" className="radio-container">
          <span>{I18n.t('parametrerProfil.form.chooseEmailDescription.other')}</span>
          <input
            {...input}
            className="radio"
            type="radio"
            value="other"
          />
          <span className="radiomark" />
        </label>
      )}
    />
  </>
);

export default EmailSelect;

// @flow
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import confApi from '../../network/api/confApi';
import Menu from './Menu';
import { HOME_PAGE_ROUTE } from '../../utils/routes';
import type { User } from '../../types/userType';
import { isFromFranceConnect, isFromIDNOT } from '../../state/user/userService';
import CartoucheIdNot from '../cartouche/idnot/CartoucheIdNot';
import HeaderConnected from '../landingPage/HeaderConnected';
import HeaderDisconnected from './HeaderDisconnected';
import CartoucheFc from '../cartouche/fc/CartoucheFc';
import Watermark from './Watermark';

type Props = {|
  history: Object,
  location: Object,
  isConnected: boolean,
  user: User,
|};

const isHome = (pathname: string) => pathname === HOME_PAGE_ROUTE || pathname === '/';

const renderCartouche = (user: User, isConnected: boolean) => {
  if (!user) {
    return <></>;
  } else if (isFromIDNOT(user)) {
    return <CartoucheIdNot user={user} isConnected={isConnected} />;
  } else if (isFromFranceConnect(user)) {
    return <CartoucheFc user={user} isConnected={isConnected} />;
  }
  return <></>;
};

const HeaderApp = ({
  isConnected, history, location, user,
}: Props) => {
  const [environment, setEnvironment] = useState<string>('prod');

  useEffect(() => {
    confApi
      .fetchEnv()
      .then((response: Response) => response.text())
      .then(setEnvironment);
  }, []);

  const { pathname } = location;

  if (isConnected || !isHome(pathname)) {
    return (
      <>
        <HeaderConnected
          user={user}
          history={history}
          showBorderBottom={!isConnected}
          isConnected={isConnected}
          cartouche={renderCartouche(user, isConnected)}
        >
          <Watermark environment={environment} />
        </HeaderConnected>
        {isConnected && (
          <Menu history={history} location={location} user={user} />
        )}
      </>
    );
  }

  return <HeaderDisconnected><Watermark environment={environment} /></HeaderDisconnected>;
};

export default withRouter(HeaderApp);

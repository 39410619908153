// @flow

import React, { useContext, useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import DashboardBloc from '../../components/dashboard/DashboardBloc';
import {
  COTISATION_DATA_ROUTE,
  DELEGATION_HOME_ROUTE,
  FAMILY_DATA_ROUTE,
  FILE_DETAIL_ROUTE,
  FILES_ROUTE,
  PERSONAL_DATA_HOME_ROUTE,
  PERSONAL_DATA_ROUTE,
  PROFESSIONAL_DATA_ROUTE,
  RETREAT_DATA_ROUTE,
  SERVICE_HOME_ROUTE,
  TIER_FILES_ROUTE,
  TIER_PROFESSIONAL_DATA_ROUTE,
} from '../../utils/routes';
import { dashboardMyRetirementList, dashboardServiceList, momentFormat, NAVIGATION_RIGHTS } from '../../utils/const';
import Alert from '../../components/dashboard/Alert';
import documentApi from '../../network/api/documentApi';
import { userStatus } from '../../utils/userStatus';
import type { DelegantDocument, TypeDocument, TypePersonalData } from '../../types/types';
import { DelegantsInfos } from '../../types/types';
import ModalContext from '../../context/modal/ModalContext';
import { extractErrorFromSigma } from '../../utils/modal';
import { colors, typeCourToFileRoute } from '../../utils/enum';
import {
  fetchIfUserHasFamilyData,
  storeUserNotairesDelegantsData,
  storeUserPersonalData,
} from '../../state/user/userService';
import userApi from '../../network/api/userApi';
import { canSeeFamilyData } from '../../services/permissionService';
import tierApi from '../../network/api/tierApi';

type Props = {|
  history: Object,
  familyData: boolean,
  status: string,
  dispatch: Function,
|};

const Dashboard = ({
  history, familyData, dispatch, status,
}: Props) => {
  const [fileList, setFileList] = useState<TypeDocument[]>([]);
  const [delegantFileList, setDelegantFileList] = useState<DelegantDocument[]>([]);
  const [isDocumentListEmpty, setIsDocumentListEmpty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { openErrorModal } = useContext(ModalContext);
  const nbDocumentsToDisplay = 3;

  const handleFiles = (delegantDocuments: DelegantDocument[], fileListNull: boolean) => {
    setDelegantFileList(delegantDocuments);
    setIsDocumentListEmpty(fileListNull);
  };

  useEffect(() => {
    if (status === userStatus.TIERS_DE_CONFIANCE) {
      setIsLoading(true);
      tierApi
        .getNotaireDelegants()
        .then((response: Response) => response.json())
        .then((json: DelegantsInfos) => {
          if (json.documentDelegants.length === 0 || !NAVIGATION_RIGHTS.DOCUMENT.includes(status)) {
            handleFiles([], true);
          } else {
            const files = json.documentDelegants.slice(0, nbDocumentsToDisplay)
              .map((item: DelegantDocument) => ({
                label: item.label,
                subLabel: I18n.t('dashboard.documents.lastDocument',
                  {
                    date: moment(item.generationDate, momentFormat.DATE_TIME)
                      .format(momentFormat.DATE),
                  }),
                icone: 'fa fa-file-certificate',
                right: NAVIGATION_RIGHTS.DOCUMENT,
                route: TIER_FILES_ROUTE,
                idGED: item.idGED,
                nomDelegant: item.nomDelegant,
                alreadyDownloaded: item.alreadyDownloaded,
              }));
            handleFiles(files, false);
          }
          dispatch(storeUserNotairesDelegantsData(json));
        })
        .catch(extractErrorFromSigma(openErrorModal))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(true);
      documentApi
        .getDocuments()
        .then((response: Response) => response.json())
        .then((json: TypeDocument[]) => {
          if (json.length === 0 || !NAVIGATION_RIGHTS.DOCUMENT.includes(status)) {
            setFileList([]);
            setIsDocumentListEmpty(true);
          } else {
            setIsDocumentListEmpty(false);
            setFileList(json.map((document: TypeDocument) => ({
              label: document.label,
              subLabel: I18n.t('dashboard.documents.lastDocument',
                {
                  date: moment(document.generationDate, momentFormat.DATE_TIME)
                    .format(momentFormat.DATE),
                }),
              icone: 'fa fa-file-certificate',
              right: NAVIGATION_RIGHTS.DOCUMENT,
              route: typeCourToFileRoute[document.typeDocument] || `${FILE_DETAIL_ROUTE}?name=${document.typeDocument}`,
              idGED: document.idGED,
              alreadyDownloaded: document.alreadyDownloaded,
            })));
          }
        })
        .finally(() => setIsLoading(false));
      userApi
        .getPersonalData()
        .then((response: Response) => response.json())
        .then((json: TypePersonalData) => {
          dispatch(storeUserPersonalData(json));
        })
        .catch(extractErrorFromSigma(openErrorModal));
    }
  }, [dispatch, openErrorModal, status]);

  useEffect(() => {
    dispatch(fetchIfUserHasFamilyData());
  }, [dispatch]);

  const dashboardDataList = [
    {
      label: 'dashboard.personalData.personal',
      icone: 'fa fa-folder',
      right: NAVIGATION_RIGHTS.PERSONAL,
      route: PERSONAL_DATA_ROUTE,
    },
    {
      label: 'dashboard.personalData.family',
      icone: 'fa fa-users',
      right: NAVIGATION_RIGHTS.FAMILY,
      // route:  familyData ? FAMILY_DATA_ROUTE : "",
      noData: !canSeeFamilyData(familyData, status),
      route: (Object.values(NAVIGATION_RIGHTS.FAMILY).indexOf(status) !== -1) ? FAMILY_DATA_ROUTE : '',
    },
    {
      label: 'dashboard.personalData.profession',
      icone: 'fa fa-briefcase',
      right: NAVIGATION_RIGHTS.PROFESSIONAL,
      route: PROFESSIONAL_DATA_ROUTE,
    },
    {
      label: 'dashboard.personalData.profession',
      icone: 'fa fa-briefcase',
      right: NAVIGATION_RIGHTS.PROFESSIONAL_TIER,
      route: TIER_PROFESSIONAL_DATA_ROUTE,
    },
    {
      label: 'dashboard.personalData.retirement',
      icone: 'fa fa-list-ol',
      right: NAVIGATION_RIGHTS.RETREAT,
      route: RETREAT_DATA_ROUTE,
    },
    {
      label: 'dashboard.personalData.cotisation',
      icone: 'cotisation-svg',
      right: NAVIGATION_RIGHTS.COTISATION,
      route: COTISATION_DATA_ROUTE,
    },
  ];

  const documentBloc = () => {
    if (status === userStatus.TIERS_DE_CONFIANCE) {
      return (
        <DashboardBloc
          history={history}
          color={colors.BLUE}
          title={I18n.t('dashboard.files.title')}
          fileList={delegantFileList}
          route={isDocumentListEmpty ? '' : TIER_FILES_ROUTE}
          loading={isLoading}
        />
      );
    }
    return (
      <DashboardBloc
        history={history}
        color={colors.BLUE}
        title={I18n.t('dashboard.files.title')}
        fileList={fileList}
        route={isDocumentListEmpty ? '' : FILES_ROUTE}
        loading={isLoading}
      />
    );
  };

  return (
    <>
      <Alert status={status} history={history} />
      <div className="dashboard-container">
        <div className="bloc-container vertical">
          <div className="bloc-container">
            <DashboardBloc
              history={history}
              color={colors.COBALT}
              title={I18n.t('dashboard.personalData.title')}
              fileList={dashboardDataList}
              route={PERSONAL_DATA_HOME_ROUTE}
            />
            {
              status !== userStatus.TIERS_DE_CONFIANCE &&
              <DashboardBloc
                history={history}
                color={colors.YELLOW}
                title={I18n.t('dashboard.services.title')}
                fileList={(status === userStatus.RETRAITE || status === userStatus.BENEFICIAIRE) ?
                  dashboardMyRetirementList : dashboardServiceList}
                route={SERVICE_HOME_ROUTE}
              />
            }
            {documentBloc()}
          </div>
          {
            status === userStatus.ACTIF &&
            <div className="message-box-sizing-container">
              <Link to={DELEGATION_HOME_ROUTE} className="message-container">
                <div className="text-bold fs-16 message-container-text">
                  <span>
                    {I18n.t('dashboard.delegations.delegateAcces')}
                  </span>
                  <span className="message-container-text-underline">
                    {I18n.t('dashboard.delegations.clickHere')}
                  </span>
                </div>
                <div className="fs-16 message-container-text">
                  {I18n.t('dashboard.delegations.delegateExplanation')}
                </div>
              </Link>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default connect(state => ({
  familyData: state.user.hasFamilyData,
  status: state.user.user.statut,
}))(Dashboard);

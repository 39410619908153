// @flow

import React, { useState } from 'react';
import { ExpansionPanel as Accordion } from '@material-ui/core';

type Props = {|
  title: string,
  children: any,
|};

const SectionAccordion = ({title, children}: Props) => {
  const [toggled, setToggled] = useState<boolean>(false);

  return (
    <div className="accordion-section">
      <div className="accordion-section--separator" />
      <Accordion className="accordion" expanded={toggled}>
        <div className="accordion-section-header" onClick={() => setToggled(!toggled)}>
          <span className="title">{title}</span>
          <i className={`fas fa-lg fa-chevron-down ${toggled ? 'up' : 'down'}`} />
        </div>
        <div className="accordion-section-content">
          {children}
        </div>
      </Accordion>
    </div>
  )
}

export default SectionAccordion;
// @flow

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { I18n } from 'react-redux-i18n';
import GenericLoader from '../../components/utils/GenericLoader';
import SubPageTitle from '../../components/utils/SubPageTitle';
import Back from '../../components/buttons/Back';
import {
  PERSONAL_DATA_HOME_ROUTE,
  PERSONAL_DATA_ROUTE,
  PROFESSIONAL_DATA_ROUTE,
  TIER_PROFESSIONAL_DATA_ROUTE,
} from '../../utils/routes';
import NavigateDataButton from '../../components/buttons/NavigateDataButton';
import { NAVIGATION_RIGHTS } from '../../utils/const';
import Bloc from '../../components/accueil/Bloc';
import userApi from '../../network/api/userApi';
import { extractErrorFromSigma } from '../../utils/modal';
import ModalContext from '../../context/modal/ModalContext';
import type {
  ConjointCollaborateur,
  CotisationConjointCollaborateur,
  DelegantsInfos,
  DonneesCotisation,
  SelectedNotaire,
  TypeCotisationData,
} from '../../types/types';
import { userStatus } from '../../utils/userStatus';
import tierApi from '../../network/api/tierApi';
import { storeUserNotairesDelegantsData } from '../../state/user/userService';
import { updateSelectedPersona } from '../../utils/utils';
import NotairesFilter from '../files/NotairesFilter';
import LoadingContext from '../../context/loader/LoadingContext';
import CotisationDataContent from './cotisationSections/CotisationDataContent';

type Props = {|
  history: History,
  status: string,
  userNumpers: string,
  dispatch: Dispatch,
  conjoint?: ConjointCollaborateur,
  userFirstName: string,
  userLastName: string,
|};

const CotisationData = ({
  history, status, dispatch, userNumpers, conjoint, userFirstName, userLastName,
}: Props) => {
  const { openErrorModal } = useContext(ModalContext);
  const initialCotisationData = {
    cotisationData: {
      erreur: null,
      regimeBase: null,
      regimeComplementaireB: null,
      regimeComplementaireC: null,
      regimeComplementaireCColmarMetz: null,
      regimeInvaliditeDeces: null,
    },
    isConjoint: false,
  };
  const [uniqueDelegant, isTier] = [1, status === userStatus.TIERS_DE_CONFIANCE];
  const [donneesCotisation,
    setDonneesCotisation] = useState<DonneesCotisation>(initialCotisationData);
  const [selectedPersonas, setSelectedPersonas] = useState<SelectedNotaire[]>([]);
  const { loading, showLoading, closeLoading } = useContext(LoadingContext);

  const [loadedDelegant, setLoadedDelegant] = useState<boolean>(!isTier);

  const handleNotaireDelegants = (notaireDelegantsArray: SelectedNotaire[]) => {
    setSelectedPersonas(notaireDelegantsArray
      .map((notaire: SelectedNotaire) => ({ ...notaire, filterActive: false })));
  };

  const catchCotisationFetchError = () => setDonneesCotisation({
    cotisationData: { erreur: 'erreur' },
    isConjoint: false,
  });

  const getCotisationData = useCallback((
    numpers: string = null,
    isConjoint: boolean = false,
    conjointConsents?: boolean,
  ) => {
    const notaireNumpers = numpers || userNumpers;
    showLoading();
    if (isConjoint) {
      userApi
        .getCotisationConjointCollaborateur(numpers)
        .then((response: Response) => response.json())
        .then((cotisationConjointCollaborateur: CotisationConjointCollaborateur) =>
          setDonneesCotisation({
            cotisationData: cotisationConjointCollaborateur,
            isConjoint: true,
            conjointConsents,
          }))
        .catch(catchCotisationFetchError)
        .finally(closeLoading);
    } else {
      userApi
        .getCotisationData(notaireNumpers)
        .then((response: Response) => response.json())
        .then((cotisationData: TypeCotisationData) => {
          const data = cotisationData;
          Object.keys(data).forEach((cotisationDataKey: string) => {
            if (cotisationDataKey !== 'erreur' && data[cotisationDataKey] == null) {
              delete data[cotisationDataKey];
            }
          });
          setDonneesCotisation({
            cotisationData: data,
            isConjoint: false,
          });
        })
        .catch(catchCotisationFetchError)
        .finally(closeLoading);
    }
  }, [closeLoading, showLoading, userNumpers]);

  useEffect(() => {
    if (isTier) {
      showLoading();
      tierApi
        .getNotaireDelegants()
        .then((response: Response) => response.json())
        .then((delegants: DelegantsInfos) => {
          dispatch(storeUserNotairesDelegantsData(delegants));
          handleNotaireDelegants(delegants.selectedNotaires);

          if (delegants.selectedNotaires.length === 1) {
            getCotisationData(delegants.selectedNotaires[0].numpers);
          }
        })
        .catch(extractErrorFromSigma(openErrorModal))
        .finally(() => {
          setLoadedDelegant(true);
          closeLoading();
        });
    } else if (conjoint) {
      setSelectedPersonas([
        {
          numpers: userNumpers,
          nom: userLastName,
          prenom: userFirstName,
          conjointCollaborateur: { ...conjoint, filterActive: false },
          filterActive: true,
        },
      ]);
      getCotisationData();
    } else {
      getCotisationData();
    }
  }, [closeLoading, conjoint, dispatch, getCotisationData, isTier,
    openErrorModal, showLoading, userFirstName, userLastName, userNumpers]);

  const goToProfessionnalData = () => {
    if (isTier) {
      if (NAVIGATION_RIGHTS.PROFESSIONAL_TIER.includes(status)) {
        history.push(TIER_PROFESSIONAL_DATA_ROUTE);
      }
    } else if (NAVIGATION_RIGHTS.PROFESSIONAL.includes(status)) {
      history.push(PROFESSIONAL_DATA_ROUTE);
    }
  };
  const goToPersonnalData = () => (NAVIGATION_RIGHTS.PERSONAL.includes(status) ?
    history.push(PERSONAL_DATA_ROUTE) : null);

  const notaireDelegantName = () => {
    if (selectedPersonas && selectedPersonas.length === 1) {
      return `${selectedPersonas[0].prenom} ${selectedPersonas[0].nom}`;
    }
    return undefined;
  };

  const onClickNotaire = (
    selectedPersona: SelectedNotaire | ConjointCollaborateur, isConjoint: boolean,
  ) => {
    updateSelectedPersona(selectedPersonas, setSelectedPersonas, selectedPersona, isConjoint);
    getCotisationData(selectedPersona.numpers, isConjoint, isConjoint && selectedPersona.consent);
  };

  return (
    <div className="page-container">
      <SubPageTitle
        className="m-auto"
        message={I18n.t('personalData.pageTitle.cotisation')}
        color="cobalt"
      />
      <div id="cotisation-data">
        <Back
          className="mb-2"
          onClick={() => history.push(PERSONAL_DATA_HOME_ROUTE)}
          message={I18n.t('personalData.personalData.back')}
        />
        <div className="bloc-container">
          <NavigateDataButton
            messageLeft={I18n.t('personalData.professionalData.title')}
            onClickLeft={goToProfessionnalData}
            messageRight={isTier ? '' : I18n.t('personalData.personalData.title')}
            onClickRight={goToPersonnalData}
          />
          <Bloc className={`data${isTier ? ' cotisation-data' : ''}`} color="cobalt">
            <div>
              <div className="seperate-between-family">
                <div className="cotisation-logo" />
                <div className="cobalt family-consult">
                  {isTier && selectedPersonas.length === 1
                    ? I18n.t('personalData.cotisationData.consultOf', { name: notaireDelegantName() })
                    : I18n.t('personalData.cotisationData.consult')
                  }
                </div>
              </div>

              <div className="content tier-cotisation-content">
                {loadedDelegant
                  && (
                    <>
                      {(conjoint || (isTier && selectedPersonas.length > uniqueDelegant)) && (
                        <>
                          <div className="filter-text filter-title">{I18n.t('personalData.cotisationData.selectProfileTitle')}</div>
                          <NotairesFilter
                            selectedPersonas={selectedPersonas}
                            onClickNotaire={onClickNotaire}
                            isGreyDiv
                            isCotisationData
                          />
                        </>
                      )}
                    </>
                  )
                }
                {isTier
                  && Object.values(donneesCotisation)
                    .filter((cotisation: TypeCotisationData) => cotisation !== null)
                    .length === 0
                  &&
                  <>
                    <div className="filter-separator" />
                    <div className="filter-text filter-no-data">{I18n.t('personalData.cotisationData.noData')}</div>
                    <div className="filter-text filter-no-data">{I18n.t('personalData.cotisationData.selectNotaireText')}</div>
                  </>
                }
                {loading
                  ? <GenericLoader label={I18n.t('tooltip.loadingData')} />
                  : (
                    <CotisationDataContent
                      donneesCotisation={donneesCotisation}
                      isTier={isTier}
                    />
                  )
                }
              </div>
            </div>
          </Bloc>
        </div>
      </div>
    </div>
  );
};

export default connect(state => ({
  userNumpers: state.user.user.numpers,
  userFirstName: state.user.user.firstName,
  userLastName: state.user.user.lastName,
  status: state.user.user.statut,
  conjoint: state.user.user.conjointCollaborateur,
}))(CotisationData);

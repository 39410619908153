// @flow

import {
  DASHBOARD_ROUTE,
  DELEGATION_HOME_ROUTE,
  FILES_ROUTE,
  TIER_FILES_ROUTE,
  PERSONAL_DATA_HOME_ROUTE,
  SERVICE_HOME_ROUTE,
} from "./routes";
import { userStatus } from './userStatus';

export const menu = [
  { name: "dashboard",
    color: "gray",
    path: DASHBOARD_ROUTE,
    hideItemFor: [],
  },
  {
    name: "datas",
    color: "cobalt",
    path: PERSONAL_DATA_HOME_ROUTE,
    hideItemFor: [],
  },
  {
    name: "services",
    color: "yellow",
    path: SERVICE_HOME_ROUTE,
    hideItemFor: [userStatus.TIERS_DE_CONFIANCE],
  },
  { name: "documents",
    color: "blue",
    path: FILES_ROUTE,
    hideItemFor: [userStatus.TIERS_DE_CONFIANCE],
  },
  { name: "documents",
    color: "blue",
    path: TIER_FILES_ROUTE,
    hideItemFor: [userStatus.ACTIF, userStatus.RETRAITE, userStatus.BENEFICIAIRE, userStatus.HORS_EXERCICE],
  },
  { name: "delegations",
    color: "orange",
    path: DELEGATION_HOME_ROUTE,
    hideItemFor: [userStatus.TIERS_DE_CONFIANCE, userStatus.RETRAITE, userStatus.BENEFICIAIRE, userStatus.HORS_EXERCICE],
  },
];

export type MenuItem = {
  name: string,
  color: string,
  path: string,
  disabled?: boolean,
  hideItemFor: Array,
};

// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import type { EndUnionInfo, TypeDataUnit } from '../../types/types';
import Modal from '../modals/Modal';
import CustomButton from '../buttons/CustomButton';

type ConfirmEditProps = {
  isOpen: boolean,
  close: () => void,
  headerTitle: string,
  onSubmit: (TypeDataUnit | EndUnionInfo) => void,
  children: any,
};

export const ConfirmModal = ({
  isOpen, close, onSubmit, headerTitle, children,
}: ConfirmEditProps) => (
  <Modal
    className="modal-confirmation"
    isOpen={isOpen}
    close={close}
    headerTitle={headerTitle}
    buttons={
      [
        <CustomButton
          className="btn-cancel"
          message={I18n.t('actions.CANCEL')}
          onClick={close}
          type="button"
        />,
        <CustomButton
          className="btn-confirmation"
          message={I18n.t('actions.CONFIRM')}
          onClick={() => {
            onSubmit();
            close();
          }}
          type="button"
        />,
      ]}
  >
    {children}
  </Modal>
);


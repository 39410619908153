// @flow

import React, { memo } from "react";
import { I18n } from "react-redux-i18n";

import { FRANCE_CONNECT_URL } from "../../utils/const";

type Props = {
  classname: ?string,
};

function connect() {
  window.location.href = FRANCE_CONNECT_URL;
}

const FranceConnect = ({ classname = "" }: Props) => (
  <div
    className={`btn-connect-container btn-franceconnect-container ${classname}`}
  >
    <a className="btn-franceconnect-button" onClick={connect} />
  </div>
);

export default memo<void>(FranceConnect);

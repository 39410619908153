// @flow

import React, {memo, useContext} from "react";
import { I18n } from "react-redux-i18n";
import Bloc from "../../components/accueil/Bloc";
import fiscalApi from "../../network/api/fiscalApi";
import {download} from "../../utils/utils";
import {extractErrorFromSigma} from "../../utils/modal";
import CustomButton from "../buttons/CustomButton";
import {connect} from "react-redux";
import LoadingContext from "../../context/loader/LoadingContext";
import ModalContext from "../../context/modal/ModalContext";

type Props = {|
    attestation: TypeAttestation,
    dispatch: Function,
|};

export type TypeAttestation = {
    accrochage: number,
    codePeriodicite: string,
    dateDebutEcheance: string,
    dateFinEcheance: string,
    datePaiement: string,
    declinaisonRegime1: string,
    declinaisonRegime2: string,
    idPaiement: number,
    libelleCourt: string,
    libelleLong: string,
    montantNet: number,
    nature: string,
}

const FiscalAttestation = ({attestation}: Object) => {
    const {showLoading, closeLoading} = useContext(LoadingContext);
    const { openErrorModal } = useContext(ModalContext);

    const getMessage = (name: string) => I18n.t(`service.packFiscal.${name}`);

    const downloadFiscalAttestation = (year: String) => {
        showLoading();
        fiscalApi
            .getFiscalAttestations(year)
            .then(res => res.blob())
            .then((document: Blob) => {
                download(
                    document,
                    I18n.t("service.packFiscal.filename", {
                        date: year,
                    }),
                );
            })
            .catch(extractErrorFromSigma(openErrorModal))
            .finally(closeLoading);
    };
    return (
        <Bloc
            title={attestation[0]}
            className="mb-2"
            color="yellow"
        >
            <div className="ligne colonne-label">
                <div className="col-left">
                    {getMessage("libelleCourt")}
                </div>
                <div className="col-right">
                    {getMessage("montantImposable")}
                </div>
            </div>
            <div className="limit mb-1" />
            {attestation[1].map(organism => (
                <div className="ligne">
                    <div className="col-left">
                        {organism.libelleCourt}
                    </div>
                    <div>
                        {organism.montantImposable ? `${organism.montantImposable} €` : I18n.t('service.packFiscal.files.noData') }
                    </div>
                    <CustomButton
                        color="yellow"
                        onClick={() => downloadFiscalAttestation(attestation[0])}
                        message={getMessage("download")}
                    />
                </div>
            ))}
        </Bloc>
    )
};

export default connect()(memo<Props>(FiscalAttestation));

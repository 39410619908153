// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { DetailRegimeComplementaireB, RegimeComplementaireBAnnee } from '../../../../types/types';
import ItemAccordionRCB from './subComponents/ItemAccordionRCB';

type Props = {|
    data: RegimeComplementaireBAnnee[],
|}

const RegimeComplementaireB = ({ data }: Props) => (
  <div className="regime regime-complementaire-b">
    <div className="regime-header">
      <span className="header-item year">{I18n.t('personalData.cotisationData.year')}</span>
      <span className="header-item">{I18n.t('personalData.cotisationData.cotisationDeBase')}</span>
      <span className="header-item">{I18n.t('personalData.cotisationData.cotisationAnnuelle')}</span>
    </div>
    <div className="regime-content">
      {data && data.map((year: RegimeComplementaireBAnnee) => (year.erreur
        ? (<div key={year.annee} className="ligne-erreur">{I18n.t('personalData.cotisationData.erreur')}</div>)
        : (
          <ItemAccordionRCB yearRegimeB={year}>
            <div className="subheader">
              <span className="dates">{I18n.t('personalData.cotisationData.dates')}</span>
              <span className="item item-rcb">{I18n.t('personalData.cotisationData.classeAffectation')}</span>
              <span className="item item-rcb">{I18n.t('personalData.cotisationData.classeCotisation')}</span>
              <span className="item item-rcb">{I18n.t('personalData.cotisationData.coeff')}</span>
            </div>
            {year.detail && year.detail.map((ligne: DetailRegimeComplementaireB) =>
                (
                  <div key={ligne.dateDebut} className="ligne">
                    <span className="dates number">{ligne.dateDebut} {I18n.t('personalData.cotisationData.to')} {ligne.dateFin}</span>
                    <span className="item number item-rcb">{ligne.classeAffaire}</span>
                    <span className="item number item-rcb">{ligne.classeCotisation}</span>
                    <span className="item number item-rcb">{ligne.coefficients}</span>
                  </div>
                ))
            }
          </ItemAccordionRCB>
        )
      ))}
    </div>
  </div>
);

export default RegimeComplementaireB;

// @flow

import React, { useCallback } from "react";
import { I18n } from "react-redux-i18n";
import { withRouter } from "react-router-dom";

import Back from "../../components/buttons/Back";
import MentionLegalesContainer from "../../components/mentionLegales/MentionLegalesContainer";

type Props = {
  history: Object,
};

const MentionLegalesPage = ({ history }: Props) => {
  const onClick = useCallback(() => history.goBack(), [history]);
  return (
    <div id="cgu-page" className="page-container">
      <Back
        className="mb-2"
        onClick={onClick}
        message={I18n.t("actions.BACK")}
      />
      <div className="cgu-content">
        <h1>{I18n.t("mention.title")}</h1>
        <MentionLegalesContainer className="cgu-container-portail" />
      </div>
    </div>
  );
};

export default withRouter(MentionLegalesPage);

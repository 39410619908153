// @flow

import React, { memo, useContext, useEffect } from "react";
import CustomButton from "../buttons/CustomButton";
import { I18n } from "react-redux-i18n";
import Modal from "./Modal";
import { ModalTypeEnum } from "../../utils/modal";
import ModalContext from "../../context/modal/ModalContext";
import loggerApi from "../../network/api/loggerApi";

const canOpen = (isOpen, typeModal) => {
  return isOpen && typeModal === ModalTypeEnum.HELP;
};

const HelperModal = () => {
  const { isOpen, typeModal, helpPage, closeModal } = useContext(ModalContext);

  useEffect(() => {
    if (helpPage && canOpen(isOpen, typeModal)) {
      loggerApi.addOpenHelpModal(helpPage);
    }
  }, [isOpen, typeModal, helpPage]);

  if (!helpPage || !canOpen(isOpen, typeModal)) {
    return null;
  }

  return (
    <Modal
      className={'helper-modal'}
      close={closeModal}
      isOpen={isOpen}
      headerTitle={I18n.t(`modales.${helpPage}.title`)}
      buttons={[
        <CustomButton
          message={I18n.t("actions.understood")}
          onClick={closeModal}
        />,
      ]}
    >
      {Object.values(I18n.t(`modales.${helpPage}.subtitles`)).map(
        (translated: any, index) => {
          return (
            <React.Fragment key={`translated_${index}`}>
              <h2 className="modal-content__subtitle">{translated.label}</h2>
              <p className="modal-content__text">{translated.content}</p>
              {Object.values(translated.list).map((element: any, index) => {
                if (typeof(element) === "object") {
                  return (<ul className="modal-content__subtext">
                    {Object.values(element).map((subelement: any, subindex) => {
                      return <li key={`sublist${index}_${subindex}`}>{subelement}</li>;
                    })}
                  </ul>)
                }
                else {
                 return <li key={`${translated.label}_${index}`}>{element}</li>;
                }
              })}
            </React.Fragment>
          );
        },
      )}
    </Modal>
  );
};

export default memo<void>(HelperModal);

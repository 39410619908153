// @flow
import React, { memo } from "react";

type Props = {
  message: string,
  onClick: Function,
  className?: string,
};

const Back = ({ message, onClick, className = "" }: Props) => (
  <div onClick={onClick} className={`back cursor-pointer ${className}`}>
    <span className="mr-1 fa fa-arrow-left" />
    {message}
  </div>
);

export default memo<Props>(Back);

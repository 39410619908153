// @flow

import React, { useContext, useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import GenericLoader from '../../components/utils/GenericLoader';
import LoadingContext from '../../context/loader/LoadingContext';
import { FAMILY_DATA_ROUTE, PERSONAL_DATA_HOME_ROUTE, PERSONAL_DATA_ROUTE } from '../../utils/routes';
import Back from '../../components/buttons/Back';
import Bloc from '../../components/accueil/Bloc';
import SubPageTitle from '../../components/utils/SubPageTitle';
import userApi from '../../network/api/userApi';
import { extractErrorFromSigma } from '../../utils/modal';
import type { Pension, RetirementData, Rights } from '../../types/types';
import ModalContext from '../../context/modal/ModalContext';
import NavigateDataButton from '../../components/buttons/NavigateDataButton';
import { NAVIGATION_RIGHTS } from '../../utils/const';
import { canSeeFamilyData } from '../../services/permissionService';

type Props = {|
  history: History,
  status: string,
  hasFamilyData: boolean,
|};

type RowType = {|
  label: string,
  value: string,
|}

type SubTitleType = {|
  label: string,
|}

type PensionOrMajoration = {|
  type: "pension" | "majoration",
  obj: Pension,
|}

const RenderRow = ({ label, value }: RowType) => (
  <div className="render-row description-line">
    <div className="label text-bold">
      {I18n.t(`personalData.retreatData.${label}`)}
    </div>
    <div className="value">
      {value}
    </div>
  </div>
);

const SubTitle = ({ label }: SubTitleType) => (
  <h3 className="subTitle">
    {I18n.t(`personalData.retreatData.${label}`)}
  </h3>
);

const InfoPensionOrMajoration = ({ type, obj }: PensionOrMajoration) => (
  <>
    {/*  Le libele "Montant Brut ne faisait que peu de sense mais au cas ou */}
    {/* {obj.base && <SubTitle label={`${type}.title`} className="subTitle" />} */}
    {obj.base && <RenderRow label={`${type}.base`} value={obj.base} />}
    {obj.sectionB && <RenderRow label={`${type}.sectionB`} value={obj.sectionB} />}
    {obj.sectionC && <RenderRow label={`${type}.sectionC`} value={obj.sectionC} />}
    {obj.sectionCM && <RenderRow label={`${type}.sectionCM`} value={obj.sectionCM} />}
    {obj.education && <RenderRow label={`${type}.education`} value={obj.education} />}
    {obj.invalidity && <RenderRow label={`${type}.invalidity`} value={obj.invalidity} />}
    {obj.temporary && <RenderRow label={`${type}.temporary`} value={obj.temporary} />}
    {obj.viager && <RenderRow label={`${type}.viager`} value={obj.viager} />}
    {type === 'majoration' && <div className="mb-2" />}
  </>
);

const Droit = ({
  type, dteOuverture, dteCloture,
}: Rights) => (
  <div>
    <div className="row">
      {type && <RenderRow label="rights.type" value={I18n.t(`right.${type}`)} className="type-title" />}
      {dteOuverture && <RenderRow label="rights.opening" value={dteOuverture} className="date-title" />}
      {dteCloture && <RenderRow label="rights.closure" value={dteCloture} className="date-title" />}
    </div>
    <div className="limit" />
  </div>
);

const RetreatData = ({ history, status, hasFamilyData }: Props) => {
  const [
    retirementData,
    setRetirementData,
  ] = useState<RetirementData>({
    pension: {},
    majoration: {},
    rights: [],
  });
  const { openErrorModal } = useContext(ModalContext);
  const { loading, showLoading, closeLoading } = useContext(LoadingContext);

  useEffect(() => {
    showLoading();
    userApi
      .getRetirementData()
      .then((response: Response) => response.json())
      .then(setRetirementData)
      .catch(extractErrorFromSigma(openErrorModal))
      .finally(closeLoading);
  }, [closeLoading, openErrorModal, showLoading]);

  const onClickRight = () => {
    if (NAVIGATION_RIGHTS.FAMILY.includes(status) && canSeeFamilyData(hasFamilyData, status)) {
      history.push(PERSONAL_DATA_ROUTE);
    }
  };

  const onClickLeft = () => {
    if (NAVIGATION_RIGHTS.FAMILY.includes(status) && canSeeFamilyData(hasFamilyData, status)) {
      history.push(FAMILY_DATA_ROUTE);
    } else {
      history.push(PERSONAL_DATA_ROUTE);
    }
  };

  return (
    <div className="page-container">
      <SubPageTitle
        className="m-auto"
        message={I18n.t('personalData.pageTitle.retreatment')}
        color="cobalt"
      />
      <div id="retreat-data">
        <Back
          className="mb-2"
          onClick={() => history.push(PERSONAL_DATA_HOME_ROUTE)}
          message={I18n.t('personalData.retreatData.back')}
        />
        <div className="bloc-container">
          <NavigateDataButton
            messageRight={NAVIGATION_RIGHTS.FAMILY.includes(status) &&
            canSeeFamilyData(hasFamilyData, status) ?
              I18n.t('personalData.personalData.title') :
              ''}
            onClickRight={onClickRight}
            messageLeft={NAVIGATION_RIGHTS.FAMILY.includes(status)
            && canSeeFamilyData(hasFamilyData, status) ?
              I18n.t('personalData.familyData.title') :
              I18n.t('personalData.personalData.title')
            }
            onClickLeft={onClickLeft}
          />
          <Bloc className="data" color="cobalt">
            <div className="seperate-between-family">
              <div className="personal-logo" />
              <div className="cobalt family-consult">
                {I18n.t('personalData.retreatData.consult')}
              </div>
            </div>
            {loading
              ? <GenericLoader label={I18n.t('tooltip.loadingData')} />
              : (
                <div className="data-bloc">
                  <div className="limit" />
                  <RenderRow label="typePayment" value={retirementData.paymentMean} />
                  {retirementData.virement && (
                    <div className="row">
                      <RenderRow label="iban" value={retirementData.iban} />
                      <RenderRow label="bic" value={retirementData.bic} />
                    </div>
                  )}
                  <div className="limit" />
                  {retirementData.rights.map((right, index) =>
                    (<Droit
                      key={right.type}
                      type={right.type}
                      dteOuverture={right.dteOuverture}
                      dteCloture={right.dteCloture}
                      isLast={index === retirementData.rights.length - 1}
                    />))}
                  <div className="row">
                    {retirementData.exoTax
                      && <RenderRow label="ssContribution" value={retirementData.exoTax} />}
                    <RenderRow label="taxPAS" value={retirementData.taxPas} />
                  </div>
                  <div className="limit" />
                  <div className="next-paiement">
                    <SubTitle label="lastPayment" />
                    <RenderRow label="datePayment" value={retirementData.dateNextPay} />
                    <InfoPensionOrMajoration type="pension" obj={retirementData.pension} />
                    <InfoPensionOrMajoration type="majoration" obj={retirementData.majoration} />
                    <RenderRow label="amountSs" value={retirementData.sumCharges} />
                    {retirementData.restraint && <RenderRow label="restraint" value={retirementData.restraint} />}
                    <RenderRow label="amountSource" value={retirementData.pas} />
                    <RenderRow label="pasInfo" value={retirementData.pasInfo} />
                    <RenderRow label="amountNet" value={retirementData.amountNet} />
                  </div>
                </div>
              )
            }
          </Bloc>
        </div>
      </div>
    </div>
  );
};

export default connect(state => ({
  status: state.user.user.statut,
  hasFamilyData: state.user.hasFamilyData,
}))(RetreatData);

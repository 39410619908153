// @flow

import React, { memo } from 'react';

type Props = {|
  label: string,
|};

const GenericLoader = ({ label }: Props) => (
  <div className="text-large-size d-flex justify-content-center">
    {label}
  </div>
);

export default memo<Props>(GenericLoader);

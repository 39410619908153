// @flow

import React, { createRef, useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";

import alerteApi from "../../network/api/alerteApi";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import CustomButton from "../buttons/CustomButton";

const typeNotif = {
  PROFIL: "INFP",
  GENERAL: "INFG",
  DANGER: "ALER",
};

type AlertType = {
  titre: string,
  texte: string,
  lien: string,
  type: $Keys<typeof typeNotif>,
  cible: "I" | "E",
};

type IconType = {
  type: string,
};

const Icon = ({ type }: IconType) => (
  <div className={`icon ${type}`}>
    {type === typeNotif.PROFIL && <i className="fa fa-bell" />}
    {type === typeNotif.GENERAL && <i className="fa fa-info-circle" />}
    {type === typeNotif.DANGER && <i className="fa fa-exclamation-triangle" />}
  </div>
);

type Props = {|
  history: Object,
  status: string,
|};

const Alert = ({ history, status }: Props) => {
  const [isOpen, toggle] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertType>({
    titre: "",
    texte: "",
    lien: "",
    type: typeNotif.GENERAL,
    cible: "I",
  });

  const alertRef = createRef();
  useOnClickOutside(alertRef, () => toggle(false));

  useEffect(() => {
    alerteApi
      .fetch(status)
      .then(res => res.json())
      .then((response: AlertType) => {
        setAlert(response);
        toggle(true);
      })
      .catch(e => {
        toggle(false);
      });
  }, []);

  const { type, titre, texte, lien } = alert;

  const openExternalLink = () => {
    window.open(alert.lien, "_blank");
  };

  const openInternalLink = () => {
    history.push(alert.lien);
  };

  return (
    <div
      className={`alert ${isOpen ? "isOpen" : "isClosed"} ${type}`}
      ref={alertRef}
    >
      <div className="header">
        <i
          className={`fal fa-times-circle close ${type}`}
          onClick={() => toggle(false)}
        />
      </div>
      <div className="infos">
        <Icon type={type} />
        <div className="content">
          {titre && <h2 className={`${type}`}>{titre}</h2>}
          <p>{texte}</p>
        </div>
      </div>
      {!!lien && (
        <CustomButton
          className="ghost-btn"
          message={I18n.t("dashboard.alert.link")}
          onClick={() => {
            if (alert.cible === "E") {
              openExternalLink();
            } else if (alert.cible === "I") {
              openInternalLink();
            }
          }}
          color={`${alert.type}`}
        />
      )}
    </div>
  );
};

export default Alert;

// @flow

import { RestClientAuthenticated } from '../network';

const baseUrl = '/external-service';

const externalServiceApi = {
  getDrl: () =>
    new RestClientAuthenticated(`${baseUrl}/drl`, 'GET').execute(),
  getInterRegime: () =>
    new RestClientAuthenticated(`${baseUrl}/inter-regime`, 'GET').execute(),
  getMaCarriere: () =>
    new RestClientAuthenticated(`${baseUrl}/ma-carriere`, 'GET').execute(),
};

export default externalServiceApi;

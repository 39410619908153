// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';

import Title from '../utils/Title';
import ArrowLabel from './ArrowLabel';
import PageToBeReleased from './PageToBeReleased';
import type { User } from '../../types/userType';
import type { Color, LinkInfos } from '../../types/types';
import { userStatus } from '../../utils/userStatus';

type Props = {
  history: Object,
  color: Color,
  fileList: LinkInfos[],
  title: string,
  route: string,
  user: User,
  loading?: boolean,
};

const DashboardBloc = ({
  history,
  color,
  fileList,
  title,
  route,
  user,
  loading,
}: Props) => {
  const blocContent = () => {
    if (fileList.length !== 0) {
      return (
        <>
          {fileList.map((link: LinkInfos) => (
            <>
              <div className="seperate-click" key={link.label}>
                {/* TODO: renommer right en rights partout */}
                {link.right.find((right: string) => right === user.statut
                    || right === userStatus.ALL) && (
                    <div
                      className={`link --${color} cursor-pointer${link.subLabel ? ' doc-list' : ''}`}
                      onClick={() => {
                        if (!!link.route && !link.noData) {
                          history.push(link.route);
                        }
                      }}
                    >
                      {link.noData && (
                        <PageToBeReleased
                          message={I18n.t('tooltip.noDataAvailable')}
                          className="page-to-be-released"
                        />
                      )}
                      {!link.noData && !link.route && (
                        <PageToBeReleased
                          message={I18n.t('tooltip.pageToBeReleased')}
                          className="page-to-be-released"
                        />
                      )}
                      <ArrowLabel
                        disabled={!link.route || link.noData}
                        label={I18n.t(link.label)}
                        subLabel={link.subLabel}
                        color={color}
                        icon={link.icone}
                        text={link.nomDelegant}
                        bold={!link.alreadyDownloaded}
                        svgPath={link.svgPath}
                        link={link}
                      />
                    </div>
                  )}
              </div>
            </>
          ))}
        </>
      );
    }
    if (loading) {
      return (
        <div className="dashboard-no-data">
          <div className="link">
            {I18n.t('tooltip.loadingData')}
          </div>
        </div>
      );
    }
    return (
      <PageToBeReleased
        message={I18n.t('tooltip.noFileAvailable')}
        className="page-to-be-released"
        style="no-file-available"
      />
    );
  };

  return (
    <div className={`bloc --${color}`}>
      <Title className="m-auto" message={title} color={color} />
      {blocContent()}
      <div className="bloc-button-container">
        <div
          className={`bloc-button-circle p-relative --${color} cursor-pointer`}
          onClick={() => history.push(route)}
        >
          {route === '' && (
            <PageToBeReleased
              message={I18n.t('tooltip.noFileAvailable')}
              className="page-to-be-released"
            />
          )}
          <i className={`fa fa-chevron-right  --${color}`} />
        </div>
      </div>
    </div>
  );
};

export default connect(state => ({
  user: state.user.user,
}))(DashboardBloc);

// @flow

import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { ExpansionPanel as Accordion } from '@material-ui/core';
import { RegimeComplementaireBAnnee } from '../../../../../types/types';
import Infobulle from './Infobulle';
import { displayCotisationValue } from '../../../../../utils/utils';

type Props = {|
    yearRegimeB: RegimeComplementaireBAnnee,
    children: React.ReactNode,
|};

const ItemAccordionRCB = ({ yearRegimeB, children }: Props) => {
  const [toggled, setToggled] = useState<boolean>(false);

  const iconToDisplay = () => {
    if (toggled) {
      return 'fas fa-chevron-down up';
    }
    return 'fas fa-chevron-down down';
  };

  return (
    <div className="accordion-item" key={yearRegimeB.annee}>
      <Accordion className="accordion" expanded={toggled}>
        <div className={`accordion-item-header ${toggled ? 'up' : ''}`} onClick={() => setToggled(!toggled)}>
          <span className="header-item number year">{yearRegimeB.annee}</span>
          <span className="header-item number">{displayCotisationValue(yearRegimeB.tx, true)}</span>
          <span className="header-item number">
            {displayCotisationValue(yearRegimeB.cotisation, true)}
            <Infobulle title={I18n.t('personalData.cotisationData.rcbInfobulle')} />
          </span>
          <div className="dropdown-icon">
            <i className={iconToDisplay()} />
          </div>
        </div>
        <div className="accordion-item-content">
          {children}
        </div>
      </Accordion>
    </div>
  );
};

export default ItemAccordionRCB;

// @flow

import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import queryString from "query-string";
import { withRouter } from "react-router-dom";

import type { User } from "../../../types/userType";
import franceConnectApi from "../../../network/api/franceConnectApi";
import { FRANCE_CONNECT_LOGOUT } from "../../../utils/const";

type Props = {
  dispatch: Function,
  user: User,
  isConnected: boolean,
  location: Object,
};

function CartoucheFc({ user, isConnected, dispatch, location }: Props) {
  const [displayed, toggle] = useState<boolean>(false);
  const [historyLink, updateLink] = useState<string>("");

  useEffect(() => {
    franceConnectApi
      .getHistoryLink()
      .then(response => response.text())
      .then((link: string) => {
        updateLink(link);
      });
  }, []);

  const disconnect = () => {
    if (isConnected) {
      window.location.href = `${FRANCE_CONNECT_LOGOUT}?token=${user.tokenFc}`;
    } else {
      const { token } = queryString.parse(location.search);
      franceConnectApi
        .getTokenFC(token)
        .then(response => response.text())
        .then((token: string) => {
          window.location.href = `${FRANCE_CONNECT_LOGOUT}?token=${token}`;
        });
    }
  };

  return (
    <div id="fconnect-profile" onClick={() => toggle(!displayed)}>
      <a className="link" href="#">
        {`${user.firstName} ${user.lastName}`}
      </a>
      {displayed && (
        <div id="fconnect-access" style={{ display: "block" }}>
          <h5>{I18n.t("franceConnect.connected")}</h5>
          <a target="_blank" href="https://franceconnect.gouv.fr">
            {I18n.t("franceConnect.question")}
          </a>
          <hr />
          <a target="_blank" rel="noopener noreferrer" href={historyLink}>
            {I18n.t("franceConnect.history")}
          </a>
          <div className="logout">
            <a className="btn btn-default" onClick={disconnect}>
              {I18n.t("franceConnect.disconnect")}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo<Props>(withRouter(connect()(CartoucheFc)));

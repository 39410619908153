// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import Title from '../Title';
import Back from '../../buttons/Back';
import type { Color } from '../../../types/types';

type Props = {
  children: any,
  menu: string,
  color: Color,
  hasBackButton?: boolean,
  handleClickBack?: Function,
};

const InternalPage = ({
  children,
  menu,
  color,
  hasBackButton = false,
  handleClickBack = () => {},
}: Props) => (
  <div className="page-container">
    <Title className="m-auto" message={menu} color={color} />
    {hasBackButton && (
    <Back
      className="mb-2"
      onClick={handleClickBack}
      message={I18n.t('actions.BACK')}
    />
      )}
    {children}
  </div>
);

export default InternalPage;

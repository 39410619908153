// @flow

import React, { memo, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import frFR from 'date-fns/locale/fr';
import NumberFormat from 'react-number-format';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { CoreozDatePickerMaterial } from 'coreoz-form-base';
import 'react-datepicker/dist/react-datepicker.css';

import { Field, Form, FormSpy } from 'react-final-form';
import { I18n } from 'react-redux-i18n';

import CustomButton from '../buttons/CustomButton';
import { formFieldType, labelType, momentFormat } from '../../utils/const';
import Tooltip from '../landingPage/Tooltip';
import {
  completeValueWithMask,
  flattenObj,
  getArrayFromNumber,
  getLabel,
  getValueFromMask,
  isFieldConditionRespected,
} from '../../utils/utils';
import FormChildren from './forms/FormChildren';
import Modal from '../modals/Modal';
import type { TypeFormField } from '../../types/formTypes';

registerLocale('fr-FR', frFR);

type Props = {|
  className?: string,
  initialValues: Object,
  validate: Function,
  onSubmit?: Function,
  fields: TypeFormField[],
  translationBase: string,
  grid?: number[], // Permet de créer un formulaire sur plusieurs colonnes.
  // Les champs fils doivent contenir l'information 'row' pour que cela fonctionne.
  hasButton?: boolean,
  disabledFields?: boolean,
  color?: string,
  children?: any,
  secondaryLabel?: boolean,
  useValidation?: boolean, // si false n'utilise pas la validation interne de react final form
|};

const GenericForm = ({
  className,
  initialValues,
  validate,
  onSubmit,
  fields,
  translationBase,
  grid,
  children,
  hasButton = true,
  disabledFields = false,
  color,
  disabled = false,
  secondaryLabel = false,
  showValidationModal = false,
  useValidation = false,
}: Props) => {
  // variables needed for the confirmation modal
  const [isShowing, toggle] = useState<boolean>(false);
  const [valuesToSubmit, saveValuesToSubmit] = useState<Object>();
  const openModal = (values) => {
    saveValuesToSubmit(values);
    toggle(true);
  };
  const closeModal = () => {
    toggle(false);
  };

  const onSubmitFunction = (data) => {
    if (showValidationModal) {
      openModal(data);
    } else if (onSubmit) {
      onSubmit(data);
    }
  };

  const required = (value, field, values) => {
    const isDisabeld = !isFieldConditionRespected(values, field.disabledBy);
    return ((field.required && !isDisabeld && !value) ? I18n.t('validation.required') : undefined);
  };

  const getFieldCount = (values: Object[], name: string) =>
    (values && values[name] ? values[name].length : 0);

  const onCharacterChange = (
    e: Object,
    field: TypeFormField,
    input: Object,
  ) => {
    const { value } = e.target;
    const brutValue = getValueFromMask(field.mask, value);
    e.target.value = completeValueWithMask(
      field.mask,
      field.maxLength,
      field.space,
      brutValue,
    );
    input.onChange(e);
    const caretPosition = Math.max(
      0,
      brutValue.length + field.space * (brutValue.length - 1),
    );
    e.target.setSelectionRange(caretPosition, caretPosition);
  };

  const renderField = (
    field: TypeFormField,
    values: Object[],
    push: Function,
    pop: Function,
    name?: string = '',
  ) => {
    const formGrid = field.grid || [1];
    switch (field.type) {
      case formFieldType.INPUT:
        return (
          <Field
            name={`${name ? `${name}.` : ''}${field.name}`}
            validate={(e, allValues) => required(e, field, allValues)}
            render={({ input, meta }) => (
              <label
                htmlFor={field.name}
                id={`${name ? `${name}` : ''}${field.name}`}
                className={`
                  ${!isFieldConditionRespected(values, field.disabledBy) ||
                  field.disabled
                    ? 'disabled'
                    : ''} ${field.offset ? 'offset' : ''}`}
              >
                {!field.hideTitle &&
                <div
                  className={`label d-flex justify-content-between field-title ${
                    meta.touched && meta.error ? 'error' : ''
                    }`}
                >
                  <span className={field.required ? 'asterisk' : ''}>
                    {field.showLabel && getLabel(translationBase, field.name, labelType.label)}
                  </span>
                </div>
                }
                <div
                  className={`field field-value ${
                    meta.touched && meta.error ? 'error' : ''
                    }`}
                >
                  {field.icon && <div className={`icon ${field.icon}`} />}
                  {field.mask && !field.numeric && (
                    <input
                      {...input}
                      disabled={disabledFields}
                      placeholder={completeValueWithMask(
                        field.mask,
                        field.maxLength,
                        field.space,
                      )}
                      className="character"
                      onChange={(e: Object) =>
                        onCharacterChange(e, field, input)
                      }
                    />
                  )}
                  {field.mask && field.numeric && (
                    <NumberFormat
                      {...input}
                      format={completeValueWithMask(
                        '#',
                        field.maxLength,
                        field.space,
                      )}
                      className="number"
                      disabled={disabledFields}
                      mask={field.mask}
                      defaultValue=""
                      allowEmptyFormatting
                    />
                  )}
                  {!field.mask && (
                    <input
                      {...input}
                      className={`${field.icon ? 'form-icon' : 'w-100'}`}
                      name={field.name}
                      disabled={disabledFields}
                      maxLength={field.maxLength && field.maxLength}
                      type={field.numeric ? 'number' : ''}
                    />
                  )}
                  {field.showTooltip && (
                    <Tooltip
                      message={getLabel(
                        translationBase,
                        field.name,
                        labelType.tooltip,
                      )}
                      className="p-relative"
                      shouldTranslate={false}
                    />
                  )}
                </div>
                {meta.touched && meta.error && (
                  <div className="error-message color-red">{meta.error}</div>
                )}
              </label>
            )}
          />
        );
      case formFieldType.CHECKBOX:
        return (
          <Field
            name={`${name ? `${name}.` : ''}${field.name}`}
            disabled={
              field.disabledBy &&
              values[field.disabledBy.label] === field.disabledBy.value
            }
            type="checkbox"
            render={({ input, meta }) => (
              <label
                htmlFor={field.name}
                className={`checkbox-container ${
                  field.textJustify ? 'text-justify' : ''
                  } ${field.fontSize ? `fs-${field.fontSize}` : ''}`}
              >
                {getLabel(translationBase, field.name)}
                {field.link && (
                  <a href={field.link} target="_blank" className="zi-2 link">
                    {getLabel(translationBase, field.name, labelType.link)}
                  </a>
                )}
                <input
                  type="checkbox"
                  {...input}
                  disabled={disabledFields}
                  name={field.name}
                  color="primary"
                  className="checkbox"
                />
                <span className="checkmark" />
                {meta.touched && meta.error && (
                  <div className="error-message color-red">{meta.error}</div>
                )}
              </label>
            )}
          />
        );
      case formFieldType.RADIO:
        return field.options.map((option, index) => (
          <Field
            key={`${field.name}_option_${index}`}
            name={`${name ? `${name}.` : ''}${field.name}`}
            type="radio"
            value={option.value}
            render={({ input }) => (
              <label htmlFor={field.name} className="radio-container">
                <span>{option.label}</span>
                <strong>{option.subLabel}</strong>
                <input
                  {...input}
                  disabled={disabledFields || field.disabled}
                  className="radio"
                  type="radio"
                  value={option.value}
                />
                <span className="radiomark" />
              </label>
            )}
          />
        ));
      case formFieldType.SELECT:
        return (
          <Field
            name={`${name ? `${name}` : ''}${field.name}`}
            validate={(e, allValues) => required(e, field, allValues)}
            disabled={
              field.disabledBy &&
              values[field.disabledBy.label] === field.disabledBy.value
            }
            render={({ input, meta }) => (
              <label
                id={`${name ? `${name}` : ''}${field.name}`}
                htmlFor={field.name}
                className={`${field.classsName && field.classsName} `}
              >
                <div
                  className={`d-flex justify-content-between label ${
                    meta.touched && meta.error ? 'error' : ''
                    }`}
                >
                  <span className={field.required && 'asterisk'}>
                    {getLabel(translationBase, field.name)}
                  </span>
                </div>
                <div
                  className={`field field-value ${ // select
                    meta.touched && meta.error ? 'error' : ''
                    }`}
                >
                  <select
                    className="w-100"
                    name={field.name}
                    {...input}
                    disabled={disabledFields}
                  >
                    {field.options.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {field.showTooltip && (<Tooltip
                    message={getLabel(translationBase, field.name, labelType.tooltip)}
                    className="p-relative"
                    shouldTranslate={false}
                  />)}
                </div>
                {meta.touched && meta.error && (
                  <div className="error-message color-red">{meta.error}</div>
                )}
              </label>
            )}
          />
        );
      case formFieldType.LEGEND:
        return (
          <div className="asterisk legend">
            <span>{I18n.t('form.required')}</span>
          </div>
        );
      case formFieldType.SUBTITLE:
        return (
          <strong className={`subtitle ${field.titleColor && field.titleColor}`}>
            {getLabel(
              translationBase,
              field.name,
              labelType.label,
              getFieldCount(values, field.countField),
            )}
          </strong>
        );
      case formFieldType.LINE:
        return (
          <div
            className={`${field.className && field.className}${
                    field.bold ? ' text-bold' : ''
                }${
                    field.uppercase ? ' text-uppercase' : ''
                } ${field.color} description-subtitle
              ${field.mb ? `mb-${field.mb}` : ''}  ${field.fontSize ? `fs-${field.fontSize}` : ''}
              `}
          >
            {getLabel(
              translationBase,
              field.name,
              labelType.label,
              getFieldCount(values, field.countField),
              secondaryLabel && field.secondaryLabel,
            )}
          </div>
        );
      case formFieldType.LIMIT:
        return (
          <div
            className={`limit
                    ${field.mb ? `mb-${field.mb}` : ''}
                    ${field.color}
                    `}
          />
        );
      case formFieldType.VALUEONLY:
        return (
          <Field
            name={`${name ? `${name}.` : ''}${field.name}`}
            render={({ input }) => (
              <div className="form-description">
                <span
                  className={`
                  ${field.className && field.className}
                  ${field.bold ? ' text-bold' : ''}
                  ${field.uppercase ? ' text-uppercase' : ''}
                  ${field.fontSize ? `fs-${field.fontSize}` : ''}
                  ${field.color}`}
                  style={{
                    flexBasis: `${(typeof field.flexBasis === 'number'
                      ? `${field.flexBasis}%`
                      : field.flexBasis) || '100%'}`,
                  }}
                >
                  {input.value === null || input.value === ''
                    ? '-'
                    : input.value}
                </span>
              </div>
            )}
          />
        );
      case formFieldType.DESCRIPTION:
        return (
          <Field
            className={`test ${field.className}`}
            name={`${name ? `${name}.` : ''}${field.name}`}
            render={({ input }) => (
              <div className="description ">
                {input.name.endsWith('defaultAddress') ? (
                  <div>
                    <p
                      className={`label ${field.textJustify ? ' text-justify' : ''}${
                        field.noJustifyContent ? ' no-justify-content' : ''
                        }${
                        field.titleBold ? ' text-bold' : ''
                        } cobalt text-large-size`}
                    >
                      {getLabel(
                        translationBase,
                        field.name,
                        labelType.label,
                        getFieldCount(values, field.countField),
                        secondaryLabel && field.secondaryLabel,
                      )}
                    </p>
                    {field.subLabel && field.subLabel}
                    {input.value && (
                      <div
                        className={`${
                          field.uppercase ? ' text-uppercase' : ''
                          } default-address-format`}
                      >
                        {input.value}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={`form-description${field.largeData ? ' large-data' : ''} ${field.column ? 'column' : ''}`}>
                    <p
                      className={`label ${field.textJustify ? ' text-justify' : ''}${
                        field.noJustifyContent ? ' no-justify-content' : ''
                        }${field.titleBold ? ' text-bold' : ''}
                        ${field.titleColor ? field.titleColor : ''}`}
                    >
                      {field.showLabel !== false &&
                      `${getLabel(
                        translationBase,
                        field.name,
                        labelType.label,
                        getFieldCount(values, field.countField),
                      )}${field.noInput ? '' : ' '}`}
                      {field.subLabel && field.subLabel}
                    </p>
                    {(input && !field.noInput) && (
                      <span
                        className={`value
                        ${field.bold ? ' text-bold' : ''}
                        ${field.uppercase ? ' text-uppercase' : ''}
                        ${field.color ? ` ${field.color}` : ''}
                        ${field.noWrap ? ' no-wrap' : ''}
                        `}
                        style={{
                          flexBasis: `${(typeof field.flexBasis === 'number'
                            ? `${field.flexBasis}%`
                            : field.flexBasis) || '40%'}`,
                        }}
                      >
                        {input.value === null || input.value === ''
                          ? '-'
                          : (field.format ?
                            (<NumberFormat format={field.format} value={input.value} displayType="text" />)
                            : input.value)}
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          />
        );
      case formFieldType.DATE:
        return (
          <Field
            required={field.required}
            validate={(e, allValues) => required(e, field, allValues)}
            name={`${name ? `${name}.` : ''}${field.name}`}
            render={({ input, meta }) => (
              <label
                htmlFor={field.name}
                id={`${name ? `${name}` : ''}${field.name}`}
                className={(field.className && field.className) +
                (field.disabledBy &&
                  ((values[field.disabledBy.label] === field.disabledBy.value &&
                    !field.disabledBy.reverse) ||
                    (values[field.disabledBy.label] !==
                      field.disabledBy.value &&
                      field.disabledBy.reverse))
                    ? 'disabled'
                    : '')
                }
              >
                <div
                  className={`label d-flex justify-content-between ${
                    meta.touched && meta.error ? 'error' : ''
                    }`}
                >
                  <span className={field.required && 'asterisk'}>
                    {field.showLabel && getLabel(translationBase, field.name)}
                  </span>
                  {field.showTooltip && (
                    <Tooltip
                      message={getLabel(translationBase, field.name, labelType.tooltip)}
                      className="p-relative"
                      shouldTranslate={false}
                    />
                  )}
                </div>
                <div
                  className={`field ${
                    meta.touched && meta.error ? 'error' : ''
                    }`}
                >
                  <div className="fa fa-calendar-alt icon" />
                  <CoreozDatePickerMaterial
                    input={input}
                    meta={{
                      ...meta,
                      error: undefined,
                    }}
                    onlyDate
                    disabled={disabledFields}
                    dateFormat={
                      field.showMonthYearPicker ? momentFormat.MONTH_YEAR : momentFormat.DATE
                    }
                    className="datepicker"
                    minDate={field.minDate}
                    maxDate={field.maxDate}
                    selected={input.value}
                    locale="fr-FR"
                  />
                </div>
                {meta.touched && meta.error && (
                  <div className="error-message color-red">{meta.error}</div>
                )}
              </label>
            )}
          />
        );
      case formFieldType.ARRAY:
        return (
          <>
            <FieldArray
              name={`${name ? `${name}.` : ''}${field.name}`}
              className={`${field.className}  ${field.titleBold ? ' text-bold' : ''}
                        ${field.titleColor ? field.titleColor : ''}`}
            >
              {({ fields }) =>
                fields.map((fieldName, index) => (
                  <div key={`${fieldName}_${index}`}>
                    <div className={`mb-1
                        ${field.titleBold ? ' text-bold' : ''}
                        ${field.titleColor ? field.titleColor : ''}
                        ${field.uppercase ? ' text-uppercase' : ''}
                        ${field.color ? ` ${field.color}` : ''}
                        ${field.noWrap ? ' no-wrap' : ''}
                        ${field.fontSize ? `fs-${field.fontSize}` : ''}
                        `}
                    >
                      {field.hideTitle ? '' : I18n.t(`${translationBase}${field.name}.label`, {
                        count: index + 1,
                      })}
                      {field.disabled === false ||
                      (!field.disabled && (
                        <div
                          onClick={() => fields.remove(index)}
                          className="fa fa-times ml-1 cursor-pointer"
                        />
                      ))}
                    </div>
                    {getArrayFromNumber(formGrid.length).map(rowNumber => (
                      <div key={`row_${rowNumber}`} className="row">
                        {field.fields && field.fields
                          .filter(f => f.row === rowNumber || !f.row)
                          .map((subField, subIndex) => (
                            <div
                              key={`field_${subIndex}`}
                              className={`${subField.className && subField.className}
                              ${subField.mb ? `mb-${subField.mb}` : ''
                                } size-${formGrid[rowNumber - 1]} ${field.offset ? 'offset' : ''}`}
                            >
                              {renderField(
                                subField,
                                values,
                                push,
                                pop,
                                fieldName,
                              )}
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                ))
              }
            </FieldArray>
          </>
        );
      case formFieldType.SUBTITLE_FAMILY:
        return (
          <strong className="subtitle-family">
            {getLabel(
                        translationBase,
                        field.name,
                        labelType.label,
                        getFieldCount(values, field.countField),
                    )}
          </strong>
        );
      case formFieldType.ARRAY_FAMILY:
        return (
          <>
            <FieldArray name={`${name ? `${name}.` : ''}${field.name}`}>
              {({ fields }) =>
                            fields.map((fieldName, index) => (
                              <FormChildren
                                field={field}
                                fields={fields}
                                translationBase={translationBase}
                                formGrid={formGrid}
                                renderField={renderField}
                                fieldName={fieldName}
                                index={index}
                                values={values}
                                pop={pop}
                                push={push}
                              />
                            ))
                        }
            </FieldArray>
            {!field.disabled && !disabled &&
                    (
                    <div
                      className="add"
                    >
                      <div
                        className="fa fa-plus"
                        onClick={() => push(field.name, { genre: 'Homme' })}
                      />
                      <span
                        onClick={() => push(field.name, { genre: 'Homme' })}
                      >{getLabel(translationBase, field.name, labelType.add)}
                      </span>
                    </div>
                    )}
          </>);
      default:
        return null;
    }
  };

  const formGrid = grid || [1];
  const maxColumn = Math.max(...formGrid);
  return (
    <div className={`form ${className || ''} size-${maxColumn} ${disabled ? 'disabled' : ''}`}>
      <Form
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmitFunction}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit,
          form: {
            mutators: { push, pop },
          },
          invalid,
          values,
          pristine,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormSpy subscription={{ values: true }}>
              {({ values }: any) => (
                <>
                  {getArrayFromNumber(formGrid.length).map(rowNumber => (
                    <div key={`row_${rowNumber}`} className="row">
                      {fields && fields
                        .filter(
                          field =>
                            (field.row === rowNumber || !field.row) &&
                            isFieldConditionRespected(values, field.hiddenBy),
                        )
                        .map((field, index) => (
                          <div
                            key={`field_${index}`}
                            className={`${
                              field.mb ? `mb-${field.mb}` : ''
                              }  size-${formGrid[rowNumber - 1]}
                              ${field.offset ? 'offset' : ''}
                              ${field.className ? field.className : ''}`}
                          >
                            {renderField(field, values, push, pop)}
                          </div>
                        ))}
                    </div>
                  ))}
                  {hasButton && (
                    <CustomButton
                      color={color}
                      type="submit"
                      message={I18n.t(`${translationBase}button`)}
                      disabled={useValidation ? (pristine || invalid || disabled) : false}
                      onClick={() => {
                        if (invalid) {
                          const resultFlatten = flattenObj(errors);
                          Object.keys(resultFlatten).forEach(key => (!resultFlatten[key]) && delete resultFlatten[key]);
                          const errorsKeysList = Object.keys(resultFlatten);

                          self.location.href = `#${errorsKeysList[0]}`;
                        }
                        }
                      }
                    />
                  )}
                  {
                    children && children
                  }
                </>
              )}
            </FormSpy>

            {/* modal de validation */}
            {isShowing && (
              <Modal
                className="modal-confirmation"
                isOpen={isShowing}
                close={closeModal}
                headerTitle={I18n.t('personalData.personalData.validationModal.title')}
                buttons={
                          [
                            <CustomButton
                              className="btn-confirmation"
                              message={I18n.t('actions.CONFIRM')}
                              onClick={() => {
                                if (onSubmit) {
                                  onSubmit(valuesToSubmit);
                                }
                                closeModal();
                              }}
                            />,
                            <CustomButton
                              className="btn-cancel"
                              message={I18n.t('actions.CANCEL')}
                              onClick={() => {
                                      closeModal();
                                  }}
                            />,
                          ]}
              >
                <div className="modal-body">
                  {I18n.t('personalData.personalData.validationModal.body')}
                </div>
              </Modal>
              )}
          </form>
        )}
      />
    </div>
  );
};

export default memo<Props>(GenericForm);


// @flow

import { RestClient } from '../network';

const baseUrl = '/cartouche';

const cartoucheApi = {
  fetchCartouche: (idn: string) =>
    new RestClient(`${baseUrl}/${idn}`, 'GET').execute(),
};

export default cartoucheApi;

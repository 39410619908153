import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { getLabel, getValueFromMask } from './utils';
import { countryList, genreList } from './data';
import { formFieldType, formTranslationType, labelType, loginType } from './const';

export const step1Fields = [
  {
    type: formFieldType.INPUT,
    name: 'nir',
    showLabel: true,
    showTooltip: true,
    required: true,

    // CHARACTER
    mask: '_',
    maxLength: 13,
    space: 1,

    mb: 2,
    validation: (value: string) => {
      const regex = new RegExp(VALIDATION.NIR);
      if (!regex.test(getValueFromMask('_', value))) {
        return getLabel(formTranslationType.enrolement, 'nir', labelType.invalid);
      }
      return null;
    },
  },
  {
    type: formFieldType.INPUT,
    name: 'cprn',
    showLabel: true,
    showTooltip: true,
    required: true,
    validation: (value: string) => {
      const regex = new RegExp(/^[a-zA-Z0-9]{9}$/);
      if (!regex.test(getValueFromMask('_', value))) {
        return getLabel(formTranslationType.enrolement, 'cprn', labelType.invalid);
      }
      return null;
    },

    // CHARACTER
    mask: '_',
    maxLength: 9,
    space: 1,

    mb: 2,
  },
  {
    type: formFieldType.INPUT,
    name: 'zipcode_ID_NOT',
    showLabel: true,
    showTooltip: true,
    required: true,
    validation: (value: string) => {
      const regex = new RegExp(VALIDATION.ZIP_CODE);
      if (!regex.test(getValueFromMask('_', value))) {
        return getLabel(
          formTranslationType.enrolement,
          'zipcode_ID_NOT',
          labelType.invalid,
        );
      }
      return null;
    },
    conditionedBy: loginType.ID_NOT,
    hiddenBy: {
      label: 'notFrance',
      value: true,
    },
    // CHARACTER
    mask: '_',
    numeric: true,
    maxLength: 2,
    space: 1,

    mb: 1,
  },
  {
    type: formFieldType.INPUT,
    name: 'zipcode_FRANCE_CONNECT',
    showLabel: true,
    showTooltip: true,
    required: true,
    hiddenBy: {
      label: 'notFrance',
      value: true,
    },
    conditionedBy: loginType.FRANCE_CONNECT,

    // CHARACTER
    mask: '_',
    numeric: true,
    maxLength: 2,
    space: 1,

    mb: 1,
  },
  {
    type: formFieldType.LEGEND,
    mb: 1,
  },
  {
    type: formFieldType.CHECKBOX,
    name: 'notFrance',
    required: false,
    mb: 1,
    conditionedBy: loginType.FRANCE_CONNECT,
  },
  {
    type: formFieldType.SELECT,
    name: 'country',
    required: true,
    mb: 2,
    options: countryList.filter(c => c.value !== 'FR'),
    showTooltip: true,
    conditionedBy: loginType.FRANCE_CONNECT,
    hiddenBy: {
      label: 'notFrance',
      value: false,
    },
  },
];

export const step2Fields = [
  {
    type: formFieldType.SUBTITLE,
    name: 'chooseEmail',
    mb: 1,
  },
  {
    type: formFieldType.RADIO,
    name: 'email',
    options: [],
  },
  {
    type: formFieldType.INPUT,
    name: 'otherEmail',
    disabledBy: {
      label: 'email',
      value: 'other',
      reverse: true,
    },
    required: true,
    hideTitle: true,
    validation: (value: string) => {
      const regex = new RegExp(VALIDATION.EMAIL);
      if (!regex.test(value)) {
        return getLabel(
          formTranslationType.parametrer,
          'otherEmail',
          labelType.invalid,
        );
      }
      return null;
    },
    mb: 4,
  },
  {
    type: formFieldType.SUBTITLE,
    name: 'dematerialiser',
    mb: 1,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'dematerialisationDescription',
    textJustify: true,
    mb: 2,
    noInput: true,
  },
  {
    type: formFieldType.SUBTITLE,
    name: 'confidentialityTitle',
    mb: 1,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'confidentialityDescription1',
    textJustify: true,
    mb: 2,
    noInput: true,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'confidentialityDescription2',
    textJustify: true,
    mb: 2,
    noInput: true,
  },
  {
    type: formFieldType.CHECKBOX,
    name: 'confidentiality',
    textJustify: true,
    fontSize: 14,
    mb: 1,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'confidentialityDescription3',
    textJustify: true,
    mb: 1,
    noInput: true,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'confidentialityDescription4',
    textJustify: true,
    mb: 3,
    noInput: true,
  },
  {
    type: formFieldType.LEGEND,
    mb: 1,
  },
];

export const retreatFields = [
  {
    type: formFieldType.DATE,
    className: 'yearPicker',
    name: 'leavingDate',
    showMonthYearPicker: true,
    minDate: moment(),
    required: true,
    showLabel: true,
    validation: (value: string) => {
      const date = moment(value);
      if (!date.isValid()) {
        return I18n.t('error.invalideDate');
      }
      return null;
    },
    mb: 3,
  },
  {
    type: formFieldType.CHECKBOX,
    name: 'reversion',
    mb: 2,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'conjoint',
    noJustifyContent: true,
    flexBasis: 'auto',
    hiddenBy: {
      label: 'reversion',
      value: false,
    },
    mb: 1,
  },
  {
    type: formFieldType.DATE,
    name: 'birthdate',
    className: 'yearPicker',
    maxDate: moment()
      .subtract(18, 'year')
      .toDate(),
    showYearDropdown: true,
    showLabel: true,
    required: true,
    hiddenBy: {
      label: 'reversion',
      value: false,
    },
    validation: (value: string) => {
      const date = moment(value);
      if (!date.isValid()) {
        return I18n.t('error.invalideDate');
      }
      return null;
    },
  },
  {
    type: formFieldType.LEGEND,
    mb: 1,
  },
];

export const userPersonalInfoFields = [
  {
    type: formFieldType.VALUEONLY,
    name: 'fullName',
    className: 'title',
    titleBold: true,
    bold: true,
    color: 'cobalt',
    showLabel: false,
    fontSize: 28,
    largeData: true,
    mb: 1,
    flexBasis: 100,

  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'birthDate',
    flexBasis: 40,
    titleBold: true,
    titleColor: 'cobalt',
    largeData: true,
    mb: 1,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'numpers',
    format: '### ### ###',
    flexBasis: 40,
    titleBold: true,
    titleColor: 'cobalt',
    largeData: true,
    mb: 1,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'birthName',
    flexBasis: 40,
    titleColor: 'cobalt',
    largeData: true,
    titleBold: true,
  },
];

export const familyDataFields = [
  {
    type: formFieldType.LIMIT,
    row: 1,
  },
  {
    type: formFieldType.LINE,
    name: 'conjointUpdate',
    className: 'conjointUpdate',
    mb: 1,
    row: 1,
    secondaryLabel: true,
  },
  {
    type: formFieldType.SELECT,
    titleColor: 'cobalt',
    name: 'conjoint.genre',
    showLabel: true,
    // required: true,
    requiredIfAllFilled: true,
    options: genreList,
    mb: 1,
    row: 2,
  },
  {
    type: formFieldType.DATE,
    titleColor: 'cobalt',
    name: 'conjoint.birthDate',
    // required: true,
    requiredIfAllFilled: true,
    maxDate: moment(),
    showYearDropdown: true,
    showLabel: true,
    mb: 1,
    row: 2,
  },
  {
    type: formFieldType.INPUT,
    titleColor: 'cobalt',
    name: 'conjoint.usageName',
    showLabel: true,
    mb: 1,
    row: 2,
  },
  {
    type: formFieldType.SELECT,
    titleColor: 'cobalt',
    name: 'conjoint.unionType',
    // required: true,
    requiredIfAllFilled: true,
    showLabel: true,
    options: [
      {
        label: '',
        value: '',
      },
      {
        label: 'Marié',
        value: 'M',
      },
      {
        label: 'Pacsé',
        value: 'P',
      },
    ],
    mb: 1,
    row: 2,
  },
  {
    type: formFieldType.INPUT,
    titleColor: 'cobalt',
    name: 'conjoint.birthName',
    showLabel: true,
    requiredIfAllFilled: true,
    // required: true,
    mb: 1,
    row: 2,
  },
  {
    type: formFieldType.DATE,
    name: 'conjoint.unionDate',
    titleColor: 'cobalt',
    maxDate: moment(),
    showYearDropdown: true,
    showLabel: true,
    requiredIfAllFilled: true,
    // required: true,
    mb: 1,
    row: 2,
  },
  {
    type: formFieldType.INPUT,
    name: 'conjoint.firstName',
    titleColor: 'cobalt',
    // required: true,
    requiredIfAllFilled: true,
    showLabel: true,
    mb: 1,
    row: 2,
  },
  {
    type: formFieldType.DATE,
    name: 'conjoint.unionEndDate',
    titleColor: 'cobalt',
    maxDate: moment(),
    showYearDropdown: true,
    showLabel: true,
    mb: 3,
    row: 2,
  },
  {
    type: formFieldType.LIMIT,
    row: 3,
  },
  {
    type: formFieldType.SUBTITLE_FAMILY,
    titleColor: 'cobalt',
    name: 'childrenSubtitle',
    countField: 'children',
    row: 3,
  },
  {
    type: formFieldType.LINE,
    name: 'childrenDescription',
    className: 'childrenDescription',
    color: 'cobalt',
    fontSize: 16,
    mb: 1,
    row: 3,
    secondaryLabel: true,
  },
  {
    type: formFieldType.ARRAY_FAMILY,
    name: 'children',
    titleColor: 'cobalt',
    fields: [
      {
        type: formFieldType.SELECT,
        name: 'genre',
        titleColor: 'cobalt',
        options: genreList,
        required: true,
        showLabel: true,
        mb: 1,
        row: 2,
      },
      {
        type: formFieldType.INPUT,
        name: 'firstName',
        titleColor: 'cobalt',
        required: true,
        showLabel: true,
        mb: 1,
        row: 2,
      },
      {
        type: formFieldType.INPUT,
        name: 'usageName',
        titleColor: 'cobalt',
        required: true,
        showLabel: true,
        mb: 1,
        row: 2,
      },
      {
        type: formFieldType.DATE,
        name: 'birthDate',
        titleColor: 'cobalt',
        required: true,
        maxDate: moment(),
        showYearDropdown: true,
        showLabel: true,
        mb: 3,
        row: 2,
      },
    ],
    grid: [1, 2],
    mb: 1,
    row: 4,
  },
];

export const professionalDataFields = [
  {
    type: formFieldType.LIMIT,
    mb: 1,
    row: 1,
  },
  {
    type: formFieldType.DESCRIPTION,
    titleColor: 'cobalt',
    name: 'sermonDate',
    column: true,
    titleBold: true,
    mb: 2,
    row: 2,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'status',
    titleColor: 'cobalt',
    titleBold: true,
    column: true,
    mb: 2,
    row: 2,
  },
  {
    type: formFieldType.LIMIT,
    mb: 1,
    row: 3,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'numberExercise',
    titleColor: 'cobalt',
    column: true,
    titleBold: true,
    mb: 2,
    row: 4,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'classe',
    titleColor: 'cobalt',
    column: true,
    titleBold: true,
    mb: 2,
    row: 4,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'prelevement',
    titleColor: 'cobalt',
    column: true,
    titleBold: true,
    mb: 2,
    row: 4,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'affectationClasse',
    titleColor: 'cobalt',
    column: true,
    titleBold: true,
    mb: 2,
    row: 4,
  },
  {
    type: formFieldType.LIMIT,
    mb: 1,
    row: 5,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'iban',
    titleColor: 'cobalt',
    titleBold: true,
    column: true,
    mb: 1,
    row: 6,
    largeData: true,
    noWrap: true,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'bic',
    titleColor: 'cobalt',
    column: true,
    titleBold: true,
    mb: 2,
    row: 6,
    largeData: true,
  },
  {
    type: formFieldType.LIMIT,
    mb: 1,
    row: 7,
  },
  {
    type: formFieldType.SUBTITLE,
    name: 'activitiesSubtitle',
    countField: 'activities',
    titleColor: 'cobalt',
    fontSize: 18,
    row: 7,
  },
  {
    type: formFieldType.ARRAY,
    name: 'activities',
    className: 'activities',
    hideTitle: true,
    disabled: true,
    fontSize: 18,
    titleBold: true,
    titleColor: 'cobalt',
    color: 'cobalt',
    fields: [
      {
        type: formFieldType.VALUEONLY,
        name: 'libelle',
        className: 'libelle',
        titleBold: true,
        mb: 1,
        row: 1,
        flexBasis: 70,
        offset: true,
      },
      {
        type: formFieldType.DESCRIPTION,
        name: 'dateDeb',
        className: 'date',
        mb: 1,
        row: 1,
        flexBasis: 50,
      },
      {
        type: formFieldType.DESCRIPTION,
        name: 'dateFin',
        className: 'date',
        mb: 1,
        row: 1,
        flexBasis: 50,
      },
    ],
    grid: [1],
    mb: 1,
    row: 10,
  },
];

export const professionalCompletDataFields = [
  {
    type: formFieldType.LIMIT,
    mb: 1,
    row: 1,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'sermonDate',
    titleColor: 'cobalt',
    column: true,
    titleBold: true,
    mb: 2,
    row: 2,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'status',
    column: true,
    titleColor: 'cobalt',
    titleBold: true,
    mb: 2,
    row: 2,
  },
  {
    type: formFieldType.LIMIT,
    mb: 1,
    row: 3,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'numberExercise',
    titleColor: 'cobalt',
    column: true,
    titleBold: true,
    mb: 2,
    row: 4,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'classe',
    titleColor: 'cobalt',
    column: true,
    titleBold: true,
    mb: 2,
    row: 4,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'prelevement',
    titleColor: 'cobalt',
    column: true,
    titleBold: true,
    mb: 2,
    row: 4,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'affectationClasse',
    titleColor: 'cobalt',
    column: true,
    titleBold: true,
    mb: 2,
    row: 4,
  },
  {
    type: formFieldType.LIMIT,
    mb: 1,
    row: 5,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'iban',
    titleColor: 'cobalt',
    column: true,
    titleBold: true,
    mb: 2,
    row: 6,
    largeData: true,
    noWrap: true,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'bic',
    titleColor: 'cobalt',
    column: true,
    titleBold: true,
    mb: 2,
    row: 6,
    largeData: true,
  },
  {
    type: formFieldType.LIMIT,
    mb: 1,
    row: 7,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'numberPoints',
    titleColor: 'cobalt',
    column: true,
    titleBold: true,
    mb: 2,
    row: 8,
  },
  {
    type: formFieldType.DESCRIPTION,
    name: 'costPoint',
    titleColor: 'cobalt',
    column: true,
    titleBold: true,
    mb: 2,
    row: 8,
  },
  {
    type: formFieldType.LIMIT,
    mb: 1,
    row: 9,
  },
  {
    type: formFieldType.SUBTITLE,
    name: 'activitiesSubtitle',
    countField: 'activities',
    titleColor: 'cobalt',
    fontSize: 18,
    row: 9,
  },
  {
    type: formFieldType.ARRAY,
    className: 'activities',
    name: 'activities',
    hideTitle: true,
    disabled: true,
    fontSize: 18,
    titleBold: true,
    titleColor: 'cobalt',
    color: 'cobalt',
    fields: [
      {
        type: formFieldType.VALUEONLY,
        name: 'libelle',
        className: 'libelle',
        titleBold: true,
        mb: 1,
        row: 1,
        flexBasis: 70,
        offset: true,
      },
      {
        type: formFieldType.DESCRIPTION,
        name: 'dateDeb',
        className: 'date',
        mb: 1,
        row: 1,
        flexBasis: 50,
      },
      {
        type: formFieldType.DESCRIPTION,
        name: 'dateFin',
        className: 'date',
        canBeHide: true,
        mb: 1,
        row: 1,
        flexBasis: 50,
      },
    ],
    grid: [1],
    mb: 1,
    row: 10,
  },
];

export const startEndDatesFields = [
  {
    type: formFieldType.DATE,
    name: 'startDate',
    required: true,
    maxDate: moment().toDate(),
    disabled: true,
    icon: 'fa fa-calendar-alt icon',
    showYearDropdown: true,
    showLabel: true,
    mb: 1,
    row: 1,
  },
  {
    type: formFieldType.DATE,
    name: 'endDate',
    required: true,
    maxDate: moment().toDate(),
    disabled: true,
    icon: 'fa fa-calendar-alt icon',
    showYearDropdown: true,
    showLabel: true,
    mb: 1,
    row: 1,
  },
];
export const dateFields = [
  {
    type: formFieldType.DATE,
    name: 'date',
    required: true,
    maxDate: moment().toDate(),
    disabled: true,
    icon: 'fa fa-calendar-alt icon',
    showYearDropdown: true,
    showLabel: true,
    showMonthYearPicker: true,
    mb: 1,
    row: 1,
  },
];
export const AttestationPaymentDateFields = [
  {
    type: formFieldType.DATE,
    name: 'dateDebut',
    className: 'datePicker',
    required: true,
    maxDate: moment().toDate(),
    disabled: true,
    icon: 'fa fa-calendar-alt icon',
    showYearDropdown: true,
    showLabel: true,
    mb: 1,
    row: 1,
    validation: (value: string) => {
      const date = moment(value);
      if (!date.isValid()) {
        return I18n.t('error.invalideDate');
      }
      return null;
    },
  },
  {
    type: formFieldType.DATE,
    name: 'dateFin',
    className: 'datePicker',
    required: true,
    maxDate: moment().toDate(),
    disabled: true,
    icon: 'fa fa-calendar-alt icon',
    showYearDropdown: true,
    showLabel: true,
    mb: 1,
    row: 1,
    validation: (value: string) => {
      const date = moment(value);
      if (!date.isValid()) {
        return I18n.t('error.invalideDate');
      }
      return null;
    },
  },
];

export const VALIDATION = {
  EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  // cf https://fr.wikipedia.org/wiki/Num%C3%A9ro_de_s%C3%A9curit%C3%A9_sociale_en_France
  NIR: /^([1-47-8])([0-9]{2})(0[1-9]|1[0-2]|20|3[0-9]|4[0-2]|[5-9][0-9])((0[1-9]|[1-9][0-9]|2[abAB])(00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)|(9[78][0-9])(0[1-9]|[1-8][0-9]|90)|(99(00[1-9]|0[1-9]{2}|[1-8][0-9]{2}|9[0-8][0-9]|990)))([0-9]{3})$/,
  ZIP_CODE: /^(0[1-9]|[1-8][0-9]|9[0-5]|97)([0-9]{3})$/,
};

export const optionsTypeUnion = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Marié',
    value: 'M',
  },
  {
    label: 'Pacsé',
    value: 'P',
  },
];

export const optionsMotiveUnionEnd = [
  {
    label: 'Séparation',
    value: 'S',
  },
  {
    label: 'Décès',
    value: 'D',
  },
];

// @flow
import { unwrap } from '../network/handleError';
import { userStatus } from '../utils/userStatus';

function hasPermission(permission: string) {
  if (sessionStorage.getItem('currentUser')) {
    return JSON.parse(
      unwrap(sessionStorage.getItem('currentUser')),
    ).permissions.includes(permission);
  }
  return false;
}

export default hasPermission;

export function canSeeFamilyData(hasFamilyData: boolean, status: string) {
  // You can see the Familydata page if you have familydata
  // or if you are not yet retired, beneficiary or if you're not a third party
  return hasFamilyData ||
    !(status === userStatus.RETRAITE ||
      status === userStatus.BENEFICIAIRE ||
      status === userStatus.TIERS_DE_CONFIANCE
    );
}

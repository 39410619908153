// @flow

import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Dispatch } from 'redux';
import { Delegate } from '../../types/types';
import Modal from '../../components/modals/Modal';
import userApi from '../../network/api/userApi';
import { notifySuccess } from '../../network/notification';
import { extractErrorFromSigma } from '../../utils/modal';
import { fetchCollaborators, fetchDelegations } from '../../state/user/userService';
import LoadingContext from '../../context/loader/LoadingContext';
import type { ConjointCollaborateur, TypePersonalData } from '../../types/types';

type Props = {|
  delegate: Delegate,
  delegateNumber: string,
  isDelegate: boolean,
  dispatch: Dispatch,
  hasMultipleRattachements: boolean,
  personalData: TypePersonalData,
  conjointCollaborateur: ConjointCollaborateur,
|};

const TIMEOUT_IN_MILLIS = 50;

const DelegateItem = ({
  delegate,
  delegateNumber,
  isDelegate,
  dispatch,
  hasMultipleRattachements,
  personalData,
  conjointCollaborateur,
}: Props) => {
  const [loadingMessage, timeoutDuration] = [I18n.t('delegation.loadingMessage'), 10];
  const {
    prenom, nom, sousType, mail, dateEnrolement, adresse,
  } = delegate;
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const fullname = `${prenom} ${nom}`;
  const modalHeaderTitle = isDelegate
    ? I18n.t('delegation.delegationModal.revocateAccesFrom.title', { name: fullname })
    : I18n.t('delegation.delegationModal.delegateAccesTo.title', { name: fullname });
  const modalContentText = isDelegate
    ? I18n.t('delegation.delegationModal.revocateAccesFrom.text', { name: fullname })
    : I18n.t('delegation.delegationModal.delegateAccesTo.text', { name: fullname });
  const modalContentTextList = I18n.t('delegation.delegationModal.list');
  const [isShowing, toggle] = useState<boolean>(false);

  const openModal = () => toggle(true);

  const closeModal = () => toggle(false);

  const switchDisabled = () => (delegateNumber === 2) && (isDelegate === false);

  const updateDelegation = () => {
    showLoading(loadingMessage, timeoutDuration);
    if (isDelegate) {
      userApi.deleteDelegation(delegate, personalData.email)
        .then(() => closeModal())
        .then(dispatch(notifySuccess))
        .catch(extractErrorFromSigma);
    } else {
      userApi.addDelegation(delegate, personalData.email)
        .then(() => closeModal())
        .then(dispatch(notifySuccess))
        .catch(extractErrorFromSigma);
    }

    // setTimeOut mis en place car l'api IDnot renvoie souvent des données qui
    // ne sont pas à jour dans le getDelegations même avec le code suivant :
    // userApi.deleteDelegation(delegate)
    //   .then(dispatch(notifySuccess))
    //   .then(dispatch(fetchDelegations()))
    //   .then(dispatch(fetchCollaborators()))
    //   .catch(extractErrorFromSigma);
    // Mettre en place le code ci-dessus dans le if else des lignes 38 à 46
    // quand le problème sera reglé de leur coté
    setTimeout(() => {
      Promise.all([
        dispatch(fetchDelegations()),
        dispatch(fetchCollaborators()),
      ])
        .then(() => closeLoading());
    }, TIMEOUT_IN_MILLIS);
  };

  return (
    <div className="delegate-item fs-16">
      {isShowing && (
        <Modal
          isOpen={isShowing}
          withCloseButton={false}
          close={closeModal}
          headerTitle={modalHeaderTitle}
          className="delegation-modal"
        >
          <div className="delegation-modal-content">
            <div className="delegation-modal-content-text">
              <p>{modalContentText}</p>
              <p>
                {Object.values(modalContentTextList).map((element: string, index: number) => (
                  <li key={`list_${index}`}>{element}</li>
                ))}
                {conjointCollaborateur && conjointCollaborateur.numpers
                  && (
                    <>
                      <li>{I18n.t('delegation.delegationModal.conjointCollaborateur.documents')}</li>
                      <li>{I18n.t('delegation.delegationModal.conjointCollaborateur.cotisation')}</li>
                    </>
                  )
              }
              </p>
            </div>
            <div className="btn-wrapper btn-custom">
              <button
                className="ripple white"
                type="button"
                onClick={closeModal}
              >{I18n.t('actions.CANCEL')}
              </button>
              <button
                className="ripple blue"
                type="button"
                onClick={updateDelegation}
              >{I18n.t('actions.CONFIRM')}
              </button>
            </div>
          </div>
        </Modal>
      )}
      <div className="delegate-item-name">
        <div className="name text-bold">{fullname}</div>
        {isDelegate && dateEnrolement &&
          <div className="item-txt">
            {I18n.t('delegation.delegationForm.enrolled')} {dateEnrolement}
          </div>
        }
      </div>
      <div className="delegate-item-infos">
        <div className="job">{sousType}</div>
        {hasMultipleRattachements &&
          <div className="text-uppercase">{adresse}</div>
        }
        {isDelegate && mail &&
          <div className="item-txt">{mail}</div>
        }
      </div>
      <div className="delegate-item-action centered-container field">
        <label className="switch small">
          <input
            type="checkbox"
            readOnly
            checked={isDelegate}
            onClick={openModal}
            disabled={switchDisabled()}
          />
          <span className={`slider round small${switchDisabled() ? ' cursor-disabled' : ''}`} />
        </label>
      </div>
    </div>

  );
};

export default connect(state => ({
  personalData: state.user.personalData,
  conjointCollaborateur: state.user.user.conjointCollaborateur,
  hasMultipleRattachements: state.user.hasMultipleRattachements,
}))(DelegateItem);

// @flow

import { RestClientAuthenticated } from '../network';

const baseUrl = '/documents';

const documentApi = {
  getDocuments: () =>
    new RestClientAuthenticated(baseUrl, 'GET').execute(),
  getDocumentsByTypeDocument: (typeCour: string, numpers?: string) => new RestClientAuthenticated(
    `${baseUrl}?typeDocument=${typeCour}${numpers ? `&numpers=${numpers}` : ''}`,
    'GET',
  ).execute(),
  downloadDocument: (idGED: string) =>
    new RestClientAuthenticated(`${baseUrl}/${idGED}`, 'GET').execute(),
  downloadDocumentFromTier: (idGED: string, numpers: string) =>
    new RestClientAuthenticated(`${baseUrl}/${idGED}?numpers=${numpers}`, 'GET').execute(),
  getDocumentsDisponibles: (numpers: string) => new RestClientAuthenticated(
    `${baseUrl}/disponibles?numpers=${numpers}`,
    'GET',
  ).execute(),
};

export default documentApi;

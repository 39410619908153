// @flow

import { RestClientAuthenticated } from '../network';

const baseUrl = '/demandes';

const demandesApi = {
  fetchSuiviDemandes: () =>
    new RestClientAuthenticated(`${baseUrl}/suivi`, 'GET').execute(),
  getAffiliationCertificate: () => new RestClientAuthenticated(`${baseUrl}/affiliation`, 'GET').execute(),
  getCertificateRetirementPayment: (year: string) => new RestClientAuthenticated(`${baseUrl}/certificate/${year}`, 'GET').execute(),
  downloadDocument: (idDocument: string) =>
    new RestClientAuthenticated(
      `${baseUrl}/download/${idDocument}`,
      'GET',
    ).execute(),
  getUniqueDemandeLink: () => new RestClientAuthenticated(`${baseUrl}/unique`, 'GET').execute(),
};

export default demandesApi;

// @flow

import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Container from '@material-ui/core/Container';
import Back from '../../components/buttons/Back';
import { HOME_PAGE_ROUTE } from '../../utils/routes';
import DelegationsList from './DelegationsList';
import { delegateType } from '../../utils/enum';
import LoadingContext from '../../context/loader/LoadingContext';
import {
  fetchCollaborators,
  fetchDelegations,
  fetchHasMultipleRattachements,
  storeUserPersonalData
} from '../../state/user/userService';
import userApi from '../../network/api/userApi';
import type { TypePersonalData } from '../../types/types';
import { extractErrorFromSigma } from '../../utils/modal';
import ModalContext from '../../context/modal/ModalContext';

type Props = {|
  dispatch: Function,
  delegations: Object,
  collaborators: Object,
|};

const DelegationForm = ({ history, dispatch, delegations, collaborators }: Props) => {
  const [loadingMessage, timeoutDuration] = [I18n.t('delegation.loadingMessage'), 10];
  const { showLoading, closeLoading, loading } = useContext(LoadingContext);
  const { openErrorModal } = useContext(ModalContext);
  window.scrollTo(0, 0);

  useEffect(() => {
    if (collaborators.length === 0) {
      showLoading(loadingMessage, timeoutDuration);
      dispatch(fetchHasMultipleRattachements());

      userApi
        .getPersonalData()
        .then(res => res.json())
        .then((json: TypePersonalData) => {
          dispatch(storeUserPersonalData(json));
          Promise.all([
            dispatch(fetchDelegations(json.email)),
            dispatch(fetchCollaborators())
          ])
            .then(() => closeLoading());
        })
        .catch(extractErrorFromSigma(openErrorModal));
    }
  }, [closeLoading, showLoading])

  const delegatesNumber = delegations.length;

  return (
    <div className="page-container mb-2">
      <div className="title">
        <div className="app-title cobalt m-auto">
          <span>{I18n.t('delegation.delegationForm.delegate')}</span>
          <h2>{I18n.t('delegation.delegationForm.trustedThirdParty')}</h2>
        </div>
      </div>
      <Back
        className="mb-2"
        onClick={() => history.push(HOME_PAGE_ROUTE)}
        message={I18n.t('actions.BACK')}
      />
      <Container maxWidth="md" className="mt-2 delegation-form">
        <div className="delegation-form-header">
          <div className="delegation-form-header-icon centered-container">
            <div className="circle-wrapper centered-container">
              <i className="fas fa-5x fa-share" />
            </div>
          </div>
          <div className="delegation-form-header-text">
            <h2 className="fs-28 text-bold">{I18n.t('delegation.delegationForm.delegate')} {I18n.t('delegation.delegationForm.trustedThirdParty')}</h2>
          </div>
        </div>
        <div className="delegation-form-separator" />
        <div className="delegation-form-content">
          <h3 className="text-large-size text-uppercase">{I18n.t('delegation.delegationForm.myThirdParties')}</h3>
          <DelegationsList title={I18n.t('delegation.delegationForm.iDelegatedTo')} delegations={loading ? [] : delegations}
                           type={delegateType.DELEGATE} delegateNumber={delegatesNumber} />
          <DelegationsList title={I18n.t('delegation.delegationForm.iWantToDelegatedTo')} delegations={loading ? [] : collaborators}
                           type={delegateType.COLLABORATOR} delegateNumber={delegatesNumber} />
        </div>
      </Container>
    </div>
  )
}

export default connect(state => ({
  delegations: state.user.delegations,
  collaborators: state.user.collaborators,
  hasMultipleRattachements: state.user.hasMultipleRattachements,
}))(DelegationForm)
// @flow

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FileSquare from '../../components/files/FileSquare';
import GenericLoader from '../../components/utils/GenericLoader';
import Title from '../../components/utils/Title';
import documentApi from '../../network/api/documentApi';
import { extractErrorFromSigma } from '../../utils/modal';
import type {
  ConjointCollaborateur,
  SelectedNotaire,
  TypeDocument,
} from '../../types/types';
import { colors, logoNames, typeCourToFileRoute } from '../../utils/enum';
import { NAVIGATION_RIGHTS } from '../../utils/const';
import { FILE_DETAIL_ROUTE, FILES_ROUTE } from '../../utils/routes';
import LoadingContext from '../../context/loader/LoadingContext';
import ModalContext from '../../context/modal/ModalContext';
import NotairesFilter from './NotairesFilter';

type Props = {|
  history: History,
  status?: string,
  userNumpers: string,
  userFirstName: string,
  userLastName: string,
  conjoint?: ConjointCollaborateur,
|};

const FilePage = ({
  history, status, userNumpers, userFirstName, userLastName, conjoint,
}: Props) => {
  const [userFiles, setUserFiles] = useState<TypeDocument[]>();
  const [selectedPersona, setSelectedPersona] = useState<SelectedNotaire>();
  const { loading, showLoading, closeLoading } = useContext(LoadingContext);
  const { openErrorModal } = useContext(ModalContext);

  const urlParams = new URLSearchParams(location.search);
  const urlNumpers = urlParams.get('numpers');

  const setPersonaAndFetchDocs = (isNotaireActive: string, numpers: string) => {
    setSelectedPersona(
      {
        numpers: userNumpers,
        nom: userLastName,
        prenom: userFirstName,
        conjointCollaborateur: conjoint
          ? { ...conjoint, filterActive: !isNotaireActive, disabled: !conjoint.consent }
          : undefined,
        filterActive: isNotaireActive,
      },
    );
    getDocumentsDisponiblesByNumpers(numpers);
  };

  useEffect(() => {
    // If the notaire doesn't have a conjoint, no need to take care of the selected persona
    if (conjoint && urlNumpers && urlNumpers === conjoint.numpers) {
      setPersonaAndFetchDocs(false, conjoint.numpers);
    } else {
      setPersonaAndFetchDocs(true, userNumpers);
    }
  }, []);

  const onDocumentCategoryClick = (name: string) => {
    const basePath = `${typeCourToFileRoute[name] || `${FILE_DETAIL_ROUTE}?name=${name}`}`;

    // Append the numpers of the notaire / conjoint coll to the path when needed
    if (selectedPersona && selectedPersona.conjointCollaborateur) {
      const selectedNumpers = selectedPersona.filterActive
        ? selectedPersona.numpers
        : selectedPersona.conjointCollaborateur.numpers;
      history.push(`${basePath}&numpers=${selectedNumpers}`);
    } else {
      history.push(basePath);
    }
  };

  const evenLineNumberList = [0, 1, 4, 5, 8, 9, 12, 13];

  const alternateColor = (index) => {
    if ((index % 3) === 0) {
      return colors.COBALT;
    }
    if ((index % 3) === 1) {
      return colors.BLUE;
    }
    return colors.YELLOW;
  };

  const alternateLogo = (index) => {
    if ((index % 5) === 0) {
      return logoNames.COTISATION;
    }
    if ((index % 5) === 1) {
      return logoNames.ESTIMATION;
    }
    if ((index % 5) === 2) {
      return logoNames.FICT;
    }
    if ((index % 5) === 3) {
      return logoNames.ATTESTATION;
    }
    return logoNames.POINT_REPURCHASE;
  };

  const documentContent = () => {
    if (userFiles && userFiles.length !== 0 && NAVIGATION_RIGHTS.DOCUMENT.includes(status)) {
      return (
        <>
          {userFiles.map((file: TypeDocument, index: number) => (
            <Grid key={`doc_${file.typeDocument}`} item xs className="grid-style">
              {file.nbDocuments !== 0 &&
                <FileSquare
                  onClick={() => onDocumentCategoryClick(file.typeDocument)}
                  color={alternateColor(index)}
                  name={file.label}
                  logo={alternateLogo(index)}
                  documentLogoIsAfter={evenLineNumberList.indexOf(index) > -1}
                />
              }
            </Grid>
          ))}
        </>
      );
    }
    return (
      <div className="text-large-size d-flex justify-content-center">
        {loading ? I18n.t('tooltip.loadingData') : I18n.t('tooltip.noFileAvailable')}
      </div>
    );
  };

  const getDocumentsDisponiblesByNumpers = useCallback((numpers: string) => {
    showLoading();
    documentApi
      .getDocumentsDisponibles(numpers)
      .then((response: Response) => response.json())
      .then(setUserFiles)
      .catch(extractErrorFromSigma(openErrorModal))
      .finally(closeLoading);
  });

  const addNumpersToUrl = (selectedProfile: SelectedNotaire | ConjointCollaborateur) => {
    // adds the numpers of the selected profile to the url to save the selected profile
    const path = `${FILES_ROUTE}?numpers=${selectedProfile.numpers}`;
    window.history.pushState({}, '', path);
    window.history.replaceState({}, '', path);
  };

  const handleSelectProfile = (
    selectedProfile: SelectedNotaire | ConjointCollaborateur,
    isConjoint?: boolean,
  ) => {
    if (isConjoint) {
      setSelectedPersona({
        ...selectedPersona,
        filterActive: false,
        conjointCollaborateur: {
          ...selectedPersona.conjointCollaborateur,
          filterActive: true,
        },
      });
    } else {
      setSelectedPersona({
        ...selectedPersona,
        filterActive: true,
        conjointCollaborateur: selectedPersona.conjointCollaborateur
          ? {
            ...selectedPersona.conjointCollaborateur,
            filterActive: false,
          }
          : undefined,
      });
    }
    addNumpersToUrl(selectedProfile);
    getDocumentsDisponiblesByNumpers(selectedProfile.numpers);
  };

  return (
    <div className="page-container mb-2">
      <Title className="m-auto" message={I18n.t('files.title')} color="blue" />
      {conjoint && selectedPersona && (
        <div className="content tier-documents-content">
          <div className="text-center text-large-size text-italic text-bold mb-2 mr-2 ml-2">
            {I18n.t('personalData.cotisationData.conjointCollaborateur.selectProfile')}
          </div>
          <NotairesFilter
            selectedPersonas={[selectedPersona]}
            onClickNotaire={handleSelectProfile}
          />
        </div>
      )}
      {loading
        ? <GenericLoader label={I18n.t('tooltip.loadingData')} />
        : (
          <Container maxWidth="md" className="mt-2 pl-2 pr-2" disableGutters>
            {userFiles && (
              <div className="text-center text-large-size text-italic mb-2 mr-2 ml-2">
                {I18n.t('files.select')}
              </div>
            )}
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={3}
            >
              {documentContent()}
            </Grid>
          </Container>
        )}
    </div>
  );
};

export default connect(state => ({
  userNumpers: state.user.user.numpers,
  userFirstName: state.user.user.firstName,
  userLastName: state.user.user.lastName,
  status: state.user.user.statut,
  conjoint: state.user.user.conjointCollaborateur,
}))(FilePage);

// @flow

import React, { memo } from "react";
import { I18n } from "react-redux-i18n";

import Idnot from "../buttons/Idnot";
import FranceConnect from "../buttons/FranceConnect";
import { loginType } from "../../utils/const";

import Tooltip from "./Tooltip";
import DevIdnot from "../utils/DevIdnot";

type Props = {
  names: string[],
  type: $Keys<typeof loginType>,
  text: string,
};

const TileConnexion = ({ names, type, text }: Props) => {
  const renderButton = () => {
    if (type === loginType.ID_NOT) {
      if (process.env.NODE_ENV === "production") {
        return <Idnot />;
      }
      return <DevIdnot />;
    }
    return <FranceConnect />;
  };

  return (
    <div className="connexion-container">
      <div className="tile-text">{I18n.t(`landingpage.${text}`)}</div>
      <div className="item-container">
        {names.map((name) => (
          <div key={name} className={`tile-connexion --${name}`}>
            <div className={`circle-img --${name}`}>
              <div className="jesuis">
                <span>{I18n.t("landingpage.jesuis")}</span>
                <b className={`jesuis${name}`}>{I18n.t(`landingpage.${name}`)}</b>
                <Tooltip message={name} size={"fa-lg"} />
              </div>
            </div>
          </div>
        ))}
      </div>
      {renderButton()}
    </div>
  );
};

export default memo<Props>(TileConnexion);

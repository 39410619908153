// @flow

import React, { useEffect, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

import { isUserConnected, disconnect } from "../../state/login/loginService";
import { CGU_PAGE_ROUTE, DASHBOARD_ROUTE, HOME_PAGE_ROUTE } from '../../utils/routes';
import loggerApi from "../../network/api/loggerApi";
import ModalContext from "../../context/modal/ModalContext";
import type { User } from "../../types/userType";
import { ROUTING_RIGHTS } from '../../utils/routingRights';

type Props = {
  component: any,
  location: Object,
  isConnected: boolean,
  dispatch: Function,
  history: Object,
  user: User,
};

function PrivateRoute({
  component: Component,
  isConnected,
  location,
  dispatch,
  history,
  user,
  ...rest
}: Props) {
  const { openErrorModal } = useContext(ModalContext);
  useEffect(() => {
    if (isConnected) {
      loggerApi
        .addViewAuthenticated(location.pathname)
        .catch(error => disconnect(dispatch, openErrorModal, error));
    } else {
      loggerApi.addTokenInvalid(location.pathname);
    }
  }, [location.pathname, isConnected]);
  const hasRoutingRight = (userStatus: string, route: string) => {
    let hasRight = false;
    if (ROUTING_RIGHTS[userStatus]) {
      hasRight = ROUTING_RIGHTS[userStatus].includes(route);
    }
    return hasRight;
  }
  return (
    <Route
      {...rest}
      location={location}
      render={props => {
        if (isConnected) {
          if (user.cguValidated || location.pathname === CGU_PAGE_ROUTE) {
            if (hasRoutingRight(user.statut, location.pathname)) {
              return <Component {...props} />;
            }
            else {
              return <Redirect to={DASHBOARD_ROUTE} />;
            }
          }
          return <Redirect to={CGU_PAGE_ROUTE} />;
        }
        return <Redirect to={HOME_PAGE_ROUTE} />;
      }}
    />
  );
}

export default connect(state => ({
  isConnected: isUserConnected(state.login),
  user: state.user.user,
}))(PrivateRoute);

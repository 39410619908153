// @flow

import React, { useState } from 'react';
import { ExpansionPanel as Accordion } from '@material-ui/core';
import { displayCotisationValue } from '../../utils/utils';

type Props = {|
  headerData: string[],
  isRid?: boolean,
  isEncaissement?: boolean,
  children: React.ReactNode,
|};

const ItemAccordion = ({
  headerData, isRid, isEncaissement, children,
}: Props) => {
  const [toggled, setToggled] = useState<boolean>(false);

  const iconToDisplay = () => {
    if (isEncaissement) {
      return 'fa fa-calendar-alt';
    }
    if (toggled) {
      return 'fas fa-chevron-down up';
    }
    return 'fas fa-chevron-down down';
  };

  return (
    <div className="accordion-item">
      <Accordion className="accordion" expanded={toggled}>
        <div className={`accordion-item-header ${toggled ? 'up' : ''}`} onClick={() => setToggled(!toggled)}>
          {headerData && headerData.map((data: string) =>
            (
              <span
                key={data}
                className={`header-item number${isRid && data === headerData[headerData.length - 1]
                  ? ' last'
                  : ''}`}
              >
                {displayCotisationValue(data)}
              </span>
            ))
          }
          <div className="dropdown-icon">
            <i className={iconToDisplay()} />
          </div>
        </div>
        <div className="accordion-item-content">
          {children}
        </div>
      </Accordion>
    </div>
  );
};

export default ItemAccordion;

// flow

import {
  CONTRIBUTOR_CERTIFICATION_ROUTE,
  FILE_CONTRIBUTOR_CERTIFICATION_ROUTE,
  FILE_COTISATION_COUNT_ROUTE,
  FILE_RETREAT_ESTIMATION_ROUTE,
  FILE_TAX_DOCUMENT_ROUTE,
  FILE_TECHNICAL_SHEET_ROUTE,
  RETREAT_ESTIMATION_ROUTE,
} from './routes';

export const typeCourToFileRoute = {
  ESTR: FILE_RETREAT_ESTIMATION_ROUTE,
  DECO: FILE_COTISATION_COUNT_ROUTE,
  ATTF: FILE_TAX_DOCUMENT_ROUTE,
  FICT: FILE_TECHNICAL_SHEET_ROUTE,
};

export const typeCourToName = {
  ESTR: 'history',
  DECO: 'cotisation',
  ATTF: 'fiscal',
  FICT: 'technicalFile',
};

export const nameToTypeCour = {
  'estimation-retraite': 'ESTR',
  'decompte-cotisation': 'DECO',
  'rachat-points': '',
  'certification-affiliation-cotisant': '',
  'attestation-fiscale': 'ATTF',
  'classe-cotisation': '',
  'fiche-technique': 'FICT',
};

export const unionType = {
  '': '',
  D: 'Divorcé',
  C: 'Célibataire',
  M: 'Marié',
  P: 'Pacsé',
};

export const fileNameToServiceRoute = {
  // FIXME : A décommenter début 2024, une fois le service mis à disposition
  // 'estimation-retraite': RETREAT_ESTIMATION_ROUTE,
  'estimation-retraite': '',
  'decompte-cotisation': '',
  'rachat-points': '',
  'certification-affiliation-cotisant': CONTRIBUTOR_CERTIFICATION_ROUTE,
  'attestation-fiscale': '',
  'classe-cotisation': '',
  'fiche-technique': '',
};

export const serviceNameToFileRoute = {
  EIG: '',
  retreat: '',
  retreatRequest: '',
  requestMonitoring: '',
  contributorCertification: FILE_CONTRIBUTOR_CERTIFICATION_ROUTE,
  packPayment: '',
  packFiscal: '',
  certificateRetirementPayment: '',
};

export const typeDocumentFullName = {
  RIS: 'ReleveIndividuelSituation',
  EIGB: 'EstimationIndicativeGlobaleDroitsBloques',
  EIG: 'EstimationIndicativeGlobaleDroitsConstants',
};

export const delegateType = {
  DELEGATE: 'delegate',
  COLLABORATOR: 'collaborator',
};

export const motiveUnionEndOptions = {
  SEPARATION: 'S',
  DECES: 'D',
};

export const fieldNameEndUnion = {
  SEPARATION: 'dteFinUnion',
  DECES: 'dteDeces',
};

export const linkType = {
  CONJOINT: 'CJT',
  ENFANT: 'EFT',
};

export const colors = {
  COBALT: 'cobalt',
  BLUE: 'blue',
  YELLOW: 'yellow',
};

export const logoNames = {
  COTISATION: 'deco',
  ESTIMATION: 'estr',
  FICT: 'fict',
  ATTESTATION: 'attf',
  POINT_REPURCHASE: 'point_repurchase',
};

export const externalServiceNames = {
  INTER_REGIME: 'interRegime',
  MA_CARRIERE: 'maCarriere',
  DRL: 'retreatRequest',
};

export const paymentMode = {
  VIREMENT: 'VIREMENT',
  PRELEVEMENT: 'PRELEVEMENT',
  MASQUE: 'MASQUE',
};

export const regimeCotisation = {
  BASE: 'BASE',
  COMPLEMENTAIRE_B: 'COMPLEMENTAIRE_B',
  COMPLEMENTAIRE_C: 'COMPLEMENTAIRE_C',
  COMPLEMENTAIRE_COLMAR_METZ: 'COMPLEMENTAIRE_COLMAR_METZ',
  INVALIDITE_DECES: 'INVALIDITE_DECES',
};

export const optionRegimeBase = {
  FORFAIT: 'FORFAIT',
  ASSIETTE_PARTAGE: 'ASSIETTE_PARTAGE',
  ASSIETTE: 'ASSIETTE',
};

export const personaType = {
  NOTAIRE: 'SelectedNotaire',
  CONJOINT_COLLABORATEUR: 'ConjointCollaborateur',
};

export const screenSizes = {
  MOBILE: 450,
  CUSTOM: 621,
  TOUCHPAD: 768,
  NOTEPAD: 860,
  DESKTOP: 1100,
  BIG_SCREEN: 1500,
};

export const donneesServicesArrowSizes = {
  MOBILE: 'M25,95 L500,95 L555,50 500,5 L400,5',
  TOUCHPAD: 'M0,95 L580,95 L635,50 L580,5 L480,5',
  DESKTOP: 'M50,95 L580,95 L635,50 L580,5 L480,5',
  BIG_SCREEN: 'M50,95 L600,95 L655,50 L600,5 L500,5',
  MAX_SIZE: 'M0,95 L640,95 L695,50 L640,5 L540,5',
};

export const mesDocumentsArrowSizes = {
  MOBILE: 'M0,98 L430,98 L500,40 L430,-18 L345,-18',
  TOUCHPAD: 'M0,90 L450,90 L520,30 L450,-30 L350,-30',
  DESKTOP: 'M0,100 L400,100 L460,40 L400,-20 L315,-20',
  BIG_SCREEN: 'M0,125 L400,125 L460,52.5 L400,-20 L315,-20',
  MAX_SIZE: 'M0,95 L400,95 L460,50 L400,5 L315,5',
};

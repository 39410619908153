// @flow

import React from 'react';
import {
  CERTIFICATE_RETIREMENT_ROUTE,
  CONTRIBUTOR_CERTIFICATION_ROUTE,
  COTISATION_DATA_ROUTE,
  FILE_CONTRIBUTOR_CERTIFICATION_ROUTE,
  FILE_COTISATION_CLASS_ROUTE,
  FILE_COTISATION_COUNT_ROUTE,
  FILE_POINT_REPURCHASE_ROUTE,
  FILE_RETREAT_ESTIMATION_ROUTE,
  FILE_TAX_DOCUMENT_ROUTE,
  FILE_TECHNICAL_SHEET_ROUTE,
  INTER_REGIME_ROUTE,
  MA_CARRIERE_ROUTE,
  PACK_FISCAL_ROUTE,
  PACK_PAYMENT_ROUTE,
  PERSONAL_DATA_ROUTE,
  PROFESSIONAL_DATA_ROUTE,
  RECIPIENT_INFO_ROUTE,
  REQUEST_ROUTE,
  RETREAT_DATA_ROUTE,
  RETREAT_REQUEST_ROUTE,
  TIER_PROFESSIONAL_DATA_ROUTE,
} from './routes';
import { userStatus } from './userStatus';
import { colors, nameToTypeCour } from './enum';
import marel from '../img/marel.svg';
import maCarriere from '../img/ma-carriere.svg';
import infoAllocataire from '../img/info-alloc.svg';

export const SAML_URL = `${window.location.origin}/api/saml?target=FRONT`;
export const SAML_LOGOUT_URL = `${window.location.origin}/api/saml/logout?target=FRONT`;

export const FRANCE_CONNECT_URL = `${window.location.origin}/api/fc/authorize`;
export const FRANCE_CONNECT_LOGOUT = `${window.location.origin}/api/fc/logout`;

export const NAVIGATION_RIGHTS = {
  // eslint-disable-next-line max-len
  PERSONAL: [userStatus.ACTIF, userStatus.RETRAITE, userStatus.BENEFICIAIRE, userStatus.HORS_EXERCICE],
  FAMILY: [userStatus.ACTIF, userStatus.RETRAITE, userStatus.HORS_EXERCICE],
  RETREAT: [userStatus.RETRAITE, userStatus.BENEFICIAIRE],
  PROFESSIONAL: [userStatus.ACTIF, userStatus.HORS_EXERCICE],
  PROFESSIONAL_TIER: [userStatus.TIERS_DE_CONFIANCE],
  // eslint-disable-next-line max-len
  DOCUMENT: [userStatus.ACTIF, userStatus.BENEFICIAIRE, userStatus.RETRAITE, userStatus.HORS_EXERCICE, userStatus.TIERS_DE_CONFIANCE],
  COTISATION: [userStatus.ACTIF, userStatus.TIERS_DE_CONFIANCE],
};

export const formFieldType = {
  INPUT: 'INPUT',
  CHECKBOX: 'CHECKBOX',
  RADIO: 'RADIO',
  SELECT: 'SELECT',
  LEGEND: 'LEGEND',
  SUBTITLE: 'SUBTITLE',
  DESCRIPTION: 'DESCRIPTION',
  DATE: 'DATE',
  ARRAY: 'ARRAY',
  COLUMN: 'COLUMN',
  LINE: 'LINE',
  LIMIT: 'LIMIT',
  VALUEONLY: 'VALUEONLY',
  SUBTITLE_FAMILY: 'SUBTITLE_FAMILY',
  ARRAY_FAMILY: 'ARRAY_FAMILY',
};

export const ENVIRONMENT = {
  dev: 'Développement',
  int: 'Intégration',
  preprod: 'Pré-production',
  prod: 'Production',
};

export const loginType = {
  ID_NOT: 'ID_NOT',
  FRANCE_CONNECT: 'FRANCE_CONNECT',
};

// TODO retirer les points à la fin pour plus de clarté
export const formTranslationType = {
  enrolement: 'enrolement.form.',
  parametrer: 'parametrerProfil.form.',
  retreat: 'service.retreat.form.',
  personalData: 'personalData.personalData.form.',
  familyData: 'personalData.familyData.form.',
  professionalData: 'personalData.professionalData.form.',
  packPayment: 'service.packPayment.form.',
  certificateRetirementPayment: 'service.certificateRetirementPayment.form.',
};

// MOMENT
export const momentFormat = {
  DATE_TIME: 'DD/MM/YYYY hh:mm:ss',
  DATE: 'DD/MM/YYYY',
  MONTH_YEAR: 'MM/YYYY',
  FILENAME: 'MM-YYYY',
};

export const website = 'https://www.cprn.fr/';
export const contactUrl = `${website}contactez-nous`;

export const PRIMARY_COLOR = '#204D9C';
export const SECONDARY_COLOR = '#007AC1';

export const infoList = [
  {
    label: 'dashboard.services.maCarriere',
    right: [userStatus.ACTIF, userStatus.HORS_EXERCICE],
    route: MA_CARRIERE_ROUTE,
  },
];

export const retirementList = [
  {
    label: 'dashboard.services.interRegime',
    right: [userStatus.ACTIF, userStatus.HORS_EXERCICE],
    route: INTER_REGIME_ROUTE,
  },
  // FIXME : A décommenter début 2024, une fois le service mis à disposition
  // {
  //   label: 'dashboard.services.estimation',
  //   right: [userStatus.ACTIF, userStatus.HORS_EXERCICE],
  //   route: RETREAT_ESTIMATION_ROUTE,
  // },
  {
    label: 'dashboard.services.uniqueDemande',
    right: [userStatus.ACTIF, userStatus.HORS_EXERCICE],
    route: RETREAT_REQUEST_ROUTE,
  },
  {
    label: 'dashboard.services.demandes',
    right: [userStatus.ACTIF, userStatus.HORS_EXERCICE],
    route: REQUEST_ROUTE,
  },
];

export const myRetirementList = [
  {
    label: 'dashboard.services.packFiscal',
    right: [userStatus.RETRAITE, userStatus.BENEFICIAIRE],
    icone: 'fa fa-folder-open',
    route: PACK_FISCAL_ROUTE,
  },
  {
    label: 'dashboard.services.packPayment',
    right: [userStatus.RETRAITE, userStatus.BENEFICIAIRE],
    icone: 'fa fa-chart-bar',
    route: PACK_PAYMENT_ROUTE,
  },
  {
    label: 'dashboard.services.certificateRetirementPayment',
    right: [userStatus.RETRAITE, userStatus.BENEFICIAIRE],
    icone: 'fa fa-file-contract',
    route: CERTIFICATE_RETIREMENT_ROUTE,
  },
  {
    label: 'dashboard.services.recipientInfo',
    right: [userStatus.RETRAITE, userStatus.BENEFICIAIRE],
    icone: 'fa fa-file-contract',
    route: RECIPIENT_INFO_ROUTE,
  },
];

export const dashboardMyRetirementList = [
  {
    label: 'dashboard.services.packFiscal',
    right: [userStatus.RETRAITE, userStatus.BENEFICIAIRE],
    icone: 'fa fa-chart-bar',
    route: PACK_FISCAL_ROUTE,
  },
  {
    label: 'dashboard.services.packPayment',
    right: [userStatus.RETRAITE, userStatus.BENEFICIAIRE],
    icone: 'fa fa-chart-bar',
    route: PACK_PAYMENT_ROUTE,
  },
  {
    label: 'dashboard.services.certificateRetirementPayment',
    right: [userStatus.RETRAITE, userStatus.BENEFICIAIRE],
    icone: 'fa fa-file-contract',
    route: CERTIFICATE_RETIREMENT_ROUTE,
  },
  {
    label: 'dashboard.services.recipientInfo',
    right: [userStatus.RETRAITE, userStatus.BENEFICIAIRE],
    icone: 'service-svg',
    route: RECIPIENT_INFO_ROUTE,
    svgPath: infoAllocataire,
  },
];

export const documentList = [
  {
    label: 'dashboard.services.certif',
    right: [userStatus.ALL],
    route: CONTRIBUTOR_CERTIFICATION_ROUTE,
  },
];

export const dashboardServiceList = [
  // FIXME : A décommenter début 2024, une fois le service mis à disposition
  // {
  //   label: 'dashboard.services.estimation',
  //   icone: 'fa fa-chart-bar',
  //   right: [userStatus.ACTIF, userStatus.HORS_EXERCICE],
  //   route: RETREAT_ESTIMATION_ROUTE,
  // },
  {
    label: 'dashboard.services.maCarriere',
    icone: 'service-svg',
    right: [userStatus.ACTIF, userStatus.HORS_EXERCICE],
    route: MA_CARRIERE_ROUTE,
    svgPath: maCarriere,
  },
  {
    label: 'dashboard.services.interRegime',
    icone: 'service-svg',
    right: [userStatus.ACTIF, userStatus.HORS_EXERCICE],
    route: INTER_REGIME_ROUTE,
    svgPath: marel,
  },
  {
    label: 'dashboard.services.uniqueDemande',
    icone: 'fa fa-file-contract',
    right: [userStatus.ACTIF, userStatus.HORS_EXERCICE],
    route: RETREAT_REQUEST_ROUTE,
  },
  {
    label: 'dashboard.services.certif',
    icone: 'fa fa-folder-open',
    right: [userStatus.ALL],
    route: CONTRIBUTOR_CERTIFICATION_ROUTE,
  },
];
export const personalDataList = [
  {
    label: 'personalData.personalData.consult',
    icone: '',
    right: NAVIGATION_RIGHTS.PERSONAL,
    route: PERSONAL_DATA_ROUTE,
  },
];

export const professionalDataList = [
  {
    label: 'personalData.professionalData.consult',
    icone: '',
    right: NAVIGATION_RIGHTS.PROFESSIONAL,
    route: PROFESSIONAL_DATA_ROUTE,
  },
];

export const tierProfessionalDataList = [
  {
    label: 'personalData.professionalData.consult',
    icone: '',
    right: NAVIGATION_RIGHTS.PROFESSIONAL_TIER,
    route: TIER_PROFESSIONAL_DATA_ROUTE,
  },
];

export const retirementDataList = [
  {
    label: 'personalData.retreatData.consult',
    icone: '',
    right: NAVIGATION_RIGHTS.RETREAT,
    route: RETREAT_DATA_ROUTE,
  },
];

export const cotisationDataList = [
  {
    label: 'personalData.cotisationData.consult',
    icone: '',
    right: NAVIGATION_RIGHTS.COTISATION,
    route: COTISATION_DATA_ROUTE,
  },
];

export const files = [
  [
    {
      typeCour: nameToTypeCour['decompte-cotisation'],
      name: 'cotisation_count',
      width: 10,
      height: 2,
      color: colors.BLUE,
    },
    {
      typeCour: nameToTypeCour['estimation-retraite'],
      name: 'retreat_estimation',
      width: 30,
      height: 1,
      color: 'grey',
    },
  ],
  [
    {
      typeCour: nameToTypeCour['rachat-points'],
      name: 'point_repurchase',
      width: 10,
      height: 1,
      color: 'green',
    },
    {
      typeCour: nameToTypeCour['certification-affiliation-cotisant'],
      name: 'contributor_certification',
      width: 10,
      height: 2,
      color: 'turquoise',
    },
    {
      typeCour: nameToTypeCour['attestation-fiscale'],
      name: 'tax_document',
      width: 10,
      height: 1,
      color: 'green-light',
    },
  ],
  [
    {
      typeCour: nameToTypeCour['classe-cotisation'],
      name: 'cotisation_class',
      width: 25,
      height: 1,
      color: 'blue-light',
    },
    {
      typeCour: nameToTypeCour['fiche-technique'],
      name: 'technical_sheet',
      width: 10,
      height: 1,
      color: colors.YELLOW,
    },
  ],
];

export const wsError = {
  ERR_NO_DATA_PAYMENT: 'ERR_NO_DATA_PAYMENT',
};

export const labelType = {
  label: 'label',
  tooltip: 'tooltip',
  link: 'link',
  add: 'add',
  invalid: 'invalid',
};

export const statutType = {
  TIER: 'TIER',
  ACTI: 'ACTI',
};

export const filterTypes = {
  docType: 'types',
  notaireDelegant: 'notaireDelegants',
};

export const docTypesName = {
  ESTR: 'Estimation retraite',
  DECO: 'Décompte',
  ATTF: 'Attestation fiscale',
  FICT: 'Fiche technique',
  MCCL: 'Mailing Changement de classe',
  MRSB: 'Rachat de Points B',
};

// @flow

import { reduce } from "../../network/reduce";
import type { User } from "../../types/userType";
import type { DelegantsInfos, Delegate, TypePersonalData } from '../../types/types';

export const USER = "USER";
export const PERSONAL_DATA = "PERSONAL_DATA";
export const HAS_FAMILY_DATA = "HAS_FAMILY_DATA";
export const DELEGATIONS = "DELEGATIONS";
export const COLLABORATORS = "COLLABORATORS";
export const HAS_MULTIPLE_RATTACHEMENTS = "HAS_MULTIPLE_RATTACHEMENTS";
export const NOTAIRES_DELEGANTS_DATA = "NOTAIRES_DELEGANTS_DATA";

type userStateType = {
  user: User,
  hasFamilyData: ?boolean,
  personalData: ?TypePersonalData,
  delegations: ?Delegate[],
  collaborators: ?Delegate[],
  hasMultipleRattachements: ?boolean,
  notaireDelegantsData: ?DelegantsInfos,
};

export const getInitialStateUser = (): User => {
  const user = sessionStorage.getItem("user-cprn");
  if (user) {
    return JSON.parse(user);
  }
  return {
    idn: "",
    idFC: "",
    numpers: "",
    nir: "",
    lastName: "",
    firstName: "",
    statut: "",
    cguValidated: false,
  };
};

const initialState: userStateType = {
  user: getInitialStateUser(),
  hasFamilyData: undefined,
  delegations: [],
  collaborators: [],
  hasMultipleRattachements: undefined,
  notaireDelegantsData: undefined,
};

const userReducer = reduce(initialState, {
  [USER]: "user",
  [PERSONAL_DATA]: "personalData",
  [HAS_FAMILY_DATA]: "hasFamilyData",
  [DELEGATIONS]: "delegations",
  [COLLABORATORS]: "collaborators",
  [HAS_MULTIPLE_RATTACHEMENTS]: "hasMultipleRattachements",
  [NOTAIRES_DELEGANTS_DATA]: "notairesDelegantsData",
});

export default userReducer;

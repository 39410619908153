export const countryList = [
  { label: "", value: "" },
  { value: "AF", label: "Afghanistan" },
  { value: "ZA", label: "Afrique Du Sud" },
  { value: "AL", label: "Albanie" },
  { value: "DZ", label: "Algerie" },
  { value: "DE", label: "Allemagne" },
  { value: "AD", label: "Andorre" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "SA", label: "Arabie Saoudite" },
  { value: "AR", label: "Argentine" },
  { value: "AM", label: "Armenie" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australie" },
  { value: "AT", label: "Autriche" },
  { value: "AZ", label: "Azerbaidjan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrein" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbade" },
  { value: "BE", label: "Belgique" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermudes" },
  { value: "BT", label: "Bhoutan" },
  { value: "BA", label: "Bosnie-herzegovine" },
  { value: "BW", label: "Botswana" },
  { value: "BR", label: "Bresil" },
  { value: "BG", label: "Bulgarie" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodge" },
  { value: "CM", label: "Cameroun" },
  { value: "CA", label: "Canada" },
  { value: "CV", label: "Cap-vert" },
  { value: "CL", label: "Chili" },
  { value: "CN", label: "Chine" },
  { value: "CY", label: "Chypre" },
  { value: "CO", label: "Colombie" },
  { value: "KM", label: "Comores" },
  { value: "CG", label: "Congo" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "Cote D'ivoire" },
  { value: "HR", label: "Croatie" },
  { value: "CU", label: "Cuba" },
  { value: "DK", label: "Danemark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominique" },
  { value: "EG", label: "Egypte" },
  { value: "SV", label: "El Salvador" },
  { value: "AE", label: "Emirats Arabes Unis" },
  { value: "EC", label: "Equateur" },
  { value: "ER", label: "Erythree" },
  { value: "ES", label: "Espagne" },
  { value: "EE", label: "Estonie" },
  { value: "US", label: "Etats-unis" },
  { value: "ET", label: "Ethiopie" },
  { value: "FJ", label: "Fidji" },
  { value: "FI", label: "Finlande" },
  { value: "FR", label: "France" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambie" },
  { value: "GE", label: "Georgie" },
  { value: "GS", label: "Georgie Du Sud Et Les Iles Sandwich Du Sud" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Grece" },
  { value: "GD", label: "Grenade" },
  { value: "GL", label: "Groenland" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernesey" },
  { value: "GN", label: "Guinee" },
  { value: "GQ", label: "Guinee Equatoriale" },
  { value: "GW", label: "Guinee-bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HN", label: "Honduras" },
  { value: "HU", label: "Hongrie" },
  { value: "IN", label: "Inde" },
  { value: "ID", label: "Indonesie" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Irlande, Ou Eire" },
  { value: "IS", label: "Islande" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italie" },
  { value: "JM", label: "Jamaique" },
  { value: "JP", label: "Japon" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordanie" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KG", label: "Kirghizistan" },
  { value: "KI", label: "Kiribati" },
  { value: "KW", label: "Koweit" },
  { value: "LS", label: "Lesotho" },
  { value: "LV", label: "Lettonie" },
  { value: "LB", label: "Liban" },
  { value: "LR", label: "Liberia" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lituanie" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macao" },
  { value: "MG", label: "Madagascar" },
  { value: "MY", label: "Malaisie" },
  { value: "MW", label: "Malawi" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malte" },
  { value: "MA", label: "Maroc" },
  { value: "MU", label: "Maurice" },
  { value: "MR", label: "Mauritanie" },
  { value: "MX", label: "Mexique" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolie" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MZ", label: "Mozambique" },
  { value: "NA", label: "Namibie" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NO", label: "Norvege" },
  { value: "NZ", label: "Nouvelle-zelande" },
  { value: "OM", label: "Oman" },
  { value: "UG", label: "Ouganda" },
  { value: "UZ", label: "Ouzbekistan" },
  { value: "PK", label: "Pakistan" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papouasie-nouvelle-guinee" },
  { value: "PY", label: "Paraguay" },
  { value: "NL", label: "Pays-bas" },
  { value: "PE", label: "Perou" },
  { value: "PH", label: "Philippines" },
  { value: "PL", label: "Pologne" },
  { value: "PR", label: "Porto Rico" },
  { value: "PT", label: "Portugal" },
  { value: "QA", label: "Qatar" },
  { value: "RO", label: "Roumanie" },
  { value: "GB", label: "Royaume-uni" },
  { value: "RW", label: "Rwanda" },
  { value: "EH", label: "Sahara Occidental" },
  { value: "LC", label: "Sainte-lucie" },
  { value: "SM", label: "Saint-marin" },
  { value: "VC", label: "Saint-vincent-et-les Grenadines" },
  { value: "AS", label: "Samoa Americaines" },
  { value: "ST", label: "Sao Tome-et-principe" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbie" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapour" },
  { value: "SK", label: "Slovaquie" },
  { value: "SI", label: "Slovenie" },
  { value: "SO", label: "Somalie" },
  { value: "SD", label: "Soudan" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SE", label: "Suede" },
  { value: "CH", label: "Suisse" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard Et Ile Jan Mayen" },
  { value: "SZ", label: "Swaziland" },
  { value: "TJ", label: "Tadjikistan" },
  { value: "TD", label: "Tchad" },
  { value: "CZ", label: "Tcheque (republique)" },
  { value: "TH", label: "Thailande" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinite-et-tobago" },
  { value: "TN", label: "Tunisie" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TR", label: "Turquie" },
  { value: "TV", label: "Tuvalu" },
  { value: "UA", label: "Ukraine" },
  { value: "UY", label: "Uruguay" },
  { value: "VU", label: "Vanuatu" },
  { value: "VN", label: "Viet Nam" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambie" },
  { value: "ZW", label: "Zimbabwe" },
];

export const france = { value: "FR", label: "France" };

export const genreList = [
  { label: "", value: "" },
  { label: "Masculin", value: "H" },
  { label: "Feminin", value: "F" },
];

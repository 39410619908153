// @flow

import React from 'react';

type Props = {
  title: string,
}

const Infobulle = ({ title }: Props) => (
  <div className="infobulle">
    <span className="icon" title={title}>
      i
    </span>
  </div>
);

export default Infobulle;

// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { RegimeComplementaireCCMAnnee } from '../../../../types/types';
import { displayCotisationValue } from '../../../../utils/utils';

type Props = {|
  data: RegimeComplementaireCCMAnnee[],
|}

const RegimeComplementaireCColmarMetz = ({ data }: Props) => (
  <div className="regime regime-complementaire-ccm">
    <div className="regime-header">
      <span className="header-item">{I18n.t('personalData.cotisationData.year')}</span>
      <span className="header-item">{I18n.t('personalData.cotisationData.rate')}</span>
      <span className="header-item">{I18n.t('personalData.cotisationData.cotisationAnnuelle')}</span>
    </div>
    <div className="regime-content">
      { data && data.map((year: RegimeComplementaireCCMAnnee) => (year.erreur
        ? (<div key={year.annee} className="ligne-erreur">{I18n.t('personalData.cotisationData.erreur')}</div>)
        : (
          <div key={year.annee} className="ligne">
            <span className="item number">{displayCotisationValue(year.annee)}</span>
            <span className="item number">{displayCotisationValue(year.tx, false, true)}</span>
            <span className="item number">{displayCotisationValue(year.cotisation, true)}</span>
          </div>
        )
      ))}
    </div>
  </div>
);

export default RegimeComplementaireCColmarMetz;

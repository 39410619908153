// @flow

import { RestClientAuthenticated } from '../network';

const baseUrl = '/recipient';

const recipientApi = {
  getRecipientInfo: () =>
    new RestClientAuthenticated(baseUrl, 'GET').execute(),
};

export default recipientApi;

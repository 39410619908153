// @flow

import React, { memo } from "react";

type Props = {
  children: React.ReactNode,
};

const FormBlocEnrolement = ({ children }: Props) => {
  return <div className="form-container blue mt-4">{children}</div>;
};

export default memo<Props>(FormBlocEnrolement);

// @flow

import React, { memo, useEffect, useState } from "react";
import Slide from "@material-ui/core/Slide/Slide";
import Fade from "@material-ui/core/Fade/Fade";
import type { MenuItem } from "../../utils/menu";
import { menu } from "../../utils/menu";
import { I18n } from "react-redux-i18n";
import { withRouter } from "react-router-dom";
import PageToBeReleased from "../dashboard/PageToBeReleased";
import type { User } from '../../types/userType';

type Props = {|
  history: any,
  open: boolean,
  setOpen: Function,
  location: Object,
  user: User
|};

const MenuBurger = ({ history, location, open, setOpen, user }: Props) => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [display, setDisplay] = useState<boolean>(false);
  const delay = 200;
  useEffect(() => {
    window.addEventListener("resize", updateDimension, false);
    return () => window.removeEventListener("resize", updateDimension, false);
  }, []);
  useEffect(() => {
    if (width >= 768) {
      setOpen(false);
    }
  }, [width]);
  useEffect(() => {
    if (open) {
      setDisplay(true);
    } else {
      setTimeout(() => setDisplay(false), delay);
    }
  }, [open]);
  const updateDimension = () => setWidth(window.innerWidth);
  if (display)
    return (
      <div className="menu-burger-container">
        <Fade in={open} timeout={delay} mountOnEnter unmountOnExit>
          <div className="backdrop" onClick={() => setOpen(false)} />
        </Fade>
        <Slide
          timeout={delay}
          direction="left"
          in={open}
          mountOnEnter
          unmountOnExit
        >
          <div className="menu-burger">
            <div
              className="close cursor-pointer"
              onClick={() => setOpen(false)}
            >
              <div className="fa fa-times icon" />
            </div>
            <div>
              <div className="menu">
                {menu.filter((item: MenuItem) =>
                  !item.hideItemFor.includes(user.statut)
                ).map((item: MenuItem) => (
                  <div>
                    <a
                      onClick={() => {
                        if (!item.disabled) {
                          history.push(item.path);
                          setOpen(false);
                        }
                      }}
                      key={`menu_${item.path}`}
                      className={`menu-item text-uppercase hover hover-yellow ${
                        location.pathname.includes(item.path) ? "selected" : ""
                      }`}
                    >
                      {item.disabled && (
                        <PageToBeReleased
                          message={I18n.t("tooltip.pageToBeReleased")}
                          className="page-to-be-released"
                        />
                      )}
                      {I18n.t(`menu.${item.name}`)}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Slide>
      </div>
    );
  else return null;
};

export default withRouter(memo<Props>(MenuBurger));

// @flow

// TODO: A l'ajout d'une nouvelle route, il faut rajouter celle-ci
// dans le fichier routingRights.js

export const baseApiUrl = '/api';

// Routes
export const HOME_PAGE_ROUTE = '/accueil';
export const ENROLEMENT_LANDING_PAGE_ROUTE = '/enrolement';
export const ENROLEMENT_STEP_1_ROUTE = `${ENROLEMENT_LANDING_PAGE_ROUTE}/etape-1`;
export const ENROLEMENT_STEP_2_ROUTE = `${ENROLEMENT_LANDING_PAGE_ROUTE}/etape-2`;
export const ENROLEMENT_STEP_3_ROUTE = `${ENROLEMENT_LANDING_PAGE_ROUTE}/etape-3`;

// TABLEAU DE BORD
export const DASHBOARD_ROUTE = '/tableau-de-bord';

// MES DONNEES PERSONNELLES
export const PERSONAL_DATA_HOME_ROUTE = '/mes-donnees-personnelles';
export const PERSONAL_DATA_ROUTE = `${PERSONAL_DATA_HOME_ROUTE}/mes-donnees-personnelles`;
export const FAMILY_DATA_ROUTE = `${PERSONAL_DATA_HOME_ROUTE}/mes-donnees-familiales`;
export const PROFESSIONAL_DATA_ROUTE = `${PERSONAL_DATA_HOME_ROUTE}/mes-donnees-professionnelles`;
export const RETREAT_DATA_ROUTE = `${PERSONAL_DATA_HOME_ROUTE}/mes-donnees-retraites`;
export const TIER_PROFESSIONAL_DATA_ROUTE = `${PERSONAL_DATA_HOME_ROUTE}/donnees-professionnelles`;
export const COTISATION_DATA_ROUTE = `${PERSONAL_DATA_HOME_ROUTE}/mes-donnees-cotisations`;

// MES DELEGATIONS
export const DELEGATION_HOME_ROUTE = '/mes-delegations';

// MES SERVICES
export const SERVICE_HOME_ROUTE = '/services';
// Droit à l'information
export const MA_CARRIERE_ROUTE = `${SERVICE_HOME_ROUTE}/ma-carriere`;
// Préparer ma retraite
// FIXME : A décommenter début 2024, une fois le service mis à disposition
// export const RETREAT_ESTIMATION_ROUTE = `${SERVICE_HOME_ROUTE}/estimation-retraite`;
export const RETREAT_REQUEST_ROUTE = `${SERVICE_HOME_ROUTE}/demande-unique-retraite`;
export const REQUEST_ROUTE = `${SERVICE_HOME_ROUTE}/suivi-demande`;
export const INTER_REGIME_ROUTE = `${SERVICE_HOME_ROUTE}/inter-regime`;
// Ma retraite
export const PACK_PAYMENT_ROUTE = `${SERVICE_HOME_ROUTE}/pack-paiement`;
export const PACK_FISCAL_ROUTE = `${SERVICE_HOME_ROUTE}/pack-fiscal`;
export const RECIPIENT_INFO_ROUTE = `${SERVICE_HOME_ROUTE}/info-allocataire`;
export const CERTIFICATE_RETIREMENT_ROUTE = `${SERVICE_HOME_ROUTE}/attestation-paiement`;
// Mes documents
export const CONTRIBUTOR_CERTIFICATION_ROUTE = `${SERVICE_HOME_ROUTE}/certificat-affiliation`;

// MES DOCUMENTS
export const FILES_ROUTE = '/documents';
export const TIER_FILES_ROUTE = '/documents/tier';
export const FILE_DETAIL_ROUTE = `${FILES_ROUTE}/details`;
export const FILE_RETREAT_ESTIMATION_ROUTE = `${FILE_DETAIL_ROUTE}?name=estimation-retraite`;
export const FILE_COTISATION_COUNT_ROUTE = `${FILE_DETAIL_ROUTE}?name=decompte-cotisation`;
export const FILE_POINT_REPURCHASE_ROUTE = `${FILE_DETAIL_ROUTE}?name=rachat-points`;
export const FILE_CONTRIBUTOR_CERTIFICATION_ROUTE = `${FILE_DETAIL_ROUTE}?name=certification-affiliation-cotisant`;
export const FILE_TAX_DOCUMENT_ROUTE = `${FILE_DETAIL_ROUTE}?name=attestation-fiscale`;
export const FILE_COTISATION_CLASS_ROUTE = `${FILE_DETAIL_ROUTE}?name=classe-cotisation`;
export const FILE_TECHNICAL_SHEET_ROUTE = `${FILE_DETAIL_ROUTE}?name=fiche-technique`;

// CGU et mentions légales
export const CGU_PAGE_ROUTE = '/cgu';
export const MENTION_LEGALES_ROUTE = '/mention-legales';

// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import type { AnneeCotisation } from '../../../../types/types';
import CotisationsData from './subComponents/CotisationsData';

type Props = {
  cotisations: AnneeCotisation,
}

const Cotisations = ({ cotisations }: Props) => (
  <div className="regime cotisations">
    <div className="regime-header">
      <span className="header-item">{I18n.t('personalData.cotisationData.year')}</span>
    </div>
    <div className="regime-content">
      {cotisations.map((anneeCotisations: AnneeCotisation) => (
        <CotisationsData anneeCotisation={anneeCotisations} />
      ))}
    </div>
  </div>
);

export default Cotisations;

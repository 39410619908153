// @flow

import React, { useContext } from "react";
import { I18n } from "react-redux-i18n";

import Grid from "@material-ui/core/Grid";
import TimelineSmall from "../../components/enrolement/TimelineSmall";
import type { TypeEmail } from "../../types/types";
import { ENROLEMENT_STEP_3_ROUTE } from "../../utils/routes";
import enrolementApi from "../../network/api/enrolementApi";
import { extractErrorFromSigma } from "../../utils/modal";
import ModalContext from "../../context/modal/ModalContext";
import FormStep2 from "./forms/FormStep2";

type Props = {|
  handleChangePage: Function,
  token: string,
  emails: TypeEmail[],
  statut: string
|};

const Step2 = ({
  emails,
  token,
  handleChangePage,
  statut,
}: Props) => {
  const { openErrorModal } = useContext(ModalContext);

  const onSubmit = (values: Object) => {
    const {
      email, otherEmail, dematerialisation, confidentiality,
    } = values;
    enrolementApi
      .postSecondStep({
        mail: email.includes("@") ? email : otherEmail,
        dematValidated: dematerialisation === "true",
        rgpdValidated: confidentiality,
        cguValidated: false,
        token,
      })
      .then(() => {
        handleChangePage(ENROLEMENT_STEP_3_ROUTE);
      })
      .catch(extractErrorFromSigma(openErrorModal));
  };

  return (
    <>
      <TimelineSmall active={2} />
      <h3 className="text-uppercase text-center mt-5">
        {I18n.t("parametrerProfil.form.parametrer")}
      </h3>
      <p className="description text-center lh-2">
        {I18n.t("parametrerProfil.form.description")}
      </p>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="mb-0"
      >
        <FormStep2 emails={emails} statut={statut} onSubmit={onSubmit} />
      </Grid>
    </>
  );
};

export default Step2;

// @flow

import React, { memo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  DASHBOARD_ROUTE,
  FILE_DETAIL_ROUTE,
  FILES_ROUTE,
  TIER_FILES_ROUTE,
} from "../../utils/routes";
import FilePage from "./FilePage";
import TierFilePage from "./TierFilePage";
import FileDetail from "./FileDetail";

const FilesRoutes = () => {
  return (
    <div className="file-container">
      <Switch>
        <Route exact path={TIER_FILES_ROUTE} component={TierFilePage} />
        <Route exact path={FILES_ROUTE} component={FilePage} />
        <Route exact path={FILE_DETAIL_ROUTE} component={FileDetail} />
        <Route path="*" render={() => <Redirect to={DASHBOARD_ROUTE} />} />
      </Switch>
    </div>
  );
};

export default memo<Props>(FilesRoutes);

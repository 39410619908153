// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import ItemAccordion from '../../../../../components/data/ItemAccordion';
import { displayCotisationValue } from '../../../../../utils/utils';

type Props = {|
  anneeEncaissement: AnneeEncaissement,
|}

const EncaissementData = ({ anneeEncaissement }: Props) => {
  const headerData = [anneeEncaissement.annee,
    displayCotisationValue(anneeEncaissement.cotisationAnnuelle, true),
    displayCotisationValue(anneeEncaissement.somme, true),
    displayCotisationValue(anneeEncaissement.soldeGlobalAnnee, true),
    anneeEncaissement.dtEcheances && anneeEncaissement.dtEcheances.length > 0
      ? anneeEncaissement.dtEcheances.length.toString()
      : '-',
  ];

  if (anneeEncaissement.dtEcheances && anneeEncaissement.dtEcheances.length > 0) {
    return (
      <>
        <ItemAccordion
          key={anneeEncaissement.annee}
          headerData={headerData}
          isEncaissement
        >
          <div className="subheader">
            <span className="item">
              {I18n.t('personalData.cotisationData.dateEcheances')}
            </span>
          </div>
          {anneeEncaissement.dtEcheances
              && anneeEncaissement.dtEcheances.map((echeance: string) => (
                <div key={echeance} className="ligne">
                  <span className="item number">{echeance}</span>
                </div>
          ))}
        </ItemAccordion>
      </>
    );
  }

  return (
    <div className="header-item" key={anneeEncaissement.annee}>
      <div className="ligne">
        {headerData.map((headerItem: string) =>
          <span key={headerItem} className="item number">{headerItem}</span>)
        }
        <div className="empty-header" />
      </div>
    </div>
  );
};

export default EncaissementData;

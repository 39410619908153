// @flow

import React, { memo } from "react";
import { Field } from "react-final-form";

const CustomCheckbox = ({
  label, name, disabled, value, onChange, small, important
}: Props) => (
  <div className="field">
    <Field
      name={name}
      render={({ input, meta }) => (
        <div>
          <div className={`label ${disabled ? "disabled" : ""} ${important ? "important" : ""}`}>{label}</div>
          <label className={`switch ${small ? 'small' : '' }`}>
            <input type="checkbox" checked={value} onClick={e => onChange(e.target.checked)} disabled={disabled} />
            <span className={`slider round ${small ? 'small' : '' }`}/>
          </label>
          {meta.error && <div className="error-message color-red">{meta.error}</div>}
        </div>
              )}
    />
  </div>
);

export default memo<void>(CustomCheckbox);

// @flow

import loginApi from "../network/api/loginApi";
import { disconnect } from "../state/login/loginService";
import { HOME_PAGE_ROUTE } from "../utils/routes";
import { fetchAndLoginUser } from "../state/user/userService";

export const storeToken = (authorizationToken: string, dispatch: Function) => {
  sessionStorage.setItem("authorizationToken", authorizationToken);
  window.scrollTo(0, 0);
  dispatch(fetchAndLoginUser());
};

export const login = (
  token: string,
  history: Object,
  dispatch: Function,
  openErrorModal: Function,
  showLoading: Function,
  closeLoading: Function,
) => {
  showLoading();
  sessionStorage.clear();
  loginApi
    .login(token)
    .then(response => response.text())
    .then((authorizationToken: string) => storeToken(authorizationToken, dispatch))
    .catch(error => {
      disconnect(dispatch, openErrorModal, error);
      history.replace(HOME_PAGE_ROUTE);
    })
    .finally(() => closeLoading());
};

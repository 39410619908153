// @flow

import React, { memo } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toggleConfirmationWithModal } from "../../state/notifications/notificationService";

type Props = {
  isConfirmationNotification: boolean,
  dispatch: Function,
};

const ConfirmationModal = ({ isConfirmationNotification, dispatch }: Props) => (
  <div
    id="confirmationModal"
    className={isConfirmationNotification ? "" : "hidden"}
    onClick={() => dispatch(toggleConfirmationWithModal(false))}
    role="Alertdialog"
  />
);

export default memo<Props>(
  withRouter(
    connect(state => ({
      isConfirmationNotification:
        state.customNotification.isConfirmationNotification,
    }))(ConfirmationModal),
  ),
);

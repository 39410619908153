// @flow

import { useEffect } from 'react';

const useOnClickOutside = (ref: Object, handler: Function, excludedClasses?: string[]) => {
  useEffect(() => {
    const listener = (
      event: SyntheticTouchEvent<HTMLElement> | SyntheticUIEvent<HTMLElement>,
    ) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current
        || ref.current.contains(event.target)
        // used to exclude classes that may be generated outside of the referenced node
        || (excludedClasses
        && (excludedClasses.some(excludedClass => event.target.className.includes(excludedClass))))
      ) {
        return;
      }
      handler(event);
    };

    // $FlowFixMe
    document.addEventListener('mousedown', listener);
    // $FlowFixMe
    document.addEventListener('touchstart', listener);

    return () => {
      // $FlowFixMe
      document.removeEventListener('mousedown', listener);
      // $FlowFixMe
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, excludedClasses]);
};

export default useOnClickOutside;

// @flow

import { reduce } from "../../network/reduce";

export const IS_CONNECTED = "IS_CONNECTED";

export type LoginStateType = {
  isConnected: boolean,
};

const initialState: LoginStateType = {
  isConnected: !!sessionStorage.getItem("authorizationToken"),
};

const loginReducer = reduce(initialState, {
  [IS_CONNECTED]: "isConnected",
});

export default loginReducer;

// @flow

import React, { useState } from "react";
import { I18n } from "react-redux-i18n";
import CGUContainer from "../../components/cgu/CGUContainer";
import CustomButton from "../../components/buttons/CustomButton";

type Props = {|
  handleRefus: Function,
  handleAccept: Function,
  buttonIsDisabled: boolean,
|};

const CGU = ({ handleRefus, handleAccept, buttonIsDisabled }: Props) => {
  const [cgu, updateCgu] = useState<boolean>(false);

  return (
    <div className="cgu-content">
      <h1>{I18n.t("cgu.title")}</h1>
      <CGUContainer cgu={cgu} updateCgu={updateCgu} />
      <div className="cgu-actions">
        <CustomButton
          className="ghost-btn"
          message={I18n.t("cgu.refuser")}
          disabled={buttonIsDisabled}
          onClick={handleRefus}
        />
        <CustomButton
          message={I18n.t("cgu.accepter")}
          disabled={!cgu || buttonIsDisabled}
          onClick={handleAccept}
        />
      </div>
    </div>
  );
};
export default CGU;

import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import { I18n } from "react-redux-i18n";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import React from "react";

type Props = {|
  cgu: boolean,
  updateCgu: Function,
|};

const CGUCheckbox = ({ cgu, updateCgu }: Props) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          value="cgu"
          color="primary"
          inputProps={{ "aria-label": I18n.t("cgu.charte") }}
          required
        />
      }
      checked={cgu}
      value={"cgu"}
      onChange={() => updateCgu(!cgu)}
      label={I18n.t("cgu.charte")}
    />
  );
};

export default CGUCheckbox;

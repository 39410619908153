// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import type { ConjointCollaborateur, SelectedNotaire } from '../../../types/types';
import { personaType } from '../../../utils/enum';

type Props = {|
  persona: SelectedNotaire | ConjointCollaborateur,
  onClickNotaire: () => void,
  typePersona: string,
|}

const SelectedPersonaTile = ({
  persona, onClickNotaire, typePersona,
}: Props) => {
  const classToApply = `${typePersona === personaType.CONJOINT_COLLABORATEUR
    ? 'conjoint-coll '
    : `${persona.conjointCollaborateur ? 'selected-notaire ' : ''}`}`;

  const tileProps = () => {
    if (persona.disabled) {
      // TODO : wording au hover cindy
      return { className: `${classToApply}notaire disabled`, title: I18n.t('files.conjointCollaborateur.consent') };
    }
    if (persona.filterActive) {
      return { className: `${classToApply}notaire active`, onClick: () => onClickNotaire(persona) };
    }
    return { className: `${classToApply}notaire inactive`, onClick: () => onClickNotaire(persona) };
  };

  return (
    <div
      key={persona.numpers}
      {...tileProps()}
    >
      <div className={`notaire-icon ${persona.filterActive ? 'active' : ''}`}>
        <i className={`fa-2x ${typePersona === personaType.NOTAIRE ? 'fa fa-user-alt' : 'fas fa-user-plus fa-flip-horizontal'}`} />
        {persona.filterActive &&
          <i className="fas fa-check-circle tick" />
        }
      </div>
      <div className="notaire-infos">
        <p className={persona.filterActive ? 'text-bold' : ''}>{persona.prenom}</p>
        <p className={persona.filterActive ? 'text-bold' : ''}>{persona.nom}</p>
        <p className="text-smaller">{persona.numpers}</p>
      </div>
    </div>);
};

export default SelectedPersonaTile;

// @flow

import React, { memo, useContext } from 'react';
import { I18n } from 'react-redux-i18n';
import { SERVICE_HOME_ROUTE } from '../../utils/routes';
import InternalPage from '../../components/utils/pages/InternalPage';
import LoadingContext from '../../context/loader/LoadingContext';
import ModalContext from '../../context/modal/ModalContext';
import demandesApi from '../../network/api/demandesApi';
import { download } from '../../utils/utils';
import { extractErrorFromSigma } from '../../utils/modal';
import Bloc from '../../components/accueil/Bloc';
import CustomButton from '../../components/buttons/CustomButton';

type Props = {|
  history: History,
|};

const ContributorCertification = ({ history }: Props) => {
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const { openErrorModal } = useContext(ModalContext);
  const onClick = () => {
    showLoading();
    demandesApi
      .getAffiliationCertificate()
      .then((response: Response) => response.blob())
      .then((blob: blob) => {
        download(
          blob,
          // TODO : enum when info alloc is merged
          'certificatAfiliation.pdf',
        );
      }).catch(extractErrorFromSigma(openErrorModal))
      .finally(closeLoading);
  };

  return (
    <InternalPage
      menu={I18n.t('service.title')}
      color="yellow"
      hasBackButton
      handleClickBack={() => history.push(SERVICE_HOME_ROUTE)}
    >
      <div
        id="contributor-certification"
        className="bloc-container mt-1 pb-5 yellow f-dir-col align-content-center "
      >
        <Bloc
          title={I18n.t('service.contributorCertification.title')}
          className="mb-5"
          color="yellow"
        >
          <p className="text-justify mb-0">{I18n.t('service.contributorCertification.text')}</p>
          {(I18n.t('service.contributorCertification.text_2') !== 'text_2') && <p className="text-justify mb-0">{I18n.t('service.contributorCertification.text_2')}</p>}
          <p className="text-justify mt-0">
            {I18n.t('service.contributorCertification.textOptional')}
          </p>
          <CustomButton
            className="mt-3"
            color="yellow"
            message={I18n.t('actions.GENERATE')}
            onClick={onClick}
          />
        </Bloc>
      </div>
    </InternalPage>
  );
};

export default memo<Props>(ContributorCertification);

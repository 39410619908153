// @flow

import moment, { Moment } from 'moment';
import { I18n } from 'react-redux-i18n';
import type { TypeDataUnit } from '../types/types';
import { momentFormat } from './const';
import { VALIDATION } from './form';
import { getValueFromMask } from './utils';

const MAX_CHARACTER = 32;

export const fieldRequired = (value: string | Moment) => (value ? undefined : I18n.t('fieldRequirement.required'));
export const emailRequired = value => (value ? undefined : I18n.t('personalData.personalData.form.email.required'));

export const isDateInFuture = (date: Moment) => date.isAfter(moment());

export const checkEmptyField = (value: string) => {
  if (value != null) {
    if (value.trim() === '') {
      return I18n.t('fieldRequirement.empty');
    }
    return undefined;
  }
  return undefined;
};

export const checkFieldLength = (value: string) => {
  if (value != null && value.trim().length > MAX_CHARACTER) {
    return I18n.t('fieldRequirement.tooLong');
  }
  return undefined;
};

export const checkUnionDate = (
  pastConjoints: TypeDataUnit[], conjointData: TypeDataUnit, unionDate: Moment, prefix: string,
) => {
  // The starting date of a union cannot be in the future
  if (isDateInFuture(unionDate)) {
    return I18n.t(`personalData.familyData.form.${prefix}.dateInFuture`);
  }

  if (prefix === 'endUnionDate') {
    // The ending date of a union cannot be before the start of this union
    if (unionDate.isBefore(moment(conjointData.dteUnion, momentFormat.DATE))) {
      return I18n.t(`personalData.familyData.form.${prefix}.endBeforeStart`);
    }
  }

  if (pastConjoints.length === 0) {
    return undefined;
  }
  const highestEndUnionDateConjoint = pastConjoints.reduce(
    (maxValue: TypeDataUnit, pastConjoint: TypeDataUnit) => {
      const currentEndDate = moment(pastConjoint.dteFinUnion, momentFormat.DATE);
      const maxDate = moment(maxValue.dteFinUnion, momentFormat.DATE);
      return currentEndDate.isAfter(maxDate) ? pastConjoint : maxValue;
    },
  );

  // The union date and end date cannot be before a past union
  if (unionDate.isBefore(moment(highestEndUnionDateConjoint.dteFinUnion, momentFormat.DATE))) {
    return I18n.t(`personalData.familyData.form.${prefix}.beforeFinishedUnions`);
  }
  return undefined;
};

export const checkBirthDate = (birthDate: Moment, unionDate: string) => {
  if (isDateInFuture(birthDate)) {
    return I18n.t('personalData.familyData.form.error.birthDateInFuture');
  }
  if (birthDate.isAfter(moment(unionDate, momentFormat.DATE))) {
    return I18n.t('personalData.familyData.form.dteNaissance.isAfterUnion');
  }
  return undefined;
};

export const isValidDateFormat = (date: Moment | string) => {
  const dateFormatRegex = /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/;

  if (typeof date === 'string') {
    if (dateFormatRegex.test(date)) {
      return undefined;
    }
    return I18n.t('personalData.familyData.form.error.dateFormat');
  } else if (moment.isMoment(date)) {
    if (dateFormatRegex.test(date.format(momentFormat.DATE))) {
      return undefined;
    }
    return I18n.t('personalData.familyData.form.error.dateFormat');
  }
  return undefined;
};

export function validateEmail(email: ?string, customErrorMessage: ?string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if ((email && re.test(email))) {
    return undefined;
  }
  if ((email === undefined)) {
    return undefined;
  }
  return customErrorMessage || I18n.t('form.validator.VALIDATE_EMAIL');
}

export function validatePostalCode(value: ?string, country: ?string) {
  if (country === 'FR') {
    const regex = new RegExp(VALIDATION.ZIP_CODE);
    if (!regex.test(getValueFromMask('_', value))) {
      return I18n.t('personalData.personalData.form.postalCodeError');
    }
  }
  return undefined;
}

export function validateAdressIsFull(value: ?string, formValues: ?object, errorKey: ?string) {
  // Si l'un des champs suivant est non null l'utilisateur essai d'entrer un adresse
  if (formValues.city || formValues.complement || formValues.postalCode ||
    formValues.street || formValues.country
  ) {
    if (!value) {
      return I18n.t(`personalData.personalData.form.${errorKey}`);
    }
  }
  return undefined;
}

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const requiredEmailValidator = (email: string, emailFieldDisabled: boolean) => {
  if (emailFieldDisabled) {
    return undefined;
  }
  if (emailRequired(email)) {
    return emailRequired(email);
  }
  if (validateEmail(email)) {
    return validateEmail(email, I18n.t('parametrerProfil.form.otherEmail.invalid'));
  }
  return undefined;
};

export const hasOptionalFieldChanged = (
  initialValue: string | undefined | null,
  currentValue: string | undefined | null,
) =>
  // An optional field is "null" on init, and may become "undefined" if emptied
  initialValue !== currentValue && (initialValue || currentValue);

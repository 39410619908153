// @flow

import React, { memo } from "react";
import { I18n } from "react-redux-i18n";
import { Link } from "react-router-dom";

import { contactUrl, website } from "../../utils/const";
import { CGU_PAGE_ROUTE, MENTION_LEGALES_ROUTE } from "../../utils/routes";

const Footer = () => {
  // TODO : ajouter actions sur chaque lien (modal contact, télécharger mentions légales et afficher conditions d'utilisations)
  return (
    <footer id="footer">
      <div>
        <a href={contactUrl} target="_blank" rel="noopener">
          {I18n.t("footer.contact")}
        </a>
        <Link to={MENTION_LEGALES_ROUTE}>{I18n.t("footer.mentions")}</Link>
        <Link to={CGU_PAGE_ROUTE}>{I18n.t("footer.conditions")}</Link>
        <a href={website} target="_blank" rel="noopener">
          {I18n.t("footer.site")}
        </a>
      </div>
    </footer>
  );
};

export default memo<void>(Footer);

// @flow

import React from "react";
import CGUCheckbox from "./CGUCheckbox";

type Props = {|
  cgu: boolean,
  updateCgu: Function,
  showCheckbox?: boolean,
|};

const CGUContainer = ({ cgu, updateCgu, showCheckbox = true }: Props) => (
  <div className="cgu-container">
    <div>
      <h1>Portail de Services CPRN.fr</h1>
      <p>
        Le site www.cprn.fr (ci-après dénommé « portail ») est mis en œuvre et hébergé par la Caisse de Prévoyance
        et de Retraite des Notaires (ci-après dénommée « CPRN »). Il permet l’accès à des informations d’ordre généraldans
        le domaine de la retraite ainsi qu’à un ensemble d’informations et de services personnalisés, pour les notaires actifs,
        retraités, hors exercice ou leurs bénéficiaires (ci-après dénommés « Utilisateurs ») ayant créé leur espace personnel, et
        plus particulièrement à des télé-services permettant aux personnes inscrites d’effectuer diverses démarches en ligne.
      </p>
      <p>
        Conformément aux dispositions de l’article L. 112-9 du code des relations entre le public et les administrations,
        ces conditions générales s’imposent aux utilisateurs et valent engagements de leur part.
      </p>
    </div>
    <div>
      <br />
      <h2>Définitions</h2>
      <p>
        Les termes commençant par une majuscule ont la signification indiquée ci-dessous, qu’ils soient au singulier ou
        au
        pluriel.
      </p>
      <ul>
        <li>
          «<b> Site </b>» désigne le portail de services : <a
          href="https://www.cprn.fr"
          target="_blank"
          rel="noopener noreferrer"
          ><u>https://www.cprn.fr</u>
        </a>,
        </li>
        <li>
          «<b> Utilisateur </b>» désigne toute personne physique ouvrant droit ou assuré à titre personnel au régime
          de prévoyance et de retraite de la CPRN, présumée titulaire autorisée de l’Espace personnel
        </li>
        <li>
          «<b> Conjoint collaborateur </b>» désigne toute personne physique ouvrant droit ou assuré à titre personnel au
          régime de prévoyance et de retraite de la CPRN tel que défini à l’article L. 121-4 du code du commerce
        </li>
        <li>
          «<b> Délégataire  </b>» désigne tout tiers habilité par l’Utilisateur à accéder aux Services
        </li>
        <li>
          «<b> Notaire </b>» désigne un notaire titulaire ou associé disposant de la possibilité de donner des droits à
          des
          collaborateurs de l’office.
        </li>
        <li>
          «<b> Actif </b>» désigne les notaires libéraux en activité
        </li>
        <li>
          «<b> Retraité </b>» désigne les notaires ayant liquidé leurs droits à la retraite.
        </li>
        <li>
          «<b> Bénéficiaire </b>» désigne les conjoints survivants qui bénéficient d’une pension de réversion.
        </li>
        <li>
          «<b> Hors Exercice </b>» désigne les notaires ayant cessé leur activité de notaire libéral et n’ont pas
          liquidé
          leurs droits à la retraite.
        </li>
        <li>
          «<b> Editeur </b>» désigne la personne physique ou morale qui met à disposition des Utilisateurs les
          différents
          services proposés par le Site
        </li>
        <li>
          «<b> Services </b>» désigne la prestation gratuite offerte aux Utilisateurs
        </li>
        <li>
          «<b> Espace personnel </b>» espace gratuit et sécurisé accessible à l’Utilisateur.
        </li>
      </ul>
    </div>
    <div>
      <br />
      <h2>Objet</h2>
      <p>
        Le site cprn.fr a pour vocation d’offrir à ses Utilisateurs les Services suivants :
      </p>
      <ul>
        <li>Authentification (IDNOT ou FranceConnect)</li>
        <li>Accès à des documents personnels dont la CPRN est à l’origine</li>
        <li>Estimation retraite personnalisée de la CPRN</li>
        <li>Consultation des données personnelles, professionnelles et familiales gérées par la CPRN</li>
        <li>Modification des données personnelles et familiales gérées par la CPRN</li>
        <li>Attestation de paiement</li>
        <li>Certificat d’affiliation</li>
        <li>Redirection vers le service « Ma carrière » du GIP UNION RETRAITE</li>
        <li>Redirection vers le service « Estimation retraite inter-régimes » du GIP UNION RETRAITE</li>
        <li>Redirection vers le service Pack Paiement et Pack Fiscale du GIP UNION RETRAITE</li>
        <li>Redirection vers le service de Demande Unique de Retraite du GIP UNION RETRAITE</li>
        <li>Redirection vers le service de suivi des demandes du GIP UNION RETRAITE</li>
      </ul>
    </div>
    <div>
      <br />
      <h2>Modalités de connexion à l’Espace personnel</h2>
      <p>
        Les modalités de connexion dépendent du statut de l’Utilisateur.
      </p>
      <h4>
        Modalité de connexion pour les notaires actifs :
      </h4>
      <p>
        ID.Not : solution d'authentification de la profession permettant aux notaires d'ajouter les services de la CPRN
        dans son portail professionnel, sans reconnexion.
      </p>
      <h4>
        Modalités de connexion pour les notaires retraités, les notaires Hors exercice et les Bénéficiaires :
      </h4>
      <p>
        FranceConnect : solution authentification commun à l'ensemble des services publics (impôt.gouv, ameli...)
        <br />
        FranceConnect est un télé-service mis en œuvre par le Secrétariat général pour la modernisation de l'action publique et hébergé par
        la Direction de l’information légale et administrative contribuant à simplifier les démarches administratives des utilisateurs..
        <br />
        Le service FranceConnect est un dispositif permettant aux utilisateurs de se connecter à toutes les administrations en ligne à partir des
        identifiants d’une administration dont il est déjà utilisateur sans avoir besoin de mémoriser des identifiants pour chaque site internet.
      </p>
      <p>
        L’utilisateur souhaitant créer son espace personnel a ainsi la possibilité, s’il dispose déjà d’un compte auprès
        d'
        <a href="https://impots.gouv.fr" target="_blank" rel="noopener noreferrer"><u>impots.gouv.fr</u></a>, d'<a
          href="https://ameli.fr"
          target="_blank"
          rel="noopener noreferrer"
        ><u>ameli.fr</u>
        </a>
         ou de <a href="https://laposte.fr" target="_blank" rel="noopener noreferrer"><u>laposte.fr</u></a> de le faire en cliquant
        sur le bouton FranceConnect disponible sur la page d’inscription de l’espace personnel. Ces trois administrations, aussi
        appelées fournisseurs d’identité, offrent un accès sécurisé renforcé.
      </p>
      <p>
        L’utilisateur se connecte alors à l’espace personnel du site de l’Assurance retraite en utilisant l'un des trois
        comptes sus-énoncés et cela sans aucune inscription au préalable.
      </p>
      <p>
        La consultation des conditions générales d’utilisation du service France Connect est disponible en cliquant sur
        le lien suivant :
        <br /><br />
        <a href="https://franceconnect.gouv.fr/cgu" target="_blank" rel="noopener noreferrer">
          <u>https://franceconnect.gouv.fr/cgu</u>
        </a>
      </p>
    </div>
    <div>
      <br />
      <h2>Conditions d’accès aux services sécurisés de l’espace personnel</h2>
      <p>
        L’Utilisateur peut accéder à son Espace personnel et aux Services qui y sont associés de deux façons :
        <ul>
          <li>Soit en se connectant via ID.not,</li>
          <li>
            Soit en se connectant via France Connect et en utilisant ainsi l’un des trois fournisseurs d’identité.
          </li>
        </ul>
      </p>
      <p>
        Après identification, l’Utilisateur accède à un espace strictement personnel et sécurisé lui permettant
        d’utiliser divers télé-services, notamment transmettre ou recevoir des documents sous forme électronique, voire
        compléter directement des formulaires en ligne, etc.
      </p>
      <p>
        L’Utilisateur va également être destinataire de divers messages d’information en fonction des abonnements qu’il
        aura sélectionnés et validés lors de son inscription à son Espace personnel.
      </p>
      <p>
        L'Utilisateur est responsable des risques liés à l’utilisation de son identifiant de connexion et de son mot
        de passe. Le mot de passe doit être confidentiel.
      </p>
      <p>
        En aucun cas, la CPRN ne saurait être responsable de la perte par l’Utilisateur des identifiants et/ou mot de
        passe, nécessaires à la connexion à l’Espace personnel. De même, la CPRN décline toute responsabilité en cas
        de divulgation par l’Utilisateur de son mot de passe.
      </p>
    </div>
    <div>
      <br />
      <h2>Utilisation des Services</h2>
      <p>
        En se connectant à son Espace personnel, l’Utilisateur :
        <ul>
          <li>
            Accepte de privilégier uniquement la consultation en ligne et l’utilisation des télé-services mis à sa
            disposition et ainsi, de ne plus recevoir de document papier par voie postale ;
          </li>
          <li>Accepte de recevoir des e-mails ou SMS relatifs aux télé-services ;</li>
          <li>Accepte également de recevoir de la part de la CPRN, dans la messagerie de son espace personnel, sa
            messagerie e-mail personnelle ou par SMS, et selon les abonnements auxquels il a souscrit lors de son
            inscription à son espace personnel, tout ou partie des messages promotionnels, annonces d’évènements,
            newsletter, etc. ;
          </li>
          <li>Accepte d’échanger avec la CPRN des documents dématérialisés via le service « Mes échanges de documents »
            ;
          </li>
          <li>Est responsable de la communication de tout changement de coordonnées personnelles préalablement fournies
            à la CPRN.
          </li>
        </ul>
      </p>
      <p>
        L’Utilisateur qui fait le choix de se désabonner de tout ou partie des messages informationnels de la CPRN
        continuera toutefois de recevoir les communications et données en lien avec la gestion de son dossier retraite.
      </p>
    </div>
    <div>
      <br />
      <h2>Notification par e-mail et SMS</h2>
      <p>
        Pour des raisons de sécurité, l’utilisation de certains Services peut requérir l’usage de notification par
        e-mail ou SMS.
      </p>
      <p>
        En renseignant les champs prévus à cet effet lors de son inscription, l’Utilisateur accepte que la CPRN collecte
        et recueille ces informations.
      </p>
    </div>
    <div>
      <br />
      <h2>Disponibilité du portail</h2>
      <p>
        Le portail est accessible 7 jours sur 7 et 24 heures sur 24. Toute défaillance relevant du portail se traduit par
        un message indiquant à l’utilisateur l’indisponibilité totale ou partielle du service ou le non enregistrement
        des données saisies.
      </p>
      <p>Le site et ses différents services peuvent être interrompus ou suspendus par la CPRN notamment à l’occasion d’une
        maintenance, sans obligation de préavis ou de justification
      </p>
    </div>
    <div>
      <br />
      <h2>Responsabilités</h2>
      <p>
        La CPRN est responsable des informations traitées dans le cadre des Services et, à ce titre, s’engage à respecter les
        obligations inhérentes à ce traitement, notamment celles fixées par la loi n°78-17 du 6 janvier 1978 relative à
        l’informatique aux fichiers et aux libertés et au Règlement européen général sur la protection des données -RGPD
        (Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016.
      </p>
      <p>
        Les données transmises restent de la responsabilité de l’Utilisateur, même si celles-ci sont transmises par les moyens
        techniques mis à disposition par la CPRN. L’Utilisateur s’engage à ne fournir, dans le cadre de l’utilisation des Services
        mis à sa disposition, que des informations exactes, à jour et complètes.
      </p>
      <p>
        La responsabilité de la CPRN ne saurait être engagée en cas d’usurpation d’identité ou de toute utilisation frauduleuse
        des Services mis à la disposition de l’Utilisateur sur l’Espace personnel du portail.
      </p>
      <p>
        En utilisant les Services sécurisés mis à sa disposition sur le site <a
          href="https://www.cprn.fr"
          target="_blank"
          rel="noopener noreferrer"
        ><u>www.cprn.fr</u>
        </a>,
        l’Utilisateur s’engage formellement :
        <ul>
          <li>A ne pas communiquer à la CPRN de fausses informations ;</li>
          <li>A ne pas utiliser l'identité d'un tiers dans le but d'obtenir illégalement et indûment un droit ou une
            prestation ;
          </li>
          <li>A ne pas utiliser l'identité d'un tiers en vue d'obtenir des renseignements relatifs à ce dernier.</li>
        </ul>
      </p>
      <p>
        Entre autres comportements susceptibles de poursuites pénales, il est rappelé à l’Utilisateur qu’est passible
        des peines prévues par les dispositions visées ci-après quiconque se rend coupable des faits notamment :
        <ul>
          <li>D’usurper l’identité d’un tiers, conformément à l’article 226-4-1 du code pénal,</li>
          <li>D’escroquerie ou de tentative d’escroquerie, conformément aux articles 313-1 et 313-3 du code pénal,</li>
          <li>De constituer un faux qui a pour objet ou qui peut avoir pour effet d’établir la preuve d’un droit ou d’un
            fait ayant des conséquences juridiques, conformément aux articles 441-1, 441-2, 441-6 et 441-7 du code pénal
          </li>
        </ul>
      </p>
      <p>
        Des contrôles peuvent être effectués à tout moment et la CPRN se réserve le droit d'engager les poursuites
        pénales appropriées.
      </p>
    </div>
    <div>
      <br />
      <h2>
        Acceptation et modification des présentes conditions générales d’utilisation (CGU)
      </h2>
      <p>
        L’inscription à l’Espace personnel du portail emporte de facto, acceptation de la part de l’utilisateur des
        présentes CGU.
      </p>
      <p>
        La CPRN se réserve le droit de modifier les clauses de ces CGU à tout moment et sans justification.
        <br />
        Toute modification de ces CGU fera l’objet d’une information à destination des Utilisateurs.
      </p>
    </div>
    <br />
    <h1>
      <u>Informations légales</u>
    </h1>
    <div>
      <br />
      <h2>Editeur</h2>
      <p>
        Caisse de Prévoyance et de Retraite des Notaires<br />
        43 Avenue Hoche<br />
        75008 Paris<br />
        Tél. : 01.53.81.75.00<br />
        RCS : 784 338 899<br />
      </p>
    </div>
    <div>
      <br />
      <h2>Représentant légal</h2>
      <p>Monsieur Jean-Paul Muller – Directeur Général</p>
    </div>
    <div>
      <br />
      <h2>Hébergement</h2>
      <p>Ce site est édité et hébergé par la Caisse de Prévoyance et de Retraite des Notaires situé :<br />
        43 avenue Hoche 75 008 Paris.
      </p>
    </div>
    <div>
      <br />
      <h2>Liens</h2>
      <p>Afin de garantir l’identité et l’intégrité du site, la CPRN se réserve le droit d’interdire les liens qui ne
        répondraient pas à l’objet de ce dernier ou qui pourraient porter préjudice à l’image de l’institution.
      </p>
      <p>
        La CPRN ne peut être tenue responsable du contenu (éditoriaux, illustrations…) des sites vers lesquels son
        propre site renvoie.
      </p>
      <p>
        La CPRN dégage toute responsabilité concernant les liens créés par d’autres sites vers son site, dès lors
        qu’elle n’a aucun contrôle sur ces sites/liens. L’existence de tels liens ne peut permettre d’induire que la CPRN
        cautionne ces sites ou qu’elle en approuve le contenu.
      </p>
    </div>
    <div>
      <br />
      <h2>Traitement des données à caractère personnel </h2>
      <p>
        Le traitement des données s’effectue conformément au Règlement européen général sur la protection des données
        (RGPD) (règlement n°2016/679) et à la loi n°78-17 du 6 janvier 1978 modifiée, ainsi qu’aux référentiels édictés
        par la Commission nationale de l’informatique et des libertés.
        <br />
        L'Utilisateur est informé, conformément à l'article 116 de la loi Informatique et libertés du 6 janvier
        1978, que certains champs obligatoires des formulaires, doivent nécessairement être complétés afin que la CPRN
        puisse traiter la demande de l'internaute. Une information obligatoire non fournie empêche le traitement de la demande
        de l'internaute. Une information non obligatoire n’empêche pas le traitement de la demande de l'internaute.
      </p>
      <p>
        La CPRN est le responsable du traitement de données au sens de la loi n°78-17 du 6 janvier 1978 relative
        à l’informatique, aux fichiers et aux libertés modifiée. Conformément aux dispositions de l’article 121 de la
        loi 78-17 précitée, la CPRN prend toutes les mesures nécessaires afin de préserver la sécurité des données
        accessibles via le portail en empêchant que celles-ci soient notamment déformées, endommagées ou que des tiers
        non autorisés y aient accès.
      </p>
      <p>
        La CPRN s’engage à ce que la collecte et le traitement des données personnelles de l’Utilisateur soient
        conformes au règlement général sur la protection des données (RGPD) et à la loi Informatique et Libertés.
        À cette fin, elle s’engage à prendre toutes les mesures nécessaires permettant de garantir la sécurité et la
        confidentialité des informations fournies par l’Utilisateur.
      </p>
      <p>
        À ce titre, la CPRN limite la collecte et l’enregistrement de données personnelles pertinentes et strictement
        nécessaires.
      </p>
      <p>
        Les données personnelles recueillies dans le cadre des traitements mis en place par la CPRN sont traitées selon
        des protocoles sécurisés, permettant à la CPRN de gérer les demandes reçues dans ses applications informatiques.
      </p>
      <p>
        La CPRN garantit aux Utilisateurs les droits d’accès, de rectification et d’opposition prévus par
        le Règlement (UE) 2016/679 du 27 avril 2016 (RGPD).
      </p>
      <p>
        Pour toute information ou exercice de vos droits Informatique et Libertés sur les traitements de données personnelles
        gérés par la CPRN, vous pouvez contacter son délégué à la protection des données (DPO) :
        <ul>
          <li>Par mail à l’adresse <a href="mailto:contactDPO@cprn.fr">contactDPO@cprn.fr</a></li>
          <li>Par courrier signé accompagné de la copie d’un titre d’identité à l'adresse suivante :<br />
            Caisse de Prévoyance et de Retraite des Notaires <br />
            A l'attention du délégué à la protection des données (DPO)<br />
            43 avenue Hoche<br />
            75008 PARIS<br />
          </li>
        </ul>
      </p>
    </div>
    <div>
      <br />
      <h2>Droits de propriété intellectuelle</h2>
      <p>
        La structure générale et le contenu de ce site sont la propriété de la CPRN. Les images, textes et autres
        composants du site sont protégés par des droits de propriété́ intellectuelle.
      </p>
      <p>
        Par ailleurs, en application de l’article L.122-4 du code de la propriété intellectuelle, toute représentation
        ou reproduction totale ou partielle non autorisée par la CPRN, des informations et des documents de toute nature
        contenus dans le présent portail internet, par quelque moyen et sur quelque support que ce soit, est strictement
        interdite sous peine de poursuites judiciaires.
      </p>
      <p>
        Cet acte constitue un délit de contrefaçon sanctionné selon les modalités prévues à l’article L.335-2 du code de
        la propriété intellectuelle.
      </p>
      <p>
        Les seules dérogations autorisées sont prévues à l’article L.122-5 de la propriété intellectuelle, notamment la
        copie sur support papier à usage privé.
      </p>
      <p>
        La CPRN est titulaire des droits sur les bases de données qu’elle met à la disposition du public sur son site et
        sont protégés par les dispositions des articles L.341-1 et suivants du code de la propriété intellectuelle.
      </p>
      <p>
        Conformément à l’article L.342-3 du code précité, l’Utilisateur est seulement autorisé à extraire ou réutiliser
        une partie non substantielle du contenu de ces bases.
      </p>
      <p>
        L’appréciation du caractère non substantiel de l’extraction ou de la réutilisation se fait de manière
        qualitative et quantitative, celle-ci ne devant pas excéder manifestement les conditions d’utilisation normale.
      </p>
      <p>
        Toute réutilisation ou extraction substantielle du contenu des bases de données figurant sur le portail internet
        est totalement prohibée.
      </p>
      <p>
        Toute utilisation quelle qu’elle soit des noms de marques et logos de ce site est interdite sans l’autorisation
        de la CPRN, titulaire des droits (articles L.713-1 et suivants du code la propriété intellectuelle).
      </p>
      <p>
        L’atteinte portée au droit du propriétaire de la marque pourrait alors être constitutive d’une contrefaçon
        engageant la responsabilité civile de son auteur (article L.716-1 du code précité).
      </p>
      <p>
        En vertu des dispositions qui précèdent, la CPRN interdit également l’imitation qui pourrait être faite de sa
        marque et/ou de son logo.
      </p>
    </div>
    <div>
      <br />
      <h2>Atteinte aux systèmes de traitement automatisé de données</h2>
      <p>
        Le code pénal sanctionne les atteintes aux systèmes de traitement automatisé de données.
      </p>
      <p>
        Le fait, notamment, d’accéder ou de se maintenir, frauduleusement, dans tout ou partie d’un
        système de traitement automatisé de données, d’entraver ou de fausser son fonctionnement, d’y
        introduire frauduleusement des données, de supprimer ou de modifier frauduleusement les données qu’il
        contient, est puni par la loi (articles 323-1 et suivants du code pénal).
      </p>
      <p>
        La tentative sera punie des mêmes peines (article 323-7 du code pénal).
      </p>
      <p>
        Si de telles atteintes étaient portées au présent portail internet, la CPRN se verrait donc dans l’obligation de
        saisir la justice.
      </p>
    </div>
    <div>
      <br />
      <h2>Droits et accès des tiers à l’espace personnel de l’Utilisateur</h2>
      <p>
        Le portail des Utilisateurs de la CPRN permet à ces derniers de désigner et d’autoriser une tierce personne
        (tiers de confiance (délégataire), conjoint collaborateur, etc.) à avoir accès à l’Espace personnel de l’Utilisateur.
      </p>
      <p>
        Cette possibilité est offerte uniquement aux notaires Actifs.
      </p>
      <br />
      <h2>
        Droits du Délégataire
      </h2>
      <p>
        La CPRN s’engage à respecter les mentions relatives aux paragraphes « Traitement des données à caractère
        personnel ».
      </p>
      <p>
        Le Délégataire dispose d’un accès limité aux Services, à savoir :
      </p>
      <p>
        Accès à certaines informations concernant l’Utilisateur, notamment :
      </p>
      <ul>
        <li>Les données professionnelles (Chiffre d’affaires, déclarations fiscales et sociale, K-Bis, etc.) ;
        </li>
        <li>Les données de cotisation, ainsi que les documents afférents (tels que les appels de cotisations).
        </li>
        <li>Télécharger ces informations/documents.
        </li>
      </ul>
      <p>
        La CPRN pourrait être amenée à enrichir les informations de l’espace personnel de l’Utilisateur,
        auxquelles le Délégataire a accès.
      </p>
      <br />
      <h2>
        L’information concernant la délégation des droits d’accès
      </h2>
      <p>
        L’Utilisateur est informé des droits de délégations qu’il donne, à savoir :
        <ul>
          <li>
            Ses données professionnelles
          </li>
          <li>
            Ses données de cotisation
          </li>
          <li>Ses documents en lien à vos cotisations</li>
        </ul>
      </p>
      <br />
      <h2>
        Le recueil du consentement de l’Utilisateur
      </h2>
      <p>
        Le consentement est une démarche active de l’Utilisateur, l’acte de délégation est l’accord de l’Utilisateur
        auprès de son Délégataire.<br />
        Le consentement est "préalable" à la collecte des données.
      </p>
      <p>
        L’accès aux Services est réservé aux seuls tiers ayant reçu délégation de l’Utilisateur.
      </p>
      <p>
        L’Utilisateur est responsable de tout changement concernant le délégataire.
        La CPRN n’est pas responsable en l’absence d’information du changement du Délégataire.
      </p>
      <br />
      <h2>
        Droits et accès de l’espace personnel du Conjoint collaborateur par l’Utilisateur
      </h2>
      <p>
        Le portail des Utilisateurs de la CPRN permet au Conjoint collaborateur d’avoir accès
        à son espace personnel par le biais du compte CPRN de l’Utilisateur.
      </p>
      <br />
      <h2>
        Droits du Conjoint collaborateur
      </h2>
      <p>
        La CPRN s’engage à respecter les mentions relatives aux paragraphes
        « Traitement des données à caractère personnel ».
      </p>
      <p>
        Certaines informations du Conjoint collaborateur seront mises à disposition
        depuis l’Espace personnel du notaire Actif, notamment :
        <ul>
          <li>Les données professionnelles
          </li>
          <li>Les données de cotisations, ainsi que les documents afférents (tels que les appels de cotisations).
          </li>
          <li>Télécharger ces informations/documents.
          </li>
        </ul>
      </p>
      <p>
        La CPRN pourrait être amenée à enrichir les informations de l’Espace personnel de l’Utilisateur et
        du Conjoint collaborateur, qui seront mises à disposition depuis cet espace.
      </p>
      <br />
      <h2>
        L’information concernant la délégation des droits d’accès à l’espace du Conjoint collaborateur
      </h2>
      <p>
        Le Conjoint collaborateur est informé des droits de délégations qu’il donne à l’Utilisateur et,
        le cas échéant, au(x) Délégataire(s) autorisé(s) par l’Utilisateur, à savoir :
      </p>
      <ul>
        <li>Ses données professionnelles
        </li>
        <li>Ses données de cotisation
        </li>
        <li>Ses documents en lien à ses cotisations
        </li>
      </ul>
      <br />
      <h2>
        Le recueil du consentement du Conjoint collaborateur
      </h2>
      <p>
        Le consentement est une démarche active du Conjoint collaborateur, l’acte de délégation est
        l’accord de ce dernier auprès de l’Utilisateur et, le cas échéant, de son ou ses Délégataire(s).
        <br />
        Le consentement est "préalable" à la collecte des données.
      </p>
      <p>
        En cas de changement concernant le Délégataire (suppression, ajout, modification d’un délégataire),
        le Conjoint collaborateur est réputé avoir été informé par l’Utilisateur, et qu’il accepte ainsi les
        changements apportés par l’Utilisateur tant que le consentement initial est accordé.
      </p>
      <p>
        L’accès à l’espace personnel du Conjoint collaborateur est réservé aux seuls tiers ayant reçu délégation.
      </p>
      <p>
        L’Utilisateur est responsable de tout changement concernant le Délégataire. La CPRN n’est pas responsable en
        l’absence d’information du changement du Délégataire.
      </p>
    </div>
    <br />
    <br />
    {showCheckbox && (
      <div className="cgu-actions">
        <CGUCheckbox cgu={cgu} updateCgu={updateCgu} />
      </div>
    )}
  </div>
);

CGUContainer.defaultProps = {
  showCheckbox: true,
};

export default CGUContainer;

// @flow

import React, { memo, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import Modal from '../modals/Modal';

const testProfil = [
  {
    idn: 'IDN00073078I',
    nom: 'DURAND',
    prenom: 'Guillaume',
    result: 'Notaire actif',
  },
  {
    idn: 'IDN00083017I',
    nom: 'PAELINCK',
    prenom: 'Eliane',
    result: 'Tiers de confiance',
  },
  {
    idn: 'IDN00094544I',
    nom: 'TORELLI',
    prenom: 'Julie',
    result: 'Notaire actif avec conjoint et enfants',
  },
  {
    idn: 'IDN00021893I',
    nom: 'PARMENTIER',
    prenom: 'Jean',
    result: 'Notaire actif avec conjoint collaborateur',
  },
  {
    idn: 'IDN00060455I',
    nom: 'BOSSU',
    prenom: 'Pierre',
    result: 'Notaire actif pour enrôlement',
  },
  {
    idn: 'IDN00087156I',
    nom: 'VILLEMONT',
    prenom: 'Henry',
    result: 'Notaire CM',
  },
  {
    idn: 'IDN00099042I',
    nom: 'LEMON',
    prenom: 'Victor',
    result: 'Notaire m@rel & carrière',
  },
  {
    idn: 'IDN00085317I',
    nom: 'FAGNON-RAGONDET',
    prenom: 'Marie-Line',
    result: 'Notaire m@rel & carrière',
  },
];

function DevIdnot() {
  const [isShowing, toggle] = useState<boolean>(false);
  const [profil, update] = useState(testProfil[0]);

  const openModal = () => {
    toggle(true);
  };
  const closeModal = () => {
    toggle(false);
  };

  const onSubmit = e => {
    const { idn, nom, prenom } = profil;
    window.location.href = `http://localhost:8080/api/saml/dev?idn=${idn}&nom=${nom}&prenom=${prenom}`;
    e.preventDefault();
  };

  return (
    <div className="btn-connect-container">
      <div className="btn-idnot" onClick={() => openModal()}>
        {isShowing && (
          <Modal isOpen={isShowing} close={closeModal}>
            <form onSubmit={onSubmit}>
              <label>Quel profil </label>
              <select
                value={JSON.stringify(profil)}
                onChange={e => update(JSON.parse(e.target.value))}
              >
                {testProfil.map(profil => (
                  <option key={profil.idn} value={JSON.stringify(profil)}>
                    {" "}
                    {profil.nom} {profil.prenom} -- {profil.result}
                  </option>
                ))}
              </select>
              <input type="submit" value="Envoyer" />
            </form>
          </Modal>
        )}
        <div className="logo" />
        <div className="text-container">
          <div className="text">{I18n.t("actions.IDENTIFY")}</div>
          <div className="text text-bold">{I18n.t("actions.ID_NOT")}</div>
        </div>
      </div>
    </div>
  );
}

export default memo<void>(DevIdnot);

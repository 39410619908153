// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import type { AnneeEncaissement, DonneesEncaissement } from '../../../../types/types';
import EncaissementData from './subComponents/EncaissementData';

type Props = {|
    encaissementData: DonneesEncaissement,
|}

const Encaissement = ({ encaissementData }: Props) => (
  <div className="regime encaissement">
    <div className="regime-header">
      <span className="header-item">{I18n.t('personalData.cotisationData.year')}</span>
      <span className="header-item">{I18n.t('personalData.cotisationData.cotisationAnnuelle')}</span>
      <span className="header-item">{I18n.t('personalData.cotisationData.cotisationEncaisse')}</span>
      <span className="header-item">{I18n.t('personalData.cotisationData.soldeRestant')}</span>
      <span className="header-item">{I18n.t(`personalData.cotisationData.${encaissementData.prelevement ? 'echeancePrelever' : 'echeanceRestant'}`)}</span>
      <span className="header-item empty-header" />
    </div>
    <div className="regime-content">
      {encaissementData.anneeEncaissementList.map((anneeEncaissement: AnneeEncaissement) => (
        <EncaissementData anneeEncaissement={anneeEncaissement} />
      ))}
    </div>
  </div>
);

export default Encaissement;

// @flow

import { RestClient } from '../network';

const baseUrl = '/login';

const loginApi = {
  login: (token: string) =>
    new RestClient(`${baseUrl}/`, 'POST').jsonBody(token).execute(),
};

export default loginApi;

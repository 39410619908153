// @flow

import { I18n } from "react-redux-i18n";
import React from "react";

type Props = {
  message: string,
  className: string,
  shouldTranslate?: boolean,
  style?: string,
};

type State = {|
  height: number,
  width: number,
  position: "right" | "left",
|};

class PageToBeReleased extends React.Component<Props, State> {
  state = {
    width: window.innerWidth,
    position: "right",
  };
  ref = React.createRef();
  minWidth = 180;

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions, false);
    this.checkPosition();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions, false);
  }

  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
    this.checkPosition();
  };

  checkPosition = () => {
    const dimensions = this.ref.current.getBoundingClientRect();
    if (this.state.width - dimensions.x < this.minWidth) {
      this.setState({ position: "left" });
    } else {
      this.setState({ position: "right" });
    }
  };

  render() {
    const { className = "", message, shouldTranslate = true } = this.props;
    return (
      <div
        ref={this.ref}
        className={`tooltip cursor-not-allowed ${className} ${this.state.position}`}
      >
        {this.props.style ? (
          <span className={`tooltip-text ${this.props.style}`}>
            {shouldTranslate ? I18n.t(`tooltip.${message}`) : message}
          </span>
        ) : (
          <span className="tooltip-text">
            {shouldTranslate ? I18n.t(`tooltip.${message}`) : message}
          </span>
        )}
      </div>
    );
  }
}
export default PageToBeReleased;

// @flow

import React, { memo } from "react";

type Props = {
  message: string,
  onClick?: Function,
  className?: string,
  selected?: boolean,
  color?: string,
};

const DateButton = ({
  message,
  onClick,
  className,
  selected = false,
  color = selected === true ? "yellow" : "gray",
}: Props) => (
  <div className={`${className}`}>
    <button
      className={`${selected ? "selected" : ""} --${color}`}
      onClick={onClick}
    >
      <span>{message}</span>
    </button>
  </div>
);

export default memo<Props>(DateButton);

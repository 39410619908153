// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import Bloc from '../../components/accueil/Bloc';
import CustomButton from '../../components/buttons/CustomButton';
import { SERVICE_HOME_ROUTE } from '../../utils/routes';
import InternalPage from '../../components/utils/pages/InternalPage';
import { colors, externalServiceNames } from '../../utils/enum';

type Props = {
  history: History,
  serviceName: string,
  onLinkClick: () => void,
};

const ExternalService = ({ history, serviceName, onLinkClick }: Props) => {
  const getMessage = (name: string) => I18n.t(`service.${serviceName}.${name}`);

  return (
    <InternalPage
      menu={I18n.t('service.title')}
      color={colors.YELLOW}
      hasBackButton
      handleClickBack={() => history.push(SERVICE_HOME_ROUTE)}
    >
      <div id="retreat-request" className="bloc-container mt-1 pb-5 yellow">
        <Bloc title={getMessage('title')} color={colors.YELLOW}>
          { serviceName === externalServiceNames.DRL ? (
            <>
              <p className="text-justify">{getMessage('text_1')}</p>
              <p className="text-justify">{getMessage('text_2')}</p>
            </>
            )
            : <p className="text-justify">{getMessage('text')}</p>
          }
          <CustomButton color={colors.YELLOW} onClick={onLinkClick} message={getMessage('button')} />
        </Bloc>
      </div>
    </InternalPage>
  );
};

export default ExternalService;

// @flow

import React, { useCallback, useContext, useState } from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import CGUContainer from "../../components/cgu/CGUContainer";
import Back from "../../components/buttons/Back";
import { DASHBOARD_ROUTE, HOME_PAGE_ROUTE } from '../../utils/routes';
import type { User } from "../../types/userType";
import CGUCheckbox from "../../components/cgu/CGUCheckbox";
import CustomButton from "../../components/buttons/CustomButton";
import {
  disconnectOnly,
  isUserConnected,
} from "../../state/login/loginService";
import userApi from "../../network/api/userApi";
import { extractErrorFromSigma } from "../../utils/modal";
import ModalContext from "../../context/modal/ModalContext";
import { storeToken } from "../../services/loginService";
import { storeUserCguValidated } from '../../state/user/userService';

type Props = {|
  history: Object,
  user: User,
  isConnected: boolean,
  dispatch: Function,
|};

const CguPage = ({ history, user, isConnected, dispatch }: Props) => {
  const { openErrorModal } = useContext(ModalContext);
  const [cgu, updateCgu] = useState(false);
  const [buttonIsDisabled, toggleDisabledStatus] = useState<boolean>(false);

  const handleRefus = useCallback(() => {
    if (isConnected) {
      toggleDisabledStatus(true);
      userApi
        .refusCgu()
        .then(() => {
          disconnectOnly(dispatch);
          history.replace(HOME_PAGE_ROUTE);
        })
        .catch(e => {
          extractErrorFromSigma(openErrorModal)(e);
          disconnectOnly(dispatch);
          history.replace(HOME_PAGE_ROUTE);
        })
        .finally(() => toggleDisabledStatus(false));
    }
  }, [dispatch, isConnected]);

  const handleAccept = () => {
    if (isConnected) {
      toggleDisabledStatus(true);
      userApi
        .acceptCgu()
        .then(res => res.text())
        .then((token: string) => {
          storeToken(token, dispatch);
          dispatch(storeUserCguValidated());
          history.replace(DASHBOARD_ROUTE);
        })
        .catch(e => {
          extractErrorFromSigma(openErrorModal)(e);
          disconnectOnly(dispatch);
          history.replace(HOME_PAGE_ROUTE);
        })
        .finally(() => toggleDisabledStatus(false));
    }
  };

  const onClick = useCallback(() => history.goBack(), [history]);
  const { cguValidated } = user;
  return (
    <div id="cgu-page" className="page-container">
      {(!isConnected || cguValidated) && (
        <Back
          className="mb-2"
          onClick={onClick}
          message={I18n.t("actions.BACK")}
        />
      )}
      {isConnected && !cguValidated && (
        <div className="cgu-alert">
          <span>{I18n.t("cgu.alert")}</span>
        </div>
      )}
      <div className="cgu-content">
        <h1>{I18n.t("cgu.title")}</h1>
        <CGUContainer className="cgu-container-portail" showCheckbox={false} />
        {isConnected && !cguValidated && (
          <>
            <div>
              <CGUCheckbox cgu={cgu} updateCgu={updateCgu} />
            </div>
            <div className="d-flex buttons">
              <CustomButton
                className="ghost-btn"
                message={I18n.t("cgu.refuser")}
                disabled={buttonIsDisabled}
                onClick={handleRefus}
              />
              <CustomButton
                message={I18n.t("cgu.accepter")}
                disabled={!cgu || buttonIsDisabled}
                onClick={handleAccept}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(
  connect(state => ({
    user: state.user.user,
    isConnected: isUserConnected(state.login),
  }))(CguPage),
);

// @flow

import { RestClient } from '../network';

const baseUrl = '/fc';

const franceConnectApi = {
  getTokenFC: (token: string) => new RestClient(`${baseUrl}/token?token=${token}`, 'GET').execute(),
  getHistoryLink: () => new RestClient(`${baseUrl}/history`, 'GET').execute(),
};

export default franceConnectApi;

import { combineReducers } from "redux";
import { reducer as notificationsReducer } from "reapop";
import { i18nReducer } from "react-redux-i18n";
import customNotificationReducer from "./notifications/notificationReducer";
import loginReducer from "./login/loginReducer";
import userReducer from "./user/userReducer";

export default combineReducers({
  i18n: i18nReducer,
  notifications: notificationsReducer(),
  customNotification: customNotificationReducer,
  login: loginReducer,
  user: userReducer,
});

// @flow

import React, { memo } from "react";
import { Translate } from "react-redux-i18n";
import type { Identity } from "../../pages/enrolement/EnrolementRoutes";
import ConditionalWrapper from "../utils/ConditionalWrapper";

type Props = {|
  subTitle?: string,
  big?: boolean,
  identity: Identity,
|};

const IdentityBanner = ({ subTitle, big, identity }: Props) => (
  <div className="identity-banner-container pt-1 pb-1">
    <ConditionalWrapper
      condition={big || false}
      wrappers={[
        children => <h1>{children}</h1>,
        children => <h2>{children}</h2>,
      ]}
    >
      <Translate
        value="banner.title"
        nom={identity.lastName.toUpperCase()}
        prenom={identity.firstName}
      />
    </ConditionalWrapper>
    <div>{subTitle}</div>
  </div>
);

IdentityBanner.defaultProps = {
  subTitle: "",
  big: false,
};

export default memo<Props>(IdentityBanner);

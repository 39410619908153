// @flow

import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import Bloc from "../../components/accueil/Bloc";
import { documentList, infoList, myRetirementList, retirementList, } from "../../utils/const";
import type { User } from "../../types/userType";
import { userStatus } from "../../utils/userStatus";
import InternalPage from "../../components/utils/pages/InternalPage";

type Props = {|
  history: Object,
  user: User,
|};

const myServicesList = [
  {
    name: "informationRight",
    right: [userStatus.ACTIF, userStatus.HORS_EXERCICE],
    color: "yellow",
    linkedList: infoList,
  },
  {
    name: "prepareRetirement",
    right: [userStatus.ACTIF, userStatus.HORS_EXERCICE],
    color: "cobalt",
    linkedList: retirementList,
  },
  {
    name: "myRetirement",
    right: [userStatus.RETRAITE, userStatus.BENEFICIAIRE],
    color: "cobalt",
    linkedList: myRetirementList,
  },
  {
    name: "documents",
    right: [userStatus.ALL],
    color: "blue",
    linkedList: documentList,
  },
];

const ServicesPage = ({ history, user }: Props) =>
  <InternalPage menu={I18n.t("service.title")} color="yellow">
    <div className="bloc-container mt-5">
      {myServicesList.map(item => (
        <>
          {item.right.find(right => right.toString() === user.statut.toString() || right === userStatus.ALL) && (
            <Bloc
              color={item.color}
              title={I18n.t(`dashboard.services.${item.name}`)}
              linkedList={item.linkedList}
              status={user.statut}
            />
          )}
        </>
      ))}
    </div>
  </InternalPage>;

export default connect(state => ({
  user: state.user.user,
}))(ServicesPage);

// @flow

import React, { useCallback, useContext, useState } from 'react';
import { withRouter } from "react-router-dom";

import TimelineSmall from "../../components/enrolement/TimelineSmall";
import type { Identity } from "./EnrolementRoutes";
import CGU from "./CGU";
import enrolementApi from "../../network/api/enrolementApi";
import { HOME_PAGE_ROUTE } from "../../utils/routes";
import { extractErrorFromSigma } from "../../utils/modal";
import ModalContext from "../../context/modal/ModalContext";

import LoadingContext from "../../context/loader/LoadingContext";
import connect from "react-redux/es/connect/connect";
import { login } from "../../services/loginService";
import { statutType } from '../../utils/const';

type Props = {|
  identity: Identity,
  token: string,
  history: Object,
  statut: string,
|};

const Step3 = ({ identity, token, history, statut, dispatch }: Props) => {
  const { openErrorModal } = useContext(ModalContext);
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const [ buttonIsDisabled, toggleDisabledStatus ] = useState<boolean>(false);

  const acceptCgu = useCallback(() => {
    const isTier = statut === statutType.TIER;
    toggleDisabledStatus(true);
    enrolementApi
      .acceptCgu(token, isTier)
      .then(res => {
        return res.text();
      })
      .then(token =>
        login(
          token,
          history,
          dispatch,
          openErrorModal,
          showLoading,
          closeLoading,
        ),
      )
      .catch(extractErrorFromSigma(openErrorModal))
      .finally(() => toggleDisabledStatus(false));
  }, [token]);

  const refusCgu = useCallback(() => {
    toggleDisabledStatus(true);
    enrolementApi
      .refusCgu(token)
      .then(() => history.push(HOME_PAGE_ROUTE))
      .catch(extractErrorFromSigma(openErrorModal))
      .finally(() => toggleDisabledStatus(false));
  }, [token]);

  return (
    <div id="step-3">
      <TimelineSmall active={3} />
      <CGU token={token} handleAccept={acceptCgu} handleRefus={refusCgu} buttonIsDisabled={buttonIsDisabled} />
    </div>
  );
};

export default withRouter(connect()(Step3));

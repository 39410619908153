// @flow

import React, { memo } from 'react';
import { I18n } from 'react-redux-i18n';
import { DASHBOARD_ROUTE, HOME_PAGE_ROUTE } from '../../utils/routes';
import type { User } from '../../types/userType';

type Props = {|
  history: Object,
  showBorderBottom?: boolean,
  isConnected: boolean,
  cartouche: React.ReactNode,
  user: User,
  children: React.ReactNode,
|};

const HeaderConnected = ({
  history,
  showBorderBottom = false,
  isConnected,
  cartouche,
  user,
  children,
}: Props) => {
  const { cguValidated } = user;

  const onClick = () => {
    if (!isConnected) {
      history.push(HOME_PAGE_ROUTE);
    } else if (isConnected && cguValidated) {
      history.push(DASHBOARD_ROUTE);
    }
  };

  return (
    <header
      className={`enrolement-header${showBorderBottom ? ' border-bottom' : ''}`}
    >
      <div>
        <div
          className={`logo-container${
            cguValidated || !isConnected ? ' cursor-pointer' : ''
          }`}
          onClick={onClick}
        >
          <div className="logo" />
          <div className="line" />
        </div>
        <div
          className={`title${
            cguValidated || !isConnected ? ' cursor-pointer' : ''
          }`}
          onClick={onClick}
        >
          <h2>{I18n.t('header.subtitle')}</h2>
          <h1>{I18n.t('header.title')}</h1>
        </div>
      </div>
      <div className="cartouche">{cartouche}</div>
      {children}
    </header>
  );
};

export default memo<Props>(HeaderConnected);

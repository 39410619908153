// @flow

import React from "react";
import {momentFormat} from "../../utils/const";
import moment from "moment";
import {I18n} from "react-redux-i18n";

export type PaymentList = {
  date: string,
  organisme: string,
  montant: number,
};

type Props = {
  month: string,
  paymentList: PaymentList[],
  onClick: Function,
};

const Popup = ({ selectedData, paymentList, onClick }: Props) => {
  return (
    <div className="yellow row-bloc popup">
      <div className="popup_inner">
        <div
          className="fal fa-times-circle close close-icon"
          onClick={onClick}
        />
        <p>{I18n.t(`service.packPayment.popup.detail`, selectedData)}</p>
        <div className="table">
          <div className="evenly-row title">
            <p className="col">{I18n.t(`service.packPayment.popup.date`)}</p>
            <p className="col">{I18n.t(`service.packPayment.popup.organism`)}</p>
            <p className="col">{I18n.t(`service.packPayment.popup.amount`)}</p>
          </div>
          <div className="limit" />
          <div className="data">
            <div className="evenly-row">
              <p className="col">{moment(selectedData.datePaiement).format(momentFormat.DATE)}</p>
              <p className="col">{selectedData.libelleCourt}</p>
              <p className="col">{`${selectedData.montantNet ? selectedData.montantNet : '-' } €`}</p>
              {/* Les données découpes ne sont pas dans l'appel générique,*/}
              {/* TODO les trouver */}
            </div>
            {paymentList.map(line => (
                <>
                  <div className="limit" />
                  <div className="evenly-row">
                    <p className="col">{moment(line.date).format(momentFormat.DATE)}</p>
                    <p className="col">{line.libelleCourt}</p>
                    <p className="col">{`${line.montantNet ? line.montantNet : '-' } €`}</p>
                  </div>
                </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;

// @flow

import { I18n } from 'react-redux-i18n';
import { addNotification as notify } from 'reapop';
import toMessage from '../services/i18nService';

export function notifySuccess(reduxDispatch: Function) {
  return (response: Object) => {
    reduxDispatch(
      notify({
        message: 'Les modifications ont bien été prises en compte',
        status: 'success',
      }),
    );
    return response;
  };
}

export function notifyRequestSuccess(reduxDispatch: Function) {
  return (response: Object) => {
    reduxDispatch(
      notify({
        message: I18n.t('message.NEW_REQUEST'),
        status: 'success',
      }),
    );
    return response;
  };
}
export function notifyError(reduxDispatch: Function) {
  return (error: Object) => {
    reduxDispatch(
      notify({
        message: I18n.t(
          `wsError.${error.errorCode}.content`,
          toMessage(error),
        ),
        status: 'error',
      }),
    );
    if (error.errorCode === 'AUTHENTIFICATION_EXPIRED') {
      sessionStorage.clear();
    }
  };
}

// @flow

import React, { memo } from "react";
import { I18n } from "react-redux-i18n";
import TimelineSmallItem from "./TimelineSmallItem";

type Props = {| active: number, completed?: ?Function |};

const Timeline = ({ active, completed }: Props) => (
  <>
    {Object.values(I18n.t(`enrolement.timeline`)).map((translated, index) => {
      return (
        <TimelineSmallItem
          key={`timeline_${index}`}
          activated={active === index + 1}
          completed={(completed && completed(index)) || active > index + 1}
          message={translated}
        />
      );
    })}
  </>
);
export default memo<Props>(Timeline);

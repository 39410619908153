// @flow

import React, { memo } from "react";
import Timeline from "./Timeline";

type Props = {| active: number |};

const TimelineSmall = ({ active }: Props) => (
  <div className="timeline">
    <Timeline active={active} />
  </div>
);
export default memo<Props>(TimelineSmall);

// @flow

import React from "react";

type Props = {
  current: boolean,
  date: string,
  paymentInfo: string,
  onClick: Function,
};

const PaymentContainerCircle = ({
  current,
  date,
  paymentInfo,
  onClick,
}: Props) => (
  <div className="container-circle">
    <div className={`circle ${current ? "current" : ""}`} onClick={onClick}>
      <div className="circle-text">
        <p className="title">{date}</p>
        <p>{paymentInfo}</p>
      </div>
    </div>
  </div>
);

export default PaymentContainerCircle;

const fr = {
  actions: {
    BACK: 'Retour',
    CANCEL: 'Annuler',
    SAVE: 'Enregistrer',
    DELETE: 'Supprimer',
    NEW: 'Nouveau',
    CONFIRM: 'Confirmer',
    SEARCH: 'Rechercher',
    START: 'COMMENCER',
    CONTINUE: 'Continuer',
    VALIDATE: 'Valider',
    IDENTIFY: 'S\'identifier avec',
    ID_NOT: 'ID.not',
    needhelp: 'J\'ai besoin d\'aide...',
    understood: 'J\'ai compris.',
    ACCEPT: 'J\'accepte',
    DECLINE: 'Je refuse',
    GENERATE: 'Générer',
  },
  message: {
    SUCCESS: 'Les modifications ont bien été prises en compte',
    ERROR: 'Les modifications ont échoués',
    NEW_REQUEST: 'Votre demande a bien été prises en compte',
    YES: 'Oui',
    NO: 'Non',
  },
  wsError: {
    ERR_TECHNIQUE: {
      title: 'Erreur',
      content:
        'Une erreur technique est survenue, merci de réessayer ultérieurement.',
    },
    ERR_DATE_BEFORE_CURRENT_UNION: {
      title: 'Erreur de données',
      content: 'La date de fin d\'union renseignée est avant la date d\'union de cette même union',
    },
    ERR_NO_CORRESPONDING_CHILD: {
      title: 'Erreur de données',
      content: 'Aucun enfant existant ne correspond à votre demande',
    },
    ERR_NO_CORRESPONDING_CONJOINT: {
      title: 'Erreur de données',
      content: 'Aucun conjoint existant ne correspond à votre demande',
    },
    ERR_DATE_END_UNION: {
      title: 'Erreur de données',
      content: 'Vous ne pouvez pas renseigner la fin d\'union avec un conjoint dans la mise à jour de ses données',
    },
    ERR_DATE_UNION: {
      title: 'Erreur de données',
      content: 'Vous ne pouvez pas renseigner la fin d\'union car la date d\'union n\'est pas renseignée',
    },
    EMAIL_INVALID: {
      title: 'Email invalide',
      content: 'L\'email renseigné n\'est pas valide, merci de réessayer.',
    },
    INTERNAL_ERROR: {
      title: 'Erreur',
      content:
        'Une erreur technique est survenue, merci de réessayer ultérieurement.',
    },
    INVALID_TOKEN: {
      title: 'Le délais du token est expiré',
      content:
        'Le token utilisé est expérié, merci de recommencer l\'enrolement',
    },

    INVALID_CONNECTION_TOKEN: {
      title: 'Token invalide',
      content: 'Le token utilisé pour vous connecter est invalide',
    },
    AUTHENTIFICATION_EXPIRED: {
      title: 'Session expirée',
      content: 'Connexion interrompue, merci de vous reconnecter.',
    },
    ERR_EMPTY_FIELD: {
      title: 'Champ renseigné vide',
      content: 'Le champ que vous avez renseigné est vide.',
    },
    ERR_NO_DATE_EDIT_ALLOWED: {
      title: 'Erreur de données',
      content: 'Vous ne pouvez pas modifier les dates d\'union d\'une union passée',
    },
    ERR_MAUVAIS_MODE_IDNOT: {
      title: 'Vous souhaitez vous connecter via ID.not.',
      content:
        'Votre profil CPRN nous signale que vous n\'êtes pas un notaire actif. Nous vous invitons à vous connecter avec votre compte FranceConnect.',
    },
    ERR_MAUVAIS_MODE_FC: {
      title: 'Vous souhaitez vous connecter via FranceConnect.',
      content:
        'Votre profil CPRN nous signale que vous êtes un notaire actif. Nous vous invitons à vous connecter avec votre compte ID.not.',
    },
    ERR_NO_DELETE_CONJOINT: {
      title: 'Vous ne pouvez pas supprimer les informations de votre conjoint.',
      content:
        'Nous vous invitons à vous tourner vers les services de la CPRN si vous souhaitez supprimer les informations liées à votre conjoint.',
    },
    ERR_LIMIT_AGE_NOT_REACHED: {
      title: 'Service indisponible',
      content: 'Vous n’avez pas atteint l’âge requis pour accéder à ce service. Nous vous invitons à vous rapprocher de la CPRN si vous souhaitez plus d’informations.',
    },
    ERR_REQUEST_LIMIT_REACHED: {
      title: 'Accès limité',
      content: 'Nous vous informons que ce service permet de générer qu’un seul document toutes les 24h.',
    },
    ERR_REQUEST_STATE_iS_DELAYED: {
      title: 'Service indisponible',
      content: 'Vous ne pouvez pas générer un nouveau document tant que le statut de la dernière demande n\'est pas résolu. Pour plus d\'informations veuillez vous rapprocher de la CPRN.',
    },
    ERR_INCONNU: {
      title: 'Vous n\'avez pas accès à nos services',
      content: 'Nous ne pouvons donner une suite favorable à votre demande.',
    },
    ERROR_MESSAGE: {
      title: 'Service indisponible',
      content: 'Une erreur technique est survenue, merci de réessayer ultérieurement.',
    },
    ERR_IDENTITE: {
      title: 'Vous n\'avez pas accès à nos services',
      content: 'Nous ne pouvons donner une suite favorable à votre demande.',
    },

    ERR_COHERENCE: {
      title: 'Erreur de saisie',
      content: 'Merci de vériﬁer les informations saisies.',
    },

    ERR_PARAM: {
      title: 'Erreur de saisie',
      content: 'Merci de vériﬁer les informations saisies.',
    },

    FIELD_REQUIRED: {
      title: 'Un champ obligatoire n\'est pas renseigné',
      content: 'Le champ "%{field0}" est requis',
    },

    ERR_ESTIMATION_RETRAITE: {
      title: 'Estimation retraite non disponible',
      content: 'L\'âge de départ doit être compris entre 50 et 71 ans.',
    },
    ERR_NO_DATE_INDICATED: {
      title: 'Erreur de données',
      content: 'Aucune date de fin d\'union n\'a été renseignée',
    },
    ERR_DATE_IN_FUTURE: {
      title: 'Erreur de données',
      content: 'La date renseignée est dans le futur',
    },
    ERR_NAISSANCE_AFTER_UNION: {
      title: 'Erreur de données',
      content: 'La date de naissance renseignée est après la date d\'union',
    },
    ERR_NO_CURRENT_UNION: {
      title: 'Erreur de données',
      content: 'Vous n\'avez pas d\'union en cours',
    },
    ERR_NO_DATA: {
      title: 'Erreur de données',
      content: 'Merci de vous rapprocher de la CPRN.',
    },
    ERR_ACCESS_PERSONA: {
      title: 'Vous n\'avez pas accès à ces données',
      content: 'Les données de cette personne ne vous sont pas accessibles.',
    },

    ERR_DOUBLON: {
      title: 'Vous n\'avez pas accès à nos services',
      content: 'Nous ne pouvons donner une suite favorable à votre demande.',
    },

    INVALID_ENROLEMENT: {
      title: 'Erreur',
      content:
        'Une erreur technique est survenue, merci de réessayer ultérieurement.',
    },

    ERR_NO_DATA_PAYMENT: {
      title: 'Erreur',
      content: 'Document indisponible pour l\'année demandée (%{field0})',
    },
    ERR_NO_DOCUMENT: {
      title: 'Erreur',
      content: 'Il n\'y a pas de documents disponibles pour les critères sélectionnés.',
    },
    ERROR_MINUS_100: {
      title: 'Erreur',
      content: 'L\'assuré n\'est pas connu de la CPRN.',
    },

    GIPUR_NOT_FOUND: {
      title: 'Erreur',
      content: 'L\'assuré n\'est pas connu de la CPRN.',
    },

    GIPUR_NO_DATA: {
      title: 'Erreur',
      content: 'Aucune demande de régime n\'a été trouvé dans la caisse CPRN',
    },

    GIPUR_TECHICAL: {
      title: 'Service indisponible',
      content: 'Une erreur technique est survenue, merci de réessayer ultérieurement.',
    },

    GIPUR_TIMEOUT: {
      title: 'Service indisponible',
      content: 'Une erreur technique est survenue, merci de réessayer ultérieurement.',
    },

    GIPUR_MAINTENANCE: {
      title: 'Service en maintenance',
      content: 'Service en maintenance, merci de réessayer ultérieurement.',
    },

    GIPUR_ERROR: {
      title: 'Erreur',
      content: 'Une erreur technique est survenue, merci de réessayer ultérieurement.',
    },

    COLOR_INVALID:
      'Le champ "%{field0}" doit être une couleur au format hexadecimale : #ff00ff',
    USERNAME_ALREADY_EXISTS:
      'Cet identifiant est déjà utilisé sur un autre compte, veuillez en choisir un autre',
    EMAIL_ALREADY_EXISTS:
      'Cette adresse e-mail est déjà utilisée sur un autre compte, veuillez en choisir une autre',
    PASSWORDS_DIFFERENT:
      'Les mots de passe "%{field0}" et "%{field1}" doivent être identiques',
    WRONG_LOGIN_OR_PASSWORD: 'Nom d\'utilisateur ou mot de passe incorrect',
    TOO_MANY_WRONG_ATTEMPS:
      'Suite à des erreurs dans la saisie de vos identifiants, votre compte est verrouillé pendant %{field0} minutes, veuillez-vous reconnecter ultérieurement',
    ROLE_LABEL_EXISTS: 'Il existe déjà un rôle avec ce libellé',
    PASSWORD_TOO_SHORT:
      'Le mot de passe est trop court, il doit faire au moins %{field0} caractères',
    MESSAGE: '%{field0}',
  },
  cartouche: {
    account: 'Mon compte ID.not',
    deconnexion: 'Me déconnecter',
    notaccess: 'Portail Notaccess',
  },
  sentry: {
    TITLE: 'Oups',
    SUBTITLE: 'Une erreur vient de se produire',
    LINK_DESCRIPTION:
      'Merci de réessayer ultérieurement et de rafraîchir cette page. Vous pouvez également faire un rapport de bug ',
    LINK_ACTION: 'ici',
    RELOAD: 'Recharger la page',
  },
  date: {
    DATE_START: 'Date de début',
    DATE_END: 'Date de fin',
    long: 'DD/MM/YYYY',
  },
  footer: {
    contact: 'Contactez-nous',
    mentions: 'Mentions légales',
    conditions: 'Conditions générales d\'utilisation',
    site: 'Site institutionnel',
  },
  header: {
    title: 'Portail des affiliés',
    subtitle: 'Caisse de Prévoyance et de Retraite des Notaires',
    portalTitle: 'Portail affilié de la CPRN',
    portalSubtitle: 'Mon espace personnel',
  },
  banner: {
    title: 'Bienvenue %{prenom} %{nom}',
    subtitle:
      'C\'est votre première connexion sur le nouveau portail affilié de la CPRN',
  },
  enrolement: {
    timeline: [
      'Validez votre identité',
      'Paramétrez votre profil',
      'Validez les CGUs',
    ],
    presentation: {
      part1:
        'La CPRN souhaite sécuriser votre première connexion afin de garantir l\'authentification de votre identité.',
      part2:
        'La validation de votre compte personnel se décline en trois étapes :',
    },
    identify: 'Vérifiez votre identité',
    profilParameter: 'Paramétrez votre profil',
    portail: 'Pilotez votre portail personnalisé',
    form: {
      identify: 'Vérifions votre identité',
      nir: {
        label: 'Votre numéro de Sécurité Sociale',
        tooltip:
          'Vous retrouverez votre numéro de sécurité sociale sur votre carte vitale. Saisissez les 13 premiers caractères.',
        invalid: 'Le format de votre numéro de sécurité social est invalide',
      },
      cprn: {
        label: 'Votre numéro d\'affilié CPRN',
        tooltip:
          'Vous retrouverez votre numéro d\'affilié sur tous les documents officiels de la CPRN. Il doit comporter 9 caractères.',
        invalid: 'Le format de votre numéro d\'affilié est invalide',
      },
      zipcode_ID_NOT: {
        label: 'Code département de votre étude principale',
        tooltip:
          'Le code département à renseigner correspond à celui de votre office.',
        invalid: 'Le format de votre code département est invalide',
      },
      zipcode_FRANCE_CONNECT: {
        label: 'Code département de votre adresse personnelle',
        tooltip:
          'Le code département à renseigner correspond à celui de votre adresse personnelle.',
        invalid: 'Le format de votre code département est invalide',
      },
      notFrance: {
        label: 'Je ne réside pas en France',
      },
      country: {
        label: 'Pays de résidence',
        tooltip: 'Votre pays de résidence.',
      },
      button: 'Continuer',
    },
  },
  parametrerProfil: {
    form: {
      parametrer: 'Paramétrez votre profil',
      description:
        'Dans le cadre des échanges avec la CPRN, nous souhaitons valider l\'adresse mail et le mode de communication avec nos services.',
      chooseEmail: {
        label: 'Sélectionnez votre courriel :',
      },
      chooseEmailDescription: {
        label: ' ',
        CPRN: 'Connu de la CPRN :',
        IDNOT: 'Connu d\'ID.not :',
        FC: 'Connu de FranceConnect :',
        other: 'Utiliser un autre courriel :',
      },
      otherEmail: {
        label: 'Renseignez votre courriel',
        invalid: 'Le format de votre email est invalide',
      },
      confidentialityTitle: {
        label: 'Protection des données personnelles',
      },
      fillEmail: {
        label: 'Saisissez votre courriel',
      },
      emailDescription: {
        label:
          'Ce courriel sera utilisé afin de vous informer de la mise à disposition des documents relatifs à vos délégations sur le portail des affiliés.',
      },
      confidentialityDescription1: {
        label:
          'Les informations recueillies sont nécessaires à la première authentification par la CPRN et seront conservées durant toute la durée de votre affiliation.',
      },
      confidentialityDescription2: {
        label:
          'Dans le cadre de partenariats avec la profession, la CPRN peut être amenée à communiquer votre adresse postale aux associations suivantes : Association de Prévoyance du Notariat de France (APNF) et Association Nationale des Notaires Retraités (ANNOR).',
      },
      confidentiality: {
        label:
          'J\'autorise la CPRN à communiquer mes données personnelles aux partenaires de la profession précités, afin de recevoir des informations de leur part.',
      },
      confidentialityDescription3: {
        label:
          'Vous pouvez à tout moment demander à être retiré de leur liste de diffusion.',
      },
      confidentialityDescription4: {
        label:
          'Conformément à loi n°78-17 du 6 janvier 1978 modifiée relative à l\'informatique, aux fichiers et aux libertés ainsi qu\'au Règlement n°2019/679/UE sur la protection des données personnelles (RGPD), vous bénéficiez d\'un droit d\'accès, de rectification et d\'opposition vis-à-vis des données vous concernant. Vous pouvez exercer ces droits en vous adressant au Délégué à la protection des données de la CPRN à l\'adresse suivante contactDPO@cprn.fr.',
      },
      dematerialiser: {
        label: 'Dématérialisation des échanges',
      },
      dematerialisationDescription: {
        label:
          'Dès que votre compte sera validé sur le portail affiliés, la CPRN vous adressera exclusivement par mail une notification afin de vous informer de la mise à disposition de vos documents personnels (appel de cotisation, estimation retraite, rachat de point B…).',
      },
      button: 'Valider',
    },
  },
  landingpage: {
    title: 'Mon espace personnel',
    textActif: 'Je suis actif au sein de la profession',
    textInactif: 'Je n\'exerce plus au sein de la profession',
    subtitle:
      'Le portail personnalisé de la CPRN s\'adresse aux notaires libéraux actifs,  retraités ou hors exercice ayant cotisé à la CPRN et à leurs bénéficiaires.',
    jesuis: 'Je suis',
    actif: 'Actif',
    retraite: 'Retraité',
    beneficiaire: 'Bénéficiaire',
    horsexercice: 'Hors exercice',
    tiersdeconfiance: 'Délégataire',
    conjointcollaborateur: 'Conjoint collaborateur',
  },
  service: {
    title: 'services',
    EIG: {
      title: 'Service EIG',
      back: 'Retour',
      text: 'L’estimation indicative globale (EIG) comprend les mêmes informations que le RIS ainsi qu’une estimation du montant de votre future retraite pour les régimes de base et complémentaire. ',
      text_2: 'L’EIG vous est adressé à partir de 55 ans, puis tous les 5 ans jusqu’au départ en retraite.',
      subtitle: 'Mes dernières estimations indicatives globales (EIG)',
      files: {
        label: 'EIG',
        link: 'Voir tous mes documents générés',
      },
      isTooOld: 'Vous être trop agé pour accéder à ce service (plus de 65 ans)',
    },
    retreat: {
      title: 'Demande d\'estimation retraite CPRN',
      text:
        'Veuillez estimer la date à laquelle vous comptez partir à la retraite. Par défaut la date sélectionnée correspond au mois de vos 62 ans.',
      leavingDate: 'Votre date de départ au plus tôt est le %{date}',
      full: 'Votre date de départ à taux plein est le %{date}',
      back: 'Retour',
      filename: 'Estimation-retraite-%{date}.pdf',
      filename2: 'Attestation-Paiement-%{date}.pdf',
      file: {
        loader:
          'Veuillez patienter la génération de votre estimation peut prendre jusqu\'à 1 minute.',
      },
      form: {
        leavingDate: {
          label: 'Date d\'ouverture des droits',
        },
        reversion: {
          label: 'Option de réversion à 100%',
        },
        conjoint: {
          label: 'Conjoint(e) : ',
          unknown: 'Inconnu',
        },
        birthdate: {
          label: 'Date de naissance du conjoint(e) :',
        },
        button: 'Générer',
      },
      subtitle: 'Mes dernières estimations retraites',
      files: {
        link: 'Voir mes derniers documents générés',
      },
    },
    retreatRequest: {
      title: 'Demande unique de retraite',
      back: 'Retour',
      text_1: 'La demande unique de retraite vous permet d\'adresser en une seule fois votre demande de liquidation des droits auprès des régimes auxquels vous avez cotisé au cours de votre carrière.',
      text_2: 'Pour accéder à cette demande, vous allez être redirigé vers un téléservice dédié du GIP Union Retraite.',
      button: 'Demander la liquidation de mes droits',
    },
    interRegime: {
      title: 'Estimation de retraite inter-régime',
      back: 'Retour',
      text:
        'Ce service vous permet de calculer le montant estimatif de votre retraite auprès des régimes auxquels vous avez cotisé au cours de votre carrière.',
      button: 'Accéder au service',
    },
    maCarriere: {
      title: 'Ma carrière',
      back: 'Retour',
      text: 'Le service ma carrière vous permet de consulter tous vos trimestres et vos points ' +
          'de retraite acquis et d\'identifier les éventuelles anomalies.',
      button: 'Visualiser ma carrière',
    },
    requestMonitoring: {
      title: 'Suivi des demandes info retraite',
      back: 'Retour',
      date: 'Effectuée le %{date}',
      noData: 'Aucune demande',
      type: {
        RELEVE_INDIVIDUEL_SITUATION: 'Relevé individuel de situation',
        ESTIMATION_INDICATIVE_GLOBALE_DROITS_BLOQUES:
          'Estimation indicative globale à droits bloqués',
        ESTIMATION_INDICATIVE_GLOBALE_DROITS_CONSTANTS:
          'Estimation indicative globale à droits constants',
        ESTIMATION_INDICATIVE_GLOBALE_STANDARD:
          'Estimation indicative globale standard',
      },
      status: {
        ENCOURS: 'En attente de validation',
        RETARDEE: 'Retardée',
        TRAITEE: 'Traitée',
        REJETEE: 'Rejetée',
        EN_COURS: 'En attente de validation',
      },
    },
    contributorCertification: {
      title: 'Certificat d\'affiliation cotisant',
      back: 'Retour',
      text:
        'Générez votre certificat d\'affiliation cotisant et téléchargez le document.',
      textOptional:
        'Retrouvez l\'historique des certificats d\'affiliation cotisant.',
      subtitle: 'Mes derniers certificats d\'affiliation cotisant',
      files: {
        label: 'Certificat d\'affiliation cotisant',
        link: 'Voir tous mes documents générés',
      },
    },
    packPayment: {
      payment: 'Historique des paiements',
      noData: 'Données absentes',
      description: 'Historique des paiements des 24 derniers mois et dates prévisionnelles des paiements à venir.',
      subtitle: 'Demande d\'attestation de paiement',
      back: 'Retour',
      text:
        'Sélectionner une période sur laquelle vous souhaitez générer une attestation de paiement',
      form: {
        dateDebut: {
          label: 'Date de début de période',
        },
        dateFin: {
          label: 'Date de fin de période',
        },
      },
      button: 'Demander l\'attestation de paiement de retraite',
      generate: 'Générer',
      filename: 'Attestation-Paiement-%{date}.pdf',
      files: {
        label: 'Paiement de retraite',
        link: 'Voir tous mes paiements de retraite',
      },
      popup: {
        detail: 'Détails des paiements du mois de %{date}',
        date: 'Date',
        organism: 'Organisme',
        amount: 'Montant',
      },
    },
    packFiscal: {
      title: 'Pack fiscal',
      back: 'Retour',
      text:
        'Ce Pack permet à un affilié de visualiser sur un seul écran l’ensemble des montants à déclarer à l’administration fiscale pour tous ses régimes de retraite (CPRN, CRPCEN, Régime Général, ...) sur 3 ans. Le Pack permet aussi d’éditer une attestation annuelle.',
      subtitle: 'Mes montants à déclarer à l\'administration fiscale',
      description: 'Accédez à vos  attestations fiscales des deux dernières années.',
      noData: 'Aucune attestation de paiement disponible',
      libelleCourt: 'Organisme',
      montantImposable: 'Montant à déclarer',
      download: 'Télécharger',
      filename: 'Attestation-Fiscale-%{date}.pdf',
      files: {
        label: 'Mon montant à déclarer',
        link: 'Voir tous mes montants à déclarer',
        noData: 'Non communiqué',
      },
    },
    certificateRetirementPayment: {
      title: 'Demande d\'attestation de paiement de retraite',
      description:
        'La liste des opérations de paiement sur les 24 derniers mois et dates prévisionnelles de des paiements à venir.',
      back: 'Retour',
      form: {
        date: {
          label: 'Sélectionnez une date',
        },
        button: 'Télécharger',
      },
      text:
        'Cette fonctionnalité offre la possibilité à l’affilié de télécharger un PDF : la liste des versements de l’année par la CPRN, la liste des montants de Prélèvement A à la Source retenus',
      button: 'Demander l\'attestation de paiement de retraite',
      generate: 'Générer',
    },
    recipientInfo: {
      title: 'Service information annuelle de vos droits',
      back: 'Retour',
      text: 'Ce service permet d\'actualiser vos droits en cas de changement ' +
          '(taux PAS, précomptes sociaux...).',
      button: 'Générer',
    },
  },
  delegation: {
    loadingMessage: 'Veuillez patientez, cette opération peut prendre jusqu\'à 1 minute',
    delegationForm: {
      delegate: 'Déléguer',
      delegateDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ipsum',
      trustedThirdParty: 'à un collaborateur',
      myThirdParties: 'Mes délégations',
      iDelegatedTo: 'J\'ai délégué mes droits à',
      iWantToDelegatedTo: 'Je souhaite déléguer mes droits aux collaborateurs',
      grantedDelegations: 'Délégations accordées : %{number}/2',
      availableDelegations: 'Délégations possibles à accorder : %{number}/2',
      maxDelegations: 'Nombre de délégations maximal atteint',
      enrolled: 'Enrôlé le',
      noDelegations: 'Vous n\'avez actuellement accordé aucune délégation.',
      noPossibleDelegations: 'Vous n\'avez aucun délégué potentiel.',
    },
    delegationModal: {
      delegateAccesTo: {
        title: 'Confirmation de la délégation à %{name}',
        text: 'Vous donnez délégation à %{name} à :',
      },
      revocateAccesFrom: {
        title: 'Suppression de la délégation à %{name}',
        text: 'Vous supprimez la délégation à %{name} qui n’aura plus accès à :',
      },
      list: [
        'Vos données professionnelles',
        'Vos données de cotisations',
        'Vos documents en lien à vos cotisations',
      ],
      conjointCollaborateur: {
        documents: 'Les documents de votre conjoint collaborateur',
        cotisation: 'Les données de cotisations de votre conjoint collaborateur',
      },
    },
  },
  personalData: {
    dataPage: {
      personal: 'Personnelles',
      family: 'Familiales',
      professional: 'Professionnelles',
      retreatment: 'Retraites',
      cotisation: 'Données de cotisations',
    },
    pageTitle: {
      personal: 'données personnelles',
      family: 'données familiales',
      professional: 'données professionnelles',
      retreatment: 'données retraites',
      cotisation: 'données de cotisations',
    },
    personalData: {
      title: 'Mes données personnelles',
      consult: 'Consulter mes données personnelles',
      back: 'Retour',
      updatePending: 'Vos modifications sont en cours de traitement',
      defaultAddressLabel: 'Adresse par défaut pour les envois de documents',
      form: {
        fullName: {
          label: 'Prénom NOM',
        },
        birthDate: {
          label: 'Date de naissance',
        },
        numpers: {
          label: 'Numéro d\'affilié',
        },
        birthName: {
          label: 'Nom de naissance',
        },
        defaultAddress: {
          label: 'Adresse par défaut pour les envois de documents',
        },
        address2: {
          label: 'Adresse personnelle',
        },
        defaultAddress2: {
          label: 'Adresse par défaut pour les envois de documents',
        },
        adresse: {
          street: {
            label: 'Adresse postale personnelle',
          },
          complement: {
            label: 'Adresse complémentaire',
          },
          postalCode: {
            label: 'Code postal',
          },
          city: {
            label: 'Ville',
          },
          country: {
            label: 'Pays',
          },
        },
        email: {
          label: 'Email personnel (utilisé pour la communication avec la CPRN)',
          invalid: 'Le format de votre email est invalide',
          required: 'L\'adresse email est requise',
        },
        phone: {
          label: 'Téléphone',
        },
        dematAgreement: {
          label: 'Autorisation de dématerialisation',
        },
        dateCgu: {
          label: 'Date d\'acceptation des CGU',
        },
        transmissionAgreement: {
          label: 'Autorisation de transmission à un tiers',
        },
        dematerialisationDescription: {
          label: 'Dématérialisation des échanges',
        },
        tiersDescription: {
          label:
            'J\'autorise la CPRN à communiquer mes données personnelles aux partenaires de la profession précités, afin de recevoir des informations de leur part.',
        },
        button: 'Enregistrer',
        adresseCodeError: 'Veuillez remplir une adresse valide',
        cityCodeError: 'Veuillez remplir un nom de ville valide',
        postalCodeError: 'Veuillez remplir un code postal valide',
        countryCodeError: 'Veuillez sélectionner un pays',
      },
      validationModal: {
        title: 'Confirmation',
        body: 'Souhaitez-vous valider ces informations ?',
        body_otherCountry: 'Attention, en cas de changement d’adresse de votre pays de résidence, nous vous remercions de vous rapprocher de la CPRN.',
      },
      confirmDeleteModal: {
        title: 'Confirmation',
        body: 'Souhaitez-vous supprimer cet enfant ?',
      },
    },
    familyData: {
      title: 'Mes données familiales',
      consult: 'Consulter mes données  \n familiales',
      description:
        'Afin de compléter votre profil, veuillez renseigner les informations de votre situation familiale.',
      description_endUnion:
        'Veuillez renseigner quelques informations pour déclarer la fin de votre union',
      description_retreat:
        'Les informations de votre situation familiale.',
      pastUnions_label:
        'Mes unions passées',
      back: 'Retour',
      form: {
        consultFamilyData: {
          label: 'Consulter mes données familiales',
        },
        modifyFamilyData: {
          label: 'Modifier mes données familiales',
        },
        conjointCreate: {
          label: 'Renseignez les informations de votre conjoint',
        },
        conjointUpdate: {
          label: 'Modifiez les données de votre conjoint',
          label_secondary: 'Les données de votre conjoint',
        },
        dteNaissance: {
          isAfterUnion: 'La date de naissance renseignée est après la date d\'union',
        },
        startUnionDate: {
          dateInFuture: 'Cette date d\'union est dans le futur',
          beforeFinishedUnions: 'Cette union commence avant une union passée',
        },
        endUnionDate: {
          dateInFuture: 'Cette date de fin d\'union est dans le futur',
          beforeFinishedUnions: 'Cette date de fin d\'union est avant une union passée',
          endBeforeStart: 'La date de fin d\'union est avant la date d\'union',
        },
        error: {
          dateFormat: 'Le format de date renseigné est incorrect',
          birthDateInFuture: 'La date de naissance renseignée est dans le futur',
        },
        conjoint: {
          genre: {
            label: 'Genre',
          },
          birthDate: {
            label: 'Date de naissance',
          },
          usageName: {
            label: 'Nom d\'usage',
          },
          unionType: {
            label: 'Type d\'Union',
          },
          birthName: {
            label: 'Nom de naissance',
          },
          unionDate: {
            label: 'Date d\'Union',
          },
          firstName: {
            label: 'Prénoms',
          },
          unionEndDateSeparation: {
            label: 'Date de fin d\'union',
          },
          unionEndDateDeces: {
            label: 'Date de décès',
          },
          declareEndUnion: {
            label: 'Déclarer la fin de l\'union',
          },
          pastUnions: {
            label: 'Consulter mes unions passées',
          },
          incompleteData: {
            label: 'Veuillez rectifier la saisie de l’union et l’enregistrer, avant d’en saisir la fin.',
          },
          motiveUnionEnd: {
            label: 'Motif de la fin d\'union',
          },
          unionPeriod: {
            label: '- Du %{dateUnion} au %{dateFinUnion}',
          },
        },
        childrenFields: {
          genre: {
            label: 'Genre',
          },
          birthDate: {
            label: 'Date de naissance',
          },
          usageName: {
            label: 'Nom d\'usage',
          },
          firstName: {
            label: 'Prénoms',
          },
        },
        childrenSubtitle: {
          label_0: 'Enfant (%{count})',
          label: 'Enfant (%{count})',
          label_plural: 'Vos enfants (%{count})',
        },
        childrenDescription: {
          label:
            'Veuillez renseigner vos enfants de moins de 21 ans ou inaptes.',
          label_secondary: 'Vos enfants de moins de 21 ans ou inaptes.',
        },
        genre: {
          label: 'Genre',
        },
        firstName: {
          label: 'Prénoms',
        },
        usageName: {
          label: 'Nom d\'usage',
        },
        birthDate: {
          label: 'Date de naissance',
        },
        children: {
          label: 'Enfant %{count}',
          add: 'Ajouter un enfant',
        },
        unions: {
          label: 'Union %{count} ',
        },
        button: 'Enregistrer',
      },
    },
    professionalData: {
      title: 'Mes données professionnelles',
      consult: 'Consulter mes données professionnelles',
      back: 'Retour',
      form: {
        myProfessionalData: {
          label: 'Mes données professionnelles',
        },
        office: {
          label: 'Nom de la société :',
        },
        sermonDate: {
          label: 'Date de première prestation de serment',
        },
        status: {
          label: 'Statut du notaire',
        },
        numberExercise: {
          label: 'Durée d\'activité',
        },
        address: {
          professional_only: 'Adresse par défaut pour les envois de documents :',
          personal_empty: 'Adresse personnelle :',
          professional: 'Adresse professionnelle :',
          personal: 'Adresse par défaut pour les envois de documents :',
        },
        affectationClasse: {
          label: 'Classe d\'affection',
        },
        prelevement: {
          label: 'Prélèvement',
        },
        classe: {
          label: 'Classe de cotisation',
        },
        iban: {
          label: 'IBAN',
        },
        numberPoints: {
          label: 'Nombre de Points Rachetables',
        },
        bic: {
          label: 'BIC',
        },
        costPoint: {
          label: 'Coût de rachats',
        },
        activitiesSubtitle: {
          label_0: 'Carrière (%{count})',
          label: 'L\'historique de la carrière (%{count})',
          label_plural: 'L\'historique de la carrière (%{count})',
        },
        activitiesDescription: {
          label: 'L\'historique de la carrière',
        },
        activities: {
          label: 'L\'historique de la carrière (%{count})',
          add: 'Ajouter une carrière',
          dateDeb: {
            label: 'Date de début',
          },
          dateFin: {
            label: 'Date de Fin',
          },
          libelle: {
            label: 'Statut',
          },
        },
        dateDeb: {
          label: 'Depuis le ',
        },
        dateFin: {
          label: 'Jusqu\'au ',
        },
        libelle: {
          label: 'Statut',
        },
        history: {
          label: 'Historique de mes activités',
        },
        label: {
          label: 'Libellé',
        },
        estimation: {
          label: 'Estimation de votre prochaine cotisation',
        },
        baseScheme: {
          label: 'Régime de base',
        },
        bScheme: {
          label: 'Régime complémentaire B',
        },
        cScheme: {
          label: 'Régime complémentaire C',
        },
        cmScheme: {
          label: 'Régime complémentaire CM',
        },
        idScheme: {
          label: 'Régime ID',
        },
        requiredDate: {
          label: 'Date d\'exigibilité',
        },
        levyDate: {
          label: 'Date de prélèvement',
        },
      },
    },
    professionalDataTier: {
      offices: 'Mes offices',
      delegations: 'Délegations',
      contactMail: 'Courriel de contact',
      since: 'depuis le',
    },
    cotisationData: {
      title: 'Mes données de cotisations',
      consult: 'Consulter mes données de cotisations',
      consultOf: 'Consulter les données de cotisations de %{name}',
      rbl: 'Régime de base',
      encaissement: 'Encaissement',
      conjointCollaborateur: {
        selectProfile: 'Sélectionnez le profil',
        consent: 'Votre conjoint collaborateur n\'a pas autorisé la consultation de ses données',
        consentTiers: 'Le conjoint collaborateur n\'a pas autorisé la consultation de ses données',
        donneesPro: {
          title: 'Informations',
          dateCollaboration: 'Date de début de collaboration',
          regimeBase: 'Option régime de base',
          infobulle: 'Option 1 : Assiette forfaitaire\n' +
              'Option 2 : 25% ou 50% de l\'assiette du Notaire\n' +
              'Option 3 : 25% ou 50% du revenu du Notaire avec partage de l\'assiette',
          typeRegimeBase: {
            FORFAIT: 'Forfait',
            ASSIETTE_PARTAGE: '%{pourcentage} du revenu',
            ASSIETTE: '%{pourcentage} de l\'assiette',
          },
          modePaiement: {
            PRELEVEMENT: 'Prélèvement',
            VIREMENT: 'Virement',
          },
          regimeComplementaire: 'Option régime complémentaire',
          payment: 'Mode de paiement',
        },
        cotisations: {
          title: 'Cotisations',
          regime: {
            BASE: 'Régime de base',
            COMPLEMENTAIRE_B: 'Régime complémentaire - section B',
            COMPLEMENTAIRE_C: 'Régime complémentaire - section C',
            COMPLEMENTAIRE_COLMAR_METZ: 'Régime complémentaire - Colmar et Metz',
            INVALIDITE_DECES: 'Régime invalidité / décès',
          },
        },
      },
      cotisationAnnuelle: 'Cotisation annuelle',
      cotisationEncaisse: 'Cotisation encaissée à ce jour',
      soldeRestant: 'Solde restant à régler',
      echeanceRestant: 'Échéances restantes',
      echeancePrelever: 'Échéances à prélever',
      dateEcheances: 'Date des échéances',
      rcb: 'Régime complémentaire - Section B',
      rcbInfobulle: 'Votre cotisation annuelle correspond à la cotisation de base X le coefficient d’âge\n' +
          'Attention : le calcul doit être réalisé pour chaque trimestre',
      rcc: 'Régime complémentaire - Section C',
      rcccm: 'Régime complémentaire - Colmar et Metz',
      rid: 'Régime invalidité / décès',
      year: 'Année',
      income: 'Revenus',
      incomeYear: 'Année des revenus',
      rate: 'Taux',
      rate_value: '%{taux} %',
      assiette: 'Assiette',
      motif: 'Motif',
      tx: 'Tx',
      tranche: 'Tranche',
      cotisation: 'Cotisation',
      cotisationDeces: 'Cotisation Décès',
      cotisationInvalidite: 'Cotisation Invalidité',
      dates: 'Période',
      to: 'au',
      classeAffectation: 'Classe d\'affectation',
      classeCotisation: 'Classe cotisation',
      cotisationDeBase: 'Cotisation de base',
      coeff: 'Coefficient d\'âge',
      officeAverageIncome: 'Moyenne des produits de l\'office',
      parts: 'Parts',
      forfaitDc_garantie: 'Garantie décès',
      forfaitDc_cotisation: 'Cotisation forfaitaire',
      forfaitInvalidite_garantie: 'Garantie invalidité',
      forfaitInvalidite_cotisation: 'Cotisation forfaitaire',
      abattementJeune: 'Abattement jeune notaire appliqué',
      abattementMEP: 'Abattement mise en place',
      erreur: 'Données indisponibles. Veuillez contacter la CPRN',
      selectProfileTitle: 'Sélectionnez un profil :',
      noData: 'Aucune donnée de cotisation',
      selectNotaireText: 'Sélectionnez un notaire pour visualiser les données de cotisations ',
    },
    retreatData: {
      title: 'Mes données de retraite',
      consult: 'Consulter mes données de retraite',
      back: 'Retour',
      typePayment: 'Modalité de paiement',
      RBDate: 'Date d\'ouverture des droits RB',
      RCDate: 'Date d\'ouverture des droits RC',
      ssContribution: 'Exonération des charges sociales',
      taxPAS: 'Taux Prélèvement à la source',
      iban: 'IBAN',
      bic: 'BIC',
      rights: {
        type: 'Type de droits',
        opening: 'Date d\'ouverture',
        closure: 'Date de clôture',
      },
      pension: {
        title: 'Montant brut',
        base: 'Régime de Base',
        sectionB: 'Régime Complémentaire Section B',
        sectionC: 'Régime Complémentaire Section C',
        sectionCM: 'Régime Colmar & Metz',
        education: 'Rente Education',
        invalidity: 'Rente Invalidité',
        temporary: 'Rente Temporaire',
        viager: 'Rente Viagère',
      },
      majoration: {
        title: 'Montant Majoration Brut',
        base: 'Régime de Base',
        sectionB: 'Régime Complémentaire Section B',
        sectionC: 'Régime Complémentaire Section C',
        sectionCM: 'Régime Colmar & Metz',
        education: 'Rente Education',
        invalidity: 'Rente Invalidité',
        temporary: 'Rente Temporaire',
        viager: 'Rente Viagère',
      },
      amountSs: 'Montant des charges sociales',
      restraint: 'Retenue',
      amountSource: 'Montant prélèvement à la source',
      amountNet: 'Montant NET',
      datePayment: 'Date du paiement',
      lastPayment: 'Prochain Paiement',
      pasInfo: 'Informations prélèvement à la source',
    },
  },
  tooltip: {
    actif:
      'Cette section s\'adresse aux notaires libéraux en activité et ayant des parts dans une étude.',
    retraite: 'Ce profil s’adresse aux notaires ayant liquidé leurs droits à la retraite.',
    beneficiaire: 'Ce profil s’adresse aux conjoints survivants qui bénéficient d’une pension de réversion.',
    horsexercice: 'Ce profil s’adresse aux notaires ayant cessé leur activité de notaire libéral et n’ont pas liquidé leurs droits à la retraite.',
    tiersdeconfiance: 'Ce profil s’adresse aux collaborateurs du notaire qui bénéficient d’un droit d’accès spécifique.',
    pageToBeReleased: 'Section en cours de développement',
    noDataAvailable: 'Pas de données disponibles',
    noFileAvailable: 'Pas de documents disponibles',
    loadingData: 'Chargement des données...',
  },
  contactUs: 'Contactez-nous',
  error: {
    coherenceTitle: 'Problème de cohérence',
    coherenceContent: 'Nous vous invitons à vérifier les informations saisies.',
    noresultTitle:
      'Les informations fournies ne correspondent à aucun compte chez nous.',
    noresultContent: 'Veuillez nous contacter en cas de désaccord.',
    invalideDate: 'La date que vous avez renseignée n\'est pas valide',
    invalideDateNoFuture: 'La date ne peut pas être dans le future',
  },
  form: {
    required: 'Champs obligatoires',
    noModification: 'Aucune modification n\'a été apportée',
  },
  validation: {
    required: 'Champ obligatoire',
    validPostalCode: 'Veuillez renseigner un code postal valide',
  },
  fieldRequirement: {
    required: 'Ce champ est requis',
    tooLong: 'Ce champ est trop long (32 caractères max)',
    empty: 'Ce champ est vide',
  },
  cgu: {
    title: 'Conditions Générales d\'Utilisation',
    charte: 'J’ai lu et j’accepte les conditions générales d’utilisation',
    politique: 'J\'ai lu et j\'accepte la politique de confidentialité',
    accepter: 'J\'accepte. J\'accède au Portail.',
    refuser: 'Je refuse. Je quitte le Portail',
    alert:
      'Les Conditions Générales d\'Utilisation ont évoluées, merci d\'en prendre connaissance et de les valider.',
  },
  mention: {
    title: 'Mentions Légales',
  },
  dashboard: {
    alert: {
      link: 'En savoir plus',
    },
    personalData: {
      title: 'Données',
      personal: 'Mes données personnelles',
      family: 'Mes données familiales',
      profession: 'Mes données professionnelles',
      retirement: 'Mes données de retraite',
      cotisation: 'Mes données de cotisations',
      modify: 'Modifier mes données',
      consult: 'Consulter mes données',
    },
    services: {
      title: 'Services',
      informationRight: 'Droit à l\'information',
      prepareRetirement: 'Préparer ma retraite',
      myRetirement: 'Ma retraite',
      documents: 'Mes documents',
      estimation: 'Estimation retraite CPRN',
      uniqueDemande: 'Demande unique de retraite',
      interRegime: 'Estimation retraite inter-régimes',
      maCarriere: 'Ma carrière',
      certif: 'Certificat d\'affilation',
      eig: 'Service EIG',
      demandes: 'Suivi des demandes',
      packPayment: 'Pack paiement',
      packFiscal: 'Pack fiscal',
      recipientInfo: 'Information annuelle de vos droits',
      certificateRetirementPayment:
        'Attestation de paiement',
      button: '+ de services',
    },
    files: {
      title: 'documents',
    },
    delegations: {
      delegateAcces: 'Souhaitez-vous déléguer vos accès à un collaborateur ? ',
      clickHere: 'Cliquez ici',
      delegateExplanation: 'Déléguer vos accès permet à une personne de votre choix d\'accéder à vos documents',
    },
    documents: {
      lastDocument: 'dernier document le %{date}',
    },
  },
  menu: {
    dashboard: 'Tableau de bord',
    datas: 'Mes données',
    services: 'Mes services',
    documents: 'Mes documents',
    delegations: 'Mes délégations',
  },
  files: {
    title: 'documents',
    delegatesDocuments: 'Les documents de mes délégués',
    delegateDocuments: 'Les documents de mon délégué %{prenom} %{nom}',
    back: 'Retour',
    select: 'Sélectionnez le type de documents que vous recherchez :',
    cotisation_count: 'Décompte de cotisation',
    cotisation_class: 'Demande d\'information de classe de cotisation',
    point_repurchase: 'Rachat de points',
    tax_document: 'Attestation fiscale',
    contributor_certification: 'Certification d\'affiliation cotisant',
    retreat_estimation: 'Historique d\'estimation retraite',
    technical_sheet: 'Fiche technique',
    document_infos: 'Vous visualisez les documents de %{fullname} (%{numpers})',
    conjointCollaborateur: {
      consent: 'Le conjoint collaborateur n\'a pas autorisé le notaire à consulter ses données',
    },
    tier: {
      filter: 'Filtres',
      documents: 'Documents',
      generatedOn: 'généré le %{date}',
      showMore: 'Voir plus',
      noAvailableDocuments: 'Aucun document disponible',

    },
    details: {
      back: 'Revenir à la mosaïque des documents',
      noFiles: 'Aucun document disponible',
      estimation_retraite: {
        title: 'Historique des estimations retraite',
        subtitle: 'Mes estimations retraite',
        link: 'Générer de nouveaux documents à la demande',
      },
      rachat_points: {
        title: 'Rachats de points',
        subtitle: ' ',
        link: 'Générer de nouveaux documents à la demande',
      },
      decompte_cotisation: {
        title: 'Décomptes de cotisation',
        subtitle: ' ',
        link: 'Générer de nouveaux documents à la demande',
      },
      MCCL: {
        title: 'Mailing Changement de classe',
        subtitle: ' ',
        link: 'Générer de nouveaux documents à la demande',
      },
      certification_affiliation_cotisant: {
        title: 'Certifications d\'affiliation cotisant',
        subtitle: ' ',
        link: 'Générer de nouveaux documents à la demande',
      },
      attestation_fiscale: {
        title: 'Attestations fiscales',
        subtitle: ' ',
        link: 'Générer de nouveaux documents à la demande',
      },
      classe_cotisation: {
        title: 'Demande d\'information de classe de cotisation',
        subtitle: ' ',
        link: 'Générer de nouveaux documents à la demande',
      },
      fiche_technique: {
        title: 'Fiches techniques',
        subtitle: ' ',
        link: 'Générer de nouveaux documents à la demande',
      },
    },
  },
  franceConnect: {
    question: 'Qu\'est ce que France Connect ?',
    connected: 'Vous êtes identifié grâce à FranceConnect',
    history: 'Historique des connexions/échanges de données',
    disconnect: 'Se déconnecter',
  },
  my: 'Mes',
  modales: {
    LANDING_PAGE: {
      btn: 'Quel profil dois-je sélectionner ?',
      title: 'Quel profil sélectionner ...',
      subtitles: [
        {
          label: 'Quel profil vous correspond ?',
          content:
            'En fonction de votre situation, l\'espace sécurisé est accessible par deux modes de connexion :',
          list: [
            'ID.Not : ce mode de connexion s’adresse aux actifs au sein de la profession :',
            [
              'Notaires actifs',
              'Collaborateurs avec délégation',
              'Conjoints collaborateurs',
            ],
            'France Connect : FranceConnect : ce mode de connexion s’adresse aux :',
            [
              'Notaires retraités',
              'Bénéficiaires (conjoints survivants)',
              'Notaires hors exercice',
            ],
          ],
        },
        {
          label: 'Qu\'est-ce que ID.not  et FranceConnect ?',
          content: '',
          list: [
            'ID.Not : solution d\'authentification de la profession permettant aux notaires d\'ajouter les services de la CPRN dans son portail professionnel, sans reconnexion.',
            'FranceConnect est la solution proposée par l’État pour simplifier votre connexion aux services en ligne. Pour vous connecter à votre compte utilisateur utilisez le service FranceConnect.',
          ],
        },
        {
          label:
            'Aucune de ces situations professionnelles ne vous correspond ?',
          content:
            'Si votre profil correspond à un notaire salarié, collaborateur ou  clerc, nous vous  invitons à vous rapprocher de la CRPCEN : la caisse de retraite et de prévoyance des clercs et employés de notaires.',
          list: [],
        },
      ],
    },
    STEP_1: {
      btn: 'J\'ai besoin d\'aide...',
      title: 'J\'ai besoin d\'aide...',
      subtitles: [
        {
          label: 'Où trouver mon numéro de sécurité social ?',
          content:
            'Le NIR, ou numéro d\'inscription au registre, est communément appelé \'numéro de sécurité sociale\'. Il s\'agit d\'un numéro d\'identification unique pour chaque individu inscrit au répertoire national d\'identification des personnes physiques (RNIPP) composé de 13 chiffres. Vous trouverez ce numéro sur votre carte vitale ou toutes attestation de la sécurité sociale.',
          list: [],
        },
        {
          label: 'Qu\'est ce que mon numéro d\'affilié ?',
          content:
            'Un numéro d\'affilié unique vous a été attribué dès la publication au Journal Officiel de votre arrêté de nominations. Vous retrouverez ce numéro sur tous documents officiel de la CPRN vous étant adressé, tel que :',
          list: [
            'les lettres d\'information trimestrielle',
            'le rapport d\'activité annuel',
            'l\'information annuelle sur vos droits (à compter de l\'âge de 50 ans)',
          ],
        },
        {
          label: 'Quel code département renseigner ?',
          content:
            'Dans le cas où vous vous connectez via ID.not, il vous est demandé de renseigner le département de votre étude principale. Si vous vous connectez via France connect, vous pouvez renseigner votre code département de votre domicile.',
          list: [],
        },
      ],
    },
    STEP_2: {
      btn: 'J\'ai besoin d\'aide...',
      title: 'J\'ai besoin d\'aide...',
      subtitles: [
        {
          label: 'Quel adresse mail sélectionner ?',
          content:
            'L\'adresse mail que vous sélectionnez, ou renseignez dans le champ de saisi, sera le courriel avec lequel la CPRN vous contactera pour l\'ensemble de vos démarches administratives.',
          list: [],
        },
        {
          label: 'Pourquoi choisir la dématérialisation des échanges ?',
          content:
            'Dans le cadre de la mise en oeuvre d\'une politique \'zéro papier\', la CPRN s\'engage à réduire l\'envoi de document papier. Afin de vous engager au côté de la CPRN, il est nécessaire que vous nous informiez de votre souhait de dématérialiser vos échanges.',
          list: [],
        },
      ],
    },
  },
  right: {
    RB: 'Régime de base',
    RC: 'Régime Complémentaire',
    RIT: 'Rente Temporaire',
    RIV: 'Rente Viagère',
    RIE: 'Rente Education',
    RII: 'Rente Invalidité',
  },
};
export default fr;

// @flow

import React, { memo, useContext, useEffect, useState } from 'react';
import SubPageTitle from '../../components/utils/SubPageTitle';
import { I18n } from 'react-redux-i18n';
import { connect } from "react-redux";
import Back from '../../components/buttons/Back';
import { COTISATION_DATA_ROUTE, HOME_PAGE_ROUTE } from '../../utils/routes';
import Bloc from '../../components/accueil/Bloc';
import CustomButton from '../../components/buttons/CustomButton';
import userApi from '../../network/api/userApi';
import { extractErrorFromSigma } from '../../utils/modal';
import ModalContext from '../../context/modal/ModalContext';
import type { notaireOffice } from "../../types/types";
import { donneesProTiers } from '../../types/types';
import { notifySuccess } from '../../network/notification';
import NavigateDataButton from '../../components/buttons/NavigateDataButton';


type Props = {|
  history: Object,
  dispatch: Function,
|}

const ProfessionalDataTier = ({history, dispatch}: Props) => {
  const onClick = () => history.push(HOME_PAGE_ROUTE);
  const { openErrorModal } = useContext(ModalContext);
  const initialMailContact = "";
  const [mailContact, setMailContact] = useState<string>(initialMailContact);
  const [notairesOffices, setNotairesOffices] = useState<notaireOffice>([]);

  const handleMailChange = (mail: string) => {
    setMailContact(mail);
  }

  const handleSubmit = (event: Event) => {
    userApi.saveTierMail(mailContact)
      .then(dispatch(notifySuccess))
      .catch(extractErrorFromSigma(openErrorModal));
    event.preventDefault();
  }

  useEffect(() => {
    userApi
      .getTierProfessionalData()
      .then(res => res.json())
      .then((json: donneesProTiers) => {
        setMailContact(json.mailTier);
        setNotairesOffices(json.notaireOffices);
      });
  }, [])

  return (
    <div className="page-container">
      <SubPageTitle
        className="m-auto"
        message={I18n.t("personalData.pageTitle.professional")}
        color="cobalt"
      />
      <div id="professional-data-tier">
        <Back
          className="mb-2"
          onClick={onClick}
          message={I18n.t("personalData.professionalData.back")}
        />
      </div>
      <div className="bloc-container">
        <NavigateDataButton
          messageLeft={""}
          onClickLeft={() => {}}
          messageRight={I18n.t("personalData.cotisationData.title")}
          onClickRight={() => history.push(COTISATION_DATA_ROUTE)}
        />
        <Bloc className="data" color="cobalt">
          <div className="seperate-between-family">
            <div className="professional-logo" />
            <div className="cobalt family-consult">
              {" "}
              {I18n.t("personalData.professionalData.consult")}{" "}
            </div>
          </div>
            <div className="professional-data-wrapper">
              <div className="limit mb-1" />
              <div className="email-tier">
                <div className="email-tier-label">{I18n.t("personalData.professionalDataTier.contactMail")}</div>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    className="fs-16 email-tier-input"
                    value={mailContact ? mailContact : ""}
                    onChange={(e) => handleMailChange(e.target.value)}
                  />
                  <CustomButton
                    className="btn-confirmation"
                    type="submit"
                    message={I18n.t("actions.SAVE")}
                  />
                </form>
              </div>
              <div className="offices">
                <div className="offices-label text-uppercase">{I18n.t("personalData.professionalDataTier.offices")}</div>
                { notairesOffices.length >= 1 && notairesOffices.map(office =>
                  <div key={office.numSociete} className="office-wrapper">
                    <div className="office-wrapper-title text-bold">{office.intituleSociete}</div>
                    <div className="office-wrapper-adresse text-uppercase">
                      { office.adresse.street && <>{office.adresse.street}</>}
                      { office.adresse.postalCode && <>, {office.adresse.postalCode}</>}
                      { office.adresse.city && <>, {office.adresse.city}</>}
                      { office.adresse.country && <>, {office.adresse.country}</>}
                      { office.adresse.complement && <>, {office.adresse.complement}</>}
                    </div>
                    <div className="office-wrapper-delegations">{I18n.t("personalData.professionalDataTier.delegations")}</div>
                    {office.notaires.map(notaire =>
                      <div key={notaire.numpers} className="office-wrapper-notaire">
                        <div>
                          <span>{notaire.prenom} {notaire.nom}</span>
                          <span> - </span>
                          <span>{notaire.statut}</span>
                          <div className="office-wrapper-notaire-numpers">{notaire.numpers}</div>
                        </div>
                        <div className="office-wrapper-notaire-enrol">{I18n.t("personalData.professionalDataTier.since")} {notaire.dteDeleg}</div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
        </Bloc>
      </div>
    </div>
  );
};

export default connect(state => ({
  hasFamilyData: state.user.hasFamilyData,
}))(memo<Props>(ProfessionalDataTier));
// @flow

import { FormApi } from 'final-form';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import CustomButton from '../../../components/buttons/CustomButton';
import { EndUnionInfo } from '../../../types/types';
import type { TypeDataUnit } from '../../../types/types';
import { ConjointFormFields } from './ConjointFormFields';

type ConjointFormProps = {
  className?: string,
  onSubmit: (TypeDataUnit) => void,
  onSubmitEndUnion: (EndUnionInfo) => void,
  onSubmitPastUnion: (TypeDataUnit) => void,
  translationBase: string,
  initialValue: TypeDataUnit,
  endUnionInitialValue: EndUnionInfo,
  disabled?: boolean,
  disabledFields?: boolean,
  finishedUnions: TypeDataUnit[],
  setFormRef: ({current: FormApi | undefined}) => void,
  setHasSubmitted: (boolean) => void,
  hasSubmitted: boolean,
  isFormModified: boolean,
  setIsFormModified: (boolean) => void,
};

export const ConjointForm = ({
  className,
  onSubmit,
  onSubmitEndUnion,
  onSubmitPastUnion,
  translationBase,
  initialValue,
  endUnionInitialValue,
  disabled,
  disabledFields,
  finishedUnions,
  setFormRef,
  hasSubmitted,
  setHasSubmitted,
  isFormModified,
  setIsFormModified,
}: ConjointFormProps) => (
  <div className={`${className || ''} mb-1 size-2${disabled ? ' disabled' : ''}`}>
    <ConjointFormFields
      className={className}
      onSubmit={onSubmit}
      onSubmitEndUnion={onSubmitEndUnion}
      onSubmitPastUnion={onSubmitPastUnion}
      translationBase={translationBase}
      disabled={disabled}
      disabledFields={disabledFields}
      initialValue={initialValue}
      endUnionInitialValue={endUnionInitialValue}
      finishedUnions={finishedUnions}
      setFormRef={setFormRef}
      setIsFormModified={setIsFormModified}
      setHasSubmitted={setHasSubmitted}
    />
    <div className="save-button-container">
      {!isFormModified && hasSubmitted &&
          (
            <div className="no-modification-error-text">
              <p>{I18n.t('form.noModification')}</p>
            </div>
          )
        }
      {!disabledFields && (
        <CustomButton
          type="submit"
          form="conjoint-data-form"
          message={I18n.t(`${translationBase}button`)}
        />)
      }
    </div>
  </div>
);

// @flow

import { RestClientAuthenticated } from '../network';

const baseUrl = '/tier';

const tierApi = {
  getNotaireDelegants: () =>
    new RestClientAuthenticated(`${baseUrl}/delegants`, 'GET').execute(),
};

export default tierApi;

// @flow

import { CoreozDatePickerMaterial } from 'coreoz-form-base';
import { FormApi } from 'final-form';
import moment, { Moment } from 'moment';
import { Field, Form } from 'react-final-form';
import { I18n } from 'react-redux-i18n';
import React, { useRef, useState } from 'react';
import CustomButton from '../../../components/buttons/CustomButton';
import { ConfirmModal } from '../../../components/modaleNotification/ConfirmModal';
import type { TypeDataUnit } from '../../../types/types';
import { labelType, momentFormat } from '../../../utils/const';
import { genreList } from '../../../utils/data';
import useToggle from '../../../utils/useToggle';
import { getLabel } from '../../../utils/utils';
import {
  checkBirthDate,
  checkEmptyField,
  checkFieldLength,
  fieldRequired,
  isValidDateFormat,
} from '../../../utils/validator';
import { DropdownFieldForm } from './components/DropdownFieldForm';
import { RequiredFieldLabel } from './components/RequiredFieldLabel';

type ChildDataFormProps = {
  child?: TypeDataUnit,
  deleteNotSavedChild: (TypeDataUnit) => void,
  setChildToDelete: (TypeDataUnit) => void,
  onSubmit: (TypeDataUnit) => void,
  translationBase: string,
  index?: number,
  disabledFields?: boolean,
  toggleModal: () => void,
};

export const ChildDataForm = ({
  child,
  onSubmit,
  setChildToDelete,
  deleteNotSavedChild,
  translationBase,
  index,
  disabledFields,
  toggleModal,
}: ChildDataFormProps) => {
  const [isFormModified, setIsFormModified] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [confirmModal, toggleConfirmModal] = useToggle();
  const formRef = useRef<FormApi>();
  const [childValuesToSave, setChildValuesToSave] = useState<TypeDataUnit>();

  const isDirty = () => {
    // Use a custom isDirty to handle string / moment comparison for dates
    if (formRef && formRef.current) {
      const { values } = formRef.current.getState();
      return child.sexe !== values.sexe ||
        child.nom !== values.nom ||
        child.prenom !== values.prenom ||
        !moment(child.dteNaiss, momentFormat.DATE)
          .isSame(moment(values.dteNaiss, momentFormat.DATE));
    }
    return false;
  };

  const updateFormModified = () => setIsFormModified(isDirty());

  return (
    <div key={child.numpers ? child.numpers : child.numDF}>
      {confirmModal && (
        <ConfirmModal
          isOpen={confirmModal}
          close={toggleConfirmModal}
          headerTitle={I18n.t('personalData.personalData.validationModal.title')}
          onSubmit={() => onSubmit(childValuesToSave)}
        >
          <div className="modal-body">
            {I18n.t('personalData.personalData.validationModal.body')}
          </div>
        </ConfirmModal>
      )}
      <Form
        initialValues={child}
        onSubmit={(values: TypeDataUnit) => {
          if (isDirty()) {
            setIsFormModified(true);
            setChildValuesToSave(values);
            toggleConfirmModal();
          }
          setHasSubmitted(true);
        }}
        render={({ handleSubmit, form }) => {
          formRef.current = form;
          return (
            <form
              onSubmit={handleSubmit}
              onChange={updateFormModified}
              className="child-form"
              id={`child_${index}`}
            >
              <div className="child-header">
                <h3>{I18n.t(`${translationBase}children.label`, { count: index + 1 })}</h3>
                {!disabledFields && (
                  <CustomButton
                    onClick={() => {
                      if (child.numpers === undefined && child.numDF === undefined) {
                        deleteNotSavedChild(index);
                      } else {
                        setChildToDelete(child);
                        toggleModal();
                      }
                    }}
                    className="form-button delete-button-container cursor-pointer"
                    buttonClassName="delete-button"
                    message={I18n.t('actions.DELETE')}
                    type="button"
                    icon="fa fa-trash"
                  />)
              }
              </div>
              <div className={`children-container${disabledFields ? ' margin-read-only' : ''}`}>
                <DropdownFieldForm
                  className="children-data"
                  name="sexe"
                  label="childrenFields.genre"
                  options={genreList}
                  mandatoryField
                  translationBase={translationBase}
                  disabledFields={disabledFields}
                />
                <Field
                  name="prenom"
                  validate={(childFirstName: string) =>
                    fieldRequired(childFirstName) ||
                    checkEmptyField(childFirstName) ||
                    checkFieldLength(childFirstName)
                  }
                  render={({ input, meta }) => (
                    <div className="children-data">
                      <label
                        htmlFor="prenom"
                        id="prenom"
                      >
                        <RequiredFieldLabel
                          label={getLabel(translationBase, 'childrenFields.firstName', labelType.label)}
                          isDisabled={disabledFields}
                        />
                        <div
                          className={`field field-value${
                              meta.touched && meta.error ? ' error' : ''
                          }`}
                        >
                          <input
                            {...input}
                            disabled={disabledFields}
                            className="character"
                          />
                        </div>
                        {meta.touched && meta.error && (
                          <div className="error-message color-red">{meta.error}</div>
                        )}
                      </label>
                    </div>
                  )}
                />
                <Field
                  name="nom"
                  validate={(childName: string) =>
                    fieldRequired(childName) ||
                    checkEmptyField(childName) ||
                    checkFieldLength(childName)
                  }
                  render={({ input, meta }) => (
                    <div className="children-data">
                      <label
                        htmlFor="nom"
                        id="nom"
                      >
                        <RequiredFieldLabel
                          label={getLabel(translationBase, 'childrenFields.usageName', labelType.label)}
                          isDisabled={disabledFields}
                        />
                        <div
                          className={`field field-value${
                              meta.touched && meta.error ? ' error' : ''
                          }`}
                        >
                          <input
                            {...input}
                            disabled={disabledFields}
                            className="character"
                          />
                        </div>
                        {meta.touched && meta.error && (
                        <div className="error-message color-red">{meta.error}</div>
                          )}
                      </label>
                    </div>
                  )}
                />
                <Field
                  name="dteNaiss"
                  validate={(childBirthDate: Moment | string) =>
                    fieldRequired(childBirthDate) ||
                    isValidDateFormat(childBirthDate) ||
                    checkBirthDate(moment(childBirthDate, momentFormat.DATE))
                  }
                  render={({ input, meta }) => (
                    <div className="children-data">
                      <label
                        htmlFor="dteNaiss"
                        id="dteNaiss"
                      >
                        <RequiredFieldLabel
                          label={getLabel(translationBase, 'childrenFields.birthDate', labelType.label)}
                          isDisabled={disabledFields}
                        />
                        <div
                          className={`date-picker-field${disabledFields ? ' unset-bg' : ''}${
                              meta.touched && meta.error ? ' error' : ''
                            }`}
                        >
                          <div className={`fa fa-calendar-alt calendar-icon${disabledFields ? ' disabled' : ''}`} />
                          <CoreozDatePickerMaterial
                            input={input}
                            meta={{
                              ...meta,
                              error: undefined,
                            }}
                            onlyDate
                            onChange={updateFormModified}
                            disabled={disabledFields}
                            dateFormat={momentFormat.DATE}
                            className={`datepicker${disabledFields ? ' disabled' : ''}`}
                            selected={input.value}
                            locale="fr-FR"
                          />
                        </div>
                        {meta.touched && meta.error && (
                          <div className="error-message color-red">{meta.error}</div>
                        )}
                      </label>
                    </div>
                  )}
                />
              </div>
              <div className="save-button-container">
                {!isFormModified && hasSubmitted &&
                  <div className="no-modification-error-text">
                    <p>{I18n.t('form.noModification')}</p>
                  </div>
                }
                {!disabledFields && (
                  <CustomButton
                    type="submit"
                    buttonClassName="save-button-placement"
                    form={`child_${index}`}
                    message={I18n.t(`${translationBase}button`)}
                  />
                )}
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

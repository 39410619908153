// @flow

import React, { memo } from "react";
import Svg from "../utils/Svg";
import { IMAGES } from "../../utils/images";

type Props = {|
  activated?: boolean,
  completed?: boolean,
  message: string,
|};

const TimelineSmallItem = ({ activated, completed, message }: Props) => (
  <div
    className={`timeline-item
      ${activated ? "active" : ""}
      ${completed ? "complete" : ""}`}
  >
    <Svg image={IMAGES.ENROLEMENT.image} viewbox={IMAGES.ENROLEMENT.viewbox} />
    <span>{message}</span>
  </div>
);

TimelineSmallItem.defaultProps = {
  activated: false,
  completed: false,
};

export default memo<Props>(TimelineSmallItem);

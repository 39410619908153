// @flow

import { I18n } from 'react-redux-i18n';
import type { ConjointCollaborateur, SelectedNotaire } from '../types/types';

import { formFieldType, labelType } from './const';
import type { TypeFormField, TypeFormSelectOption } from '../types/formTypes';
import { VALIDATION } from './form';

export const parseUrl = (url: string) => {
  const searchObject = {};
  const data = url
    .split('/')[url.split('/').length - 1].replace('?', '&')
    .split('&');
  data.forEach((d) => {
    const split = d.split('=');
    searchObject[split[0]] = { ...split[1] };
  });
  return searchObject;
};

export const completeValueWithMask = (
  mask: string,
  length: number,
  space: number = 0,
  value: string = '',
) => {
  const text = [];
  const array = value.split('');
  for (let i = 0; i < length; i += 1) {
    if (array.length > i) {
      text.push(array[i]);
    } else {
      text.push(mask);
    }
  }
  return text.join(' '.repeat(space));
};

export const getValueFromMask = (mask: string, value: string) =>
  value && value.replace(/ /g, '').replace(new RegExp(mask, 'g'), '');

export const atLeastOneIsFilled = (
  field: TypeFormField,
  values: Object,
) => {
  let filled = false;
  Object.keys(values).forEach((v) => {
    if (v.startsWith(field.name.split('.')[0]) && values[v] && values[v].length > 0) {
      filled = true;
    }
  });
  return filled;
};

export const required = (
  field: TypeFormField,
  values: TypeFormField[],
) => {
  if (!field.requiredIfAllFilled) {
    if (field.required) {
      return true;
    }
  } else {
    if (atLeastOneIsFilled(field, values)) {
      return field.required;
    }
    return false;
  }
  return false;
};

export const checkRequired = (
  field: TypeFormField,
  values: TypeFormField[],
  condition: string,
) =>
  required(field, values) &&
  isConditionedBy(field, condition) &&
  isFieldConditionRespected(values, field.hiddenBy) &&
  isFieldConditionRespected(values, field.disabledBy);

export const isConditionedBy = (
  field: TypeFormField,
  condition: string = '',
) => !field.conditionedBy || condition === field.conditionedBy;

export const isFieldConditionRespected = (
  values: TypeFormField[],
  condition: ?TypeFormSelectOption,
) =>
  !condition ||
  (values[condition.label] !== condition.value && !condition.reverse) ||
  (values[condition.label] === condition.value && condition.reverse);

export const getLabel = (
  translationBase: string,
  name: string,
  type: string = labelType.label,
  count: number = 1,
  secondaryLabel: boolean = false,
) => I18n.t(`${translationBase}${name}.${type}${secondaryLabel ? '_secondary' : ''}`, { count });

export const validate = (
  fields: TypeFormField[],
  values: Object,
  login: string = '',
) => {
  const errors = {};
  Object.keys(fields).forEach((key: string) => {
    const field = fields[key];
    const { name } = field;
    if (name) {
      const names = name.split('.');
      if (field.type === formFieldType.ARRAY) {
        if (values[name]) {
          errors[name] = new Array(values[name].length);
          const n = values[name].length;
          errors[name] = getArrayFromNumber(n).map(i =>
            validate(field.fields, values[name][i - 1], login));
        }
      } else if (
        checkRequired(field, values, login) &&
        (!getValue(values, names.concat()) ||
          (field.numeric &&
            getValue(values, names.concat()) ===
            completeValueWithMask('_', field.maxLength, field.space)))
      ) {
        setError(errors, names.concat(), I18n.t('validation.required'));
      } else if (
        field.validation &&
        field.validation(getValue(values, names.concat())) &&
        checkRequired(field, values, login)
      ) {
        setError(errors, names.concat(), field.validation(getValue(values, names.concat())));
      }
      // cas particulier pour le code postal si pays = FR
      if (name === 'adresse.postalCode' && values && values.adresse && values.adresse.country === 'FR') {
        const regex = new RegExp(VALIDATION.ZIP_CODE);
        if (!regex.test(getValueFromMask('_', values.adresse.postalCode))) {
          setError(errors, names.concat(), I18n.t('validation.validPostalCode'));
        }
      }
    }
  });
  return errors;
};

const setError = (errors: Object, names: string[], error: string) => {
  if (names.length === 1) {
    return errors[names[0]] = error;
  }
  const name = names.shift();
  if (!errors[name]) {
    errors[name] = {};
  }
  return setError(errors[name], names, error);
};

const getValue = (values: Object, names: string[]) => {
  if (names.length === 1) {
    return values && values[names[0]];
  }
  const name = names.shift();
  return getValue(values[name], names);
};

export const getArrayFromNumber = (n: number) =>
  Array.from(Array(n).keys()).map(number => number + 1);

export const download = (blobOrUrl, filename, cb) => {
  if (typeof blobOrUrl === 'string') {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = blobOrUrl;
    a.download = filename;
    a.click();
  } else if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blobOrUrl, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(blobOrUrl);
    a.download = filename;
    a.click();
  }
  if (cb) {
    cb();
  }
};
export const getAgeFromBirthdate = (birthdateStr: string) => {
  if (!birthdateStr) {
    return null;
  }
  const birthdate = new Date(birthdateStr.replace(/(\d{2})[-/](\d{2})[-/](\d+)/, '$2/$1/$3')); // format date
  const cur = new Date();
  const diff = cur - birthdate; // This is the difference in milliseconds
  return Math.floor(diff / 31557600000); // Divide by 1000*60*60*24*365.25
};

export const flattenObj = (object) => {
  const toReturn = {};

  for (const prop in object) {
    if (!object.hasOwnProperty(prop)) continue;
    if ((typeof object[prop]) === 'object' && object[prop] !== null) {
      const flatObject = flattenObj(object[prop]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[`${prop}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[prop] = object[prop];
    }
  }
  return toReturn;
};

export const displayCotisationValue = (value: string,
  isCurrency: boolean = false, isPercentage: boolean = false) => {
  if (value === '' || value === null) {
    return '-';
  }
  if (isCurrency) {
    const newValue = parseFloat(value.replace(',', '.')).toFixed(2);
    const parts = newValue.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return `${parts.join(',')} €`;
  }
  if (isPercentage) {
    const formattedValue = new Intl.NumberFormat('fr', { style: 'percent', minimumFractionDigits: 2 })
      // converts value to float
      .format(+value.replace(',', '.'));
    // Split the integer and decimal part of the formatted value
    const splittedPercentage = formattedValue.replace('%', '').trim().split(',');
    if (splittedPercentage.length > 1) {
      const decimalPart = splittedPercentage[1];
      // if it's a round number only return the integer part
      if (decimalPart === '00') {
        return `${splittedPercentage[0]} %`;
      }
    }
    return formattedValue;
  }
  return value;
};

export const openExternalService = (externalLink: string) => {
  const winUrl = URL.createObjectURL(new Blob([externalLink], { type: 'text/html' }));
  window.open(winUrl, 'win2');
};

export const updateSelectedPersona = (
  selectedPersonas: SelectedNotaire[],
  setSelectedPersonas: (SelectedNotaire[]) => void,
  selectedPersona: SelectedNotaire | ConjointCollaborateur,
  isConjoint?: boolean,
) => {
  const delegantsArray = selectedPersonas.map((persona: SelectedNotaire) => {
    // Conjoint collaborateur selected
    if (isConjoint) {
      if (persona.conjointCollaborateur) {
        return {
          ...persona,
          filterActive: false,
          conjointCollaborateur: {
            ...persona.conjointCollaborateur,
            filterActive: persona.conjointCollaborateur
              && persona.conjointCollaborateur
                .numpers === selectedPersona.numpers,
          },
        };
      }
      return { ...persona, filterActive: false };
    }
    // Regular notaire selected
    return {
      ...persona,
      filterActive: persona.numpers === selectedPersona.numpers,
      conjointCollaborateur: persona.conjointCollaborateur
        ? { ...persona.conjointCollaborateur, filterActive: false }
        : undefined,
    };
  });
  setSelectedPersonas(delegantsArray);
};

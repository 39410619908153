// @flow

import React, { memo, useContext, useEffect, useState } from 'react';
import LoadingContext from '../../context/loader/LoadingContext';
import type { TypeEmptyProps } from '../../types/types';

const Loader = () => {
  const { loading, message, timeOutDuration } = useContext(LoadingContext);
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setDisplayLoader(loading);
      }, timeOutDuration);
      return () => {
        clearTimeout(timer);
        setDisplayLoader(false);
      };
    }
  }, [loading, timeOutDuration]);

  if (!displayLoader) {
    return <></>;
  }

  return (
    <div
      className="loader"
      style={displayLoader ? { display: 'block' } : { display: 'none' }}
    >
      {message && <div className="loader-message">{message}</div>}
    </div>
  );
};

export default memo<TypeEmptyProps>(Loader);

// @flow

import moment from "moment";
import React, {memo, useCallback, useContext, useEffect, useState} from "react";
import { I18n } from "react-redux-i18n";
import { SERVICE_HOME_ROUTE } from "../../utils/routes";
import Bloc from "../../components/accueil/Bloc";
import DateButton from "../../components/buttons/DateButton";
import GenericForm from "../../components/utils/GenericForm";
import { download, validate } from "../../utils/utils";
import { AttestationPaymentDateFields } from "../../utils/form";
import { formTranslationType, momentFormat } from "../../utils/const";
import CustomButton from "../../components/buttons/CustomButton";
import Popup from "../../components/services/Popup";
import PaymentContainerCircle from "../../components/services/PaymentContainerCircle";
import InternalPage from "../../components/utils/pages/InternalPage";
import { extractErrorFromSigma } from "../../utils/modal";
import paymentApi from "../../network/api/paymentApi";
import LoadingContext from "../../context/loader/LoadingContext";
import {connect} from "react-redux";
import ModalContext from "../../context/modal/ModalContext";

type Props = {|
  history: Object,
|};
type PaiementResource = {|
  accrochage: number,
  codePeriodicite: string,
  dateDebutEcheance: string,
  dateFinEcheance: string,
  datePaiement: string,
  declinaisonRegime1: string,
  declinaisonRegime2: string,
  idPaiement: number,
  libelleCourt: string,
  libelleLong: string,
  links: Object,
  montantNet: number,
  nature: string,
|};

const PackPayment = ({ history }: Props) => {
  moment.locale("fr");
  const [selectedMonth, setSelectedMonth] = useState(moment().format("MMM"));
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [clickedData, setClickedData] = useState(null);
  const [paymentData, setPaymentData] = useState([]);
  const {showLoading, closeLoading} = useContext(LoadingContext);
  const { openErrorModal } = useContext(ModalContext);

  useEffect(() => {
    paymentApi
      .getPayments()
      .then(res => res.json())
      .then((value: PaiementResource[]) => {
        setPaymentData(value);
      })
      .catch(extractErrorFromSigma(openErrorModal));
  }, []);

  const getMessage = (name: string) => I18n.t(`service.packPayment.${name}`);

  const onMonthClick = (month: string) => setSelectedMonth(month);
  const onYearClick = (year: string) => setSelectedYear(year);

  const downloadAttestationPayment =  useCallback((values: Object) => {
    showLoading();
    paymentApi
        .getAttestationPaymentBis(values.dateDebut.toISOString().split("T")[0], values.dateFin.toISOString().split("T")[0])
        .then(res => res.blob())
        .then((attestation: Blob) =>
          download(
              attestation,
              "AttestationDePaiement_" + moment(values.date).format(momentFormat.FILENAME)
              ,
              () => {
              }
          ))
        .catch( (e) => {
          closeLoading();
          extractErrorFromSigma(openErrorModal)(e);
        })
        .finally(closeLoading);
}, []);

  const onDataOpenClick = (data: Object) => {
    setIsPopupOpen(true);
    setClickedData(data);
  };

  const onDataCloseClick = () => {
    setIsPopupOpen(false);
    setClickedData(null);
  };

  //DONN2e tampon
  const getDate = (addedNumber: number) =>
      moment(`${selectedMonth} ${selectedYear}`, "MMM YYYY")
          .add(addedNumber, "month")
          .format("MMMM YYYY");

  const getPaymentDate = (selectedData: Object) => {
    var res = I18n.t("service.packPayment.noData");
    if(selectedData && selectedData.montantNet){
      res = `${selectedData.montantNet} € ${selectedData.libelleCourt}`;
    }if(selectedData && !selectedData.montantNet){
      res = `${moment(selectedData.datePaiement).format("DD/MM")} - ${selectedData.libelleCourt}`;
    }
    return res;
  };

  const getDataByDate = (targetDate : Object) => {
    return paymentData.find(value => {
      return moment(value.datePaiement).month() === targetDate.month()
          && moment(value.datePaiement).year() === targetDate.year()
    });
  };

  const paymentDataList = {
    previousData: getDataByDate(moment(`${selectedMonth} ${selectedYear}`, "MMM YYYY").add(-1, "month")),
    currentData: getDataByDate(moment(`${selectedMonth} ${selectedYear}`, "MMM YYYY")),
    nextData: getDataByDate(moment(`${selectedMonth} ${selectedYear}`, "MMM YYYY").add(1, "month")),
    previousMonth: getDate(-1),
    currentDate: getDate(0),
    nextMonth: getDate(1),
  };

  const paymentInfo = [
    {
      current: false,
      date: paymentDataList.previousMonth,
      paymentInfo: getPaymentDate(paymentDataList.previousData),
      ... paymentDataList.previousData
    },
    {
      current: true,
      date: paymentDataList.currentDate,
      paymentInfo: getPaymentDate(paymentDataList.currentData),
      ... paymentDataList.currentData
    },
    {
      current: false,
      date: paymentDataList.nextMonth,
      paymentInfo: getPaymentDate(paymentDataList.nextData),
      ... paymentDataList.nextData},
  ];

  const yearsList = [
    Number(selectedYear) - 1,
    selectedYear,
    Number(selectedYear) + 1,
  ];

  return (
    <InternalPage
      menu={I18n.t("service.title")}
      color="yellow"
      hasBackButton
      handleClickBack={() => history.push(SERVICE_HOME_ROUTE)}
    >
      <div
        id="pack-payment"
        className="bloc-container mt-1 pb-5 yellow f-dir-col"
      >
        <Bloc title={getMessage("payment")} className="mb-5" color="yellow">
          <p className="text-justify">{getMessage("description")}</p>
          <div className="row-bloc mt-2 mb-2 circle-container">
            <div className="ligne" />
            {paymentInfo.map(container => (
              <PaymentContainerCircle
                current={container.current}
                date={container.date}
                paymentInfo={container.paymentInfo}
                onClick={(container.paymentInfo !== I18n.t("service.packPayment.noData")) ? () => onDataOpenClick(container) : () => {}}
              />
            ))}
          </div>
          <div className="month-bloc">
            {moment.monthsShort().map(month => (
              <DateButton
                className="month-button"
                message={month}
                selected={selectedMonth === month}
                onClick={() => onMonthClick(month)}
              />
            ))}
          </div>
          <div className="year-bloc">
            {yearsList.map(year => (
              <DateButton
                className="year-button"
                message={year}
                selected={selectedYear === year}
                onClick={() => onYearClick(year)}
              />
            ))}
          </div>
        </Bloc>
        <Bloc title={getMessage("subtitle")} color="yellow">
          <p className="text-justify">{getMessage("text")}</p>
          <GenericForm
            className="m-auto mt-2 text-left"
            onSubmit={downloadAttestationPayment}
            validate={(values: Object) => validate(AttestationPaymentDateFields, values)}
            translationBase={formTranslationType.packPayment}
            fields={AttestationPaymentDateFields}
            grid={[2]}
          >
            <CustomButton type="submit" color="yellow" message={getMessage("generate")} />
          </GenericForm>
        </Bloc>
      </div>
      {isPopupOpen === true ? (
        <Popup
          selectedData={clickedData}
          paymentList={[]} // todo
          onClick={() => onDataCloseClick()}
        />
      ) : (
        <></>
      )}
    </InternalPage>
  );
};

export default connect()(memo<Props>(PackPayment));

// @flow

import React, { memo } from "react";
import Timeline from "./Timeline";

const VerticalTimeline = () => (
  <div className="vertical-timeline">
    <Timeline active={1} completed={index => index === 0} />
  </div>
);

export default memo<void>(VerticalTimeline);

// @flow

import { Link } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { ConfirmModal } from '../../../components/modaleNotification/ConfirmModal';
import { TypeDataUnit } from '../../../types/types';
import useToggle from '../../../utils/useToggle';
import { ChildDataForm } from './ChildDataForm';

type ChildrenFormProps = {
  onSubmit: (TypeDataUnit) => void,
  deleteChild: (TypeDataUnit) => void,
  translationBase: string,
  initialValue: TypeDataUnit[],
  disabledFields?: boolean,
  showAddButton: boolean,
  setShowAddButton: (boolean) => void,
};

export default function ChildrenForm({
  onSubmit,
  deleteChild,
  translationBase,
  initialValue,
  disabledFields,
  showAddButton,
  setShowAddButton,
}: ChildrenFormProps) {
  const [childrenList, setChildrenList] = useState<TypeDataUnit[]>([]);
  const [confirmModal, toggleConfirmModal] = useToggle();
  const [childToDelete, setChildToDelete] = useState<TypeDataUnit>();

  const addChild = () => {
    const tempList = [...childrenList];
    const newChild: TypeDataUnit = {
      sexe: '',
      lien: 'EFT',
      nom: null,
      prenom: null,
      dteNaiss: null,
    };
    tempList.push(newChild);
    setChildrenList(tempList);
    setShowAddButton(false);
  };

  const deleteEmptyChild = (index: number) => {
    const newChildrenList = [...childrenList];
    newChildrenList.splice(index, 1);
    setChildrenList(newChildrenList);
    setShowAddButton(true);
  };

  useEffect(() => {
    if (initialValue) {
      setChildrenList(initialValue);
    }
  }, [initialValue]);

  return (
    <>
      <div className="asterisk split-border">
        <div className="children-count">
          <h3>{I18n.t(`${translationBase}childrenSubtitle.label_0`, { count: childrenList.length })}</h3>
        </div>
        <h4>{I18n.t(`${translationBase}childrenDescription.label${disabledFields ? '_secondary' : ''}`)}</h4>
      </div>
      {confirmModal &&
        <ConfirmModal
          isOpen={confirmModal}
          close={toggleConfirmModal}
          onSubmit={() => deleteChild(childToDelete)}
          headerTitle={I18n.t('personalData.personalData.confirmDeleteModal.title')}
        >
          <div className="modal-body">
            {I18n.t('personalData.personalData.confirmDeleteModal.body')}
          </div>
        </ConfirmModal>
      }
      {childrenList && (
        childrenList.map((child: TypeDataUnit, index: number) => (
          <ChildDataForm
            child={child}
            onSubmit={onSubmit}
            deleteNotSavedChild={deleteEmptyChild}
            toggleAddButton={setShowAddButton}
            setChildToDelete={setChildToDelete}
            translationBase={translationBase}
            index={index}
            disabledFields={disabledFields}
            toggleModal={toggleConfirmModal}
          />
        )))
      }
      {showAddButton && !disabledFields && (
        <button className="add-child-button" onClick={addChild}>
          <div className="fa fa-plus" />
          <Link className="add-child-link">
            {I18n.t(`${translationBase}children.add`)}
          </Link>
        </button>
      )}
    </>
  );
}

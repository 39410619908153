// @flow

import { FormApi } from 'final-form';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import GenericLoader from '../../components/utils/GenericLoader';
import LoadingContext from '../../context/loader/LoadingContext';
import { formTranslationType, momentFormat, NAVIGATION_RIGHTS } from '../../utils/const';
import { linkType, motiveUnionEndOptions } from '../../utils/enum';
import {
  PERSONAL_DATA_HOME_ROUTE,
  PERSONAL_DATA_ROUTE,
  PROFESSIONAL_DATA_ROUTE,
  RETREAT_DATA_ROUTE,
} from '../../utils/routes';
import { familyDataFields } from '../../utils/form';
import Back from '../../components/buttons/Back';
import Bloc from '../../components/accueil/Bloc';
import { extractErrorFromSigma } from '../../utils/modal';
import type { EndUnionInfo, TypeDataUnit, TypeFamilyData } from '../../types/types';
import userApi from '../../network/api/userApi';
import ModalContext from '../../context/modal/ModalContext';
import SubPageTitle from '../../components/utils/SubPageTitle';
import NavigateDataButton from '../../components/buttons/NavigateDataButton';
import { fetchIfUserHasFamilyData } from '../../state/user/userService';
import { userStatus } from '../../utils/userStatus';
import { notifySuccess } from '../../network/notification';
import ChildrenForm from '../enrolement/forms/ChildrenForm';
import { ConjointForm } from '../enrolement/forms/ConjointForm';

type Props = {|
  history: History,
  status: string,
  dispatch: Dispatch,
|};

const FamilyData = ({
  history, status, dispatch,
}: Props) => {
  const [isDmdEnCours, setIsDmdEnCours] = useState<boolean | undefined>();
  const [conjointData, setConjointData] = useState<TypeDataUnit>();
  const [finishedUnions, setFinishedUnions] = useState<TypeDataUnit[]>([]);
  const [childrenData, setChildrenData] = useState<TypeDataUnit[]>([]);
  const [endUnionInitialValue, setEndUnionInitialValue] = useState<EndUnionInfo>();
  const { openErrorModal } = useContext(ModalContext);
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const [isFamilyDataLoaded, setIsFamilyDataLoaded] = useState<boolean>(false);
  const [showAddButton, setShowAddButton] = useState<boolean>(true);
  const [isConjointFormModified, setIsConjointFormModified] = useState<boolean>(false);
  const [hasSubmittedConjoint, setHasSubmittedConjoint] = useState<boolean>(false);
  const [formRef, setFormRef] = useState<{current: FormApi | undefined}>();

  const handleDataInitialization = useCallback((responseData: TypeFamilyData) => {
    setIsDmdEnCours(responseData.isDmdEnCours);
    setChildrenData(responseData.children ? responseData.children : []);
    setEndUnionInitialValue(buildInitialEndUnionData);
    if (responseData.conjointEnCours) {
      setConjointData(responseData.conjointEnCours);
    }
    if (responseData.conjointsPasses) {
      setFinishedUnions(responseData.conjointsPasses);
    }
    setEndUnionInitialValue(buildInitialEndUnionData);
  }, []);

  const resetFieldStates = (refForm: {current: FormApi | undefined}) => {
    refForm.current.resetFieldState('nom');
    refForm.current.resetFieldState('prenom');
    refForm.current.resetFieldState('dteNaiss');
    refForm.current.resetFieldState('nomJF');
    refForm.current.resetFieldState('sexe');
    refForm.current.resetFieldState('typeUnion');
    refForm.current.resetFieldState('dteUnion');
  };

  const initFamilyData = useCallback(() => {
    showLoading();
    userApi
      .getFamilyData()
      .then((response: Response) => response.json())
      .then(handleDataInitialization)
      .catch(extractErrorFromSigma(openErrorModal))
      .finally(() => {
        setIsFamilyDataLoaded(true);
        closeLoading();
      });
  }, [handleDataInitialization, openErrorModal]);

  useEffect(() => {
    initFamilyData();
  }, [openErrorModal, handleDataInitialization,
    initFamilyData, formRef, showLoading]);

  useEffect(() => {
    dispatch(fetchIfUserHasFamilyData());
  }, [dispatch]);

  const buildInitialEndUnionData = () => ({
    motiveUnionEnd: motiveUnionEndOptions.SEPARATION,
    dteFinUnion: '',
    dteDeces: '',
  });

  const onSubmitConjoint = (conjointValues: TypeDataUnit) => {
    // La valeur "" renvoie une erreur 400, la valeur null n'est pas prises en compte par la cprn.
    if (conjointValues && !conjointValues.nom) {
      conjointValues.nom = ' ';
    }
    conjointValues.lien = linkType.CONJOINT;
    if (typeof conjointValues.dteUnion !== 'string') {
      conjointValues.dteUnion = conjointValues.dteUnion.format(momentFormat.DATE);
    }
    if (typeof conjointValues.dteNaiss !== 'string') {
      conjointValues.dteNaiss = conjointValues.dteNaiss.format(momentFormat.DATE);
    }

    // reset display of the "no modification" error message for conjoint form
    setHasSubmittedConjoint(false);
    setIsConjointFormModified(false);

    userApi
      .updateConjointData(conjointValues)
      .then((response: Response) => response.json())
      .then(setConjointData)
      .then(dispatch(notifySuccess))
      .catch(extractErrorFromSigma(openErrorModal));
  };

  const onSubmitPastUnionData = (pastConjointValues: TypeDataUnit) => {
    if (pastConjointValues && !pastConjointValues.nom) {
      pastConjointValues.nom = ' ';
    }
    pastConjointValues.lien = linkType.CONJOINT;
    if (typeof pastConjointValues.dteUnion !== 'string') {
      pastConjointValues.dteUnion = pastConjointValues.dteUnion.format(momentFormat.DATE);
    }
    if (typeof pastConjointValues.dteNaiss !== 'string') {
      pastConjointValues.dteNaiss = pastConjointValues.dteNaiss.format(momentFormat.DATE);
    }

    userApi
      .updatePastUnionData(pastConjointValues)
      .then((response: Response) => response.json())
      .then(setFinishedUnions)
      .then(dispatch(notifySuccess))
      .catch(extractErrorFromSigma(openErrorModal));
  };

  const onSubmitEndUnion = (endUnionData: EndUnionInfo) => {
    const familyDataToUpdate: TypeDataUnit = conjointData;
    familyDataToUpdate.dteFinUnion =
      endUnionData.dteFinUnion ? endUnionData.dteFinUnion.format(momentFormat.DATE) : null;
    familyDataToUpdate.dteDeces =
      endUnionData.dteDeces ? endUnionData.dteDeces.format(momentFormat.DATE) : null;

    // reset display of the "no modification" error message for conjoint form
    setHasSubmittedConjoint(false);
    setIsConjointFormModified(false);

    userApi
      .endUnion(familyDataToUpdate)
      .then((response: Response) => response.json())
      .then((donneesFamiliales: TypeFamilyData) => {
        // Reset conjointEnCoursState and update conjointPasses
        // Empty each field of the conjoint form to hide any error
        // displayed below them until touched
        setConjointData(donneesFamiliales.conjointEnCours);
        resetFieldStates(formRef);
        setFinishedUnions(donneesFamiliales.conjointsPasses);
      })
      .then(dispatch(notifySuccess))
      .catch(extractErrorFromSigma(openErrorModal));
  };

  const onSubmitChild = (valuesChild: TypeDataUnit) => {
    if (typeof valuesChild.dteNaiss !== 'string') {
      valuesChild.dteNaiss = valuesChild.dteNaiss.format(momentFormat.DATE);
    }

    userApi
      .updateChildData(valuesChild)
      .then((response: Response) => response.json())
      .then(setChildrenData)
      .then(dispatch(notifySuccess))
      .then(setShowAddButton(true))
      .catch(extractErrorFromSigma(openErrorModal));
  };

  const deleteChild = (childToBeDeleted: TypeDataUnit) => {
    userApi
      .deleteChild(childToBeDeleted.numDF, childToBeDeleted.numpers)
      .then((response: Response) => response.json())
      .then(setChildrenData)
      .then(dispatch(notifySuccess))
      .catch(extractErrorFromSigma(openErrorModal));
  };

  const onClick = () => history.push(PERSONAL_DATA_HOME_ROUTE);
  const onClickLeft = () => history.push(PERSONAL_DATA_ROUTE);
  const onClickRight = () => (NAVIGATION_RIGHTS.RETREAT.includes(status) ?
    history.push(RETREAT_DATA_ROUTE) :
    history.push(PROFESSIONAL_DATA_ROUTE));

  return (
    <div className="page-container">
      <SubPageTitle
        className="m-auto"
        message={I18n.t('personalData.pageTitle.family')}
        color="cobalt"
      />
      <div id="family-data">
        <Back
          className="mb-2"
          onClick={onClick}
          message={I18n.t('personalData.personalData.back')}
        />
        <div className="bloc-container">
          <NavigateDataButton
            messageLeft={I18n.t('personalData.personalData.title')}
            onClickLeft={onClickLeft}
            className=""
            messageRight={NAVIGATION_RIGHTS.RETREAT.includes(status) ?
              I18n.t('personalData.retreatData.title') :
              I18n.t('personalData.professionalData.title')}
            onClickRight={onClickRight}
          />
          <Bloc className="data" color="cobalt">
            <div className="seperate-between-family">
              <div className="family-logo" />
              <div className="cobalt family-consult">
                {I18n.t('personalData.familyData.consult')}
              </div>
            </div>
            { !isFamilyDataLoaded
              ? <GenericLoader label={I18n.t('tooltip.loadingData')} />
              : (
                <>
                  {
                  isDmdEnCours && (
                    <p className="updatingText"><i className="far fa-info-circle" />{I18n.t('personalData.personalData.updatePending')}</p>
                  )
                }
                  <div className="family-data-bloc">
                    <p className="text-center cobalt fs-16">
                      {I18n.t(`personalData.familyData.description${status === userStatus.RETRAITE || status === userStatus.BENEFICIAIRE ? '_retreat' : ''}`)}
                    </p>
                  </div>
                  <ConjointForm
                    className="m-auto"
                    onSubmit={onSubmitConjoint}
                    onSubmitEndUnion={onSubmitEndUnion}
                    onSubmitPastUnion={onSubmitPastUnionData}
                    initialValue={conjointData}
                    endUnionInitialValue={endUnionInitialValue}
                    translationBase={formTranslationType.familyData}
                    disabled={!NAVIGATION_RIGHTS.FAMILY.includes(status)}
                    disabledFields={!NAVIGATION_RIGHTS.FAMILY.includes(status)
                      || status === userStatus.RETRAITE
                    }
                    fields={familyDataFields}
                    finishedUnions={finishedUnions}
                    setFormRef={setFormRef}
                    hasSubmitted={hasSubmittedConjoint}
                    setHasSubmitted={setHasSubmittedConjoint}
                    isFormModified={isConjointFormModified}
                    setIsFormModified={setIsConjointFormModified}
                  />
                  <ChildrenForm
                    initialValue={childrenData}
                    onSubmit={onSubmitChild}
                    deleteChild={deleteChild}
                    disabledFields={!NAVIGATION_RIGHTS.FAMILY.includes(status)
                      || status === userStatus.RETRAITE}
                    translationBase={formTranslationType.familyData}
                    showAddButton={showAddButton}
                    setShowAddButton={setShowAddButton}
                  />
                </>
              )}
          </Bloc>
        </div>
      </div>
    </div>
  );
};

export default connect(state => ({
  status: state.user.user.statut,
}))(FamilyData);

// @flow

import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import { I18n } from "react-redux-i18n";
import queryString from "query-string";

import {
  DASHBOARD_ROUTE,
  ENROLEMENT_LANDING_PAGE_ROUTE,
  ENROLEMENT_STEP_1_ROUTE,
  ENROLEMENT_STEP_2_ROUTE,
  ENROLEMENT_STEP_3_ROUTE,
  HOME_PAGE_ROUTE,
} from "../../utils/routes";
import EnrolementLandingPage from "./Landing";
import Step1 from "./Step1";
import Step2 from "./Step2";
import enrolementApi from "../../network/api/enrolementApi";
import ModalContext from "../../context/modal/ModalContext";
import { extractErrorFromSigma } from "../../utils/modal";
import { isUserConnected } from "../../state/login/loginService";
import loggerApi from "../../network/api/loggerApi";
import type { EnumLogin, TypeEmail } from "../../types/types";
import { storeUser } from "../../state/user/userService";
import IdentityBanner from "../../components/enrolement/IdentityBanner";
import FormBlocEnrolement from "./FormBlocEnrolement";
import Step3 from "./Step3";
import { statutType } from '../../utils/const';

type Props = {|
  history: Object,
  location: Object,
  isConnected: boolean,
  dispatch: Function,
|};

type QueryParams = {|
  token: string,
  connectType: EnumLogin,
  step: string,
  status: string,
|};

export type Identity = {|
  idn: string,
  idFC: string,
  lastName: string,
  firstName: string,
  emails: TypeEmail[],
|};

const getQueryParams = (search: string): QueryParams => {
  return queryString.parse(search);
};

const canEnroll = (
  token: ?string,
  connectType: ?EnumLogin,
  step: ?string,
  pathname: string,
  status: ?string,
) => {
  return !(
    token === undefined ||
    connectType === undefined ||
    step === undefined ||
    status === undefined ||
    (Number.parseInt(step) > 0 && !pathname.includes(step))
  );
};

function EnrolementRoutes({ history, location, isConnected, dispatch }: Props) {
  const { token, connectType, step, status } = getQueryParams(location.search);

  const [identity, updateIdentity] = useState<Identity>({
    idn: "",
    idFC: "",
    lastName: "",
    firstName: "",
    emails: [],
  });

  const { openErrorModal } = useContext(ModalContext);
  const [emails, updateMails] = useState<TypeEmail[]>([]);

  useEffect(() => {
    if (
      !isConnected &&
      canEnroll(token, connectType, step, location.pathname, status)
    ) {
      enrolementApi
        .checkToken(token, connectType, step)
        .then(res => res.json())
        .then((user: Identity) => {
          updateIdentity(user);
          dispatch(
            storeUser({
              idn: user.idn,
              idFC: user.idFC,
              firstName: user.firstName,
              lastName: user.lastName,
              numpers: "",
              statut: status,
              cguValidated: false,
            }),
          );

          if (Number.parseInt(step) === 2) {
            updateMails(user.emails);
          }
        })
        .catch(error => {
          extractErrorFromSigma(openErrorModal)(error);
          loggerApi.addAttemptEnrolment(location.pathname);
          history.replace(HOME_PAGE_ROUTE);
        });
    }
  }, [location.search, location.pathname, token, connectType, step, status]);

  useEffect(() => {
    if (
      !isConnected &&
      canEnroll(token, connectType, step, location.pathname, status)
    ) {
      loggerApi.addView(location.pathname, token);
    }
  }, [isConnected, location.pathname, token, connectType, step, status]);

  const handleChangePage = useCallback(
    (page: string) => {
      // Si c'est un tier de confiance, on saute la première étape
      if (step === '0' && status === statutType.TIER) {
        // prettier-ignore
        history.push(`${page}?connectType=${connectType}&step=2&status=${status}&token=${token}`);
      } else {
        // prettier-ignore
        history.push(`${page}?connectType=${connectType}&step=${Number.parseInt(step) + 1}&status=${status}&token=${token}`);
      }
    },
    [history, step, token, connectType],
  );

  if (isConnected) {
    return <Redirect to={DASHBOARD_ROUTE} />;
  }

  if (!canEnroll(token, connectType, step, location.pathname, status)) {
    loggerApi.addAttemptEnrolment(location.pathname);
    return <Redirect to={HOME_PAGE_ROUTE} />;
  }

  return (
    <Container fixed>
      <IdentityBanner
        subTitle={Number.parseInt(step) === 0 ? I18n.t("banner.subtitle") : ""}
        identity={identity}
        big={Number.parseInt(step) === 0}
      />
      <Switch>
        <Route
          exact
          path={ENROLEMENT_LANDING_PAGE_ROUTE}
          render={() => (
            <EnrolementLandingPage
              identity={identity}
              handleChangePage={handleChangePage}
              statut={status}
            />
          )}
        />
        <FormBlocEnrolement>
          <>
            <Route
              exact
              path={ENROLEMENT_STEP_1_ROUTE}
              render={() => (
                <Step1
                  identity={identity}
                  handleChangePage={handleChangePage}
                  connectType={connectType}
                  token={token}
                  updateMails={updateMails}
                />
              )}
            />
            <Route
              exact
              path={ENROLEMENT_STEP_2_ROUTE}
              render={() => (
                <Step2
                  identity={identity}
                  handleChangePage={handleChangePage}
                  connectType={connectType}
                  token={token}
                  emails={emails}
                  statut={status}
                />
              )}
            />
            <Route
              exact
              path={ENROLEMENT_STEP_3_ROUTE}
              render={() => (
                <Step3
                  identity={identity}
                  connectType={connectType}
                  token={token}
                  statut={status}
                />
              )}
            />
          </>
        </FormBlocEnrolement>
        <Route path="*" render={() => <Redirect to={HOME_PAGE_ROUTE} />} />
      </Switch>
    </Container>
  );
}

export default memo<Props>(
  connect(state => ({
    isConnected: isUserConnected(state.login),
  }))(EnrolementRoutes),
);

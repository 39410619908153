// @flow

import ReactGA from "react-ga4";

const hasLoadedGA = (): boolean => ReactGA.isInitialized;

const sendDataGA = (data: object) => {
  if (hasLoadedGA()) {
    ReactGA.send(data);
  }
};

export const visitPage = (path?: string) => {
  if (path) {
    sendDataGA({ hitType: "pageview", page: path });
  }
};

export const initializeGA = (analyticsKey: string) => {
  ReactGA.initialize([
    {
      trackingId: analyticsKey,
    },
  ]);
};

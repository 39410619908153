// @flow

import React, { memo } from 'react';
import { Field } from 'react-final-form';

const CustomInputNew = (props: Props) => (
  <div className="field" id={props.id}>
    <div className={`label${props.important ? ' important' : ''}${
      props.disabled ? ' disabled' : ''}${(props.required || props.asterisk) ? ' asterisk' : ''}`}
    >
      {props.label}
    </div>
    <Field
      component="input"
      {...props}
    >
      {({ input, meta }) => (
        <div className="input">
          <input
            {...input}
            className={`custom-input${props.disabled ? ' disabled' : ''}`}
            disabled={props.disabled}
          />
          <div className="error-field">
            {meta.error && meta.touched && <div className="error-message">{meta.error}</div>}
          </div>
        </div>
      )}
    </Field>
  </div>
);

export default memo<void>(CustomInputNew);

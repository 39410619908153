// @flow

import React, { memo } from "react";

type Props = {| image: any, viewbox: string |};

const Svg = ({ image, viewbox }: Props) => (
  <figure>
    <svg version="1.1" viewBox={viewbox}>
      {image}
    </svg>
  </figure>
);

export default memo<Props>(Svg);

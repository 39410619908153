// @flow

import React from "react";

import { Field } from "react-final-form";
import { I18n } from "react-redux-i18n";

const ConfidentialityCheckbox = () => (
  <Field
    name="confidentiality"
    type="checkbox"
    render={({ input, meta }) => (
      <label
        htmlFor="confidentiality"
        className="checkbox-container text-justify fs-14"
      >
        {I18n.t("parametrerProfil.form.confidentiality.label")}
        <input
          type="checkbox"
          {...input}
          name="confidentiality"
          color="primary"
          className="checkbox"
        />
        <span className="checkmark" />
        {meta.touched && meta.error && (
          <div className="error-message color-red">{meta.error}</div>
        )}
      </label>
    )}
  />
);

export default ConfidentialityCheckbox;

// @flow

import React from "react";

import { Field } from "react-final-form";
import { requiredEmailValidator } from "../../../../utils/validator";

type Props = {
  values: any,
}

const EmailOther = ({ values }: Props) => {
  const emailFieldDisabled = values.email !== "other";

  return (
    <div className="mb-4 size-1">
      <Field
        name="otherEmail"
        disabled={emailFieldDisabled}
        validate={(email: string, allValues) => requiredEmailValidator(email, allValues.email !== "other")}
        render={({ input, meta }) => (
          <label htmlFor="otherEmail" id="otherEmail" className={emailFieldDisabled ? "disabled" : ""}>
            <div className={`field field-value ${meta.error ? "error" : ""}`}>
              <input
                {...input}
                disabled={emailFieldDisabled}
                className="w-100"
                name="otherEmail"
              />
            </div>
            {meta.error && (
              <div className="error-message color-red">{meta.error}</div>
            )}
          </label>
        )}
      />
    </div>
  );
};

export default EmailOther;

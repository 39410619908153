// @flow

import { FormApi, ValidationErrors } from 'final-form';
import React, { useContext, useEffect, useState, FormEvent, useRef } from 'react';
import { Form } from 'react-final-form';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import GenericLoader from '../../components/utils/GenericLoader';
import LoadingContext from '../../context/loader/LoadingContext';

import { formTranslationType, labelType, NAVIGATION_RIGHTS } from '../../utils/const';
import {
  COTISATION_DATA_ROUTE,
  FAMILY_DATA_ROUTE,
  PERSONAL_DATA_HOME_ROUTE,
  PROFESSIONAL_DATA_ROUTE,
  RETREAT_DATA_ROUTE,
} from '../../utils/routes';
import GenericForm from '../../components/utils/GenericForm';
import { getLabel, validate } from '../../utils/utils';
import { userPersonalInfoFields } from '../../utils/form';
import Back from '../../components/buttons/Back';
import Bloc from '../../components/accueil/Bloc';
import { extractErrorFromSigma } from '../../utils/modal';
import userApi from '../../network/api/userApi';
import ModalContext from '../../context/modal/ModalContext';
import SubPageTitle from '../../components/utils/SubPageTitle';
import NavigateDataButton from '../../components/buttons/NavigateDataButton';
import { storeUserPersonalData } from '../../state/user/userService';
import type { TypePersonalData, TypePersonalDataInputs } from '../../types/types';
import { countryList, france } from '../../utils/data';
import CustomButton from '../../components/buttons/CustomButton';
import CustomCheckbox from '../../components/utils/forms/CustomCheckbox';
import { notifySuccess } from '../../network/notification';
import Modal from '../../components/modals/Modal';
import CustomInputNew from '../../components/utils/forms/CustomInputNew';
import CustomSelectNew from '../../components/utils/forms/CustomSelectNew';
import { userStatus } from '../../utils/userStatus';
import {
  composeValidators,
  validateAdressIsFull,
  validateEmail,
  validatePostalCode,
  emailRequired,
  hasOptionalFieldChanged,
} from '../../utils/validator';
import { canSeeFamilyData } from '../../services/permissionService';

type Props = {|
    history: History,
    personalData: TypePersonalData,
    dispatch: Dispatch,
    status: string,
    hasFamilyData: boolean,
|};

const PersonalData = ({
  history, dispatch, personalData, status, hasFamilyData,
}: Props) => {
  const [isShowing, toggle] = useState<boolean>(false);
  const [isFormModified, setIsFormModified] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const { openErrorModal } = useContext(ModalContext);
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const [isPersonalDataLoaded, setIsPersonalDataLoaded] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<TypePersonalDataInputs>({
    street: personalData && personalData.adresse.street,
    country: personalData && personalData.adresse.country,
    postalCode: personalData && personalData.adresse.postalCode,
    city: personalData && personalData.adresse.city,
    complement: personalData && personalData.adresse.complement,
    email: personalData && personalData.email,
    phone: personalData && personalData.phone,
  });
  const formRef = useRef<FormApi>();

  const openModal = () => {
    toggle(true);
  };

  const closeModal = () => {
    toggle(false);
  };

  const isDirty = () => {
    // Use a custom isDirty to handle string / moment comparison for dates
    if (formRef && formRef.current) {
      const { values } = formRef.current.getState();
      return initialValues.street !== values.street ||
          initialValues.country !== values.country ||
          initialValues.postalCode !== values.postalCode ||
          hasOptionalFieldChanged(initialValues.complement, values.complement) ||
          initialValues.city !== values.city ||
          hasOptionalFieldChanged(initialValues.phone, values.phone) ||
          initialValues.email !== values.email ||
          initialValues.transmissionAgreement !== values.transmissionAgreement;
    }
    return false;
  };

  const updateFormModified = () => setIsFormModified(isDirty());

  const initValues = (newPersonalData: TypePersonalData) => setInitialValues({
    street: newPersonalData.adresse.street,
    country: newPersonalData.adresse.country,
    postalCode: newPersonalData.adresse.postalCode,
    complement: newPersonalData.adresse.complement,
    city: newPersonalData.adresse.city,
    phone: newPersonalData.phone,
    email: newPersonalData.email,
    transmissionAgreement: newPersonalData.transmissionAgreement,
    dateCgu: newPersonalData.dateCgu,
  });

  useEffect(() => {
    showLoading();
    userApi
      .getPersonalData()
      .then((response: Response) => response.json())
      .then((fetchedPersonalData: TypePersonalData) => {
        dispatch(storeUserPersonalData(fetchedPersonalData));
        initValues(fetchedPersonalData);
      })
      .catch(extractErrorFromSigma(openErrorModal))
      .finally(() => {
        setIsPersonalDataLoaded(true);
        closeLoading();
      });
  }, [dispatch, openErrorModal]);

  const sendData = (formData: TypePersonalDataInputs) => {
    const data = {
      numpers: personalData.numpers,
      fullName: personalData.fullName,
      birthName: personalData.birthName,
      birthDate: personalData.birthDate,
      email: formData.email,
      transmissionAgreement: formData.transmissionAgreement,
      phone: formData.phone ? formData.phone : ' ',
      adresse: {
        street: formData.street ? formData.street : ' ',
        complement: formData.complement ? formData.complement : ' ',
        city: formData.city ? formData.city : ' ',
        country: formData.country ? formData.country : ' ',
        postalCode: formData.postalCode ? formData.postalCode : ' ',
      },
      isDmdEnregEnCours: true,
    };

    userApi
      .updatePersonalData(data)
      .then((response: Response) => response.json())
      .then((savedPersonalData: TypePersonalData) => {
        dispatch(storeUserPersonalData(savedPersonalData));
        closeModal();
        setHasSubmitted(false);
        setIsFormModified(false);
        initValues(savedPersonalData);
      })
      .then(dispatch(notifySuccess))
      .catch(extractErrorFromSigma(openErrorModal));
  };

  const goToCotisationData = () => {
    if (NAVIGATION_RIGHTS.RETREAT.includes(status)) {
      history.push(RETREAT_DATA_ROUTE);
    } else if (NAVIGATION_RIGHTS.COTISATION.includes(status)) {
      history.push(COTISATION_DATA_ROUTE);
    } else {
      history.push(PROFESSIONAL_DATA_ROUTE);
    }
  };

  const goToFamilyData = () => {
    if (NAVIGATION_RIGHTS.FAMILY.includes(status)
        && canSeeFamilyData(hasFamilyData, status)) {
      history.push(FAMILY_DATA_ROUTE);
    } else if (NAVIGATION_RIGHTS.RETREAT.includes(status)) {
      history.push(RETREAT_DATA_ROUTE);
    } else {
      history.push(PROFESSIONAL_DATA_ROUTE);
    }
  };

  const scrollToFirstFaultyField = (errors: ValidationErrors) => {
    if (errors && Object.keys(errors).length > 0) {
      // Scroll to first field with an error
      const field = document.getElementById(Object.keys(errors)[0]);
      if (field) {
        field.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  // TODO : error message when no field was modified

  return (
    <div className="page-container">
      <SubPageTitle
        className="m-auto"
        message={I18n.t('personalData.pageTitle.personal')}
        color="cobalt"
      />
      <div id="personal-data">
        <Back
          className="mb-2"
          onClick={() => history.push(PERSONAL_DATA_HOME_ROUTE)}
          message={I18n.t('personalData.personalData.back')}
        />
        <div className="bloc-container">
          <NavigateDataButton
            messageLeft={
            NAVIGATION_RIGHTS.RETREAT.includes(status)
                ? I18n.t('personalData.retreatData.title')
                : (NAVIGATION_RIGHTS.COTISATION.includes(status)
                    ? I18n.t('personalData.cotisationData.title')
                    : I18n.t('personalData.professionalData.title'))
            }
            onClickLeft={goToCotisationData}
            className=""
            messageRight={NAVIGATION_RIGHTS.FAMILY.includes(status)
              && canSeeFamilyData(hasFamilyData, status)
                ? I18n.t('personalData.familyData.title')
                : NAVIGATION_RIGHTS.RETREAT.includes(status)
                    ? I18n.t('personalData.retreatData.title')
                    : I18n.t('personalData.professionalData.title')
            }
            onClickRight={goToFamilyData}
          />
          <Bloc
            className="data"
            color="cobalt"
          >
            {!isPersonalDataLoaded
              ? <GenericLoader label={I18n.t('tooltip.loadingData')} />
              : (
                <>
                  <div className="seperate-between header">
                    <div className="personal-logo" />
                    <div className="user-info-bloc">
                      <GenericForm
                        className="m-auto personal-data-header"
                        onSubmit={() => {}}
                        validate={(data: Object) =>
                            validate(userPersonalInfoFields, data)
                        }
                        initialValues={personalData}
                        translationBase={formTranslationType.personalData}
                        fields={userPersonalInfoFields}
                        hasButton={false}
                      />
                    </div>
                  </div>
                  {personalData && personalData.dmdEnregEnCours && (
                    <p className="updatingText">
                      <i className="far fa-info-circle" />
                      {I18n.t('personalData.personalData.updatePending')}
                    </p>
                  )}
                  <p className="label-address">{I18n.t('personalData.personalData.defaultAddressLabel')}</p>
                  <p className="value-address">{personalData ? personalData.defaultAddress : '-'}</p>
                  <Form
                    onSubmit={() => {
                      if (isDirty()) {
                        openModal();
                      }
                      setHasSubmitted(true);
                    }}
                    initialValues={initialValues}
                    render={({
                      handleSubmit,
                      errors,
                      form,
                      values,
                    }) => {
                      formRef.current = form;
                      return (
                        <form
                          onSubmit={(formEvent: FormEvent<HTMLFormElement>) => {
                            scrollToFirstFaultyField(errors);
                            handleSubmit(formEvent);
                          }}
                          onChange={updateFormModified}
                          className="m-auto form main-form"
                        >
                          <CustomInputNew
                            label={I18n.t('personalData.personalData.form.adresse.street.label')}
                            name="street"
                            id="street"
                            asterisk
                            important
                            validate={(value, AllValues) => validateAdressIsFull(value, AllValues, 'adresseCodeError')}
                          />
                          <CustomInputNew
                            label={I18n.t('personalData.personalData.form.adresse.complement.label')}
                            name="complement"
                            type="select"
                            important
                          />
                          <CustomInputNew
                            label={I18n.t('personalData.personalData.form.adresse.postalCode.label')}
                            name="postalCode"
                            id="postalCode"
                            type="select"
                            asterisk
                            important
                            validate={(value, AllValues) => composeValidators(
                                () => validatePostalCode(value, AllValues.country),
                                () => validateAdressIsFull(value, AllValues, 'postalCodeError'),
                            )()}
                          />
                          <CustomInputNew
                            label={I18n.t('personalData.personalData.form.adresse.city.label')}
                            name="city"
                            id="city"
                            type="select"
                            asterisk
                            important
                            validate={(value, AllValues) => validateAdressIsFull(value, AllValues, 'cityCodeError')}
                          />
                          <CustomSelectNew
                            label={I18n.t('personalData.personalData.form.adresse.country.label')}
                            name="country"
                            id="country"
                            options={countryList}
                            preferedOptions={[{ label: '', value: '' }, france]}
                            asterisk
                            important
                            validate={(value, AllValues) => validateAdressIsFull(value, AllValues, 'countryCodeError')}
                          />
                          <CustomInputNew
                            label={I18n.t('personalData.personalData.form.email.label')}
                            name="email"
                            id="email"
                            required
                            important
                            validate={composeValidators(
                                value => validateEmail(value, getLabel(formTranslationType.personalData, 'email', labelType.invalid)),
                                emailRequired,
                            )}
                          />
                          <CustomInputNew
                            label={I18n.t('personalData.personalData.form.phone.label')}
                            name="phone"
                            important
                          />
                          <CustomCheckbox
                            label={I18n.t('personalData.personalData.form.transmissionAgreement.label')}
                            name="transmissionAgreement"
                            onChange={newValue => form.change('transmissionAgreement', newValue)}
                            value={values.transmissionAgreement}
                            small
                            important
                          />
                          <div className="field">
                            <div className="label">{I18n.t('personalData.personalData.form.dateCgu.label')}</div>
                            <div className="value">{values.dateCgu}</div>
                          </div>
                          <div className="save-button-container">
                            {!isFormModified && hasSubmitted && (
                            <div className="no-modification-error-text">
                              <p>{I18n.t('form.noModification')}</p>
                            </div>
                            )}
                            <CustomButton
                              type="submit"
                              message={I18n.t('personalData.personalData.form.button')}
                            />
                          </div>
                          {/* modal de validation */}
                          {isShowing && (
                            <Modal
                              className="modal-confirmation"
                              isOpen={isShowing}
                              close={closeModal}
                              headerTitle={I18n.t('personalData.personalData.validationModal.title')}
                              buttons={
                                [
                                  <CustomButton
                                    className="btn-confirmation"
                                    onClick={() => sendData(values)}
                                    message={I18n.t('actions.SAVE')}
                                    type="button"
                                  />,
                                  <CustomButton
                                    className="btn-cancel"
                                    message={I18n.t('actions.CANCEL')}
                                    onClick={closeModal}
                                    type="button"
                                  />,
                              ]}
                            >
                              <div className="modal-body">
                                {I18n.t('personalData.personalData.validationModal.body')}
                                <br />
                                <br />
                                {personalData.adresse
                                      && (status === userStatus.RETRAITE
                                          || status === userStatus.BENEFICIAIRE)
                                      && personalData.defaultAddressCountry !== values.country
                                    && I18n.t('personalData.personalData.validationModal.body_otherCountry')
                                }
                              </div>
                            </Modal>
                            )}
                        </form>
                      );
                    }}
                  />
                </>
              )
            }
          </Bloc>
        </div>
      </div>
    </div>
  );
};

export default connect(state => ({
  status: state.user.user.statut,
  personalData: state.user.personalData,
  hasFamilyData: state.user.hasFamilyData,
}))(PersonalData);

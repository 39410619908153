// @flow

import React, { memo, useContext } from 'react';
import ModalContext from '../../context/modal/ModalContext';
import documentApi from '../../network/api/documentApi';
import type { Color, LinkInfos } from '../../types/types';
import { donneesServicesArrowSizes, mesDocumentsArrowSizes, screenSizes } from '../../utils/enum';
import { extractErrorFromSigma } from '../../utils/modal';
import { download } from '../../utils/utils';

type Props = {
  label: string,
  subLabel: string,
  color: Color,
  icon: string,
  disabled?: boolean,
  text?: string,
  bold?: boolean,
  svgPath?: string,
  link: LinkInfos,
};

const ArrowLabel = ({
  label, subLabel, color, icon, disabled, text, bold, svgPath, link,
}: Props) => {
  const { openErrorModal } = useContext(ModalContext);

  const onFileClick = (idGed: string, filename: string) => {
    documentApi
      .downloadDocument(idGed)
      .then((response: Response) => response.blob())
      .then((blob: Blob) => {
        download(blob, filename);
      })
      .catch(extractErrorFromSigma(openErrorModal));
  };

  const downloadButton =
    (
      <>
        {link.subLabel && (
        <div
          onClick={() => onFileClick(link.idGED, link.label)}
          className={`link --${color} --icon fa fa-arrow-alt-to-bottom cursor-pointer download-button`}
        />
        )}
      </>
    );

  const arrowClassName = `multilines arrow-document${bold ? ' arrow-document--bold' : ''}`;

  const arrowSizeAccordingToScreenSize = () => {
    if (window.innerWidth <= screenSizes.MOBILE) {
      return {
        donnneesServicesSize: donneesServicesArrowSizes.MOBILE,
        mesDocumentsSize: mesDocumentsArrowSizes.MOBILE,
      };
    }
    if (window.innerWidth <= screenSizes.TOUCHPAD) {
      return {
        donnneesServicesSize: donneesServicesArrowSizes.TOUCHPAD,
        mesDocumentsSize: mesDocumentsArrowSizes.TOUCHPAD,
      };
    }
    if (window.innerWidth <= screenSizes.DESKTOP) {
      return {
        donnneesServicesSize: donneesServicesArrowSizes.DESKTOP,
        mesDocumentsSize: mesDocumentsArrowSizes.DESKTOP,
      };
    }
    if (window.innerWidth <= screenSizes.BIG_SCREEN) {
      return {
        donnneesServicesSize: donneesServicesArrowSizes.BIG_SCREEN,
        mesDocumentsSize: mesDocumentsArrowSizes.BIG_SCREEN,
      };
    }
    return {
      donnneesServicesSize: donneesServicesArrowSizes.MAX_SIZE,
      mesDocumentsSize: mesDocumentsArrowSizes.MAX_SIZE,
    };
  };

  return (
    <div className={`container-arrow${disabled ? ' disabled' : ''}`}>
      {svgPath
          ? (<img src={svgPath} className={icon} alt={label} />)
          : (<i className={`${icon} --${color}`} />)
      }
      <div className={`arrow-label${subLabel ? ' mes-docs' : ''}`}>
        {!subLabel
          ? (
            <>
              <a className={`dashboard-label --${color}`}>{label}</a>
              <svg width="100%" height="100%" viewBox="0 0 592 92">
                <g transform="matrix(1,0,0,1,-4,-4)">
                  <path
                    className="arrow"
                    d={arrowSizeAccordingToScreenSize().donnneesServicesSize}
                    fill="none"
                  />
                </g>
              </svg>
            </>
          )
          : (
            <div className="dashboard-label multiline">
              <div className={arrowClassName}>
                <a className={color}>{label}</a>
                <p className="text-smaller">{subLabel}</p>
                {text &&
                <p className="text-bold">{text}</p>
                }
                <svg width="100%" height="100%" viewBox="0 0 550 120">
                  <path
                    className="arrow"
                    d={arrowSizeAccordingToScreenSize().mesDocumentsSize}
                    fill="none"
                  />
                </svg>
              </div>
              {downloadButton}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default memo<Props>(ArrowLabel);

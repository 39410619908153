// @flow

import { RestClient } from '../network';
import type { FormType } from '../../pages/enrolement/Step1';

const baseUrl = '/enrolement';

const enrolementApi = {
  checkToken: (token: string, connectType: string, step: string) =>
    new RestClient(`${baseUrl}/checkToken`, 'POST')
      .jsonBody({ token, connectType, step })
      .execute(),
  postFirstStep: (values: FormType) =>
    new RestClient(`${baseUrl}/firstStep`, 'POST').jsonBody(values).execute(),
  postSecondStep: (values: FormType) =>
    new RestClient(`${baseUrl}/secondStep`, 'POST').jsonBody(values).execute(),
  acceptCgu: (token: string, isTier: boolean) =>
    new RestClient(`${baseUrl}/acceptCgu?isTier=${isTier}`, 'POST').body(token).execute(),
  refusCgu: (token: string) =>
    new RestClient(`${baseUrl}/refusCgu`, 'POST').body(token).execute(),
  clearEnrolementToken: (token: string) =>
    new RestClient(`${baseUrl}/clearToken?token=${token}`, 'POST').execute(),
};

export default enrolementApi;

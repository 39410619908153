// @flow

import React, { memo, useCallback, useState } from "react";
import ModalContext from "./ModalContext";
import type { ErrorModalType, HelperModalType } from "../../utils/modal";
import { ModalTypeEnum } from "../../utils/modal";
import HelperModal from "../../components/modals/HelperModal";
import ErrorModal from "../../components/modals/ErrorModal";
import { FRANCE_CONNECT_LOGOUT, loginType, SAML_LOGOUT_URL } from "../../utils/const";

type Props = {
  children: any,
};

const ModalProvider = ({ children }: Props) => {
  const openErrorModal = useCallback(
    (errorCode: ErrorModalType, source?: string = undefined, token?: string = undefined, statusArguments?: string[]) => {
      toggleModal(prevState => {
        return {
          ...prevState,
          isOpen: true,
          typeModal: ModalTypeEnum.ERROR,
          errorCode,
          source,
          token,
          statusArguments,
        };
      });
    },
    [],
  );

  const openHelperModal = useCallback((helpPage: HelperModalType) => {
    toggleModal(prevState => {
      return {
        ...prevState,
        isOpen: true,
        typeModal: ModalTypeEnum.HELP,
        helpPage,
      };
    });
  }, []);

  const closeModal = useCallback(() => {
    toggleModal(prevState => {
      if (prevState.source) {
        switch (prevState.source) {
          case loginType.ID_NOT:
            window.location = SAML_LOGOUT_URL;
            break;
          case loginType.FRANCE_CONNECT:
            window.location.href = `${FRANCE_CONNECT_LOGOUT}?token=${prevState.token}`;
            break;
          default:
            break;
        }
      }
      return {
        ...prevState,
        isOpen: false,
        typeModal: undefined,
        errorCode: undefined,
        source: undefined,
        token: undefined,
        helpPage: undefined,
      };
    });
  }, []);

  const modalState = {
    isOpen: false,
    typeModal: undefined,
    errorCode: undefined,
    source: undefined,
    helpPage: undefined,
    statusArguments: [],
    openErrorModal,
    openHelperModal,
    closeModal,
  };

  const [modal, toggleModal] = useState(modalState);

  return (
    <ModalContext.Provider value={modal}>
      <HelperModal />
      <ErrorModal />
      {children}
    </ModalContext.Provider>
  );
};

export default memo<Props>(ModalProvider);

// @flow

import React, { memo } from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import PageToBeReleased from '../dashboard/PageToBeReleased';
import type { Color, LinkInfos } from '../../types/types';
import { userStatus } from '../../utils/userStatus';
import { colors } from '../../utils/enum';

type Props = {
  history: Object,
  className: string,
  color?: Color,
  title?: string,
  linkedList?: LinkInfos[],
  children: any,
  status?: string,
};

const Bloc = ({
  history,
  className,
  color = colors.BLUE,
  title,
  linkedList,
  children,
  status,
}: Props) => (
  <div className={`bloc ${className} --${color}`}>
    {title && <h2 className={`title --${color}`}>{title}</h2>}
    {children}
    {linkedList &&
      linkedList
        .filter((link: LinkInfos) => link.right.includes(status)
            || link.right.includes(userStatus.ALL))
        .map((link: LinkInfos) => (
          <div
            className={`link p-relative --${color}`}
            key={link.label}
            onClick={() => {
              if (link.route && !link.noData) {
                history.push(link.route);
              }
            }}
          >
            {link.noData && (
              <PageToBeReleased
                message={I18n.t('tooltip.noDataAvailable')}
                className="page-to-be-released"
              />
            )}
            {!link.noData && !link.route && (
              <PageToBeReleased
                message={I18n.t('tooltip.pageToBeReleased')}
                className="page-to-be-released"
              />
            )}
            <a className={`mb-2 cursor-pointer hover hover-${color}${(!link.route || link.noData) ? ' disabled' : ''}`}>
              {I18n.t(link.label)}
            </a>
          </div>
        ))}
  </div>
);

export default memo<Props>(withRouter(Bloc));

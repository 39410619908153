// @flow

import React, {memo, useCallback, useContext, useEffect, useState} from "react";
import { I18n } from "react-redux-i18n";
import { SERVICE_HOME_ROUTE } from "../../utils/routes";
import InternalPage from "../../components/utils/pages/InternalPage";
import Bloc from "../../components/accueil/Bloc";
import FiscalAttestation from "../../components/services/FiscalAttestation";
import { extractErrorFromSigma } from "../../utils/modal";
import type { TypeAttestation } from "../../components/services/FiscalAttestation";
import fiscalApi from "../../network/api/fiscalApi";
import LoadingContext from '../../context/loader/LoadingContext';

type Props = {|
  history: Object,
|};

const getMessage = (name: string) => I18n.t(`service.packFiscal.${name}`);
const PackFiscal = ({ history }: Props) => {
  const [data, setData] = useState([]);
  const { loading, showLoading, closeLoading } = useContext(LoadingContext);

    useEffect(() => {
      showLoading();
      fiscalApi
      .getFiscalInformations()
        .then(res => res.json())
        .then((fiscalData: Object) => {
            const fiscalYears = Object.entries(fiscalData.declarationsFiscales)
            setData(fiscalYears.slice(fiscalYears.length-2, fiscalYears.length));
        })
      .catch(extractErrorFromSigma)
      .finally(closeLoading);
  }, []);

    const getEmptyState  = () => {
      return (
        loading ? I18n.t('tooltip.loadingData') : getMessage("noData")
      )
    }

  return (
    <InternalPage
      menu={I18n.t("service.title")}
      color="yellow"
      hasBackButton
      handleClickBack={() => history.push(SERVICE_HOME_ROUTE)}
    >
      <div
        id="pack-fiscal"
        className="bloc-container mt-1 pb-5 yellow f-dir-col"
      >
        <Bloc title={getMessage("title")} color="yellow" className="mb-5">
          <p className="text-justify">{getMessage("description")}</p>
          <div className=" row">
            {data && data.length > 0 ? (
                  data.map(item => <FiscalAttestation attestation={item} />)
              ) : (
                <p>{getEmptyState()}</p>
              )}
          </div>
        </Bloc>
      </div>
    </InternalPage>
  );
};

export default memo<Props>(PackFiscal);

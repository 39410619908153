// @flow

import React from "react";
import { errorObject } from "../network/handleError";
import { IS_CONNECTED } from "../state/login/loginReducer";
import { store } from "../network/reduce";
import { store as reduxStore } from "..";
import { getInitialStateUser } from "../state/user/userReducer";
import { storeUser } from "../state/user/userService";
import { wsError } from "./const";
import { notifyError } from "../network/notification";

export const extractErrorFromSigma = (handleError: Function, dispatch?: Function) => {
  return (error: Function) => {
    let errorResponse = error;
    if (typeof error.then !== "function") {
      errorResponse = Promise.resolve(errorObject);
    }

    return (errorResponse: Object).then(errorJson => {
      if (errorJson.errorCode === wsError.ERR_NO_DATA_PAYMENT && dispatch) {
        notifyError(dispatch)(errorJson);
      } else {
        handleError(errorJson.errorCode, errorJson.statusArguments);
        if (
          errorJson.errorCode === "AUTHENTIFICATION_EXPIRED" ||
          errorJson.errorCode === "INVALID_CONNECTION_TOKEN" ||
          errorJson.errorCode === "INVALID_TOKEN"
        ) {
          sessionStorage.clear();
          reduxStore.dispatch(store(IS_CONNECTED, false));
          reduxStore.dispatch(storeUser(getInitialStateUser()));
        }
      }
    });
  };
};

export const ModalTypeEnum = {
  HELP: "HELP",
  ERROR: "ERROR",
};

export const HelperModalEnum = {
  LANDING_PAGE: "LANDING_PAGE",
  STEP_1: "STEP_1",
  STEP_2: "STEP_2",
};

export const ErrorModalEnum = {
  ERR_INCONNU: "ERR_INCONNU",
  ERROR_MESSAGE: "ERROR_MESSAGE",
  ERR_IDENTITE: "ERR_IDENTITE",
  INTERNAL_ERROR: "INTERNAL_ERROR",
  ERR_MAUVAIS_MODE_FC: "ERR_MAUVAIS_MODE_FC",
  ERR_MAUVAIS_MODE_IDNOT: "ERR_MAUVAIS_MODE_IDNOT",
  ERR_NO_DELETE_CONJOINT: "ERR_NO_DELETE_CONJOINT",
  ERR_LIMIT_AGE_NOT_REACHED: "ERR_LIMIT_AGE_NOT_REACHED",
  ERR_REQUEST_LIMIT_REACHED: "ERR_REQUEST_LIMIT_REACHED",
  ERR_REQUEST_STATE_iS_DELAYED: "ERR_REQUEST_STATE_iS_DELAYED",
};

export type ModalType = $Keys<typeof ModalTypeEnum>;
export type HelperModalType = $Keys<typeof HelperModalEnum>;
export type ErrorModalType = $Keys<typeof ErrorModalEnum>;

// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import type { DonneesCotisation } from '../../../types/types';
import CotisationDataNotaire from './CotisationDataNotaire';
import CotisationDataConjointCollaborateur from './CotisationDataConjointCollaborateur';

type Props = {
  donneesCotisation: DonneesCotisation,
  isTier: boolean,
};

const CotisationDataContent = ({ donneesCotisation, isTier }: Props) => {
  if (!donneesCotisation || !!donneesCotisation.cotisationData.erreur) {
    return (
      <div className="erreur">
        {I18n.t('personalData.cotisationData.erreur')}
      </div>
    );
  }

  return (
    <>
      {donneesCotisation.isConjoint
        ? <CotisationDataConjointCollaborateur
          donneesCotisation={donneesCotisation.cotisationData}
          conjointConsents={donneesCotisation.conjointConsents}
          isTier={isTier}
        />
        : <CotisationDataNotaire donneesCotisation={donneesCotisation.cotisationData} />
      }
    </>
  );
};

export default CotisationDataContent;

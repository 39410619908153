// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import ItemAccordion from '../../../../components/data/ItemAccordion';
import { DetailRegimeComplementaireC, RegimeComplementaireCAnnee } from '../../../../types/types';
import { displayCotisationValue } from '../../../../utils/utils';

type Props = {|
    data: RegimeComplementaireCAnnee[],
|}

const RegimeComplementaireC = ({ data }: Props) => (
  <div className="regime regime-complementaire-c">
    <div className="regime-header">
      <span className="header-item">{I18n.t('personalData.cotisationData.year')}</span>
      <span className="header-item">{I18n.t('personalData.cotisationData.rate')}</span>
      <span className="header-item">{I18n.t('personalData.cotisationData.cotisationAnnuelle')}</span>
    </div>
    <div className="regime-content">
      { data && data.map((year: RegimeComplementaireCAnnee) => (year.erreur
        ? (<div key={year.annee} className="ligne-erreur">{I18n.t('personalData.cotisationData.erreur')}</div>)
        : (
          <ItemAccordion
            key={year.annee}
            headerData={[year.annee,
              displayCotisationValue(year.tx, false, true),
              displayCotisationValue(year.cotisation, true),
            ]}
          >
            <div className="subheader">
              <span className="dates">{I18n.t('personalData.cotisationData.dates')}</span>
              <span className="item item-c moy">{I18n.t('personalData.cotisationData.officeAverageIncome')}</span>
              <span className="item item-c">{I18n.t('personalData.cotisationData.parts')}</span>
              <span className="item item-c">{I18n.t('personalData.cotisationData.assiette')}</span>
            </div>
            {year.detail && year.detail.map((ligne: DetailRegimeComplementaireC) =>
                (
                  <div key={ligne.dateDebut} className="ligne">
                    <span className="dates number">{ligne.dateDebut} {I18n.t('personalData.cotisationData.to')} {ligne.dateFin}</span>
                    <span className="item number item-c moy">{displayCotisationValue(ligne.moyenneProduitOffice, true)}</span>
                    <span className="item number item-c">{displayCotisationValue(ligne.parts, false, true)}</span>
                    <span className="item number item-c">{displayCotisationValue(ligne.assiette, true)}</span>
                  </div>
                ))
            }
          </ItemAccordion>
        )
      ))}
    </div>
  </div>
);

export default RegimeComplementaireC;

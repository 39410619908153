// @flow

import { RestClientAuthenticated } from '../network';

const baseUrl = '/payments';

const paymentApi = {
  getPayments: () =>
    new RestClientAuthenticated(`${baseUrl}`, 'GET').execute(),
  getAttestationPayment: (date: string) =>
    new RestClientAuthenticated(`${baseUrl}/attestation/${date}`, 'GET').execute(),
  getAttestationPaymentBis: (dateDebut: string, dateFin: string) =>
    new RestClientAuthenticated(`${baseUrl}/attestation?dateDebut=${dateDebut}&dateFin=${dateFin}`, 'GET').execute(),

};

export default paymentApi;

// @flow

import React, { useContext } from 'react';
import ModalContext from '../../context/modal/ModalContext';
import externalServiceApi from '../../network/api/externalServiceApi';
import LoadingContext from '../../context/loader/LoadingContext';
import { extractErrorFromSigma } from '../../utils/modal';
import { openExternalService } from '../../utils/utils';
import { externalServiceNames } from '../../utils/enum';
import ExternalService from '../../components/services/ExternalService';

type Props = {
  history: History,
};

const RetreatRequest = ({ history }: Props) => {
  const { openErrorModal } = useContext(ModalContext);
  const { showLoading, closeLoading } = useContext(LoadingContext);

  const onLinkClick = () => {
    showLoading();
    externalServiceApi.getDrl()
      .then((response: Response) => response.text())
      .then(openExternalService)
      .catch(extractErrorFromSigma(openErrorModal))
      .finally(closeLoading);
  };

  return (
    <ExternalService
      history={history}
      serviceName={externalServiceNames.DRL}
      onLinkClick={onLinkClick}
    />
  );
};

export default RetreatRequest;

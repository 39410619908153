// @flow
import React, { memo } from "react";

type Props = {
  messageLeft: string,
  onClickLeft: Function,
  className?: string,
  messageRight: string,
  onClickRight: Function,
};

const NavigateDataButton = ({
  messageLeft,
  onClickLeft,
  className = "",
  messageRight,
  onClickRight,
}: Props) => (
  <div className="navigate-button">
    {messageLeft !== "" ? (
      <div
        onClick={onClickLeft}
        className={`cursor-pointer text-large-size text-bold ${className}`}
      >
        <span className="mr-2 fa fa-chevron-left" />
        {messageLeft}
      </div>
    ) : (
      <div onClick={onClickLeft} className={`cursor-pointer ${className}`} />
    )}
    {messageRight !== "" && (
      <div
        onClick={onClickRight}
        className={`cursor-pointer text-large-size text-bold ${className}`}
      >
        {messageRight}
        <span className="fa ml-2 fa-chevron-right" />
      </div>
    )}
  </div>
);

export default memo<Props>(NavigateDataButton);

// @flow

import { RestClientAuthenticated } from '../network';
import type { Delegate, TypeDataUnit, TypePersonalData } from '../../types/types';

const baseUrl = '/user';

const userApi = {
  fetchUser: () =>
    new RestClientAuthenticated(`${baseUrl}`, 'GET').execute(),
  acceptCgu: () =>
    new RestClientAuthenticated(`${baseUrl}/cgu`, 'PUT')
      .jsonBody(true)
      .execute(),
  refusCgu: () =>
    new RestClientAuthenticated(`${baseUrl}/cgu`, 'PUT')
      .jsonBody(false)
      .execute(),
  getFamilyData: () =>
    new RestClientAuthenticated(`${baseUrl}/family`, 'GET').execute(),
  getPersonalData: () =>
    new RestClientAuthenticated(`${baseUrl}/personal`, 'GET').execute(),
  updatePersonalData: (values: TypePersonalData) =>
    new RestClientAuthenticated(`${baseUrl}/personal`, 'POST').jsonBody(values).execute(),
  updateConjointData: (values: TypeDataUnit) =>
    new RestClientAuthenticated(`${baseUrl}/conjoint`, 'POST').jsonBody(values).execute(),
  endUnion: (values: TypeDataUnit) =>
    new RestClientAuthenticated(`${baseUrl}/end-union`, 'PUT').jsonBody(values).execute(),
  updatePastUnionData: (values: TypeDataUnit) =>
    new RestClientAuthenticated(`${baseUrl}/past-conjoint`, 'PUT').jsonBody(values).execute(),
  updateChildData: (values: TypeDataUnit) =>
    new RestClientAuthenticated(`${baseUrl}/child`, 'POST').jsonBody(values).execute(),
  deleteChild: (numDF?: string, numpers?: string) =>
    new RestClientAuthenticated(`${baseUrl}/child?${numpers ? `numpers=${numpers}` : `numDF=${numDF}`}`, 'DELETE').execute(),
  getProfessionalData: () =>
    new RestClientAuthenticated(`${baseUrl}/professional`, 'GET').execute(),
  getCotisationData: (numpers: string) =>
    new RestClientAuthenticated(`${baseUrl}/cotisation?numpers=${numpers}`, 'GET').execute(),
  getCotisationConjointCollaborateur: (numpers: string) =>
    new RestClientAuthenticated(`${baseUrl}/cotisation/conjoint-collaborateur/${numpers}`, 'GET').execute(),
  getTierProfessionalData: () =>
    new RestClientAuthenticated(`${baseUrl}/tier-professional`, 'GET').execute(),
  hasFamilyData: () =>
    new RestClientAuthenticated(`${baseUrl}/hasFamilyData`, 'GET').execute(),
  getRetirementData: () =>
    new RestClientAuthenticated(`${baseUrl}/retirement`, 'GET').execute(),
  getHasMultipleRattachements: () =>
    new RestClientAuthenticated(`${baseUrl}/has-multiple-rattachements`, 'GET').execute(),
  fetchDelegations: (email: string) =>
    new RestClientAuthenticated(`${baseUrl}/delegations?mailNotaire=${email}`, 'GET').execute(),
  addDelegation: (delegate: Delegate, notaireMail: string) =>
    new RestClientAuthenticated(`${baseUrl}/delegations`, 'POST')
      .jsonBody({ delegate, notaireMail })
      .execute(),
  deleteDelegation: (delegate: Delegate, notaireMail: string) =>
    new RestClientAuthenticated(
      `${baseUrl}/delegations?uid=${delegate.uid}&nom=${delegate.nom}&prenom=${delegate.prenom}&nomEntite=${delegate.nomEntite}&mail=${delegate.mail}&notaireMail=${notaireMail}`,
      'DELETE',
    )
      .execute(),
  fetchCollaborators: () =>
    new RestClientAuthenticated(`${baseUrl}/collaborators`, 'GET').execute(),
  saveTierMail: (delegateMail: string) =>
    new RestClientAuthenticated(`${baseUrl}/tier-mail`, 'POST')
      .jsonBody(delegateMail)
      .execute(),
};

export default userApi;

// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import ItemAccordion from '../../../../components/data/ItemAccordion';
import { DonneesInvaliditeDeces, CotisationRid } from '../../../../types/types';
import { displayCotisationValue } from '../../../../utils/utils';

type Props = {|
    data: DonneesInvaliditeDeces,
|}

const RegimeInvaliditeDeces = ({ data }: Props) => (
  <div className="regime regime-invalidite">
    <div className="regime-header">
      <span className="header-item">{I18n.t('personalData.cotisationData.year')}</span>
      <span className="header-item last">{I18n.t('personalData.cotisationData.cotisationAnnuelle')}</span>
    </div>
    <div className="regime-content">
      <ItemAccordion
        isRid
        headerData={[
          data.anneeEnCours,
          displayCotisationValue(data.cotisationAnneeEnCours, true),
        ]}
      >
        <div className="subheader">
          <span className="item year">{I18n.t('personalData.cotisationData.year')}</span>
          <div className="double-label item item-rcb">
            <span>{I18n.t('personalData.cotisationData.forfaitDc_garantie')}</span>
            <span>{I18n.t('personalData.cotisationData.forfaitDc_cotisation')}</span>
          </div>
          <div className="double-label item item-rcb">
            <span>{I18n.t('personalData.cotisationData.forfaitInvalidite_garantie')}</span>
            <span>{I18n.t('personalData.cotisationData.forfaitInvalidite_cotisation')}</span>
          </div>
          <span className="item item-rcb">{I18n.t('personalData.cotisationData.abattementJeune')}</span>
        </div>
        { data && data.cotisationsRid &&
            data.cotisationsRid.map((ligne: CotisationRid) => (
              <div key={ligne.annee} className="ligne">
                <span className="item year number">{displayCotisationValue(ligne.annee)}</span>
                <span className="item number">{displayCotisationValue(ligne.cotisationDeces, true)}</span>
                <span className="item number">{displayCotisationValue(ligne.cotisationInval, true)}</span>
                <span className="item number">{displayCotisationValue(ligne.txAbatJeuneNot, false, true)}</span>
              </div>
            ))
        }
      </ItemAccordion>
    </div>
  </div>
);

export default RegimeInvaliditeDeces;

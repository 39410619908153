// @flow

import { Link } from '@material-ui/core';
import { CoreozDatePickerMaterial } from 'coreoz-form-base';
import { FormApi } from 'final-form';
import moment, { Moment } from 'moment';
import React, { useRef, useState } from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { I18n } from 'react-redux-i18n';
import CustomButton from '../../../components/buttons/CustomButton';
import { ConfirmModal } from '../../../components/modaleNotification/ConfirmModal';
import Modal from '../../../components/modals/Modal';
import PastUnionTile from '../../../components/utils/PastUnionTile';
import type { TypeDataUnit } from '../../../types/types';
import { EndUnionInfo } from '../../../types/types';
import { labelType, momentFormat } from '../../../utils/const';
import { genreList } from '../../../utils/data';
import { optionsTypeUnion } from '../../../utils/form';
import useToggle from '../../../utils/useToggle';
import { getLabel } from '../../../utils/utils';
import {
  checkBirthDate,
  checkEmptyField,
  checkFieldLength,
  checkUnionDate,
  fieldRequired,
  hasOptionalFieldChanged,
  isValidDateFormat,
} from '../../../utils/validator';
import { DropdownFieldForm } from './components/DropdownFieldForm';
import { RequiredFieldLabel } from './components/RequiredFieldLabel';
import { ConjointEndUnionFields } from './ConjointEndUnionFields';

type ConjointFormFieldsProps = {
  className?: string,
  onSubmit: (TypeDataUnit) => void,
  onSubmitEndUnion: (EndUnionInfo) => void,
  onSubmitPastUnion: (TypeDataUnit) => void,
  translationBase: string,
  initialValue: TypeDataUnit,
  endUnionInitialValue?: EndUnionInfo,
  disabledFields?: boolean,
  disabled?: boolean,
  finishedUnions: TypeDataUnit[],
  setIsFormModified: (boolean) => void,
  setFormRef: ({ current: FormApi | undefined }) => void,
  setHasSubmitted: (boolean) => void,
};

export const ConjointFormFields = ({
  className,
  initialValue,
  translationBase,
  disabled,
  disabledFields,
  onSubmit,
  onSubmitEndUnion,
  onSubmitPastUnion,
  endUnionInitialValue,
  finishedUnions,
  setIsFormModified,
  setFormRef,
  setHasSubmitted,
}: ConjointFormFieldsProps) => {
  const [valuesToSubmit, saveValuesToSubmit] = useState<TypeDataUnit>();
  const [confirmModal, toggleConfirmModal] = useToggle();
  const [endUnionModal, toggleEndUnionModal] = useToggle();
  const [finishedUnionsModal, toggleFinishedUnionsModal] = useToggle();
  const formRef = useRef<FormApi>();

  const isDirty = () => {
    // Use a custom isDirty to handle string / moment comparison for dates
    if (formRef && formRef.current) {
      const { values } = formRef.current.getState();
      return initialValue.sexe !== values.sexe ||
        initialValue.nom !== values.nom ||
        hasOptionalFieldChanged(initialValue.nomJF, values.nomJF) ||
        initialValue.prenom !== values.prenom ||
        initialValue.typeUnion !== values.typeUnion ||
        !moment(initialValue.dteNaiss, momentFormat.DATE)
          .isSame(moment(values.dteNaiss, momentFormat.DATE)) ||
        !moment(initialValue.dteUnion, momentFormat.DATE)
          .isSame(moment(values.dteUnion, momentFormat.DATE));
    }
    return false;
  };

  const isInitialValueIncomplete = () => {
    return !initialValue.sexe || !initialValue.nom || !initialValue.prenom ||
      !initialValue.dteNaiss || !initialValue.typeUnion || !initialValue.dteUnion
  }

  const updateFormModified = () => setIsFormModified(isDirty());

  const doesCurrentConjointExist = (currentConjoint: TypeDataUnit) => currentConjoint &&
    (currentConjoint.numDF || currentConjoint.numpers);

  const getInstructionLabel = () => {
    if (disabledFields) {
      return I18n.t('personalData.familyData.form.conjointUpdate.label_secondary');
    }
    return getLabel(translationBase, I18n.t(`familyData.form.${doesCurrentConjointExist(initialValue)
      ? 'conjointUpdate'
      : 'conjointCreate'
    }`), labelType.label);
  };

  return (
    <div className={`${className || ''} mb-1 size-2${disabled ? ' disabled' : ''} split-border`}>
      {confirmModal && (
        <ConfirmModal
          isOpen={confirmModal}
          close={toggleConfirmModal}
          headerTitle={I18n.t('personalData.personalData.validationModal.title')}
          onSubmit={() => {
            onSubmit(valuesToSubmit);
          }}
        >
          <div className="modal-body">
            {I18n.t('personalData.personalData.validationModal.body')}
          </div>
        </ConfirmModal>
      )}
      {endUnionModal && (
        <ConjointEndUnionFields
          className={className}
          onSubmitEndUnion={onSubmitEndUnion}
          translationBase={translationBase}
          endUnionInitialValue={endUnionInitialValue}
          disabled={disabled}
          disabledFields={disabledFields}
          isOpen={endUnionModal}
          close={toggleEndUnionModal}
          finishedUnions={finishedUnions}
          conjointData={initialValue}
        />
      )}
      {finishedUnionsModal && (
        <Modal
          headerTitle={I18n.t('personalData.familyData.pastUnions_label')}
          isOpen={finishedUnionsModal}
          close={toggleFinishedUnionsModal}
          isLeftAligned
          buttons={
            [
              <CustomButton
                type="button"
                className="btn-cancel"
                message={I18n.t('actions.BACK')}
                onClick={toggleFinishedUnionsModal}
              />,
            ]
          }
        >
          {finishedUnions.map((pastUnion: TypeDataUnit, index: number) => (
            <div id="family-data" key={pastUnion.numDF}>
              <PastUnionTile
                index={index}
                onSubmitPastUnion={onSubmitPastUnion}
                union={pastUnion}
                pastUnionNumber={finishedUnions.length - index}
                className={className}
                translationBase={translationBase}
                disabledFields={disabledFields}
              />
            </div>
          ))}
        </Modal>
      )}
      <Form
        id="conjoint-form"
        initialValues={initialValue}
        onSubmit={(values: TypeDataUnit) => {
          if (isDirty()) {
            saveValuesToSubmit(values);
            toggleConfirmModal();
          }
          setHasSubmitted(true);
        }}
        render={({
          handleSubmit, form,
        }) => {
          formRef.current = form;
          setFormRef(formRef);
          return (
            <>
              <div className="conjoint-instruction">
                {getInstructionLabel()}
              </div>
              <form onSubmit={handleSubmit} id="conjoint-data-form" onChange={updateFormModified}>
                <div className={`conjoint-container${disabledFields ? ' margin-read-only' : ''}`}>
                  <DropdownFieldForm
                    name="sexe"
                    label="conjoint.genre"
                    options={genreList}
                    mandatoryField
                    translationBase={translationBase}
                    disabledFields={disabledFields}
                  />
                  <Field
                    name="dteNaiss"
                    validate={(birthdate: Moment) =>
                      fieldRequired(birthdate) ||
                      isValidDateFormat(birthdate) ||
                      checkBirthDate(moment(birthdate, momentFormat.DATE),
                      formRef.current.getState().values.dteUnion)
                    }
                    render={({ input, meta }: FieldRenderProps) => (
                      <div id="family-data">
                        <label
                          htmlFor="dteNaiss"
                          id="dteNaiss"
                        >
                          <RequiredFieldLabel
                            label={getLabel(translationBase, 'conjoint.birthDate', labelType.label)}
                            isDisabled={disabledFields}
                          />
                          <div
                            className={`date-picker-field${disabledFields ? ' unset-bg' : ''}${
                              meta.touched && meta.error ? ' error' : ''
                            }`}
                          >
                            <div className={`fa fa-calendar-alt calendar-icon${disabledFields ? ' disabled' : ''}`} />
                            <CoreozDatePickerMaterial
                              input={input}
                              meta={{
                                ...meta,
                                error: undefined,
                              }}
                              onChange={updateFormModified}
                              onlyDate
                              disabled={disabledFields}
                              dateFormat={momentFormat.DATE}
                              className={`datepicker${disabledFields ? ' disabled' : ''}`}
                              selected={input.value}
                              locale="fr-FR"
                            />
                          </div>
                          {meta.touched && meta.error && (
                            <div className="error-message color-red">{meta.error}</div>
                          )}
                        </label>
                      </div>
                    )}
                  />
                  <Field
                    name="nom"
                    validate={(nom: string) =>
                      fieldRequired(nom) ||
                      checkEmptyField(nom) ||
                      checkFieldLength(nom)
                    }
                    render={({ input, meta }: FieldRenderProps) => (
                      <div id="family-data">
                        <label
                          htmlFor="nom"
                          id="nom"
                        >
                          <RequiredFieldLabel
                            label={getLabel(translationBase, 'conjoint.usageName', labelType.label)}
                            isDisabled={disabledFields}
                          />
                          <div
                            className={`field field-value${
                              meta.touched && meta.error ? ' error' : ''
                            }`}
                          >
                            <input
                              {...input}
                              disabled={disabledFields}
                              className="character"
                            />
                          </div>
                          {meta.touched && meta.error && (
                            <div className="error-message color-red">{meta.error}</div>
                          )}
                        </label>
                      </div>
                    )}
                  />
                  <DropdownFieldForm
                    name="typeUnion"
                    label="conjoint.unionType"
                    options={optionsTypeUnion}
                    mandatoryField
                    translationBase={translationBase}
                    disabledFields={disabledFields}
                  />
                  <Field
                    name="nomJF"
                    validate={(nomJF: string) => {
                      if (nomJF != null) {
                        return checkEmptyField(nomJF) || checkFieldLength(nomJF);
                      }
                      return undefined;
                    }}
                    render={({ input, meta }: FieldRenderProps) => (
                      <div id="family-data">
                        <label
                          htmlFor="nomJF"
                          id="nomJF"
                        >
                          <span className={`field-label${disabledFields ? ' disabled' : ''}`}>
                            {getLabel(translationBase, 'conjoint.birthName', labelType.label)}
                          </span>
                          <div
                            className={`field field-value ${
                              meta.touched && meta.error ? 'error' : ''
                            }`}
                          >
                            <input
                              {...input}
                              disabled={disabledFields}
                              className="character"
                            />
                          </div>
                          {meta.touched && meta.error && (
                            <div className="error-message color-red">{meta.error}</div>
                          )}
                        </label>
                      </div>
                    )}
                  />
                  <Field
                    name="dteUnion"
                    validate={(startUnionDate: Moment) =>
                      fieldRequired(startUnionDate) ||
                      isValidDateFormat(startUnionDate) ||
                      checkUnionDate(
                        finishedUnions,
                        valuesToSubmit,
                        moment(startUnionDate, momentFormat.DATE),
                        'startUnionDate',
                      )
                    }
                    render={({ input, meta }: FieldRenderProps) => (
                      <div id="family-data" className="datePicker">
                        <label
                          htmlFor="dteUnion"
                          id="dteUnion"
                        >
                          <RequiredFieldLabel
                            label={getLabel(translationBase, 'conjoint.unionDate', labelType.label)}
                            isDisabled={disabledFields}
                          />
                          <div
                            className={`date-picker-field${disabledFields ? ' unset-bg' : ''}${
                              meta.touched && meta.error ? ' error' : ''
                            }`}
                          >
                            <div className={`fa fa-calendar-alt calendar-icon${disabledFields ? ' disabled' : ''}`} />
                            <CoreozDatePickerMaterial
                              input={input}
                              meta={{
                                ...meta,
                                error: undefined,
                              }}
                              onChange={updateFormModified}
                              onlyDate
                              disabled={disabledFields}
                              dateFormat={momentFormat.DATE}
                              className={`datepicker input-field${disabledFields ? ' disabled' : ''}`}
                              selected={input.value}
                              locale="fr-FR"
                            />
                          </div>
                          {meta.touched && meta.error && (
                            <div className="error-message color-red">{meta.error}</div>
                          )}
                        </label>
                      </div>
                    )}
                  />
                  <Field
                    name="prenom"
                    validate={(prenom: string) =>
                      fieldRequired(prenom) ||
                      checkEmptyField(prenom) ||
                      checkFieldLength(prenom)
                    }
                    render={({ input, meta }: FieldRenderProps) => (
                      <div id="family-data">
                        <label
                          htmlFor="prenom"
                          id="prenom"
                        >
                          <RequiredFieldLabel
                            label={getLabel(translationBase, 'conjoint.firstName', labelType.label)}
                            isDisabled={disabledFields}
                          />
                          <div
                            className={`field field-value${
                              meta.touched && meta.error ? ' error' : ''
                            }`}
                          >
                            <input
                              {...input}
                              disabled={disabledFields}
                              className="character"
                            />
                          </div>
                          {meta.touched && meta.error && (
                            <div className="error-message color-red">{meta.error}</div>
                          )}
                        </label>
                      </div>
                    )}
                  />
                  <div id="family-data" className="conjoint-link">
                    {doesCurrentConjointExist(initialValue) && !disabledFields && (
                      <>
                        {isInitialValueIncomplete() && (
                          <label>{getLabel(translationBase, 'conjoint.incompleteData', labelType.label)}</label>
                        )}
                        <Link className={`past-union-links${isInitialValueIncomplete() ? '--disabled' : ''}`}
                              component="button" type="button" disabled={isInitialValueIncomplete()} onClick={toggleEndUnionModal}>
                          {getLabel(translationBase, 'conjoint.declareEndUnion', labelType.label)}
                        </Link>
                      </>
                    )}
                    {finishedUnions.length > 0 && (
                      <Link className="past-union-links" onClick={toggleFinishedUnionsModal}>
                        {getLabel(translationBase, 'conjoint.pastUnions', labelType.label)}
                      </Link>
                    )}
                  </div>
                </div>
              </form>
            </>
          );
        }}
      />
    </div>
  );
};

// @flow

import { RestClient, RestClientAuthenticated } from '../network';
import type { ErrorModalType, HelperModalType } from '../../utils/modal';
import type { ErrorType } from '../../types/errors';

const baseUrl = '/log';

const loggerApi = {
  addViewAuthenticated: (pathname: string) =>
    new RestClientAuthenticated(`${baseUrl}/viewAuthenticated`, 'POST')
      .body(pathname)
      .execute(),
  addView: (pathname: string, token?: string) =>
    new RestClient(`${baseUrl}/view`, 'POST')
      .jsonBody({ pathname, token })
      .execute(),
  addTokenInvalid: (pathname: string) =>
    new RestClient(`${baseUrl}/invalidToken`, 'POST').body(pathname).execute(),
  addAttemptEnrolment: (pathname: string) =>
    new RestClient(`${baseUrl}/attemptEnrolment`, 'POST')
      .body(pathname)
      .execute(),
  addOpenHelpModal: (helpPage: HelperModalType) =>
    new RestClient(`${baseUrl}/openHelpModal`, 'POST').body(helpPage).execute(),
  addErrorModal: (errorCode: ErrorModalType) =>
    new RestClient(`${baseUrl}/openErrorModal`, 'POST')
      .body(errorCode)
      .execute(),
  addErrorOccured: (error: ErrorType, componentStack: string) =>
    new RestClient(`${baseUrl}/errorOccured`, 'POST')
      .jsonBody({
        error: error.message,
        stack: error.stack,
        componentStack,
      })
      .execute(),
};

export default loggerApi;

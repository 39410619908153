// @flow

import * as Sentry from "@sentry/browser";
import confApi from "../network/api/confApi";

const fetchSentryInfos: Promise<string> = new Promise(resolve =>
  confApi
    .fetchEnv()
    .then(res => res.text())
    .then(resolve)
    .catch(() => resolve("error")),
);

export const initSentry: Promise<void> = new Promise(resolve => {
  fetchSentryInfos
    .then((env: string) => {
      Sentry.init({
        environment: env,
        dsn: "https://bae123340d6e42ec90dfb55ab468a73f@sentry.io/1522612",
      });
      resolve();
    })
    .catch(resolve);
});

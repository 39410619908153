// @flow

import React, { useCallback, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Redirect } from "react-router-dom";

import CustomButton from "../components/buttons/CustomButton";
import TileConnexion from "../components/landingPage/TileConnexion";
import { loginType } from "../utils/const";
import { HelperModalEnum } from "../utils/modal";
import ModalContext from "../context/modal/ModalContext";
import queryString from "query-string";
import LoadingContext from "../context/loader/LoadingContext";
import { DASHBOARD_ROUTE, HOME_PAGE_ROUTE } from "../utils/routes";
import { disconnectOnly, isUserConnected } from "../state/login/loginService";
import loggerApi from "../network/api/loggerApi";
import { login } from "../services/loginService";
import type { User } from "../types/userType";

type Props = {|
  history: Object,
  location: Object,
  isConnected: boolean,
  dispatch: Function,
  user: User,
|};

type QueryParamsType = {|
  t?: string,
  e?: string,
|};

const getQueryParams = (search: string): QueryParamsType => {
  return queryString.parse(search);
};

const LandingPage = ({ isConnected, location, history, dispatch }: Props) => {
  if (isConnected) {
    const { disconnect } = location.search && getQueryParams(location.search);
    if (!disconnect) {
      return <Redirect to={DASHBOARD_ROUTE} />;
    }
  }

  const { showLoading, closeLoading } = useContext(LoadingContext);
  const { openErrorModal } = useContext(ModalContext);

  useEffect(() => {
    loggerApi.addView(location.pathname);
    if (location.search) {
      const { t, e, source, token, disconnect } = getQueryParams(location.search);
      if (t) {
        login(t, history, dispatch, openErrorModal, showLoading, closeLoading);
      } else if (e) {
        openErrorModal(e, source, token);
        history.replace(HOME_PAGE_ROUTE);
      } else if (disconnect) {
        disconnectOnly(dispatch);
        history.replace(HOME_PAGE_ROUTE);
      }
    }
  }, []);

  const { openHelperModal } = useContext(ModalContext);

  return (
    <>
      <div className="content-landingpage">
        <div className="tiles-connexion">
          <TileConnexion names={["actif", "tiersdeconfiance"]} type={loginType.ID_NOT} text="textActif" />
          <TileConnexion names={["retraite", "beneficiaire", "horsexercice"]} type={loginType.FRANCE_CONNECT} text="textInactif" />
        </div>
        <CustomButton
          message={I18n.t("modales.LANDING_PAGE.btn")}
          onClick={useCallback(
            () => openHelperModal(HelperModalEnum.LANDING_PAGE),
            [],
          )}
        />
      </div>
    </>
  );
};

export default connect(state => ({
  isConnected: isUserConnected(state.login),
  user: state.user.user,
}))(LandingPage);

// @flow

import React, { memo } from 'react';
import { I18n } from 'react-redux-i18n';

import File from './File';
import type { TypeFile } from '../../types/types';

type Props = {|
  files: TypeFile[],
  getMessage: (string) => string,
  onLinkClick: () => void,
  redirectionRoute: string,
  hideSubtitle?: boolean,
|};

const FileList = ({
  files,
  getMessage,
  onLinkClick,
  redirectionRoute,
  hideSubtitle,
}: Props) => {
  if (!files) {
    return <></>;
  }

  return (
    <div className="files">
      {!hideSubtitle && (
        <h2 className="text-center mt-0 mb-3">{getMessage('subtitle') === 'subtitle' ? '' : getMessage('subtitle')}</h2>
      )}
      {files.length > 0 ? (
        files.map((file: TypeFile) => <File key={file.idGED} file={file} />)
      ) : (
        <i className="mb-2">{I18n.t('files.details.noFiles')}</i>
      )}
      {redirectionRoute && (
        <div
          onClick={onLinkClick}
          className="text-right text-underline cursor-pointer mt-1 mb-2 w-100"
        >
          {getMessage('link')}
          <span className="fa fa-chevron-right ml-1" />
        </div>
      )}
    </div>
  );
};

export default memo<Props>(FileList);

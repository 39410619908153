// @flow
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { withErrorHandler } from "coreoz-error-handler";

import {
  CGU_PAGE_ROUTE,
  MENTION_LEGALES_ROUTE,
  DASHBOARD_ROUTE,
  ENROLEMENT_LANDING_PAGE_ROUTE,
  FILES_ROUTE,
  HOME_PAGE_ROUTE,
  PERSONAL_DATA_HOME_ROUTE,
  SERVICE_HOME_ROUTE, DELEGATION_HOME_ROUTE,
} from './utils/routes';
import LandingPage from "./pages/LandingPage";
import EnrolementRoutes from "./pages/enrolement/EnrolementRoutes";
import PersonalDataRoutes from "./pages/personalData/PersonalDataRoutes";
import ServicesRoutes from "./pages/services/ServicesRoutes";
import FilesRoutes from "./pages/files/FilesRoutes";
import DelegationsRoutes from "./pages/delegations/DelegationsRoutes";
import ErrorPage from "./components/utils/ErrorPage";
import Dashboard from "./pages/dashboard/Dashboard";
import LoadingProvider from "./context/loader/LoadingProvider";
import ModalProvider from "./context/modal/ModalProvider";
import PrivateRoute from "./components/utils/PrivateRoute";
import loggerApi from "./network/api/loggerApi";
import type { ErrorType } from "./types/errors";
import CguPage from "./pages/cgu/CguPage";
import MentionLegalesPage from "./pages/mentionLegales/MentionLegalesPage";

let eventId = null;

const onError = (error: ErrorType, info: { componentStack: string }) => {
  loggerApi.addErrorOccured(error, info.componentStack);
  if (process.env.NODE_ENV === "production") {
    Sentry.withScope(scope => {
      scope.setExtras(info);
      const eventIdLocal = Sentry.captureException(error);
      eventId = eventIdLocal;
    });
  }
};

const MainContent = () => {
  return (
    <ModalProvider>
      <LoadingProvider>
        <Switch>
          <Route exact path={HOME_PAGE_ROUTE} component={LandingPage} />
          <Route
            path={ENROLEMENT_LANDING_PAGE_ROUTE}
            component={EnrolementRoutes}
          />

          {/* TABLEAU DE BORD */}
          <PrivateRoute exact path={DASHBOARD_ROUTE} component={Dashboard} />

          {/*MES DONNEES PERSONNELLES */}
          <PrivateRoute
            path={PERSONAL_DATA_HOME_ROUTE}
            component={PersonalDataRoutes}
          />

          {/* MES SERVICES */}
          <PrivateRoute path={SERVICE_HOME_ROUTE} component={ServicesRoutes} />

          {/* MES DOCUMENTS */}
          <PrivateRoute path={FILES_ROUTE} component={FilesRoutes} />

          {/* MES DELEGATIONS */}
          <PrivateRoute path={DELEGATION_HOME_ROUTE} component={DelegationsRoutes} />

          <Route exact path={CGU_PAGE_ROUTE} component={CguPage} />

          <Route
            exact
            path={MENTION_LEGALES_ROUTE}
            component={MentionLegalesPage}
          />

          <Route path="*" render={() => <Redirect to={HOME_PAGE_ROUTE} />} />
        </Switch>
      </LoadingProvider>
    </ModalProvider>
  );
};

export default withErrorHandler(
  MainContent,
  <ErrorPage eventId={eventId} />,
  onError,
);

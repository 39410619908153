// @flow

import { CoreozDatePickerMaterial } from 'coreoz-form-base';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { FieldRenderProps, Field, Form } from 'react-final-form';
import { I18n } from 'react-redux-i18n';
import CustomButton from '../../../components/buttons/CustomButton';
import Modal from '../../../components/modals/Modal';
import type { TypeDataUnit } from '../../../types/types';
import { EndUnionInfo } from '../../../types/types';
import { fieldNameEndUnion, motiveUnionEndOptions } from '../../../utils/enum';
import { checkUnionDate, fieldRequired, isValidDateFormat } from '../../../utils/validator';
import { DropdownFieldForm } from './components/DropdownFieldForm';
import { labelType, momentFormat } from '../../../utils/const';
import { optionsMotiveUnionEnd } from '../../../utils/form';
import { getLabel } from '../../../utils/utils';
import { RequiredFieldLabel } from './components/RequiredFieldLabel';

type ConjointEndUnionFieldsProps = {
  className?: string,
  onSubmitEndUnion: (EndUnionInfo) => void,
  translationBase: string,
  endUnionInitialValue: EndUnionInfo,
  disabled?: boolean,
  disabledFields?: boolean,
  isOpen: boolean,
  close: () => void,
  finishedUnions: TypeDataUnit[],
  conjointData: TypeDataUnit,
};

export const ConjointEndUnionFields = (
  {
    className,
    onSubmitEndUnion,
    translationBase,
    endUnionInitialValue,
    disabled,
    disabledFields,
    isOpen,
    close,
    finishedUnions,
    conjointData,
  }: ConjointEndUnionFieldsProps,
) => {
  const [endUnionValues, setEndUnionValues] = useState<EndUnionInfo>();

  const submitEndUnionData = (endUnionData: EndUnionInfo) => {
    onSubmitEndUnion(endUnionData);
    close();
  };

  const setEndUnionDateLabel = (motiveEndUnion: string) => {
    if (motiveEndUnion === motiveUnionEndOptions.DECES) {
      return getLabel(translationBase, 'conjoint.unionEndDateDeces', labelType.label);
    }
    return getLabel(translationBase, 'conjoint.unionEndDateSeparation', labelType.label);
  };

  const setFieldName = (valuesEndUnion: EndUnionInfo) => {
    if (valuesEndUnion.motiveUnionEnd === optionsMotiveUnionEnd[0].value) {
      return fieldNameEndUnion.SEPARATION;
    }
    return fieldNameEndUnion.DECES;
  };

  const setResetField = (valuesEndUnion: EndUnionInfo, form) => {
    setEndUnionValues(valuesEndUnion);
    if (valuesEndUnion.motiveUnionEnd === optionsMotiveUnionEnd[0].value) {
      return form.mutators.resetSeperationDate;
    }
    return form.mutators.resetDeathDate;
  };

  return (
    <div className={`${className ? `${className} ` : ''}mb-1 size-2${disabled ? ' disabled' : ''}`}>
      <Form
        initialValues={endUnionInitialValue}
        onSubmit={submitEndUnionData}
        mutators={{
          resetSeperationDate: (args, state, { changeValue }) => {
            changeValue(state, 'dteDeces', () => endUnionValues.dteFinUnion && moment(endUnionValues.dteFinUnion));
            changeValue(state, 'dteFinUnion', () => undefined);
          },
          resetDeathDate: (args, state, { changeValue }) => {
            changeValue(state, 'dteFinUnion', () => endUnionValues.dteDeces && moment(endUnionValues.dteDeces));
            changeValue(state, 'dteDeces', () => undefined);
        },
        }}
        render={({
          handleSubmit: handleSubmitEndUnion,
          values: valuesEndUnion,
          form,
        }) => (
          <Modal
            modalClassName="end-union-modal"
            isOpen={isOpen}
            close={close}
            headerTitle={getLabel(translationBase, 'conjoint.declareEndUnion', labelType.label)}
            buttons={
                [
                  <CustomButton
                    type="button"
                    className="btn-cancel"
                    message={I18n.t('actions.BACK')}
                    onClick={close}
                  />,
                  <CustomButton
                    type="submit"
                    form="end-union-form"
                    className="btn-confirmation"
                    message={I18n.t('actions.VALIDATE')}
                  />,
                ]
              }
          >
            <form
              onSubmit={handleSubmitEndUnion}
              id="end-union-form"
              className="end-union-form"
            >
              <div className="end-union-container">
                <DropdownFieldForm
                  className="end-union-data"
                  name="motiveUnionEnd"
                  label="conjoint.motiveUnionEnd"
                  options={optionsMotiveUnionEnd}
                  translationBase={translationBase}
                  mandatoryField
                  resetValue={setResetField(valuesEndUnion, form)}
                />
                <Field
                  name={setFieldName(valuesEndUnion)}
                  validate={(endUnionDate: Moment) =>
                      fieldRequired(endUnionDate) ||
                      isValidDateFormat(endUnionDate) ||
                      checkUnionDate(
                        finishedUnions,
                        conjointData,
                        moment(endUnionDate, momentFormat.DATE),
                        'endUnionDate',
                      )
                    }
                  render={({ input, meta }: FieldRenderProps) => (
                    <div className="end-union-data">
                      <label
                        className="end-union-field-label"
                        htmlFor="unionEndDate"
                        id="unionEndDate"
                      >
                        <RequiredFieldLabel
                          label={setEndUnionDateLabel(valuesEndUnion.motiveUnionEnd)}
                          isDisabled={disabledFields}
                        />
                        <div
                          className={`date-picker-field${disabledFields ? ' unset-bg' : ''}${
                              meta.touched && meta.error ? ' error' : ''
                            }`}
                        >
                          <div className={`fa fa-calendar-alt calendar-icon${disabledFields ? ' disabled' : ''}`} />
                          <CoreozDatePickerMaterial
                            input={input}
                            meta={{
                                ...meta,
                                error: undefined,
                              }}
                            onlyDate
                            disabled={disabledFields}
                            dateFormat={momentFormat.DATE}
                            className="datepicker"
                            selected={input.value}
                            locale="fr-FR"
                          />
                        </div>
                        {meta.touched && meta.error && (
                        <div className="error-message color-red">{meta.error}</div>
                          )}
                      </label>
                    </div>
                  )}
                />
              </div>
            </form>
          </Modal>
          )}
      />
    </div>
  );
};

// @flow

import React, { ChangeEvent } from 'react';
import { FieldRenderProps, Field } from 'react-final-form';
import { getLabel } from '../../../../utils/utils';
import { fieldRequired } from '../../../../utils/validator';
import { RequiredFieldLabel } from './RequiredFieldLabel';

type Option = {
  label: String,
  value: String,
}

type DropdownProps = {
  className?: string,
  name: string,
  label: string,
  options: Option[],
  translationBase: string,
  disabledFields?: boolean,
  mandatoryField?: boolean,
  resetValue?: () => void,
};

export const DropdownFieldForm = ({
  className, name, label, options, translationBase, disabledFields, mandatoryField, resetValue,
}: DropdownProps) => (
  <div id={className ? undefined : 'family-data'} className={className}>
    <Field
      name={name}
      validate={mandatoryField ? fieldRequired : undefined}
      render={({ input, meta }: FieldRenderProps) => (
        <label
          className="end-union-field-label"
          id={name}
          htmlFor={name}
        >
          <div
            className={`d-flex justify-content-between label ${
                meta.touched && meta.error ? 'error' : ''
              }`}
          >
            <RequiredFieldLabel
              label={getLabel(translationBase, label)}
              isDisabled={disabledFields}
            />
          </div>
          <div
            className={`${
                meta.touched && meta.error ? 'error' : ''
              }`}
          >
            <select
              className={`w-100 union-field${disabledFields ? ' disabled' : ''}`}
              name={name}
              {...input}
              disabled={disabledFields}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                  if (resetValue) {
                    resetValue();
                  }
                  input.onChange(event);
                }}
            >
              {options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
                ))}
            </select>
            {meta.touched && meta.error && (
              <div className="error-message color-red">{meta.error}</div>
              )}
          </div>
        </label>
        )}
    />
  </div>
);

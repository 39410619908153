// @flow

import React from "react";
import { I18n } from "react-redux-i18n";

type Props = {
  message: string,
  className?: string,
  shouldTranslate?: boolean,
  size?: string,
};

type State = {|
  height: number,
  width: number,
  position: "right" | "left",
|};

class Tooltip extends React.Component<Props, State> {
  state = {
    width: window.innerWidth,
    position: "right",
  };
  ref = React.createRef();
  minWidth = 180;

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions, false);
    this.checkPosition();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions, false);
  }

  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
    this.checkPosition();
  };

  checkPosition = () => {
    const dimensions = this.ref.current.getBoundingClientRect();
    if (this.state.width - dimensions.x < this.minWidth) {
      this.setState({ position: "left" });
    } else {
      this.setState({ position: "right" });
    }
  };

  render() {
    const { className, message, shouldTranslate, size } = this.props;
    return (
      <div
        ref={this.ref}
        className={`tooltip ${className} ${this.state.position}`}
      >
        <i className={`far ${size} fa-info-circle`} />
        <span className="tooltip-text">
          {shouldTranslate ? I18n.t(`tooltip.${message}`) : message}
        </span>
      </div>
    );
  }
}

Tooltip.defaultProps = {
  className: "",
  shouldTranslate: true,
};

export default Tooltip;

// @flow

import React, { useCallback } from "react";
import * as Sentry from "@sentry/browser";
import { I18n, Translate } from "react-redux-i18n";
import CustomButton from "../buttons/CustomButton";

type Props = {
  eventId: ?number,
};

const ErrorPage = ({ eventId }: Props) => {
  // FIXME: A charter
  return (
    <div className="error-page">
      <div className="error-message">
        <h1>
          <Translate value="sentry.TITLE" />
        </h1>
        <h3>
          <Translate value="sentry.SUBTITLE" />
        </h3>
        <div>
          <Translate value="sentry.LINK_DESCRIPTION" />
          <CustomButton
            onClick={useCallback(
              () => Sentry.showReportDialog({ eventId: eventId }),
              [eventId],
            )}
            message={I18n.t("sentry.LINK_ACTION")}
          />
          <CustomButton
            onClick={useCallback(() => window.location.reload(), [])}
            message={I18n.t("sentry.RELOAD")}
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;

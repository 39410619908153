// @flow

import React, { createRef, memo } from 'react';
import ReactDOM from 'react-dom';
import useOnClickOutside from '../../hooks/useOnClickOutside';

type Props = {
  withHeaderTitle?: boolean,
  headerTitle?: string,
  withCloseButton?: boolean,
  isOpen: boolean,
  children: React.ReactNode,
  close: Function,
  modalClassName: string,
  buttons?: React.ReactNodeArray,
  isLeftAligned?: boolean,
};

const renderHeader = (
  withHeaderTitle?: boolean,
  headerTitle?: string,
  withCloseButton?: boolean,
  close: Function,
  isLeftAligned?: boolean,
) => (
  <div className="modal-header">
    {withCloseButton && <i className="fa fa-times" onClick={close} />}
    {withHeaderTitle && (
      <div className={`modal-header__title${isLeftAligned ? ' left-align' : ''}`}>
        <h1>{headerTitle}</h1>
      </div>
    )}
  </div>
);

// $FlowFixMe
const renderBody = (children: React.ReactChildren) => (
  <div className="modal-body">{children}</div>
);

const renderFooter = (buttons?: React.ReactNodeArray) => (
  <div className="modal-footer">
    {buttons.map((button, index) => (
      <React.Fragment key={`modal-footer-button-${index}`}>
        {button}
      </React.Fragment>
    ))}
  </div>
);

const Modal = ({
  withHeaderTitle = true,
  headerTitle = '',
  withCloseButton = true,
  isOpen,
  children,
  close,
  className,
  modalClassName,
  buttons,
  isLeftAligned,
}: Props) => {
  const modal = createRef();

  useOnClickOutside(modal, close, ['custom-button']);

  const root = document.getElementById('root');

  if (root === null) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div className={isOpen ? (modalClassName || 'modal') : 'hidden'}>
        <div className={`modal-content ${className}`} ref={modal}>
          {renderHeader(withHeaderTitle, headerTitle, withCloseButton, close, isLeftAligned)}
          {renderBody(children)}
          {buttons && buttons.length > 0 && renderFooter(buttons)}
        </div>
      </div>
    </>,
    root,
  );
};

export default memo<Props>(Modal);

// @flow

import { Dispatch } from 'redux';
import type { User } from '../../types/userType';
import { store } from '../../network/reduce';
import {
  COLLABORATORS,
  DELEGATIONS,
  getInitialStateUser,
  HAS_FAMILY_DATA,
  HAS_MULTIPLE_RATTACHEMENTS,
  NOTAIRES_DELEGANTS_DATA,
  PERSONAL_DATA,
  USER,
} from './userReducer';
import userApi from '../../network/api/userApi';
import { IS_CONNECTED } from '../login/loginReducer';
import { notifySuccess } from '../../network/notification';
import { extractErrorFromSigma } from '../../utils/modal';
import { userStatus } from '../../utils/userStatus';
import type { DelegantsInfos, Delegate, TypePersonalData } from '../../types/types';

export const isFromIDNOT = (user: User): boolean => user.idn !== '' && user.idn !== undefined && user.idn !== null;

export const isFromFranceConnect = (user: User): boolean => user.idFC !== '' && user.idFC !== undefined && user.idFC !== null;

export const storeUser = (user: User) => (dispatch: Dispatch, getState: Function) => {
  if (
    user.idn !== getState().user.idn ||
    user.idFC !== getState().user.idFC ||
    user.cguValidated !== getState().user.cguValidated
  ) {
    sessionStorage.setItem('user-cprn', JSON.stringify(user));
    dispatch(store(USER, user));
  }
};

export const storeUserCguValidated = () => (dispatch: Dispatch, getState: Function) => {
  const userStored = getState().user.user;
  dispatch(store(USER,
    {
      ...userStored,
      cguValidated: true,
    }));
};

export const storeUserPersonalData = (user: TypePersonalData) => (dispatch: Dispatch) => {
  dispatch(store(PERSONAL_DATA, user));
};

export const storeUserDelegations = (delegations: Delegate[]) => (dispatch: Dispatch) => {
  dispatch(store(DELEGATIONS, delegations));
};

export const storeUserCollaborators = (collaborators: Delegate[]) =>
  (dispatch: Dispatch, getState: Function) => {
    let delegationIds = [];
    const { delegations } = getState().user;
    if (delegations.length) {
      delegationIds = delegations.map(delegation => delegation.uid);
    }
    const collaboratorsList = collaborators
      .filter((collaborator: Delegate) => !delegationIds.includes(collaborator.uid));
    dispatch(store(COLLABORATORS, collaboratorsList));
  };

export const storeUserHasMultipleRattachements = (hasMultipleRattachements: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(store(HAS_MULTIPLE_RATTACHEMENTS, hasMultipleRattachements));
  };

export const storeUserNotairesDelegantsData = (notairesDelegantsInfos: DelegantsInfos) =>
  (dispatch: Dispatch) => {
    dispatch(store(NOTAIRES_DELEGANTS_DATA, notairesDelegantsInfos));
  };

export const fetchIfUserHasFamilyData = () => (dispatch: Dispatch, getState: Function) => {
  if (getState().user.hasFamilyData === undefined
      && getState().user.user.statut !== userStatus.TIERS_DE_CONFIANCE) {
    userApi
      .hasFamilyData()
      .then((response: Response) => response.text())
      .then((hasFamilyData: string) => {
        dispatch(store(HAS_FAMILY_DATA, hasFamilyData === 'true'));
      });
  }
  if (getState().user.hasFamilyData === undefined
      && getState().user.user.statut === userStatus.TIERS_DE_CONFIANCE) {
    dispatch(store(HAS_FAMILY_DATA, false));
  }
};

export const resetUser = () => (dispatch: Dispatch) => {
  dispatch(store(HAS_FAMILY_DATA, undefined));
  dispatch(store(USER, getInitialStateUser()));
};

export const fetchAndLoginUser = () => (dispatch: Dispatch) => {
  userApi
    .fetchUser()
    .then((response: Response) => response.json())
    .then((user: User) => {
      dispatch(storeUser(user));
      dispatch(store(IS_CONNECTED, true));
      dispatch(fetchIfUserHasFamilyData());
    });
};

export const fetchDelegations = (email: string) => (dispatch: Dispatch) => userApi
  .fetchDelegations(email)
  .then((response: Response) => response.json())
  .then((delegate: Delegate[]) => {
    dispatch(storeUserDelegations(delegate));
  })
  .then(() => dispatch(notifySuccess))
  .catch(extractErrorFromSigma);

export const fetchCollaborators = () => (dispatch: Dispatch) => userApi
  .fetchCollaborators()
  .then((response: Response) => response.json())
  .then((delegates: Delegate[]) => {
    dispatch(storeUserCollaborators(delegates));
  })
  .then(() => dispatch(notifySuccess))
  .catch(extractErrorFromSigma);

export const fetchHasMultipleRattachements = () => (dispatch: Dispatch) => {
  userApi
    .getHasMultipleRattachements()
    .then((response: Response) => response.json())
    .then((hasMultipleRattachements: boolean) => {
      dispatch(storeUserHasMultipleRattachements(hasMultipleRattachements));
    })
    .then(() => dispatch(notifySuccess));
};

// @flow

import React, { memo, useState } from "react";
import { I18n } from "react-redux-i18n";
import type { MenuItem } from "../../utils/menu";
import { menu } from "../../utils/menu";
import MenuBurger from "./MenuBurger";
import PageToBeReleased from "../dashboard/PageToBeReleased";
import type { User } from "../../types/userType";

type Props = {| history: any, location: { pathname: string }, user: User |};

const Menu = ({ location, history, user }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div id="menu">
      <div className="menu-container">
        {user.cguValidated &&
          menu.filter((item: MenuItem) =>
            !item.hideItemFor.includes(user.statut)
          ).map((item: MenuItem) => (
            <div
              onClick={() => {
                if (!item.disabled) {
                  history.push(item.path);
                }
              }}
              key={`menu_${item.path}`}
              className={`menu-item text-uppercase p-relative ${item.color} ${
                location.pathname.includes(item.path) ? "selected" : ""
              } ${item.disabled ? "cursor-initial" : ""}`}
            >
              {item.disabled && (
                <PageToBeReleased
                  message={I18n.t("tooltip.pageToBeReleased")}
                  className="page-to-be-released"
                />
              )}
              {I18n.t(`menu.${item.name}`)}
            </div>
          ))}
      </div>
      {user.cguValidated && (
        <>
          <div
            className="menu-burger-button cursor-pointer"
            onClick={() => setOpen(true)}
          >
            <div className="fa fa-bars icon" />
          </div>
          <MenuBurger open={open} setOpen={setOpen} user={user} />
        </>
      )}
    </div>
  );
};

export default memo<Props>(Menu);

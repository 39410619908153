// @flow

import React, { useCallback, useContext } from 'react';
import Grid from '@material-ui/core/Grid';

import TimelineSmall from '../../components/enrolement/TimelineSmall';
import GenericForm from '../../components/utils/GenericForm';
import { formTranslationType, loginType } from '../../utils/const';
import { validate } from '../../utils/utils';
import type { TypeFormField } from '../../types/formTypes';
import { step1Fields } from '../../utils/form';
import enrolementApi from '../../network/api/enrolementApi';
import { extractErrorFromSigma } from '../../utils/modal';
import type { EnumLogin, TypeEmail } from '../../types/types';
import ModalContext from '../../context/modal/ModalContext';
import { ENROLEMENT_STEP_2_ROUTE } from '../../utils/routes';
import type { ErrorModalType } from '../../utils/modal';

type Props = {|
  handleChangePage: (string) => void,
  connectType: EnumLogin,
  token: string,
  updateMails: (TypeEmail[]) => void,
|};

export type FormType = {|
  nir: string,
  cprn: string,
  zipcode_ID_NOT?: string,
  zipcode_FRANCE_CONNECT?: string,
  notFrance?: boolean,
  country?: string,
|};

const getInitialValues = (login: EnumLogin): FormType => (login === loginType.ID_NOT
  ? {
    nir: '',
    cprn: '',
    zipcode_ID_NOT: '',
    zipcode_FRANCE_CONNECT: undefined,
  }
  : {
    nir: '',
    cprn: '',
    zipcode_ID_NOT: undefined,
    zipcode_FRANCE_CONNECT: '',
    notFrance: false,
    country: '',
  });

const cleanString = (str: string): string => (str ? str.replace(/\s|_/g, '') : '');

const onSubmit = (
  values: FormType,
  handleChangePage: (string) => void,
  token: string,
  openErrorModal: (ErrorModalType) => void,
  updateMails: (TypeEmail[]) => void,
) => {
  const {
    nir, cprn, zipcode_ID_NOT, zipcode_FRANCE_CONNECT, notFrance, country, ...rest
  } = values;
  enrolementApi
    .postFirstStep({
      nir: cleanString(nir),
      cprn: cleanString(cprn),
      zipcode:
        zipcode_ID_NOT !== undefined
          ? cleanString(zipcode_ID_NOT)
          : (!notFrance ?
            cleanString(zipcode_FRANCE_CONNECT) :
            undefined),
      token,
      country: notFrance ? country : undefined,
      notFrance,
      ...rest,
    })
    .then((response: Response) => response.json())
    .then((emailTypes: TypeEmail[]) => {
      updateMails(emailTypes);
      handleChangePage(ENROLEMENT_STEP_2_ROUTE);
    })
    .catch(extractErrorFromSigma(openErrorModal));
};

const Step1 = ({
  handleChangePage,
  token,
  connectType,
  updateMails,
}: Props) => {
  const { openErrorModal } = useContext(ModalContext);

  return (
    <>
      <TimelineSmall active={1} />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="mb-0"
      >
        <GenericForm
          className="mt-3"
          initialValues={getInitialValues(connectType)}
          translationBase={formTranslationType.enrolement}
          fields={step1Fields.filter((field: TypeFormField) => {
            if (field.conditionedBy) {
              return field.conditionedBy === connectType;
            }
            return true;
          })}
          validate={useCallback(
            (values: TypeFormField[]) => {
              validate(step1Fields, values, connectType);
            },
            [connectType],
          )}
          onSubmit={useCallback(
            values =>
              onSubmit(
                values,
                handleChangePage,
                token,
                openErrorModal,
                updateMails,
              ),
            [handleChangePage, openErrorModal, token, updateMails],
          )}
        />
      </Grid>
    </>
  );
};

export default Step1;

// @flow

import React, { memo, useContext, useState } from "react";
import { I18n } from "react-redux-i18n";
import moment, { Moment } from "moment";
import { connect } from "react-redux";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import { SERVICE_HOME_ROUTE } from "../../utils/routes";
import Bloc from "../../components/accueil/Bloc";
import CustomButton from "../../components/buttons/CustomButton";
import InternalPage from "../../components/utils/pages/InternalPage";
import demandesApi from "../../network/api/demandesApi";
import { download } from "../../utils/utils";
import LoadingContext from "../../context/loader/LoadingContext";
import { extractErrorFromSigma } from "../../utils/modal";
import ModalContext from "../../context/modal/ModalContext";

type Props = {|
  history: Object,
  dispatch: Function,
|};

const CertificateRetirementPayment = ({ history, dispatch }: Props) => {
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const { openErrorModal } = useContext(ModalContext);
  const now = moment();
  const [year, setYear] = useState<Moment>(now);

  const getMessage = (name: string) =>
    I18n.t(`service.certificateRetirementPayment.${name}`);

  const isValid = (date: Moment) => date.year() <= now.year();

  const onSubmit = () => {
    showLoading();
    demandesApi
      .getCertificateRetirementPayment(year.year())
      .then(res => res.blob())
      .then((document: Blob) => {
        download(
          document,
          I18n.t("service.retreat.filename2", {
            date: year.year(),
          }),
        );
      })
      .catch(extractErrorFromSigma(openErrorModal, dispatch))
      .finally(closeLoading);
  };

  return (
    <InternalPage
      menu={I18n.t("service.title")}
      color="yellow"
      hasBackButton
      handleClickBack={() => history.push(SERVICE_HOME_ROUTE)}
    >
      <div
        id="certificate-retirement"
        className="bloc-container mt-1 pb-5 yellow"
      >
        <Bloc title={getMessage("title")} color="yellow">
          <p className="text-justify">{getMessage("text")}</p>
          <div className="form m-auto mt-2 size-l">
            <div className="crp-calendar field">
              <label className="label">Sélectionnez une année : </label>
              <Datetime
                className="yearPicker"
                id="date"
                dateFormat="YYYY"
                locale="fr-fr"
                value={year}
                onChange={setYear}
                closeOnSelect
                isValidDate={isValid}
              />
            </div>
          </div>
          <CustomButton
            color="yellow"
            disabled={!year || !moment.isMoment(year) || !isValid(year)}
            onClick={onSubmit}
            message={getMessage("button")}
          />
        </Bloc>
      </div>
    </InternalPage>
  );
};

export default connect()(memo<Props>(CertificateRetirementPayment));

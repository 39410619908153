// @flow

import React, { useEffect } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { I18n } from "react-redux-i18n";
import {
  cotisationDataList,
  NAVIGATION_RIGHTS,
  personalDataList,
  professionalDataList,
  retirementDataList,
  tierProfessionalDataList,
} from '../../utils/const';
import Bloc from "../../components/accueil/Bloc";
import type { User } from "../../types/userType";
import { FAMILY_DATA_ROUTE } from "../../utils/routes";
import { fetchIfUserHasFamilyData } from "../../state/user/userService";
import InternalPage from "../../components/utils/pages/InternalPage";
import {canSeeFamilyData} from "../../services/permissionService";
import Container from '@material-ui/core/Container';

type Props = {|
  history: Object,
  user: User,
  dispatch: Function,
  hasFamilyData: boolean,
|};

const DataPage = ({
  history, user, dispatch, hasFamilyData,
}: Props) => {
  useEffect(() => {
    dispatch(fetchIfUserHasFamilyData());
  }, [dispatch]);

  const myPersonalDataList = [
    {
      name: "personal",
      color: "yellow",
      linkedList: personalDataList,
    },
    {
      name: "family",
      color: "cobalt",
      linkedList: [
        {
          label: "personalData.familyData.consult",
          icone: "",
          right: NAVIGATION_RIGHTS.FAMILY,
          route: FAMILY_DATA_ROUTE,
          noData: !canSeeFamilyData(hasFamilyData, user.statut),
        },
      ],
    },
    {
      name: "professional",
      color: "blue",
      linkedList: professionalDataList,
    },
    {
      name: "professional",
      color: "blue",
      linkedList: tierProfessionalDataList,
    },
    {
      name: "retreatment",
      color: "blue",
      linkedList: retirementDataList,
    },
    {
      name: "cotisation",
      color: "yellow",
      linkedList: cotisationDataList,
    },
  ];

  return (
    <InternalPage menu={I18n.t("dashboard.personalData.title")} color="cobalt">
      <Container maxWidth="md" className="mt-2">
        <Grid
          className="bloc-container"
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          {myPersonalDataList.map(item => (
            <>
              {item.linkedList[0] && item.linkedList[0].right.find(right => right.toString() === user.statut.toString()) && (
                <Grid key={item.name} item xs className="grid-style">
                  <Bloc
                    color={item.color}
                    title={I18n.t(`personalData.dataPage.${item.name}`)}
                    linkedList={item.linkedList}
                    status={user.statut}
                  />
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </Container>
    </InternalPage>
  );
};

export default connect(state => ({
  user: state.user.user,
  hasFamilyData: state.user.hasFamilyData,
}))(DataPage);

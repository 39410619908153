// @flow

import { RestClientAuthenticated } from '../network';

const baseUrl = '/fiscal';

const paymentApi = {
  getFiscalInformations: () => new RestClientAuthenticated(`${baseUrl}`, 'GET').execute(),
  getFiscalAttestations: (year: string) => new RestClientAuthenticated(`${baseUrl}/Attestation/Pdf?year=${year}`, 'GET').execute(),
};

export default paymentApi;

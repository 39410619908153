// @flow

import React, { useState } from 'react';
import type { ConjointCollaborateur, SelectedNotaire } from '../../types/types';
import { personaType } from '../../utils/enum';
import SelectedPersonaTile from './components/SelectedPersonaTile';

type Props = {|
  selectedPersonas: SelectedNotaire[],
  onClickNotaire: (persona: SelectedNotaire | ConjointCollaborateur, isConjoint?: boolean) => void,
  isGreyDiv?: boolean,
  isCotisationData?: boolean,
|}

const NotairesFilter = ({
  selectedPersonas, onClickNotaire, isGreyDiv, isCotisationData,
}: Props) => {
  // nbNotaireToDisplayMobile et nbNotaireToDisplayDesktop présent pour un affichage correct
  // en mobile tout en gardant le display en row et pas en colonne
  const [touchpadWidth, nbNotaireToDisplayMobile, nbNotaireToDisplayDesktop] = [769, 2, 5];
  const maxNotairesCurrentScreenWidth = window.innerWidth < touchpadWidth
    ? nbNotaireToDisplayMobile
    : nbNotaireToDisplayDesktop;
  const [notaireStartIndex, setNotaireStartIndex] = useState<number>(0);

  const swipeLeft = () => {
    setNotaireStartIndex(notaireStartIndex - 1);
  };

  const swipeRight = () => {
    setNotaireStartIndex(notaireStartIndex + 1);
  };

  const buildNotairesSelect = () => {
    const profileList = [];
    // counts the number of displayed profiles
    let totalPersonaCount = 0;
    let index = notaireStartIndex;
    while (totalPersonaCount < maxNotairesCurrentScreenWidth &&
      index < selectedPersonas.length &&
      // checks if by adding a notaire with a conj coll it won't exceed the
      // amount of profiles displayable
      !(selectedPersonas[index].conjointCollaborateur &&
        totalPersonaCount + 2 > maxNotairesCurrentScreenWidth
      )
    ) {
      profileList.push(selectedPersonas[index]);
      if (selectedPersonas[index].conjointCollaborateur) {
        totalPersonaCount += 2;
      } else {
        totalPersonaCount += 1;
      }
      index += 1;
    }
    return profileList;
  };

  const profilesList = buildNotairesSelect();

  // checks if the last profile is currently being displayed
  const isAtEndOfNotaireArray = !!profilesList
    .find((notaire: SelectedNotaire) => notaire.numpers
      === selectedPersonas[selectedPersonas.length - 1].numpers);

  const showNavigation = profilesList.length < selectedPersonas.length;

  return (
    <div className={`notaires-filter${isGreyDiv ? ' grey' : ''}${isCotisationData ? ' cotisation-data' : ''}`}>
      {
        // displays the arrow if it's not the last element of the array
        showNavigation && notaireStartIndex > 0 &&
        <div className="arrow arrow-blue to-left">
          <i className="fa fa-2x fa-chevron-left" onClick={swipeLeft} />
        </div>
      }
      <div className="notaires">
        { profilesList.map((persona: SelectedNotaire) =>
          (
            <>
              <SelectedPersonaTile
                persona={persona}
                onClickNotaire={(selectedPersona: SelectedNotaire) => onClickNotaire(
                  selectedPersona,
                  false,
                )}
                typePersona={personaType.NOTAIRE}
              />
              {persona.conjointCollaborateur
                && (
                  <>
                    <hr className="conjoint-coll-line" />
                    <SelectedPersonaTile
                      persona={persona.conjointCollaborateur}
                      onClickNotaire={(selectedConjoint: ConjointCollaborateur) => onClickNotaire(
                        selectedConjoint,
                        true,
                      )}
                      typePersona={personaType.CONJOINT_COLLABORATEUR}
                    />
                  </>
                )
              }
            </>
          ))
        }
      </div>
      {
        // displays the arrow if it's not the last element of the array
        showNavigation && !isAtEndOfNotaireArray &&
        <div className="arrow to-right arrow-blue">
          <i className="fa fa-2x fa-chevron-right" onClick={swipeRight} />
        </div>
      }
    </div>
  );
};

export default NotairesFilter;

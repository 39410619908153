// @flow

import React, { memo } from 'react';
import { colors } from '../../utils/enum';

type Props = {
  message: string,
  onClick?: Function,
  className?: string,
  buttonClassName?: string,
  type?: string,
  disabled?: boolean,
  color?: string,
  form?: string,
  icon?: string,
};

function CustomButton({
  message,
  onClick,
  type,
  className = '',
  buttonClassName,
  disabled = false,
  color = colors.COBALT,
  form,
  icon,
}: Props) {
  return (
    <div className={`btn-custom ${className}`}>
      {icon && <div className={icon} />}
      <button
        className={`${disabled ? 'disabled' : 'ripple'} --${color} ${buttonClassName} custom-button`}
        onClick={onClick}
        type={type}
        disabled={disabled}
        form={form}
      >
        {message}
      </button>
    </div>
  );
}

export default memo<Props>(CustomButton);

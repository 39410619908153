// @flow

import React from "react";

const MentionLegalesContainer = () => {
  return (
    <div className="cgu-container">
      <h2>Caisse de Prévoyance et de Retraite des Notaires</h2>
      <br />
      43, avenue Hoche
      <br />
      75008 Paris
      <br />
      Tél : 01 53 81 75 00 / Fax : 01 45 72 09 05
      <br />
      SIREN : 784 338 899
      <br />
      <br />
      Directeur de la publication : Jean-Paul Muller, Directeur Général.
      <br />
      Ce site est édité et hébergé par la Caisse des Dépôts et de Consignation
      dont le siège social est situé : 57 rue de Lille 75007 PARIS <br />
      <br />
      Conformément aux dispositions de la loi Informatique et Libertés du 6
      janvier 1978, toutes les données informatiques vous concernant sont
      traitées de manière strictement confidentielle.
      <br />
      Vous disposez d'un droit d'accès, de rectification, et de suppression des
      données qui vous concernent. Pour l'appliquer, il vous suffit de nous en
      faire la demande par courrier en nous indiquant vos nom, prénom et
      adresse.
      <br />
      Toute représentation ou reproduction non autorisée par la CPRN, des
      informations et des documents de toute nature contenus dans le présent
      site, par quelque moyen et sur quelque support que ce soit, est
      strictement interdite sous peine de poursuites judiciaires.
      <br />
      Dans l'hypothèse où un utilisateur souhaiterait utiliser un des contenus
      du site (texte, image...), il s'engage à requérir l'autorisation préalable
      et écrite de la CPRN. Les informations techniques qui se trouvent sur le
      site de la CPRN n'ont qu'une valeur informative et sont susceptibles
      d'évoluer en fonction des modifications législatives et réglementaires. La
      CPRN ne peut être tenue responsable de l'interprétation que vous pourriez
      faire des informations présentées sur son site internet.
      <br />
      Toute personne (physique ou morale) souhaitant créer un lien avec le site
      de la CPRN, doit préalablement obtenir une autorisation expresse et écrite
      de la CPRN.
      <br />
      Afin de garantir l'identité et l'intégrité de son site, la CPRN se réserve
      le droit d'interdire les liens qui ne répondraient pas à l'objet desdits
      sites ou qui pourraient porter atteinte à l'image de l'institution.
      <br />
      En utilisant la messagerie mise à votre disposition sur notre site, vous
      vous engagez formellement à ne pas nous communiquer de fausses
      informations, voire utiliser l'identité d'un tiers dans le but d'obtenir
      illégalement et indûment un droit ou une prestation, ou des renseignements
      relatifs à un tiers.
      <br />
      Dans le cas contraire, vous vous exposeriez à des sanctions pénales,
      notamment à celles prévues aux articles L 623- 1, L.377-1 et L.377-2 du
      code de la sécurité sociale.
      <br />
      Lorsque vous sélectionnez un lien vers un autre site, vous quittez le site
      de la Caisse de Prévoyance et de Retraite des Notaires (CPRN). La CPRN ne
      peut être tenue responsable du contenu des sites dont elle présente les
      références, et dont elle n'est pas l'auteur.
    </div>
  );
};

export default MentionLegalesContainer;

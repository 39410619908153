// @flow

import { CoreozDatePickerMaterial } from 'coreoz-form-base';
import { FormApi } from 'final-form';
import moment, { Moment } from 'moment';
import React, { useRef, useState } from 'react';
import { FieldRenderProps, Field, Form } from 'react-final-form';

import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { DropdownFieldForm } from '../../pages/enrolement/forms/components/DropdownFieldForm';
import { RequiredFieldLabel } from '../../pages/enrolement/forms/components/RequiredFieldLabel';
import type { TypeDataUnit } from '../../types/types';
import { labelType, momentFormat } from '../../utils/const';
import { genreList } from '../../utils/data';
import { optionsTypeUnion } from '../../utils/form';
import useToggle from '../../utils/useToggle';
import { getLabel } from '../../utils/utils';
import {
  checkBirthDate,
  checkEmptyField,
  checkFieldLength,
  fieldRequired,
  hasOptionalFieldChanged,
  isValidDateFormat,
} from '../../utils/validator';
import CustomButton from '../buttons/CustomButton';
import { ConfirmModal } from '../modaleNotification/ConfirmModal';

type PastUnionTileProps = {
  index: number,
  onSubmitPastUnion: (TypeDataUnit) => void,
  union: TypeDataUnit,
  pastUnionNumber: number,
  className?: string,
  disabled?: boolean,
  disabledFields?: boolean,
  translationBase: string,
};

const PastUnionTile = ({
  index,
  onSubmitPastUnion,
  union,
  pastUnionNumber,
  className,
  disabled,
  disabledFields,
  translationBase,
}: PastUnionTileProps) => {
  const [isFormModified, setIsFormModified] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [confirmPastUnionEditModal, toggleConfirmPastUnionEditModal] = useToggle();
  const [pastUnionValuesToSave, setPastUnionValuesToSave] = useState<TypeDataUnit>();
  const formRef = useRef<FormApi>();

  const isDirty = () => {
    // Use a custom isDirty to handle string / moment comparison for dates
    if (formRef && formRef.current) {
      const { values } = formRef.current.getState();
      return union.sexe !== values.sexe ||
        union.nom !== values.nom ||
        hasOptionalFieldChanged(union.nomJF, values.nomJF) ||
        union.prenom !== values.prenom ||
        union.typeUnion !== values.typeUnion ||
        !moment(union.dteNaiss, momentFormat.DATE)
          .isSame(moment(values.dteNaiss, momentFormat.DATE));
    }
    return false;
  };

  const updateFormModified = () => setIsFormModified(isDirty());

  return (
    <div
      key={union.numpers ? union.numpers : union.numDF}
      className={`${className ? `${className} ` : ''}mb-1 size-2${disabled ? ' disabled' : ''}`}
    >
      {confirmPastUnionEditModal && (
        <ConfirmModal
          isOpen={confirmPastUnionEditModal}
          close={toggleConfirmPastUnionEditModal}
          headerTitle={I18n.t('personalData.personalData.validationModal.title')}
          onSubmit={() => onSubmitPastUnion(pastUnionValuesToSave)}
        >
          <div className="modal-body">
            {I18n.t('personalData.personalData.validationModal.body')}
          </div>
        </ConfirmModal>
      )}
      <Form
        initialValues={union}
        onSubmit={(values: TypeDataUnit) => {
          if (isDirty()) {
            setIsFormModified(true);
            setPastUnionValuesToSave(values);
            toggleConfirmPastUnionEditModal();
          }
          setHasSubmitted(true);
        }}
        render={({ handleSubmit, form }: FieldRenderProps) => {
          formRef.current = form;
          return (
            <>
              <form
                onSubmit={handleSubmit}
                onChange={updateFormModified}
                id={`past-union-${index}`}
                className="past-union-form"
              >
                <div className="row past-union-header">
                  <h3>{I18n.t(`${translationBase}unions.label`, { count: pastUnionNumber })}</h3>
                  <h4 className="union-period">{I18n.t(`${translationBase}conjoint.unionPeriod.label`,
                    {
                      dateUnion: union.dteUnion,
                      dateFinUnion: union.dteFinUnion
                        ? union.dteFinUnion
                        : union.dteDeces,
                    })}
                  </h4>
                </div>
                <div className="row">
                  <div className={`past-union-container${disabledFields ? ' margin-read-only' : ''}`}>
                    <div className="column">
                      <DropdownFieldForm
                        className="past-union-data"
                        name="sexe"
                        label="conjoint.genre"
                        options={genreList}
                        mandatoryField
                        translationBase={translationBase}
                        disabledFields={disabledFields}
                      />
                      <Field
                        name="nom"
                        validate={(nom: string) =>
                          fieldRequired(nom) ||
                          checkEmptyField(nom) ||
                          checkFieldLength(nom)
                        }
                        render={({ input, meta }: FieldRenderProps) => (
                          <div className="past-union-data">
                            <label
                              htmlFor="nom"
                              id="nom"
                            >
                              <RequiredFieldLabel
                                label={getLabel(translationBase, 'conjoint.usageName', labelType.label)}
                                isDisabled={disabledFields}
                              />
                              <div
                                className={`field field-value${
                                  meta.touched && meta.error ? ' error' : ''
                                }`}
                              >
                                <input
                                  {...input}
                                  className="character"
                                  disabled={disabledFields}
                                />
                              </div>
                              {meta.touched && meta.error && (
                                <div className="error-message color-red">{meta.error}</div>
                              )}
                            </label>
                          </div>
                        )}
                      />
                      <Field
                        name="nomJF"
                        validate={(nomJF: string) => {
                          if (nomJF != null) {
                            return checkEmptyField(nomJF) || checkFieldLength(nomJF);
                          }
                          return undefined;
                        }}
                        render={({ input, meta }: FieldRenderProps) => (
                          <div className="past-union-data">
                            <label
                              className="union-field-label"
                              htmlFor="nomJF"
                              id="nomJF"
                            >
                              <span className={`field-label${disabledFields ? ' disabled' : ''}`}>
                                {getLabel(translationBase, 'conjoint.birthName', labelType.label)}
                              </span>
                              <div
                                className={`field field-value ${
                                  meta.touched && meta.error ? 'error' : ''
                                }`}
                              >
                                <input
                                  {...input}
                                  className="character union-field"
                                  disabled={disabledFields}
                                />
                              </div>
                              {meta.touched && meta.error && (
                                <div className="error-message color-red">{meta.error}</div>
                              )}
                            </label>
                          </div>
                        )}
                      />
                      <Field
                        name="prenom"
                        validate={(prenom: string) =>
                          fieldRequired(prenom) ||
                          checkEmptyField(prenom) ||
                          checkFieldLength(prenom)
                        }
                        render={({ input, meta }: FieldRenderProps) => (
                          <div className="past-union-data">
                            <label
                              htmlFor="prenom"
                              id="prenom"
                            >
                              <RequiredFieldLabel
                                label={getLabel(translationBase, 'conjoint.firstName', labelType.label)}
                                isDisabled={disabledFields}
                              />
                              <div
                                className={`field field-value${
                                  meta.touched && meta.error ? ' error' : ''
                                }`}
                              >
                                <input
                                  {...input}
                                  className="character union-field"
                                  disabled={disabledFields}
                                />
                              </div>
                              {meta.touched && meta.error && (
                                <div className="error-message color-red">{meta.error}</div>
                              )}
                            </label>
                          </div>
                        )}
                      />
                    </div>
                    <div className="column">
                      <Field
                        name="dteNaiss"
                        validate={(birthdate: Moment | string) =>
                          fieldRequired(birthdate) ||
                          isValidDateFormat(birthdate) ||
                          checkBirthDate(moment(birthdate, momentFormat.DATE),
                            formRef.current.getState().values.dteUnion)
                        }
                        render={({ input, meta }: FieldRenderProps) => (
                          <div className="past-union-data">
                            <label
                              htmlFor="dteNaiss"
                              id="dteNaiss"
                            >
                              <RequiredFieldLabel
                                label={getLabel(translationBase, 'conjoint.birthDate', labelType.label)}
                                isDisabled={disabledFields}
                              />
                              <div
                                className={`date-picker-field${disabledFields ? ' unset-bg' : ''}${
                                  meta.touched && meta.error ? ' error' : ''
                                }`}
                              >
                                <div className={`fa fa-calendar-alt calendar-icon${disabledFields ? ' disabled' : ''}`} />
                                <CoreozDatePickerMaterial
                                  input={input}
                                  meta={{
                                    ...meta,
                                    error: undefined,
                                  }}
                                  onlyDate
                                  onChange={updateFormModified}
                                  dateFormat={momentFormat.DATE}
                                  disabled={disabledFields}
                                  className={`datepicker${disabledFields ? ' disabled' : ''}`}
                                  selected={input.value}
                                  locale="fr-FR"
                                />
                              </div>
                              {meta.touched && meta.error && (
                                <div className="error-message color-red">{meta.error}</div>
                              )}
                            </label>
                          </div>
                        )}
                      />
                      <DropdownFieldForm
                        className="past-union-data"
                        name="typeUnion"
                        label="conjoint.unionType"
                        options={optionsTypeUnion}
                        mandatoryField
                        translationBase={translationBase}
                        disabledFields={disabledFields}
                      />
                    </div>
                  </div>
                  <div className="save-button-container">
                    {!isFormModified && hasSubmitted &&
                      <div className="no-modification-error-text">
                        <p>{I18n.t('form.noModification')}</p>
                      </div>
                    }
                    {!disabledFields && (
                      <CustomButton
                        type="submit"
                        buttonClassName="save-button-placement"
                        form={`past-union-${index}`}
                        message={I18n.t(`${translationBase}button`)}
                      />
                    )}
                  </div>
                </div>
              </form>
            </>
          );
        }}
      />
    </div>
  );
};
export default withRouter(PastUnionTile);

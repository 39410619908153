// @flow

import React, { memo } from "react";
import { Translate } from "react-redux-i18n";
import type { Color } from "../../types/types";

type Props = {|
  message: string,
  color: Color,
  className?: string,
|};

const SubPageTitle = ({ message, color, className = "" }: Props) => {
  return (
    <div className="title">
      <div className={`app-title ${color} ${className}`}>
        <Translate value="my" />
        <h2>{message}</h2>
      </div>
    </div>
  );
};

export default memo<Props>(SubPageTitle);

// @flow

import React, { Fragment, memo } from 'react';
import { Field } from 'react-final-form';

const CustomSelectNew = ({
  label, name, disabled, important, options, validate, preferedOptions, asterisk, required, id,
}: Props) => (
  <div className="field" id={id}>
    <div className={`label${important ? ' important' : important}${(required || asterisk) ? ' asterisk' : ''}`}>{label}</div>
    <Field
      name={name}
      type="select"
      component="select"
      disabled={disabled}
      validate={validate}
    >
      {({ input, meta }) => (
        <Fragment>
          <select
            {...input}
            disabled={disabled}
            className="input select"
          >
            {preferedOptions && preferedOptions.map((option: {value: string, label: string}) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
            <br />
            {options.map((option: {value: string, label: string}) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div className="input">
            <div className="error-field">
              {meta.error && meta.touched && <div className="error-message">{meta.error}</div>}
            </div>
          </div>
        </Fragment>
      )}
    </Field>
  </div>
);

export default memo<void>(CustomSelectNew);

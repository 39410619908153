// @flow

import React, { memo, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  COTISATION_DATA_ROUTE,
  DASHBOARD_ROUTE,
  FAMILY_DATA_ROUTE,
  PERSONAL_DATA_HOME_ROUTE,
  PERSONAL_DATA_ROUTE,
  PROFESSIONAL_DATA_ROUTE,
  RETREAT_DATA_ROUTE,
  TIER_PROFESSIONAL_DATA_ROUTE,
} from '../../utils/routes';
import PersonalDataHome from './DataPage';
import PersonalData from './PersonalData';
import FamilyData from './FamilyData';
import ProfessionalData from './ProfessionalData';
import ProfessionalDataTier from './ProfessionalDataTier';
import RetreatData from './RetreatData';
import CotisationData from './CotisationData';
import { fetchIfUserHasFamilyData } from '../../state/user/userService';
import { connect } from 'react-redux';

type Props = {|
    dispatch: Function,
|};

const PersonalDataRoutes = ({dispatch}) => {
  useEffect(() => {
    dispatch(fetchIfUserHasFamilyData());
  }, [dispatch]);
  return (
    <div className="data-container">
      <Switch>
        <Route
          exact
          path={PERSONAL_DATA_HOME_ROUTE}
          component={PersonalDataHome}
        />
        <Route exact path={PERSONAL_DATA_ROUTE} component={PersonalData} />
        <Route exact path={FAMILY_DATA_ROUTE} component={FamilyData} />
        <Route
          exact
          path={PROFESSIONAL_DATA_ROUTE}
          component={ProfessionalData}
        />
        <Route
          exact
          path={TIER_PROFESSIONAL_DATA_ROUTE}
          component={ProfessionalDataTier}
        />
        <Route exact path={RETREAT_DATA_ROUTE} component={RetreatData} />
        <Route exact path={COTISATION_DATA_ROUTE} component={CotisationData} />
        <Route path="*" render={() => <Redirect to={DASHBOARD_ROUTE} />} />
      </Switch>
    </div>
  );
};

export default connect()(memo(PersonalDataRoutes));

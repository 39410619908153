// @flow

import { createContext } from "react";
import type {
  ErrorModalType,
  HelperModalType,
  ModalType,
} from "../../utils/modal";

type ModalContextType = {|
  isOpen: boolean,
  typeModal?: ModalType,
  errorCode?: ErrorModalType,
  source?: string,
  token?: string,
  statusArguments?: string[],
  helpPage?: HelperModalType,
  openErrorModal: Function,
  openHelperModal: Function,
  closeModal: Function,
|};

const ModalContext = createContext<ModalContextType>({
  isOpen: false,
  typeModal: undefined,
  errorCode: undefined,
  source: undefined,
  token: undefined,
  statusArguments: [],
  helpPage: undefined,
  openErrorModal: (errorCode: ErrorModalType) => {},
  openHelperModal: (helpPage: HelperModalType) => {},
  closeModal: () => {},
});

export default ModalContext;

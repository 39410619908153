// @flow

import React from 'react';

type RequiredFieldLabelProps = {
  label: string,
  isDisabled: boolean,
};

export const RequiredFieldLabel = ({ label, isDisabled }: RequiredFieldLabelProps) => (
  <span className={`field-label${isDisabled ? ' disabled' : ''}`}>
    {label}
    <span className="fas fa-asterisk" />
  </span>
);

// @flow

import { RestClientAuthenticated } from '../network';
import { userStatus } from '../../utils/userStatus';

const baseUrl = '/alerte';

const alerteApi = {
  fetch: (status) => {
    const isTier = status === userStatus.TIERS_DE_CONFIANCE;
    return new RestClientAuthenticated(`${baseUrl}?isTier=${isTier}`, 'GET').execute();
  },
};

export default alerteApi;

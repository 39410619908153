// @flow

import React, { memo, useContext } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import 'react-datetime/css/react-datetime.css';

import { SERVICE_HOME_ROUTE } from '../../utils/routes';
import Bloc from '../../components/accueil/Bloc';
import CustomButton from '../../components/buttons/CustomButton';
import InternalPage from '../../components/utils/pages/InternalPage';
import { download } from '../../utils/utils';
import LoadingContext from '../../context/loader/LoadingContext';
import { extractErrorFromSigma } from '../../utils/modal';
import ModalContext from '../../context/modal/ModalContext';
import recipientApi from '../../network/api/recipientApi';

type Props = {|
  history: History,
  dispatch: Dispatch,
|};

const RecipientInfo = ({ history, dispatch }: Props) => {
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const { openErrorModal } = useContext(ModalContext);

  const getMessage = (name: string) => I18n.t(`service.recipientInfo.${name}`);

  const onSubmit = () => {
    showLoading();
    recipientApi
      .getRecipientInfo()
      .then((response: Response) => response.blob())
      .then((document: Blob) => {
        download(
          document,
          // TODO : filename
          'infoAllocataire.pdf',
        );
      })
      .catch(extractErrorFromSigma(openErrorModal, dispatch))
      .finally(closeLoading);
  };

  return (
    <InternalPage
      menu={I18n.t('service.title')}
      color="yellow"
      hasBackButton
      handleClickBack={() => history.push(SERVICE_HOME_ROUTE)}
    >
      <div
        id="certificate-retirement"
        className="bloc-container mt-1 pb-5 yellow"
      >
        <Bloc title={getMessage('title')} color="yellow">
          <p className="text-justify">{getMessage('text')}</p>
          <CustomButton
            color="yellow"
            onClick={onSubmit}
            message={getMessage('button')}
          />
        </Bloc>
      </div>
    </InternalPage>
  );
};

export default connect()(memo<Props>(RecipientInfo));

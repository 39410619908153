// @flow

import React, { useContext, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import CustomButton from "../buttons/CustomButton";
import Modal from "./Modal";
import { ErrorModalEnum, ModalTypeEnum } from "../../utils/modal";
import type { ErrorModalType } from "../../utils/modal";
import ModalContext from "../../context/modal/ModalContext";
import loggerApi from "../../network/api/loggerApi";
import { contactUrl } from "../../utils/const";
import Idnot from "../buttons/Idnot";
import FranceConnect from "../buttons/FranceConnect";

const getButtons = (
  errorCode: ErrorModalType,
  closeModal: Function,
): React.ReactNodeArray => {
  if (errorCode === ErrorModalEnum.ERR_IDENTITE) {
    // To implement
    return [<></>];
  } else if (errorCode === ErrorModalEnum.ERR_MAUVAIS_MODE_FC) {
    return [<Idnot classname="modal-button-connect" />];
  } else if (errorCode === ErrorModalEnum.ERR_MAUVAIS_MODE_IDNOT) {
    return [<FranceConnect classname="modal-button-connect" />];
  } else {
    return [
      <CustomButton
        message={I18n.t("contactUs")}
        onClick={() => {
          closeModal();
          window.open(contactUrl, "_blank");
        }}
      />,
    ];
  }
};

const canOpen = (isOpen, typeModal) => {
  return isOpen && typeModal === ModalTypeEnum.ERROR;
};

const toMessage = (statusArguments?: string[] = []) => {
  const args = {};
  statusArguments.forEach((value, key) => {
    const label = I18n.t(value);
    if (label) {
      args[`field${key}`] = label;
    } else {
      args[`field${key}`] = value;
    }
  });
  return args;
};

function ErrorModal() {
  const {
    isOpen,
    typeModal,
    errorCode = ErrorModalEnum.INTERNAL_ERROR,
    statusArguments = [],
    closeModal,
  } = useContext(ModalContext);

  useEffect(() => {
    if (canOpen(isOpen, typeModal)) {
      loggerApi.addErrorModal(errorCode);
    }
  }, [isOpen]);

  if (!canOpen(isOpen, typeModal)) {
    return <></>;
  }
  return (
    <Modal
      isOpen={isOpen}
      close={closeModal}
      headerTitle={I18n.t(`wsError.${errorCode}.title`)}
      buttons={getButtons(errorCode, closeModal)}
    >
      <div className="modal-body">
        {I18n.t(`wsError.${errorCode}.content`, toMessage(statusArguments))}
      </div>
    </Modal>
  );
}

export default ErrorModal;

// @flow
import React, { memo } from 'react';

type Props = {
  color: string,
  name: string,
  onClick: () => void,
  logo: string,
  documentLogoIsAfter: boolean,
  disabled: boolean,
};

const FileSquare = ({
  color,
  name,
  onClick,
  logo,
  documentLogoIsAfter,
  disabled,
}: Props) => {
  const disabledClassName = disabled ? '--disabled' : color;
  return (
    <div className={`${disabledClassName} square-file-bloc`}>
      {!documentLogoIsAfter && (
        <div className={`image ${disabledClassName} file-square`}>
          <div className={`${logo} icon-image`} />
        </div>
      )}
      <div
        onClick={!disabled && onClick}
        className={`file-square d-flex cursor ${disabledClassName}`}
      >
        <div className="text-icon">{name}</div>
      </div>
      {documentLogoIsAfter && (
        <div className={`image ${disabledClassName} file-square`}>
          <div className={`${logo} icon-image`} />
        </div>
      )}
    </div>
  );
};

export default memo<Props>(FileSquare);

// @flow

import React from "react";

type Props = {
  condition: boolean,
  wrappers: Function[],
  children: any,
};

const ConditionalWrapper = ({ condition, wrappers, children }: Props) => {
  if (wrappers.length > 1) {
    return condition ? wrappers[0](children) : wrappers[1](children);
  }
  return condition ? wrappers[0](children) : children;
};

export default ConditionalWrapper;

// @flow

import React, { memo } from "react";
import { I18n } from "react-redux-i18n";
import { SAML_URL } from "../../utils/const";

type Props = {
  classname: ?string,
};

function connect() {
  window.location.href = SAML_URL;
}

const Idnot = ({ classname = "" }: Props) => (
  <div className={`btn-connect-container ${classname}`}>
    <div className="btn-idnot" onClick={connect}>
      <div className="logo" />
      <div className="text-container">
        <div className="text">{I18n.t("actions.IDENTIFY")}</div>
        <div className="text text-bold">{I18n.t("actions.ID_NOT")}</div>
      </div>
    </div>
  </div>
);

export default memo<void>(Idnot);

// @flow

import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Container from '@material-ui/core/Container';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Title from '../../components/utils/Title';
import Back from '../../components/buttons/Back';
import { HOME_PAGE_ROUTE } from '../../utils/routes';
import type { ConjointCollaborateur, DelegantsInfos, SelectedNotaire } from '../../types/types';
import { DelegantDocument, DelegantDocumentFilter } from '../../types/types';
import tierApi from '../../network/api/tierApi';
import { storeUserNotairesDelegantsData } from '../../state/user/userService';
import { extractErrorFromSigma } from '../../utils/modal';
import ModalContext from '../../context/modal/ModalContext';
import FileItem from './FileItem';
import LoadingContext from '../../context/loader/LoadingContext';
import NotairesFilter from './NotairesFilter';
import { docTypesName, filterTypes } from '../../utils/const';

type Props = {|
  history: Object,
  notaireDelegantsData: DelegantsInfos,
  dispatch: Function,
|};

const TierFilePage = ({ history, notaireDelegantsData, dispatch }: Props) => {
  const [itemPerBatch, uniqueDelegant] = [20, 1];
  const [loadingMessage, timeoutDuration] = [null, 10];
  const [selectedPersonas, setSelectedPersonas] = useState<SelectedNotaire[]>([]);
  const [documents, setDocuments] = useState<DelegantDocument[]>([]);
  const [documentTypes, setDocumentTypes] = useState<string[]>([]);
  const [maxIndex, setMaxIndex] = useState<number>(itemPerBatch);
  const [filters, setFilters] = useState<DelegantDocumentFilter>(
    { types: [], notaireDelegants: [] },
  );
  const { openErrorModal } = useContext(ModalContext);
  const { showLoading, closeLoading } = useContext(LoadingContext);

  const handleNotaireDelegants = (notaireDelegantsArray: SelectedNotaire[]) => {
    setSelectedPersonas(notaireDelegantsArray
      .map((notaire: SelectedNotaire) => {
        // Disable conjoint buttons if they don't consent to share their info
        if (notaire.conjointCollaborateur && !notaire.conjointCollaborateur.consent) {
          return {
            ...notaire,
            conjointCollaborateur: { ...notaire.conjointCollaborateur, disabled: true },
          };
        }
        return { ...notaire, filterActive: false };
      }));
  };

  const appendOrRemoveFilter = (
    value: string, filterListToUpdate: string[],
  ) => {
    if (filterListToUpdate.includes(value)) {
      const filterIndex = filterListToUpdate.indexOf(value);
      if (filterIndex > -1) {
        filterListToUpdate.splice(filterIndex, 1);
      }
    } else {
      filterListToUpdate.push(value);
    }
    return filterListToUpdate;
  };

  const updateFilters = (value: string, type: string, isConjoint?: boolean) => {
    const newFilter = { ...filters };
    // A persona was clicked
    if (type === filterTypes.notaireDelegant) {
      const delegantsArray = selectedPersonas.map((notaire: SelectedNotaire) => {
        // The selected persona is a conjoint collaborateur
        if (isConjoint
            && notaire.conjointCollaborateur
            && notaire.conjointCollaborateur.numpers === value
        ) {
          return {
            ...notaire,
            conjointCollaborateur: {
              ...notaire.conjointCollaborateur,
              filterActive: !notaire.conjointCollaborateur.filterActive,
            },
          };
        }
        // The selected persona is a notaire
        if (!isConjoint && notaire.numpers === value) {
          return { ...notaire, filterActive: !notaire.filterActive };
        }

        return notaire;
      });
      setSelectedPersonas(delegantsArray);
      newFilter.notaireDelegants = appendOrRemoveFilter(value, [...newFilter.notaireDelegants]);
    } else {
      newFilter.types = appendOrRemoveFilter(value, [...newFilter.types]);
    }
    setMaxIndex(itemPerBatch);
    setFilters(newFilter);
  };

  const getDocumentTypes = (delegantDocuments: DelegantDocument[]) => {
    const types = new Set(delegantDocuments
      .map((document: DelegantDocument) => document.typeDocument));
    setDocumentTypes([...types]);
  };

  const showMore = (currentIndex: Number) => {
    const newIndex = currentIndex + itemPerBatch;
    if (currentIndex > selectedPersonas.length) {
      setMaxIndex(newIndex);
    }
  };

  const getDocumentFiltered = (delegantDocuments: DelegantDocument[]) => delegantDocuments
    .filter((document: DelegantDocument) => {
      // Si pas de filtres séléctionné : tout retourner
      // sinon filtrer sur les filtres sélectionnés
      if (filters.types.length > 0 && !filters.types.includes(document.typeDocument)) {
        return false;
      }
      return !(filters.notaireDelegants.length > 0
          && !filters.notaireDelegants.includes(document.numpersDelegant));
    });

  useEffect(() => {
    showLoading(loadingMessage, timeoutDuration);
    if (!notaireDelegantsData) {
      tierApi
        .getNotaireDelegants()
        .then((response: Response) => response.json())
        .then((delegantInfos: DelegantsInfos) => {
          dispatch(storeUserNotairesDelegantsData(delegantInfos));
          handleNotaireDelegants(delegantInfos.selectedNotaires);
          getDocumentTypes(delegantInfos.documentDelegants);
          setDocuments(delegantInfos.documentDelegants);
        })
        .catch(extractErrorFromSigma(openErrorModal))
        .finally(closeLoading);
    } else {
      handleNotaireDelegants(notaireDelegantsData.notaireDelegants);
      getDocumentTypes(notaireDelegantsData.documentDelegants);
      setDocuments(notaireDelegantsData.documentDelegants);
    }
  }, [closeLoading, dispatch, loadingMessage,
    notaireDelegantsData, openErrorModal, showLoading, timeoutDuration]);

  return (
    <div className="page-container mb-2">
      <Title className="m-auto" message={I18n.t('files.title')} color="blue" />
      <Back
        className="mb-2"
        onClick={() => history.push(HOME_PAGE_ROUTE)}
        message={I18n.t('actions.BACK')}
      />
      <Container maxWidth="md" className="mt-2 tier-documents">
        <div className="tier-documents-title">
          {selectedPersonas.length === uniqueDelegant
              ? (
                <h2 className="position">{I18n.t('files.delegateDocuments',
                  { nom: selectedPersonas[0].nom, prenom: selectedPersonas[0].prenom })}
                </h2>
              )
              : (<h2 className="position">{I18n.t('files.delegatesDocuments')}</h2>)
          }
          <div className="tier-documents-title-background position" />
        </div>
        <div className="tier-documents-content">
          {selectedPersonas.length > uniqueDelegant &&
            <NotairesFilter
              selectedPersonas={selectedPersonas}
              onClickNotaire={
              (persona: SelectedNotaire | ConjointCollaborateur, isConjoint: boolean) =>
                updateFilters(persona.numpers, filterTypes.notaireDelegant, isConjoint)
              }
              isGreyDiv
            />
          }
          <div className="files-wrapper">
            <div className="files-filter">
              <h2 className="files-filter-title text-uppercase">{I18n.t('files.tier.filter')}</h2>
              <h3 className="files-filter-subtitle">{I18n.t('files.tier.documents')}</h3>
              <div>
                {documentTypes.map((documentType: string) => (
                  <div key={documentType} className="filter-document">
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() =>
                              updateFilters(documentType, filterTypes.docType, undefined)}
                        />
                        }
                      label={docTypesName[documentType]}
                    />
                  </div>
                  ))}
              </div>
            </div>
            <div className="column-wrapper">
              <div className="files">
                {getDocumentFiltered(documents).length === 0
                ? (documents.length > 0 &&
                  <div className="no-documents">
                    {I18n.t('files.tier.noAvailableDocuments')}
                  </div>
                  )
                : (documents.length > 0 &&
                  getDocumentFiltered(documents)
                    .slice(0, maxIndex)
                    .map((document: DelegantDocument) =>
                      (
                        <FileItem
                          key={document.idGED}
                          displayName={selectedPersonas.length > 1}
                          document={document}
                        />
                      ))
                  )
                }
              </div>
              {
                getDocumentFiltered(documents).length > maxIndex &&
                <div className="show-more">
                  <button onClick={() => showMore(maxIndex)}>{I18n.t('files.tier.showMore')}</button>
                </div>
              }
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default connect(state => ({
  notaireDelegantsData: state.user.notaireDelegantsData,
  status: state.user.user.statut,
}))(TierFilePage);

// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import ItemAccordion from '../../../../components/data/ItemAccordion';
import { DetailRegimeBase, RegimeBaseAnnee } from '../../../../types/types';
import { displayCotisationValue } from '../../../../utils/utils';

type Props = {|
    data: RegimeBaseAnnee[],
|}

const RegimeBase = ({ data }: Props) => (
  <div className="regime regime-base">
    <div className="regime-header">
      <span className="header-item">{I18n.t('personalData.cotisationData.year')}</span>
      <span className="header-item">{I18n.t('personalData.cotisationData.income')}</span>
      <span className="header-item">{I18n.t('personalData.cotisationData.incomeYear')}</span>
    </div>
    <div className="regime-content">
      {data && data.map((year: RegimeBaseAnnee) => (year.erreur
        ? (<div key={year.annee} className="ligne-erreur">{I18n.t('personalData.cotisationData.erreur')}</div>)
        : (
          <ItemAccordion
            key={year.annee}
            headerData={[year.annee, displayCotisationValue(year.revenus, true), year.anneeRevenus]}
          >
            <div className="subheader">
              <span className="item">{I18n.t('personalData.cotisationData.tranche')}</span>
              <span className="item">{I18n.t('personalData.cotisationData.assiette')}</span>
              <span className="item motif">{I18n.t('personalData.cotisationData.motif')}</span>
              <span className="item">{I18n.t('personalData.cotisationData.rate')}</span>
              <span className="item">{I18n.t('personalData.cotisationData.cotisationAnnuelle')}</span>
            </div>
            {year.detail && year.detail.map((ligne: DetailRegimeBase) =>
                (
                  <div key={ligne.taux} className="ligne">
                    <span className="item number">{displayCotisationValue(ligne.taux)}</span>
                    <span className="item number">{displayCotisationValue(ligne.assiette, true)}</span>
                    <span className="item number motif">{displayCotisationValue(ligne.motif)}</span>
                    {/* TODO La donnée taux devrait être un ratio entre 0 et 1 (précisé dans le backlog #284) */}
                    <span className="item number">{I18n.t('personalData.cotisationData.rate_value', { taux: ligne.tx })}</span>
                    <span className="item number">{displayCotisationValue(ligne.cotisation, true)}</span>
                  </div>
                ))
            }
          </ItemAccordion>
        )
      ))}
    </div>
  </div>
);

export default RegimeBase;

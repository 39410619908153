// @flow

import React from "react";

import { I18n } from "react-redux-i18n";
import { Form, FormSpy } from "react-final-form";

import CustomButton from "../../../components/buttons/CustomButton";
import type { TypeEmail } from "../../../types/types";
import EmailSelect from "./components/EmailSelect";
import { formTranslationType, statutType } from "../../../utils/const";
import EmailOther from "./components/EmailOther";
import ConfidentialityCheckbox from "./components/ConfidentialityCheckbox";
import { requiredEmailValidator } from "../../../utils/validator";

type Props = {|
  emails: TypeEmail[],
  onSubmit: Function,
  statut: string
|};

const FormStep2 = ({ emails, onSubmit, statut }: Props) => {
  const initialValues = {
    email: (emails && emails.length > 0 && emails[0].email) || "other",
    otherEmail: "",
    confidentiality: false,
    dematerialisation: "true",
  };

  const validate = (
    values: Object,
  ) => {
    const errors = {};
    if (values.email === "other" && values.otherEmail && requiredEmailValidator(values.otherEmail)) {
      errors.otherEmail = requiredEmailValidator(values.otherEmail);
    }
    return errors;
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      render={({
          handleSubmit,
          invalid,
      }) => (
        <div className="form mt-1 size-3 size-1">
          <form onSubmit={handleSubmit}>
            <FormSpy subscription={{ values: true }}>
              {({ values }: any) => (
                <>
                  <div className="row">
                    <div className="mb-1 size-1">
                      <strong className="subtitle">
                        {I18n.t("parametrerProfil.form.chooseEmail.label")}
                      </strong>
                    </div>
                    <div className="size-1">
                      <EmailSelect emails={emails} />
                      <EmailOther values={values} />
                    </div>
                    {statut !== statutType.TIER && (
                      <>
                        <div className="mb-1 size-1">
                          <strong className="subtitle">
                            {I18n.t("parametrerProfil.form.dematerialiser.label")}
                          </strong>
                        </div>
                        <div className="mb-2 size-1">
                          <p className="description">
                            {I18n.t("parametrerProfil.form.dematerialisationDescription.label")}
                          </p>
                        </div>
                        <div className="mb-1 size-1">
                          <strong className="subtitle">
                            {I18n.t("parametrerProfil.form.confidentiality.label")}
                          </strong>
                        </div>
                        <div className="mb-2 size-1">
                          <p className="description">
                            {I18n.t("parametrerProfil.form.confidentialityDescription1.label")}
                          </p>
                        </div>
                        <div className="mb-2 size-1">
                          <p className="description">
                            {I18n.t("parametrerProfil.form.confidentialityDescription2.label")}
                          </p>
                        </div>
                        <div className="mb-1 size-1">
                          <ConfidentialityCheckbox />
                        </div>
                        <div className="mb-1 size-1">
                          <p className="description">
                            {I18n.t("parametrerProfil.form.confidentialityDescription3.label")}
                          </p>
                        </div>
                        <div className="mb-3 size-1">
                          <p className="description">
                            {I18n.t("parametrerProfil.form.confidentialityDescription4.label")}
                          </p>
                        </div>
                      </>
                    )}
                    <div className="mb-1 size-1">
                      <div className="asterisk legend">
                        <span>{I18n.t("form.required")}</span>
                      </div>
                    </div>
                  </div>
                  <CustomButton
                    type="submit"
                    message={I18n.t(`${formTranslationType.parametrer}button`)}
                    disabled={invalid}
                  />
                </>
              )}
            </FormSpy>
          </form>
        </div>
      )}
    />
  );
};

export default FormStep2;

// @flow

// Le système de routing actuel ne gère pas les cas d'urls incluant des paramètre
// ex : /fiches/:id
// ceci est du à la vérification effectué dans le composant PrivateRoute.jsx (hasRoutingRights())
// Si ce type d'url apparait sur ce projet, il faudra probablement gérer les droits de routing
// directement dans les pages

import {
  CERTIFICATE_RETIREMENT_ROUTE,
  CONTRIBUTOR_CERTIFICATION_ROUTE,
  COTISATION_DATA_ROUTE,
  DASHBOARD_ROUTE,
  DELEGATION_HOME_ROUTE,
  FAMILY_DATA_ROUTE,
  FILE_DETAIL_ROUTE,
  FILES_ROUTE,
  MA_CARRIERE_ROUTE,
  INTER_REGIME_ROUTE,
  PACK_FISCAL_ROUTE,
  PACK_PAYMENT_ROUTE,
  PERSONAL_DATA_HOME_ROUTE,
  PERSONAL_DATA_ROUTE,
  PROFESSIONAL_DATA_ROUTE,
  RECIPIENT_INFO_ROUTE,
  REQUEST_ROUTE,
  RETREAT_DATA_ROUTE,
  RETREAT_REQUEST_ROUTE,
  SERVICE_HOME_ROUTE,
  TIER_FILES_ROUTE,
  TIER_PROFESSIONAL_DATA_ROUTE,
} from './routes';

export const ROUTING_RIGHTS = {
  ACTIF: [
    DASHBOARD_ROUTE,
    // donnees
    PERSONAL_DATA_HOME_ROUTE, PERSONAL_DATA_ROUTE,
    PROFESSIONAL_DATA_ROUTE, FAMILY_DATA_ROUTE, COTISATION_DATA_ROUTE,
    // services
    SERVICE_HOME_ROUTE,
    // FIXME : A décommenter début 2024, une fois le service mis à disposition
    // RETREAT_ESTIMATION_ROUTE,
    RETREAT_REQUEST_ROUTE, REQUEST_ROUTE, CONTRIBUTOR_CERTIFICATION_ROUTE,
    INTER_REGIME_ROUTE, MA_CARRIERE_ROUTE,
    // documents
    FILES_ROUTE, FILE_DETAIL_ROUTE,
    // delegation
    DELEGATION_HOME_ROUTE,
  ],
  RETRAITE: [
    DASHBOARD_ROUTE,
    // donnees
    PERSONAL_DATA_HOME_ROUTE, PERSONAL_DATA_ROUTE, FAMILY_DATA_ROUTE, RETREAT_DATA_ROUTE,
    // services
    SERVICE_HOME_ROUTE, PACK_PAYMENT_ROUTE, PACK_FISCAL_ROUTE,
    CERTIFICATE_RETIREMENT_ROUTE, CONTRIBUTOR_CERTIFICATION_ROUTE, RECIPIENT_INFO_ROUTE,
    // documents
    FILES_ROUTE, FILE_DETAIL_ROUTE,
  ],
  HORS_EXERCICE: [
    DASHBOARD_ROUTE,
    // donnees
    PERSONAL_DATA_HOME_ROUTE, PERSONAL_DATA_ROUTE, FAMILY_DATA_ROUTE, PROFESSIONAL_DATA_ROUTE,
    // services
    SERVICE_HOME_ROUTE,
    // FIXME : A décommenter début 2024, une fois le service mis à disposition
    // RETREAT_ESTIMATION_ROUTE,
    RETREAT_REQUEST_ROUTE, REQUEST_ROUTE, CONTRIBUTOR_CERTIFICATION_ROUTE,
    INTER_REGIME_ROUTE, MA_CARRIERE_ROUTE,
    // documents
    FILES_ROUTE, FILE_DETAIL_ROUTE,
  ],
  BENEFICIAIRE: [
    DASHBOARD_ROUTE,
    // donnees
    PERSONAL_DATA_HOME_ROUTE, PERSONAL_DATA_ROUTE, FAMILY_DATA_ROUTE, RETREAT_DATA_ROUTE,
    // services
    SERVICE_HOME_ROUTE, PACK_PAYMENT_ROUTE, PACK_FISCAL_ROUTE,
    CERTIFICATE_RETIREMENT_ROUTE, CONTRIBUTOR_CERTIFICATION_ROUTE, RECIPIENT_INFO_ROUTE,
    // documents
    FILES_ROUTE, FILE_DETAIL_ROUTE,
  ],
  TIERS_DE_CONFIANCE: [DASHBOARD_ROUTE, PERSONAL_DATA_HOME_ROUTE,
    TIER_PROFESSIONAL_DATA_ROUTE, COTISATION_DATA_ROUTE, TIER_FILES_ROUTE],
};

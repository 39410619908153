// @flow
import React, { memo, useContext } from 'react';
import { I18n } from 'react-redux-i18n';
import documentApi from '../../network/api/documentApi';
import demandesApi from '../../network/api/demandesApi';
import { download } from '../../utils/utils';
import { extractErrorFromSigma } from '../../utils/modal';
import LoadingContext from '../../context/loader/LoadingContext';
import ModalContext from '../../context/modal/ModalContext';
import type { TypeDocument } from '../../types/types';

type Props = {|
  file: TypeDocument,
|};

const File = ({ file }: Props) => {
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const { openErrorModal } = useContext(ModalContext);

  const {
    idGED, label, etat, subtitle, gipWs,
  } = file;

  const onFileClick = () => {
    showLoading();
    if (gipWs === true) {
      demandesApi
        .downloadDocument(idGED)
        .then((response: Response) => response.blob())
        .then((blob: Blob) => download(blob, label))
        .catch(extractErrorFromSigma(openErrorModal))
        .finally(closeLoading);
    } else {
      documentApi
        .downloadDocument(idGED)
        .then((response: Response) => response.blob())
        .then((blob: Blob) => download(blob, label))
        .catch(extractErrorFromSigma(openErrorModal))
        .finally(closeLoading);
    }
  };

  return (
    <div className="file">
      <div>
        <div>{label}</div>
        <sub>{subtitle}</sub>
      </div>
      {/* This component is used in the Document page with non "etat" */}
      {/* and in the différent services pagies with an etat */}
      {/* TODO: revoir l'utilisation de la propriete 'etat'  */}
      { etat && etat !== 'TRAITEE' ?
        <div className="status">
          {I18n.t(`service.requestMonitoring.status.${etat}`)}
        </div> :
        <div onClick={onFileClick} className="icon fa fa-arrow-alt-to-bottom" />
      }
    </div>
  );
};

export default memo<Props>(File);

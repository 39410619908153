// @flow

import React, { useState } from "react";
import { connect } from 'react-redux';
import { I18n } from "react-redux-i18n";
import { Delegate } from '../../types/types';
import { List } from '@material-ui/core';
import { delegateType } from '../../utils/enum';
import DelegateItem from './DelegateItem';
import Pagination from '../../components/pagination/Pagination';

type Props = {|
  title: String,
  delegations: List<Object>,
  type: String,
  delegateNumber: String,
|};

const DelegationsList = ({ title, delegations, type, delegateNumber }: Props) => {

  return (
    <div className="delegation-list-wrapper">
      <div className="delegation-list-header">
        <h4 className="text-uppercase fs-16">{ title } :</h4>
        { type === delegateType.DELEGATE &&
          <div className="fs-16">{I18n.t('delegation.delegationForm.grantedDelegations', {number: delegateNumber})}</div>
        }
        { type === delegateType.COLLABORATOR && delegateNumber === 2 &&
          <div className="fs-16">{I18n.t('delegation.delegationForm.maxDelegations')}</div>
        }
        { type === delegateType.COLLABORATOR && delegateNumber < 2 &&
          (<div className="fs-16">{I18n.t('delegation.delegationForm.availableDelegations', {number: delegateNumber === 1 ? 1 : 2})}</div>)
        }
      </div>
      <div className="delegation-list-header-content">
        { delegations.length === 0 && type === delegateType.DELEGATE &&
          <div className="message-empty-list fs-16">{I18n.t('delegation.delegationForm.noDelegations')}</div>
        }
        { delegations.length === 0 && type === delegateType.COLLABORATOR &&
          <div className="message-empty-list fs-16">{I18n.t('delegation.delegationForm.noPossibleDelegations')}</div>
        }
        <Pagination
          renderTile={(item: Delegate) => <DelegateItem key={item.uid} delegate={item} delegateNumber={delegateNumber} isDelegate={type === delegateType.DELEGATE} />}
          data={(delegations: Delegate[])}
          itemPerPage={20}
        />
      </div>
    </div>
  )
}

export default DelegationsList;
// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import { getArrayFromNumber } from '../../../utils/utils';
import CustomButton from '../../buttons/CustomButton';
import Modal from '../../modals/Modal';

type Props = {
  field: any,
  fields: any,
  translationBase: string,
  formGrid: any,
  renderField: any,
  values: any,
  push: Function,
  pop: Function,
  fieldName: any,
  index: any,
}

type States = {
  modal: boolean,
}

class FormChildren extends React.Component<Props, States> {
  state = {
    modal: false,
  };

  // [isShowing, toggle] = useState<boolean>(false);
  openModal = () => {
    this.setState({ modal: true });
  };
  closeModal = () => {
    this.setState({ modal: false });
  };

  render() {
    return (
      <div key={`${this.props.fieldName}_${this.props.index}`} className="children">
        <div className="text-container child-header">
          <h3>{I18n.t(`${this.props.translationBase}${this.props.field.name}.label`, {
            count: this.props.index + 1,
          })}
          </h3>
          {this.props.field.disabled === false ||
            (!this.props.field.disabled && (
              <div
                onClick={() => {
                  this.props.fields.remove(this.props.index);
                }}
                className="form-button cursor-pointer"
              >
                <i className="fa fa-trash" />
                <span>{I18n.t('actions.DELETE')}</span>
              </div>
            ))}
        </div>
        {getArrayFromNumber(this.props.formGrid.length).map(rowNumber => (
          <div key={`row_${rowNumber}`} className="row">
            {this.props.field.fields && this.props.field.fields
              .filter(f => f.row === rowNumber || !f.row)
              .map((subField, subIndex) => (
                <div
                  key={`field_${subIndex}`}
                  className={`${
                    subField.mb ? `mb-${subField.mb}` : ''
                  } size-${this.props.formGrid[rowNumber - 1]}`}
                >
                  {this.props.renderField(
                    subField,
                    this.props.values,
                    this.props.push,
                    this.props.pop,
                    this.props.fieldName,
                  )}
                </div>
              ))}
          </div>
        ))}
        {this.state.modal &&
          <Modal
            className="modal-confirmation"
            isOpen={this.state.modal}
            close={() => {
              this.closeModal();
            }}
            headerTitle={I18n.t('personalData.personalData.validationModal.title')}
            buttons={
              [
                <CustomButton
                  className="btn-confirmation"
                  message={I18n.t('actions.CONFIRM')}
                  onClick={() => {
                    this.props.fields.remove(this.props.index);
                    this.closeModal();
                  }}
                />,
                <CustomButton
                  className="btn-cancel"
                  message={I18n.t('actions.CANCEL')}
                  onClick={() => {
                    this.closeModal();
                  }}
                />,
              ]}
          >
            <div className="modal-body">
              {I18n.t('personalData.personalData.validationModal.body')}
            </div>
          </Modal>}
      </div>
    );
  }
}

export default withRouter(FormChildren);

// @flow

type ActionType = {
  type: string,
  value: any,
};

export const reduce = (initialState: any, actionMap: any) => (
  state: any = initialState,
  action: ActionType,
) => {
  if (actionMap[action.type]) {
    return {
      ...state,
      [actionMap[action.type]]: action.value,
    };
  }
  return {
    ...state,
  };
};
export const store = (action: string, value: any) => (dispatch: Function) => {
  dispatch({
    type: action,
    value,
  });
};

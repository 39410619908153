// @flow

import React, { useCallback, useState } from "react";
import LoadingContext from "./LoadingContext";
import Loading from "../../components/utils/Loader";

type Props = {
  children: any,
};

const LoadingProvider = ({ children }: Props) => {
  const defaultTimeOutDuration = 1000;
  const [loading, toggleLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [timeOutDuration, setTimeOutDuration] = useState<number>(defaultTimeOutDuration);

  const showLoading = useCallback((message?: string, timeOutDuration?: number) => {
    toggleLoading(true);
    setMessage(message || "");
    setTimeOutDuration(timeOutDuration || defaultTimeOutDuration)
  }, []);

  const closeLoading = useCallback(() => {
    toggleLoading(false);
    setMessage("");
    setTimeOutDuration(defaultTimeOutDuration)
  }, []);

  const loadingState = {
    loading,
    message,
    timeOutDuration,
    showLoading,
    closeLoading,
  };

  return (
    <LoadingContext.Provider value={loadingState}>
      <Loading />
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;

// @flow

import { RestClient } from '../network';

const baseUrl = '/conf';

const confApi = {
  fetchEnv: () => new RestClient(baseUrl, 'GET').execute(),
  fetchLogoutUrl: () => new RestClient(`${baseUrl}/logout-url`, 'GET').execute(),
  fetchGAUuid: () => new RestClient(`${baseUrl}/analytics`, 'GET').execute(),
};

export default confApi;

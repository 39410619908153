// @flow

import React, {memo, useEffect} from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  DASHBOARD_ROUTE, DELEGATION_HOME_ROUTE,
} from '../../utils/routes';
import DelegationForm from "./DelegationForm";
import {connect} from "react-redux";

const DelegationsRoutes = ({}) => {
  return (
    <div className="delegation-container">
      <Switch>
        <Route
          exact
          path={DELEGATION_HOME_ROUTE}
          component={DelegationForm}
        />
        <Route path="*" render={() => <Redirect to={DASHBOARD_ROUTE} />} />
      </Switch>
    </div>
  );
};

export default connect()(memo(DelegationsRoutes));

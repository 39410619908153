// @flow

import React, { memo, useCallback, useEffect, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { I18n } from 'react-redux-i18n';
import queryString from 'query-string';

import type { User } from '../../../types/userType';
import cartoucheApi from '../../../network/api/cartoucheApi';
import thSolid from '../../../img/cartouche/th-solid.svg';
import cartoucheHomme from '../../../img/cartouche/cartouche_homme.PNG';
import cartoucheFemme from '../../../img/cartouche/cartouche_femme.PNG';
import { SAML_LOGOUT_URL } from '../../../utils/const';
import enrolementApi from '../../../network/api/enrolementApi';
import confApi from '../../../network/api/confApi';

type Props = {|
  user: User,
  isConnected: boolean,
|};

type Cartouche = {
  avatar: string,
  civilite: string,
  urlProfile: string,
  urlNotaccess: string,
};

const initialState = {
  avatar: "",
  civilite: "",
  urlProfile: "",
  urlNotaccess: "",
};

const CartoucheIdNot = ({ user, isConnected }: Props) => {
  const [cartouche, setCartouche] = useState<Cartouche>(initialState);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNota, setAnchorElNota] = React.useState(null);

  function handleClose() {
    setAnchorEl(null);
    setAnchorElNota(null);
  }

  useEffect(() => {
    cartoucheApi
      .fetchCartouche(user.idn)
      .then(resp => resp.json())
      .then((c: Cartouche) => setCartouche(c));
  }, [user.idn]);

  const logout = useCallback(() => {
    if (isConnected) {
      confApi
        .fetchLogoutUrl()
        .then(res => res.text())
        .then(url => {
          sessionStorage.clear();
          window.location = url;
        });
    } else {
      const { token } = queryString.parse(location.search);
      enrolementApi
        .clearEnrolementToken(token)
        .then(() => {
          window.location = SAML_LOGOUT_URL;
        });
    }
  }, [isConnected]);

  const getCartouche = useCallback(() => {
    if (cartouche.avatar) {
      return cartouche.avatar;
    } else {
      return cartouche.civilite === "MONSIEUR"
        ? cartoucheHomme
        : cartoucheFemme;
    }
  }, [cartouche.avatar, cartouche.civilite]);

  return (
    <div className="cartouche-idnot">
      <div className="menu" onClick={e => setAnchorEl(e.currentTarget)}>
        <img className="avatar" alt="avatar" src={getCartouche()} />
        <div className="names">
          <span>{user.firstName}</span>
          <span>{user.lastName}</span>
        </div>
        <i className="fa fa-caret-down cartoucheIDNot-caret-style" />
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <a
            className="cartoucheIDNot-menu-account"
            href={cartouche.urlProfile}
            target="_blank"
            rel="noopener noreferrer"
          >
            {I18n.t("cartouche.account")}
          </a>
        </MenuItem>
        <MenuItem onClick={logout}>{I18n.t("cartouche.deconnexion")}</MenuItem>
      </Menu>
      <div
        className="notaccess"
        onClick={e => setAnchorElNota(e.currentTarget)}
      >
        <img alt="notaccess" src={thSolid} />
      </div>
      <Menu
        anchorEl={anchorElNota}
        keepMounted
        open={Boolean(anchorElNota)}
        onClose={handleClose}
      >
        <MenuItem>
          <div className="logo-notaccess notaccess" />
          <a
            className="cartoucheIDNot-menu-account"
            href={cartouche.urlNotaccess}
            target="_blank"
            rel="noopener noreferrer"
          >
            {I18n.t("cartouche.notaccess")}
          </a>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default memo<Props>(CartoucheIdNot);

// @flow

import React, { memo } from 'react';
import { I18n } from 'react-redux-i18n';
import type { TypeEmptyProps } from '../../types/types';

type Props = {
  children: React.ReactNode,
}

const HeaderDisconnected = ({ children }: Props) => (
  <header
    className="disconnected-header"
  >
    <div>
      <div className="logo-container">
        <div className="logo" />
        <div className="line" />
      </div>
      <div
        className="title"
      >
        <h1>{I18n.t('header.portalTitle')}</h1>
        <h2>{I18n.t('header.portalSubtitle')}</h2>
      </div>
    </div>
    {children}
  </header>
);
export default memo<TypeEmptyProps>(HeaderDisconnected);

import { useCallback, useState } from 'react';

const useToggle = (defaultValue = false) => {
  const [open, setOpen] = useState(defaultValue);
  const handleState = useCallback(() => {
    setOpen((prev: boolean) => !prev);
  }, []);

  return [open, handleState];
};

export default useToggle;

// @flow

import React from 'react';

type Props = {
  environment: string,
}

const Watermark = ({ environment }: Props) => {
  if (environment === 'prod') {
    return <></>;
  }

  return (
    <div className="watermark">
      <span>Environnement de test</span>
    </div>
  );
};

export default Watermark;

// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import ItemAccordion from '../../../../../components/data/ItemAccordion';
import type { AnneeCotisation, LigneCotisation } from '../../../../../types/types';
import { displayCotisationValue } from '../../../../../utils/utils';

type Props = {|
  anneeCotisation: AnneeCotisation,
|}

const CotisationsData = ({ anneeCotisation }: Props) => {
  const headerData = [anneeCotisation.annee];

  return (
    <>
      <ItemAccordion
        key={anneeCotisation.annee}
        headerData={headerData}
      >
        {anneeCotisation.cotisationList && anneeCotisation.cotisationList
            .filter((ligneCotisation: LigneCotisation) => ligneCotisation.regimeCotisation
                && ligneCotisation.cotisation)
            .map((ligneCotisation: LigneCotisation) => (
              <div key={ligneCotisation.regimeCotisation} className="ligne">
                <span className="item regime blue-cobalt">
                  {I18n.t(`personalData.cotisationData.conjointCollaborateur.cotisations.regime.${
                          ligneCotisation.regimeCotisation}`)}
                </span>
                <span className="item number">{displayCotisationValue(ligneCotisation.cotisation, true)}</span>
              </div>
            ))
        }
      </ItemAccordion>
    </>
  );
};

export default CotisationsData;
